import { useState, useEffect } from 'react'
import { usePaginatedFetch } from '../../hooks'

const useGetUsers = () => {
  const url = 'v1/users'
  const [users, setUsers] = useState()
  let { data, ...rest } = usePaginatedFetch(url, 'Unable to fetch client data')

  useEffect(() => {
    setUsers(data)
  }, [data])

  return { ...rest, data: users, setUsers }
}

export default useGetUsers
