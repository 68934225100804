import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'

import useGetActivity from './useGetActivity'
import {
  Loader,
  TabContent,
  MediaActivityCard,
  NoteActivityCard,
  DiaryActivityCard,
  LoadingMore,
} from '../../../ui'
import { AuthContext } from '../../../../context/auth'

const Activity = ({ id }) => {
  const auth = React.useContext(AuthContext)
  const { data, isFetching, fetchMore, isFetchingMore } = useGetActivity(id)

  return (
    <>
      <Loader isFetching={isFetching}>
        <TabContent>
          {data && data.length > 0 ? (
            <InfiniteScroll
              dataLength={data.length}
              next={fetchMore}
              hasMore={!!fetchMore}
            >
              {console.log(data)}
              {data.map(activity =>
                activity.model_key === 'note' ? (
                  <NoteActivityCard key={activity.id} note={activity} />
                ) : activity.model_key === 'diary_note' ? (
                  <DiaryActivityCard
                    key={activity.id}
                    note={activity}
                    user={auth && auth.user ? auth.user : null}
                  />
                ) : activity.model_key === 'media' ? (
                  <MediaActivityCard key={activity.id} file={activity} />
                ) : null,
              )}
              {fetchMore ? (
                isFetchingMore ? (
                  <LoadingMore>Loading...</LoadingMore>
                ) : (
                  <LoadingMore>Scroll to load more</LoadingMore>
                )
              ) : null}
            </InfiniteScroll>
          ) : (
            <LoadingMore>No activity to show</LoadingMore>
          )}
        </TabContent>
      </Loader>
    </>
  )
}

Activity.defaultProps = {
  id: '',
}
Activity.propTypes = {
  id: PropTypes.string,
}
export default Activity
