import React from 'react'

import { UserList } from './userList'
import FilterView from './filterManagement'
import { AuthContext } from '../../context/auth'
import { navigate } from '@reach/router'

const AdminView = () => {
  const auth = React.useContext(AuthContext)

  React.useEffect(() => {
    if (auth && auth.user && !auth.user.admin) {
      navigate('/')
    }
  }, [auth])
  return (
    <>
      <FilterView />
      <UserList />
    </>
  )
}

export default AdminView
