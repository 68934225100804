import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import _ from 'lodash'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import useGetBranchDetails from './useGetBranchDetails'
import {
  Button,
  DeleteButton,
  PaddedDivider,
  ListHeader,
  Table,
} from '../../ui'
import { FormikInput, Form, Error } from '../../form'
import { handleBackEndErrors } from '../../../utilities'
import Layout from '../../layout'
import { AddConsultModal } from './addConsult'
import { navigate } from '@reach/router'
import { RemoveConsultant } from './delete'
import { AuthContext } from '../../../context/auth'

const FlexHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${p => p.theme.layout.phone_medium} {
    justify-content: flex-end;
  }
`

const STR = styled.tr`
  display: grid;
  width: 100%;
  ${p => p.theme.layout.phone_medium} {
    display: table-row;
  }
`

const RButton = styled(Button)`
  margin: 0 0 2rem 0;
`

const STable = styled(Table)`
  tbody {
    td {
      padding: 15px 20px 15px 20px;
    }
  }
`

export const ViewBranch = ({ id }) => {
  const { addToast } = useToasts()
  const [editName, setEditName] = React.useState(false)
  const [addModal, setAddModal] = React.useState(false)
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [isConsult, setIsConsult] = React.useState(false)
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const [selectedUser, setSelectedUser] = React.useState({})
  const { details, isFetching, updateBranch } = useGetBranchDetails(id)
  const auth = React.useContext(AuthContext)

  React.useEffect(() => {
    if (!auth?.user?.admin && auth?.user?.branch_id.toString() !== id) {
      navigate('/')
    }
  }, [auth, id])

  const openDeleteModal = (user, type) => {
    setSelectedUser(user)
    if (type === 'consult') setIsConsult(true)
    setDeleteModal(true)
  }

  const closeDeleteModal = () => {
    setDeleteModal(false)
    setIsConsult(false)
    setSelectedUser({})
  }

  const openAddModal = (user, type) => {
    setSelectedUser(user)
    if (type === 'consult') setIsConsult(true)
    setAddModal(true)
  }

  const closeAddModal = () => {
    setAddModal(false)
    setIsConsult(false)
    setSelectedUser({})
  }

  const onSubmit = async (values, { setSubmitting }) => {
    axios({
      method: 'post',
      url: `v1/branches/update/${id}`,
      data: values,
    })
      .then(response => {
        addToast(`Branch updated successfully`, {
          appearance: 'success',
        })
        updateBranch({ ...details, name: values.name })
        setEditName(false)
      })
      .catch(error => {
        setBackEndErrors(error?.response?.data?.error)
        addToast(`Error updating branch, please try again`, {
          appearance: 'error',
        })
      })
    setSubmitting(false)
  }
  return (
    <>
      <Layout isFetching={isFetching}>
        {auth?.user?.admin && (
          <FlexHead>
            <RButton
              onClick={() =>
                addModal ? setAddModal(false) : navigate('/branches')
              }
            >
              Go Back
            </RButton>
          </FlexHead>
        )}
        {editName ? (
          <Formik
            initialValues={{
              name: details?.name ? details.name : '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Required'),
            })}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <FlexHead>
                  <FormikInput label="Branch Name" name="name" />
                  {_.isEmpty(backEndErrors) ? (
                    ''
                  ) : (
                    <Error>{handleBackEndErrors(backEndErrors)}</Error>
                  )}
                  <div>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      style={{ marginRight: '10px' }}
                    >
                      Update
                    </Button>
                    <DeleteButton
                      type="button"
                      onClick={() => setEditName(false)}
                    >
                      Close
                    </DeleteButton>
                  </div>
                </FlexHead>
              </Form>
            )}
          </Formik>
        ) : (
          <ListHeader>
            <h2>{details?.name}</h2>
            <div></div>
            {auth?.user?.admin && (
              <Button onClick={() => setEditName(true)}>Edit Name</Button>
            )}
          </ListHeader>
        )}
        {addModal ? (
          <AddConsultModal
            isVisible={addModal}
            onClose={() => closeAddModal(false)}
            addUser={updateBranch}
            branch={details}
            isConsult={isConsult}
            currentUsers={details.consultants}
          />
        ) : (
          <>
            <PaddedDivider />
            <ListHeader>
              <h3>Managers</h3>
              <div></div>
              {auth?.user?.admin && (
                <Button onClick={() => openAddModal(true)}>Add Manager</Button>
              )}
            </ListHeader>
            {details?.consultants?.length > 0 && (
              <div>
                <STable>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th data-tablet>Email</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {details.consultants
                      .filter(consult => consult.branch_manager === 1)
                      .map(consult => (
                        <STR key={consult.id}>
                          <td>{consult.name}</td>
                          <td data-tablet>{consult.email}</td>
                          <td>
                            <ButtonContainer>
                              {auth?.user?.admin && (
                                <DeleteButton
                                  onClick={() => openDeleteModal(consult)}
                                >
                                  Remove
                                </DeleteButton>
                              )}
                            </ButtonContainer>
                          </td>
                        </STR>
                      ))}
                  </tbody>
                </STable>
              </div>
            )}

            <PaddedDivider />
            <ListHeader>
              <h3>Consultants</h3>
              <div></div>
              <Button onClick={() => openAddModal(true, 'consult')}>
                Add Consultant
              </Button>
            </ListHeader>
            {details?.consultants?.length > 0 && (
              <Table>
                <thead>
                  <tr>
                    <th data-tablet>Name</th>
                    <th>Email</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {details.consultants
                    .filter(consult => consult.branch_manager !== 1)
                    .map(consult => (
                      <STR key={consult.id}>
                        <td data-tablet>{consult.name}</td>
                        <td>{consult.email}</td>
                        <td>
                          <ButtonContainer>
                            <DeleteButton
                              onClick={() =>
                                openDeleteModal(consult, 'consult')
                              }
                            >
                              Remove
                            </DeleteButton>
                          </ButtonContainer>
                        </td>
                      </STR>
                    ))}
                </tbody>
              </Table>
            )}
          </>
        )}
      </Layout>
      {deleteModal && (
        <RemoveConsultant
          id={details.id}
          user={selectedUser}
          branch={details}
          isVisible={deleteModal}
          setModalVisibility={() => closeDeleteModal()}
          isConsult={isConsult}
          removeUser={updateBranch}
        />
      )}
    </>
  )
}

ViewBranch.defaultProps = {
  id: '',
}

ViewBranch.propTypes = {
  id: PropTypes.string,
}
