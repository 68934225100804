import styled from 'styled-components'
import { Box } from '../../ui'
import { Select } from '../../form'

export const JobDetailsHeader = styled.div`
  margin-bottom: 1rem;

  ${p => p.theme.layout.tablet} {
    margin-bottom: 1.5rem;
  }

  ${p => p.theme.layout.web} {
    margin-bottom: 2rem;
  }
`

export const JobBoxHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;
  ${p => p.theme.layout.phone_large} {
    grid-template-columns: 1fr 1.875rem 1.875rem;
  }
`

export const SubTitle = styled.h1`
  margin: 0;
  line-height: 1.875rem;
  font-size: 1.5rem;
  font-weight: 400;
`

export const JobDetailsGrid = styled.section`
  display: grid;
  gap: 0.5rem 1.5rem;

  ${p => p.theme.layout.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  ${p => p.theme.layout.web} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`
export const JobBox = styled(Box)`
  display: grid;
  gap: 0.75rem;
  align-items: start;

  ${p => p.theme.layout.tablet} {
    padding: 2rem;
  }

  h2 {
    margin-bottom: 0.75rem;
  }
`

export const CandidateWrapper = styled.section`
  display: grid;
  gap: 1.5rem 1.25rem;
  margin: 1.5rem 0;
  position: relative;

  ${p => p.theme.layout.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  ${p => p.theme.layout.web} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const Overlay = styled.span`
  display: block;
  position: absolute;
  background-color: white;
  opacity: 0.8;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
`

export const ButtonList = styled.section`
  button {
    margin: 0.5rem;
  }
`

export const ShortBlock = styled.div`
  display: flex;
  flex-direction: column;
`

export const ISelect = styled(Select)`
  width: 90%;
  margin: auto auto 10px;
  .react-select_menu {
    z-index: 12;
  }
`
