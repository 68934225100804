import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar.svg'

import { Label, Error, InputWrapper } from '..'

export const SDatePicker = styled(DatePicker)`
  font-size: 1rem;
  font-weight: 400;
  height: 48px;
  width: 100%;
  padding: 0 1rem;
  border: 1px solid ${p => p.theme.color.border};
  appearance: none;
  outline: none;
  position: relative;

  &:focus {
    border-color: ${p => p.theme.color.primary};
  }

  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      div {
        .react-datepicker {
          .react-datepicker__month-container {
            .react-datepicker__header {
              background-color: ${p => p.theme.color.primary};
            }
          }
        }
      }
    }
  }

  .react-datepicker__header {
    background-color: ${p => p.theme.color.primary};
  }
  .react-datepicker-popper {
    z-index: 50 !important;
  }
`

export const SCalendarIcon = styled(CalendarIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 25px;
  width: 25px;
  fill: ${p => p.theme.color.primary};
  ${p => p.theme.layout.tablet} {
    right: 0;
    margin-left: 1.5rem;
    margin-right: 1rem;
  }
`

export const Datepicker = ({ className, label, name, errorText, ...props }) => {
  return (
    <div className={className}>
      <Label htmlFor={name}>{label}</Label>
      <InputWrapper hasError={!!errorText}>
        <SDatePicker
          {...props}
          id={name}
          calendarClassName="pinpoint-calendar"
          dateFormat="dd/MM/yyyy"
        />
        <SCalendarIcon />
      </InputWrapper>
      <Error>{errorText}</Error>
    </div>
  )
}

Datepicker.defaultProps = {
  errorText: null,
  className: '',
}

Datepicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  className: PropTypes.string,
}
