import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import {
  Modal,
  ModalFooter,
  ModalColumns,
  Button,
  PaddedBottomDivider,
  Label,
  Divider,
} from '../../../ui'
import {
  Form,
  FormikFileInput,
  FormikInput,
  TagSelector,
  Error,
  FormikSelect,
  FormikCheckbox,
  FormikEditor,
} from '../../../form'
import { dateRegex, SIndustry, SRegions } from '../../../../constants'
import { handleBackEndErrors, transformTags } from '../../../../utilities'
import { useGetAllCandidateSkillTags } from '../../../../hooks'

export const EditJobModal = ({
  job,
  setJob,
  isVisible,
  setModalVisibility,
}) => {
  const { tags, isFetching } = useGetAllCandidateSkillTags()
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const [radioError, setRadioError] = React.useState(false)

  const onSubmit = async (values, { setSubmitting }) => {
    if (
      values.display_competitive_rates_website ===
      values.display_pay_rate_website
    ) {
      setRadioError(true)
      return
    } else {
      setRadioError(false)
    }
    axios({
      method: 'post',
      url: '/v1/jobs/update',
      data: values,
    })
      .then(response => {
        setJob(response.data)
        setModalVisibility(false)
        addToast(`Job updated successfully`, {
          appearance: 'success',
        })
      })
      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
          addToast(`Error creating job, please try again`, {
            appearance: 'error',
          })
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error creating job, please try again`, {
            appearance: 'error',
          })
        }
      })

    setSubmitting(false)
  }
  return (
    <Modal
      isVisible={isVisible}
      heading="Update Job"
      onClose={() => setModalVisibility(false)}
      size="large"
    >
      <Formik
        initialValues={{
          job_id: job.id ? job.id : '',
          file: job.file ? job.file : '',
          title: job.title ? job.title : '',
          industry: job.industry ? job.industry : '',
          start_date: job.start_date ? job.start_date : '',
          end_date: job.end_date ? job.end_date : '',
          salary: job.salary ? job.salary : '',
          permanent_rate: job.permanent_rate ? job.permanent_rate : '',
          contract_rate: job.contract_rate ? job.contract_rate : '',
          hourly_rate_agreed: job.hourly_rate_agreed
            ? job.hourly_rate_agreed
            : 0,
          status: job.status ? job.status : '',
          type: job.type ? job.type : '',
          quantity: job.quantity ? job.quantity : '',
          tags: job.tags ? transformTags(job.tags) : [],
          description: job.description ? job.description : '',
          search_salary: job.search_salary ? job.search_salary : '',
          show_on_site: job.show_on_site ? job.show_on_site : 0,
          location: job.location ? job.location : '',
          display_competitive_rates_website: job.display_competitive_rates_website
            ? job.display_competitive_rates_website
            : 0,
          display_pay_rate_website: job.display_pay_rate_website
            ? job.display_pay_rate_website
            : 0,
          pay_rate: job.pay_rate ? job.pay_rate : '',
          region: job.region ? job.region : '',
          consultant_email: job.consultant_email ? job.consultant_email : '',
        }}
        validationSchema={Yup.object().shape({
          file: Yup.mixed(),
          title: Yup.string().required('Required'),
          industry: Yup.string(),
          start_date: Yup.string().matches(
            dateRegex,
            'Must be a valid date in the format dd/mm/yyyy',
          ),
          end_date: Yup.string().matches(
            dateRegex,
            'Must be a valid date in the format dd/mm/yyyy',
          ),
          salary: Yup.string().required('Required'),
          permanent_rate: Yup.string(),
          contract_rate: Yup.string(),
          hourly_rate_agreed: Yup.bool(),
          status: Yup.string(),
          type: Yup.string(),
          quantity: Yup.number().required('Required'),
          tags: Yup.array()
            .of(Yup.string())
            .min(1, 'Must have at least 1 tag')
            .required('Required'),
          description: Yup.string().required('Required'),
          search_salary: Yup.string().required('Required'),
          show_on_site: Yup.bool(),
          location: Yup.string().required('Required'),
          display_competitive_rates_website: Yup.bool(),
          display_pay_rate_website: Yup.bool(),
          pay_rate: Yup.string().required('Required'),
          region: Yup.string().required('Required'),
          consultant_email: Yup.string()
            .email('Must be a valid email')
            .required('Required'),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormikFileInput label="Attach a file" name="file" />
            <ModalColumns>
              <FormikInput label="Title" name="title" />
              <FormikSelect
                name="type"
                label="Job Type"
                placeholder="Set job type"
                options={[
                  {
                    value: 'Permanent',
                    label: 'Permanent',
                  },
                  {
                    value: 'Temp',
                    label: 'Temp',
                  },
                ]}
              />
              <FormikInput label="Start Date" name="start_date" />
              <FormikInput label="End Date" name="end_date" />
              <FormikSelect
                name="status"
                label="Job Status"
                placeholder="Set job status"
                options={[
                  {
                    value: 'Live',
                    label: 'Live',
                  },
                  {
                    value: 'Cancelled',
                    label: 'Cancelled',
                  },
                  {
                    value: 'Filled',
                    label: 'Filled',
                  },
                ]}
              />
              <FormikSelect
                name="industry"
                label="Industry"
                placeholder="Select Industry"
                options={SIndustry.map((ind, idx) => {
                  return { value: ind, label: ind }
                })}
              />
              <FormikInput label="Pay" name="salary" />
              <FormikInput label="Consultant Email" name="consultant_email" />
            </ModalColumns>
            <PaddedBottomDivider />
            <ModalColumns>
              <FormikInput label="Location" name="location" />
              <FormikInput label="Search Salary" name="search_salary" />
              <FormikSelect
                name="region"
                label="Region"
                placeholder="Set Region"
                options={SRegions.map((reg, idx) => {
                  return { value: reg, label: reg }
                })}
              />
              <FormikSelect
                name="pay_rate"
                label="Pay Rate"
                placeholder="Set Pay Rate"
                options={[
                  {
                    value: 'Per Day',
                    label: 'Per Day',
                  },
                  {
                    value: 'Per Annum',
                    label: 'Per Annum',
                  },
                  {
                    value: 'Per Hour',
                    label: 'Per Hour',
                  },
                ]}
              />
            </ModalColumns>
            <PaddedBottomDivider />
            <ModalColumns>
              <FormikInput label="Permanent Rate" name="permanent_rate" />
              <FormikInput label="Contract Rate" name="contract_rate" />
              <FormikInput
                label="Total Vacancies"
                name="quantity"
                placeholder="0"
              />
              <FormikCheckbox
                style={{ display: 'flex' }}
                name="hourly_rate_agreed"
                label="Hourly Rate agreed"
              />
            </ModalColumns>
            <PaddedBottomDivider />
            <TagSelector
              name="tags"
              label="Job skill tags"
              options={isFetching ? ['Loading...'] : tags}
            />
            <PaddedBottomDivider />
            <FormikEditor name="description" label="Description" />
            <PaddedBottomDivider />
            <Label label="">You must select one of the choices below</Label>
            <FormikCheckbox
              name="display_competitive_rates_website"
              label="Display 'Competitive Rates' on the Pin Point Recruitment website"
            />
            <FormikCheckbox
              name="display_pay_rate_website"
              label="Display the pay rate on the Pin Point Recruitment website"
            />
            {radioError && <Error>You must select one option</Error>}
            <Divider />
            <FormikCheckbox
              style={{ marginTop: '1rem' }}
              name="show_on_site"
              label="Do you want this job to appear on the Pin Point Recruitment website?"
            />
            <ModalFooter>
              {_.isEmpty(backEndErrors) ? (
                ''
              ) : (
                <Error>{handleBackEndErrors(backEndErrors)}</Error>
              )}
              <Button type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

EditJobModal.propTypes = {
  job: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  setJob: PropTypes.func.isRequired,
}
