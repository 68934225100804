import React from 'react'
import PropTypes from 'prop-types'

import { Card, CardGrid, IconButton, Label, TextLink } from '../..'
import { ActivityCardBody, ActivityCardFooter, CardType } from '../'
import { getShortDate, handleData } from '../../../../utilities'

import { ReactComponent as FileIcon } from '../../../../assets/icons/file.svg'

export const MediaActivityCard = ({ file, edit, deleteFile }) => (
  <Card mb="2rem">
    <CardGrid activityCard={true}>
      <CardType size="small">
        <FileIcon />
      </CardType>
      <ActivityCardBody>
        <h2>{handleData(file.title)}</h2>
        <Label label="File">
          {file && file.file && file.file.url && file.file.name ? (
            <TextLink
              href={file.file.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {file.file.original_name
                ? file.file.original_name
                : file.file.name}
            </TextLink>
          ) : (
            ''
          )}
        </Label>
        <Label label="Description">
          <p>{handleData(file.description)}</p>
        </Label>
      </ActivityCardBody>
      {edit && <IconButton onClick={() => edit(file)} />}
      {deleteFile && <IconButton icon="bin" onClick={() => deleteFile(file)} />}
    </CardGrid>
    <CardGrid activityCard={true}>
      <ActivityCardFooter>
        Last updated: {handleData(getShortDate(file.updated_at))}{' '}
        {file && file.user_name ? 'by ' + handleData(file.user_name) : ''}
      </ActivityCardFooter>
    </CardGrid>
  </Card>
)

MediaActivityCard.defaultProps = {
  edit: null,
  deleteFile: null,
}

MediaActivityCard.propTypes = {
  file: PropTypes.object.isRequired,
  edit: PropTypes.func,
  deleteFile: PropTypes.func,
}
