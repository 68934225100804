import React from 'react'
import { Router } from '@reach/router'

import UpdatePassword from './components/login/update'
import ForgotPassword from './components/login/forgot'
import Authenticating from './components/login/authenticating'
import Login from './components/login'
import ProtectedApp from './protected-app'
import { useAuth } from './hooks'

function App() {
  const { isAuthenticating, isAuthenticated } = useAuth()

  if (isAuthenticating) return <Authenticating />
  return (
    <div className="App">
      <Router>
        <Login default />
        {isAuthenticated && <ProtectedApp path="/*" />}
        <UpdatePassword path="/reset-password/:hash" />
        <ForgotPassword path="/forgot-password" />
      </Router>
    </div>
  )
}

export default App
