import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Activity from './activity'
import Details from './details'
import Jobs from './jobs'
import Layout from '../../layout'
import Media from './media'
import Notes from './notes'
import Workers from './workers'
import useGetClientDetails from './useGetClientDetails'
import { Tabs, ProfileLayout } from '../../ui'
import DetailsModal from './modal'

const TABS = [
  {
    name: 'Activity',
    path: `activity`,
    component: Activity,
    isDefault: true,
  },
  {
    name: 'Notes',
    path: `notes`,
    component: Notes,
  },
  {
    name: 'Attachments',
    path: `media`,
    component: Media,
  },
  {
    name: 'Job Activity',
    path: `jobs`,
    component: Jobs,
  },
  {
    name: 'Current Workers',
    path: `workers`,
    component: Workers,
  },
]

const Profile = ({ id }) => {
  const { details, isFetching, updateClient } = useGetClientDetails(id)
  const [modalVisible, setModalVisibility] = React.useState(false)

  return (
    <Layout isFetching={isFetching}>
      {!_.isEmpty(details) ? (
        <ProfileLayout>
          <Details {...details} setModalVisibility={setModalVisibility} />
          <Tabs tabs={TABS} basepath={`clients/${id}`} id={id} />
        </ProfileLayout>
      ) : (
        ''
      )}
      {modalVisible ? (
        <DetailsModal
          setParentState={updateClient}
          details={details}
          isVisible={modalVisible}
          setModalVisibility={setModalVisibility}
        />
      ) : (
        ''
      )}
    </Layout>
  )
}

Profile.defaultProps = {
  id: '',
}
Profile.propTypes = {
  id: PropTypes.string,
}
export default Profile
