import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import { LoadingIndicator } from '../ui'

const Container = styled.div`
  width: 100%;
  margin-bottom: ${p => p.theme.layout.nav_height_mobile};

  ${p => p.theme.layout.tablet} {
    margin-bottom: 0;
  }
`

const LoadingContainer = styled.div`
  position: absolute;
  right: 50%;
  bottom: 50%;
  padding: 1rem;
  background: white;
  transform: translate(50%);
  ${p => p.theme.style.box_shadow};
`

const Layout = ({ isFetching, children }) => {
  return (
    <Container>
      <CSSTransition
        in={isFetching}
        timeout={200}
        classNames="fade"
        unmountOnExit
      >
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      </CSSTransition>
      <CSSTransition in={!!children} timeout={200} classNames="fade">
        <div>{children}</div>
      </CSSTransition>
    </Container>
  )
}

Layout.defaultProps = {
  children: null,
  isFetching: false,
}
Layout.propTypes = {
  isFetching: PropTypes.bool,
  children: PropTypes.node,
}
export default Layout
