import { useFetch } from '../../../../hooks'

export const useGetClientTags = () => {
  const url = `v1/all-client-tags`
  const { data, isFetching } = useFetch(
    url,
    'Unable to fetch client tag options',
  )
  const formattedData = data ? data.map(tag => tag) : null
  return { tags: formattedData, isFetching }
}
