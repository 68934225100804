import { useState, useEffect } from 'react'
import { useFetch } from '../../../hooks'

const useGetCandidateDetails = id => {
  const url = `v1/candidates/${id}`
  const { data, isFetching } = useFetch(url, 'Unable to fetch candidate data')
  const [candidate, setCandidate] = useState()

  const updateCandidate = details => {
    setCandidate(details)
  }

  useEffect(() => {
    setCandidate(data)
  }, [data])

  const formattedData = candidate && {
    id: candidate.id,
    profile_image: candidate.profile_image ? candidate.profile_image : '',
    first_name: candidate.first_name,
    last_name: candidate.last_name,
    job_title: candidate.job_title,
    employee_id: candidate.employee_id,
    // contact
    contact_email: candidate.contact_email,
    contact_mobile: candidate.contact_mobile,
    contact_alternative_mobile: candidate.contact_alternative_mobile,
    contact_landline: candidate.contact_landline,
    // emergency
    emergency_first_name: candidate.emergency_first_name,
    emergency_last_name: candidate.emergency_last_name,
    emergency_contact_number: candidate.emergency_contact_number,
    emergency_relation: candidate.emergency_relation,
    // address
    address_1: candidate.address_1,
    address_2: candidate.address_2,
    address_3: candidate.address_3,
    address_town: candidate.address_town,
    address_postcode: candidate.address_postcode,
    address_country: candidate.address_country,
    // bank
    bank_name: candidate.bank_name,
    bank_account_number: candidate.bank_account_number,
    bank_sort_code: candidate.bank_sort_code,
    ni_number: candidate.ni_number,
    // status
    // TODO status should match supported statuses in <Status /> component
    current_status: candidate.current_status,
    employment_status: candidate.employment_status,
    start_date: candidate.start_date,
    end_date: candidate.end_date,
    // qualifications
    main_skill: candidate.main_skill,
    driving_license_type: candidate.driving_license_type
      ? candidate.driving_license_type.split(',')
      : [],
    salary_expectation_min: candidate.salary_expectation_min
      ? candidate.salary_expectation_min
      : '',
    salary_expectation_max: candidate.salary_expectation_max
      ? candidate.salary_expectation_max
      : '',
    skill_tags: candidate.skill_tags,
    work_history: candidate.work_history
      ? candidate.work_history
      : [
          {
            company_name: '',
            role: '',
            start_date: '01/01/2000',
            end_date: '01/01/2000',
          },
        ],
    shift_tags: candidate.shift_tags,
    right_to_work_file: candidate.right_to_work_file,
    right_to_work_date: candidate.right_to_work_date,
    date_registered: candidate.date_registered,
    pre_registration_date: candidate.pre_registration_date,
    source: candidate.source,
  }

  return { details: formattedData, isFetching, updateCandidate }
}

export default useGetCandidateDetails
