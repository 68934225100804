import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import { WarningModal } from '../../../ui'

export const RemoveConsultant = ({
  id,
  branch,
  isVisible,
  setModalVisibility,
  user,
  isConsult,
  removeUser,
}) => {
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})

  const Delete = async userId => {
    axios({
      method: 'POST',
      url: isConsult ? 'v1/branches/remove' : 'v1/branches/remove/manager',
      data: { user_id: userId, branch_id: id },
    })
      .then(response => {
        setModalVisibility(false)
        removeUser({
          ...branch,
          consultants: branch.consultants.filter(
            remUser => remUser.id !== userId,
          ),
        })
        addToast(`${isConsult ? 'Consultant' : 'Manager'} removed`, {
          appearance: 'success',
        })
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 422) {
          setBackEndErrors(error?.response?.data?.errors)
        } else {
          setBackEndErrors(error?.response?.data?.error)
          addToast(
            `Error removing ${
              isConsult ? 'consultant' : 'manager'
            }, please try again`,
            {
              appearance: 'error',
            },
          )
        }
      })
  }

  return (
    <WarningModal
      isVisible={isVisible}
      heading={`Remove ${isConsult ? 'Consultant' : 'Manager'}?`}
      closeFunc={() => setModalVisibility(false)}
      submitButtonText={`Remove ${isConsult ? 'Consultant' : 'Manager'}`}
      submitFunc={() => Delete(user.id)}
      backEndErrors={backEndErrors}
    >
      <b>{user.name}</b> will be removed from <b>{branch.name}</b> branch
    </WarningModal>
  )
}

RemoveConsultant.propTypes = {
  id: PropTypes.number.isRequired,
  branch: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isConsult: PropTypes.bool.isRequired,
  removeUser: PropTypes.func.isRequired,
}
