export const getInitials = name => {
  if (name) {
    const allNames = name.split(' ')
    const firstName = allNames[0]
    const lastName = allNames[allNames.length - 1]
    const firstInitial = firstName.slice(0, 1)
    const secondInitial = lastName.slice(0, 1)
    return firstInitial + secondInitial
  } else {
    return '?'
  }
}

export const createFullName = (firstName, lastName) => {
  return firstName + ' ' + lastName
}
