import styled from 'styled-components'

export const Divider = styled.div`
  display: block;
  width: 100%;
  background-color: #e5e7ed;
  height: 1px;
`

export const PaddedBottomDivider = styled(Divider)`
  margin-bottom: 29px;
`

export const PaddedDivider = styled(Divider)`
  margin: 29px 0;
`
