import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Candidate from '../../jobs/jobDetails/candidateListModal/candidate'
import { CandidateWrapper } from '../../jobs/jobDetails/styles'

const CandidateBlock = styled.div`
  display: flex;
  flex-direction: column;
  button {
    width: fit-content;
    padding: 10px 15px;
    margin: 0 0 0 2rem;
  }
`

export const CandidateShortlist = ({ shortlist, candidates, setShortlist }) => {
  const updateSelection = (candidate, selection, setSelection) => {
    let check = false
    selection.map(n => {
      if (n.id === candidate.id) {
        check = true
      }
      return n
    })
    if (check) {
      setSelection(selectedCandidateList =>
        selectedCandidateList.filter(
          selectedCandidate => selectedCandidate !== candidate,
        ),
      )
    } else {
      setSelection([...selection, candidate])
    }
  }
  return (
    <>
      {candidates?.length !== 0 ? (
        <>
          <h3>Candidate list</h3>
          <CandidateWrapper>
            {candidates?.map(candidate => (
              <CandidateBlock key={candidate.id}>
                <Candidate
                  id={candidate.id}
                  jobTitle={candidate.job_title}
                  name={`${candidate.first_name} ${candidate.last_name}`}
                  email={candidate.contact_email}
                  onChange={() =>
                    updateSelection(candidate, shortlist, setShortlist)
                  }
                  value={shortlist
                    .map(selectedCandidate => selectedCandidate.id)
                    .includes(candidate.id)}
                  status={candidate.current_status}
                  action="select"
                />
              </CandidateBlock>
            ))}
          </CandidateWrapper>
        </>
      ) : null}
    </>
  )
}

CandidateShortlist.defaultProps = {
  shortlist: [],
  candidates: [],
}

CandidateShortlist.propTypes = {
  shortlist: PropTypes.array,
  candidates: PropTypes.array,
  setShortlist: PropTypes.func.isRequired,
}
