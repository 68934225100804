import { getShortDate } from '../../utilities'

export const initialValues = details => {
  let initialValues = {}
  initialValues = {
    cv_id: details.cv_id,
    profile_image: '',
    candidate_id: '',
    first_name: details.name?.first ? details.name.first : '',
    last_name: details.name?.last ? details.name.last : '',
    job_title: details.workExperience[0]?.jobTitle
      ? details.workExperience[0].jobTitle
      : '',
    id: '',
    employee_id: '',
    contact_email: details.emails?.length > 0 ? details.emails[0] : '',
    contact_mobile:
      details.phoneNumbers?.length > 0 ? details.phoneNumbers[0] : '',
    contact_alternative_mobile:
      details.phoneNumbers?.length > 1 ? details.phoneNumbers[1] : '',
    contact_landline:
      details.phoneNumbers?.length > 2 ? details.phoneNumbers[2] : '',
    emergency_first_name: '',
    emergency_last_name: '',
    emergency_contact_number: '',
    emergency_relation: '',
    address_1: details.location?.rawInput ? details.location.rawInput : '',
    address_2: '',
    address_3: '',
    address_town: '',
    address_postcode: details.location?.postalCode
      ? details.location.postalCode
      : '',
    address_country: details.location?.country ? details.location.country : '',
    bank_name: '',
    bank_account_number: '',
    bank_sort_code: '',
    ni_number: '',
    current_status: '',
    pre_registration_date: '',
    employment_status: '',
    start_date: '',
    end_date: '',
    driving_license_type: '',
    salary_expectation_min: '',
    salary_expectation_max: '',
    skill_tags: details && details.skills ? details.skills : [],
    work_history:
      details.workExperience?.length > 0
        ? details.workExperience.map(work => {
            return {
              company_name: work.organization ? work.organization : '',
              role: work.jobTitle ? work.jobTitle : '',
              start_date: work.dates?.startDate
                ? getShortDate(work.dates.startDate)
                : '',
              end_date: work.dates?.endDate
                ? getShortDate(work.dates.endDate)
                : '',
            }
          })
        : [
            {
              company_name: '',
              role: '',
              start_date: '',
              end_date: '',
            },
          ],
    shift_tags: [],
    right_to_work_file: null,
    right_to_work_date: '',
    source: '',
    cv: null,
    cscs_card: null,
  }
  return initialValues
}
