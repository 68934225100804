import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field } from 'formik'
import _ from 'lodash'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import { handleBackEndErrors } from '../../../utilities'
import { Error } from '..'
import { Button, DeleteButton, CardProfileImage } from '../../ui'

const HiddenInput = styled.input`
  display: none;
  width: 0;
  height: 0;
`

const UploadContainer = styled.div`
  width: fit-content;
  margin: 0 auto 1rem;
  display: flex;
  flex-direction: column;
  ${p => p.theme.layout.phone} {
    margin: 0 0 1rem 0;
  }
  ${p => p.theme.layout.tablet} {
    flex-direction: row;
    align-items: center;
  }
`

const InputContainer = styled.div`
  width: fit-content;

  ${p => p.theme.layout.phone} {
    margin: 1rem 0 0 1rem;
    display: flex;
    align-items: center;
  }
  ${p => p.theme.layout.tablet} {
    margin: 0 0 0 1.25rem;
  }
`

const UploadButton = styled(Button)`
  display: block;
  margin: 0 0.5rem 0 0;
  padding: 0.6375rem 1.125rem;
  transition: all 200ms ease-in-out;
  border: 2px solid
    ${p => (p.error ? p.theme.color.fail : p.theme.color.primary_button)};

  &:hover {
    border: 2px solid ${p => p.theme.color.primary_dark};
  }
`

export const FormikProfilePhoto = ({
  name,
  maxSize,
  endpointUrl,
  id,
  ...props
}) => {
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const inputEl = useRef(null)

  const triggerInput = e => {
    e.preventDefault()
    inputEl.current.click()
  }

  async function handleFileChange(e, formik) {
    e.preventDefault()
    let file = e.target.files[0]
    // clear value so can provide feedback if they try to add duplicate file
    e.target.value = null

    if (file) {
      if (file.size / 1000 / 1000 > maxSize) {
        formik.meta.error = 'File too large, maximum file size 10MB'
        return
      } else {
        const formData = new FormData()
        formData.append('file', file)

        if (id) {
          formData.set('client_id', id)
        }

        //axios stuff
        axios({
          method: 'post',
          url: endpointUrl,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(response => {
            formik.form.setFieldValue(
              formik.field.name,
              response.data.profile_image,
            )
          })
          .catch(error => {
            if (error.response.status === 422) {
              setBackEndErrors(error.response.data.errors)
            } else {
              setBackEndErrors(error.response.data.error)
              addToast(`Error uploading photo, please try again`, {
                appearance: 'error',
              })
            }
          })
      }
    }
  }

  const handleRemove = formik => {
    formik.form.setFieldValue(formik.field.name, '')
  }

  return (
    <Field name={name}>
      {formik => (
        <UploadContainer>
          <CardProfileImage imageURL={formik.meta.value} />
          <InputContainer>
            <HiddenInput
              type="file"
              id={name}
              name={name}
              accept=".jpg,.png"
              onChange={e => handleFileChange(e, formik)}
              {...props}
              tabIndex={-1}
              ref={inputEl}
              multiple={false}
            />

            <UploadButton
              type="button"
              onClick={triggerInput}
              error={formik.meta.touched && formik.meta.error}
            >
              {formik.meta.value ? 'Replace image' : 'Upload image'}
            </UploadButton>
            {formik.meta.value ? (
              <DeleteButton type="button" onClick={() => handleRemove(formik)}>
                Remove image
              </DeleteButton>
            ) : null}
          </InputContainer>
          {_.isEmpty(backEndErrors) ? (
            ''
          ) : (
            <Error>{handleBackEndErrors(backEndErrors)}</Error>
          )}
          <Error>{formik.meta.touched ? formik.meta.error : ''}</Error>
        </UploadContainer>
      )}
    </Field>
  )
}

FormikProfilePhoto.defaultProps = {
  maxSize: 10,
}

FormikProfilePhoto.propTypes = {
  name: PropTypes.string.isRequired,
  maxSize: PropTypes.number,
  endpointUrl: PropTypes.string.isRequired,
  id: PropTypes.any.isRequired,
}
