import { useState, useEffect } from 'react'
import { usePaginatedFetch } from '../../../../hooks'

const useGetJobOpportunities = id => {
  const url = `v1/candidates/${id}/job-opportunities`
  const errMsg = 'Unable to fetch opportunties'
  const [jobOpports, setJobOpport] = useState()
  const { data, ...rest } = usePaginatedFetch(url, errMsg)

  useEffect(() => {
    setJobOpport(data)
  }, [data])

  return { opportunities: jobOpports, ...rest }
}

export default useGetJobOpportunities
