import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { navigate } from '@reach/router'
import { useToasts } from 'react-toast-notifications'

import { WarningModal } from '../../../ui'

export const DeleteJobModal = ({ id, job, isVisible, setModalVisibility }) => {
  const { addToast } = useToasts()

  const DeleteJob = async id => {
    axios({
      method: 'post',
      url: `v1/jobs/${id}/delete`,
    })
      .then(response => {
        if (response.data.success) {
          setModalVisibility(false)
          navigate('/jobs')
          addToast('Job deleted successfully', { appearance: 'success' })
        } else {
          addToast('Error deleting job, please try again', {
            appearance: 'error',
          })
        }
      })
      .catch(() => {
        addToast('Error deleting client, please try again', {
          appearance: 'error',
        })
      })
  }

  return (
    <WarningModal
      isVisible={isVisible}
      heading="Delete Job?"
      closeFunc={() => setModalVisibility(false)}
      submitButtonText="Delete Job"
      submitFunc={() => DeleteJob(job.id)}
    >
      <b>{`${job.title} ${
        job.start_date ? `(Starting ${job.start_date})` : ''
      }`}</b>{' '}
      will no longer appear in searches, will lose any placed candidates or
      candidate shortlists created, and will no longer be asigned to:{' '}
      <b>{job.client_name}</b>
    </WarningModal>
  )
}

DeleteJobModal.propTypes = {
  job: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
}
