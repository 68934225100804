import styled from 'styled-components'

export const FiltersContainer = styled.div`
  background-color: white;
  border: 1px solid ${p => p.theme.color.border_light};
  padding: 1.25rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-bottom: 2rem;

  ${p => p.theme.layout.tablet} {
    padding: 1.875rem;
  }
`

export const FiltersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  height: 2.125rem;
`

export const FilterList = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem 1rem;

  ${p => p.theme.layout.phone_medium} {
    grid-template-columns: 1fr 1fr;
  }

  ${p => p.theme.layout.tablet} {
    gap: 1rem 1.5rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${p => p.theme.layout.web_small} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  ${p => p.theme.layout.web} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`

export const FilterListHeading = styled.h4`
  font-size: 1.125rem;
`

export const ActiveFilter = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem;
  background-color: ${p => p.theme.color.grey_light};
  border-radius: 0.375rem;
  padding: 0.125rem 0.25rem 0.125rem 0.75rem;
  text-transform: capitalize;
`

export const ActiveFilterName = styled.span`
  display: block;
  padding: 0.375rem 0;
  color: black;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
`
export const UnusedFilter = styled.button`
  appearance: none;
  display: block;
  padding: 0.5rem 0.75rem;
  color: black;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
  border: 1px solid ${p => p.theme.color.border_light};
  border-radius: 0.375rem;
  background-color: white;
  text-align: left;
  transition: background-color 200ms ease-in-out;
  cursor: pointer;
  outline: none;
  text-transform: capitalize;

  &:focus {
    background-color: ${p => p.theme.color.grey_light};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${p => p.theme.color.grey_light};
    }
  }
`
