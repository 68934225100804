import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { useAuth } from '../../hooks'
import { Button } from '../ui'
import { Form, FormikInput, FormTitle } from '../form'
import { Container, Box, StyledLink } from './login-ui'
import { ReactComponent as Logo } from '../../assets/pinpoint_logo.svg'

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Must be an email').required('Required'),
  password: Yup.string().min(7, 'Too short').required('Required'),
})

export default function Login() {
  const { login } = useAuth()

  const submitLogin = async ({ email, password }, actions) => {
    await login({ email, password })
    actions.setSubmitting(false)
  }

  return (
    <Container>
      <Box>
        <Logo height="54" />
        <FormTitle>Log in to your account</FormTitle>
        <Formik
          validationSchema={loginSchema}
          onSubmit={submitLogin}
          initialValues={{
            email: '',
            password: '',
          }}
        >
          {({ isSubmitting }) => (
            <>
              <Form>
                <FormikInput
                  type="email"
                  name="email"
                  label="Email Address"
                  placeholder="Enter email"
                />

                <FormikInput
                  type="password"
                  name="password"
                  label="Password"
                  placeholder="Enter password"
                />

                <Button fullWidth tall type="submit" disabled={isSubmitting}>
                  Log In
                </Button>
              </Form>
            </>
          )}
        </Formik>

        <StyledLink to="/forgot-password">Forgot Password?</StyledLink>
      </Box>
    </Container>
  )
}
