import axios from 'axios'

import { formatError } from './utils'
import { navigate } from '@reach/router'

export const login = async ({ email, password }) => {
  try {
    const url = `/login`
    const res = await axios.post(url, {
      email,
      password,
      device_name: 'api',
    })
    if (res.statusText === 'OK') {
      const access_token = res.data.token
      axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
      localStorage.setItem('auth', access_token)
      return { error: false, res }
    } else {
      return { error: true, errors: ['Error logging in'] }
    }
  } catch (e) {
    return formatError(e, 'Failed to login')
  }
}

export const reauth = async token => {
  try {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    const url = '/v1/re-auth'
    const res = await axios.get(url)

    if (res.statusText === 'OK') {
      const access_token = res.data.token
      axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
      localStorage.setItem('auth', access_token)
      return { error: false, res: res.data.user }
    } else {
      // clear the existing token
      axios.defaults.headers.common['Authorization'] = null
      localStorage.removeItem('auth')
      navigate('/')
      return { error: true, errors: ['Error logging in'] }
    }
  } catch (e) {
    return formatError(e, 'Something went wrong')
  }
}

export const logout = async () => {
  try {
    const res = await axios.post('/logout')

    if (res.statusText === 'OK') {
      axios.defaults.headers.common['Authorization'] = null
      localStorage.removeItem('auth')
      navigate('/')
      return { error: false }
    } else throw new Error()
  } catch (e) {
    return { error: true, errors: ['Unable to logout'] }
  }
}
