import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import * as Yup from 'yup'
import styled from 'styled-components'

import { Button } from '../../ui'
import { FiltersContainer } from './style'
import { useCand } from '../../../hooks'
import { Formik } from 'formik'
import { Form, FormikInput, TagSelector } from '../../form'

const FilterColumn = styled.div`
  display: grid;
  grid-gap: 0 1rem;
  grid-template-columns: 1fr;
  ${p => p.theme.layout.phone_medium} {
    grid-template-columns: 1fr 1fr;
  }
  ${p => p.theme.layout.web_small} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const Filters = ({ isVisible, searchFunction }) => {
  const {
    alphaSort,
    searchValue,
    searchSkill,
    searchTag,
    searchTitle,
    searchLocation,
    searchEmail,
    updateSearch,
  } = useCand()

  React.useEffect(() => {
    searchFunction(searchValue, alphaSort)
  })

  const onSubmit = (values, actions) => {
    updateSearch(values)
    actions.setSubmitting(false)
  }

  return (
    <CSSTransition in={isVisible} timeout={200} classNames="fade" unmountOnExit>
      <FiltersContainer>
        <Formik
          initialValues={{
            name: searchValue ? searchValue : '',
            email: searchEmail ? searchEmail : '',
            job_title: searchTitle ? searchTitle : '',
            skills: searchSkill ? searchSkill : [],
            tags: searchTag ? searchTag : [],
            location: searchLocation ? searchLocation : '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string(),
            email: Yup.string(),
            job_title: Yup.string(),
            skills: Yup.array(),
            tags: Yup.array(),
            location: Yup.string(),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FilterColumn>
                <FormikInput
                  type="text"
                  name="name"
                  label="Name"
                  placeholder="Search name"
                />
                <FormikInput
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="Search email"
                />
                <FormikInput
                  type="text"
                  name="job_title"
                  label="Job title"
                  placeholder="Search job title"
                />
                <TagSelector
                  name="skills"
                  label="Skills"
                  placeholder="Search skills"
                  noOptionsMessage={() =>
                    'Type a comma at the end to input a skill'
                  }
                />
                <TagSelector
                  name="tags"
                  label="Tags"
                  placeholder="Search tags"
                  noOptionsMessage={() =>
                    'Type a comma at the end to input a tag'
                  }
                />
                <FormikInput
                  type="text"
                  name="location"
                  label="Location"
                  placeholder="Search location"
                />
              </FilterColumn>
              <Button type="submit" disabled={isSubmitting}>
                Search
              </Button>
            </Form>
          )}
        </Formik>
      </FiltersContainer>
    </CSSTransition>
  )
}

Filters.propTypes = {
  searchFunction: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
}

export default Filters
