import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  CardGrid,
  CardHeaderContent,
  CardHeading,
  CardProfileImage,
  CardSection,
  CardSubtitle,
  CardTitle,
  DeleteButton,
  DetailsPanel,
  IconButton,
  Label,
  Status,
  TextLink,
} from '../../../ui'
import { Select, Tag, TagWrapper } from '../../../form'
import { DeleteClientModal } from '../delete'
import { createFullName } from '../../../../utilities'

const MarginDeleteButton = styled(DeleteButton)`
  margin-top: 0.5rem;
`

const Profile = ({
  id,
  profile_image,
  name,
  type,
  head_count,
  status,
  contacts,
  address_1,
  address_2,
  address_3,
  postcode,
  tel,
  email,
  website,
  invoice_address,
  invoice_email,
  tags,
  setModalVisibility,
}) => {
  const [selectedContact, setSelectedContact] = React.useState(null)
  const [DeleteModalVisible, setDeleteModalVisibility] = React.useState(false)

  React.useEffect(() => {
    if (contacts && contacts.length > 0) setSelectedContact(contacts[0])
  }, [contacts])

  return (
    <DetailsPanel>
      <CardGrid>
        <CardHeaderContent>
          <CardProfileImage imageURL={profile_image} />
          <CardTitle>{name}</CardTitle>
          <CardSubtitle>{type}</CardSubtitle>
          <CardSubtitle>{'#' + id}</CardSubtitle>
        </CardHeaderContent>
        <IconButton icon="pencil" onClick={() => setModalVisibility(true)} />
      </CardGrid>
      {/*       <CardSection>

        <Text>Client Managed by </Text>
      </CardSection> */}
      <CardSection>
        {selectedContact && (
          <Select
            value={{
              label: createFullName(
                selectedContact.first_name,
                selectedContact.last_name,
              ),
              value: selectedContact,
            }}
            label="Select a contact"
            name="contact"
            onChange={opt => setSelectedContact(opt.value)}
            options={contacts.map(contact => ({
              label: createFullName(contact.first_name, contact.last_name),
              value: contact,
            }))}
          />
        )}
        {selectedContact && (
          <>
            <CardHeading>Contact Details</CardHeading>
            <Label label="Email">
              <TextLink href={`mailto:${selectedContact.email}`}>
                {selectedContact.email
                  ? selectedContact.email.toLowerCase()
                  : ''}
              </TextLink>
            </Label>
            <Label label="Job title">
              {selectedContact.job_title ? selectedContact.job_title : ''}
            </Label>
            {selectedContact.landline ? (
              <Label label="Tel no">{selectedContact.landline}</Label>
            ) : (
              ''
            )}
            {selectedContact.mobile ? (
              <Label label="Mobile">{selectedContact.mobile}</Label>
            ) : (
              ''
            )}
            {selectedContact.eshot_contact ? (
              <Label label="Primary contact">Yes</Label>
            ) : (
              ''
            )}
          </>
        )}
        <CardHeading>Client Details</CardHeading>
        {head_count && <Label label="Head count">{head_count}</Label>}
        {address_1 || address_2 || address_3 || postcode ? (
          <Label label="Address">
            {
              <>
                {address_1}
                {address_2 ? (
                  <>
                    <br />
                    {address_2}
                  </>
                ) : (
                  ''
                )}
                {address_3 ? (
                  <>
                    <br />
                    {address_3}
                  </>
                ) : (
                  ''
                )}
                {postcode ? (
                  <>
                    <br />
                    {postcode}
                  </>
                ) : (
                  ''
                )}
              </>
            }
          </Label>
        ) : (
          ''
        )}
        {tel ? <Label label="Tel No.">{tel}</Label> : ''}
        {email ? (
          <Label label="Email">
            <TextLink href={`mailto:${email}`}>{email.toLowerCase()}</TextLink>
          </Label>
        ) : (
          ''
        )}
        {website ? (
          <Label label="Website">
            <TextLink
              target="_blank"
              rel="noopener noreferer"
              href={`https://${website}`}
            >
              {website}
            </TextLink>
          </Label>
        ) : (
          ''
        )}
        <CardHeading>Status</CardHeading>
        <Status status={status ? status : 'Unknown'} />
      </CardSection>
      {invoice_address || invoice_email ? (
        <CardSection>
          <CardHeading>Invoice details</CardHeading>
          {invoice_address ? (
            <Label label="Address">{invoice_address}</Label>
          ) : (
            ''
          )}
          {invoice_email ? <Label label="Email">{invoice_email}</Label> : ''}
        </CardSection>
      ) : (
        ''
      )}
      {tags && tags.length > 0 ? (
        <CardSection>
          <CardHeading>Tags</CardHeading>
          <TagWrapper>
            {tags.map(tag => (
              <Tag key={tag.tag}>{tag.tag}</Tag>
            ))}
          </TagWrapper>
        </CardSection>
      ) : (
        ''
      )}
      <CardSection>
        <MarginDeleteButton onClick={() => setDeleteModalVisibility(true)}>
          Delete Client
        </MarginDeleteButton>
      </CardSection>
      {DeleteModalVisible ? (
        <DeleteClientModal
          id={id}
          name={name}
          isVisible={DeleteModalVisible}
          setModalVisibility={setDeleteModalVisibility}
        />
      ) : (
        ''
      )}
    </DetailsPanel>
  )
}

Profile.defaultProps = {
  id: 0,
  profile_image: '',
  name: '',
  type: '',
  status: '',
  address_1: '',
  address_2: '',
  address_3: '',
  postcode: '',
  tel: '',
  website: '',
  contacts: [],
  email: '',
  invoice_address: '',
  invoice_email: '',
  tags: [],
}

Profile.propTypes = {
  id: PropTypes.number,
  profile_image: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  // status
  status: PropTypes.string,
  // contact
  contacts: PropTypes.array,
  address_1: PropTypes.string,
  address_2: PropTypes.string,
  address_3: PropTypes.string,
  postcode: PropTypes.string,
  tel: PropTypes.string,
  website: PropTypes.string,
  email: PropTypes.string,
  invoice_address: PropTypes.string,
  invoice_email: PropTypes.string,
  // tags
  tags: PropTypes.array,
  setModalVisibility: PropTypes.func.isRequired,
}
export default Profile
