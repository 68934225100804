import { getShortDate, transformTags } from '../../../../utilities'

export const initialValues = details => {
  let initialValues = {}
  initialValues = {
    profile_image: details.profile_image ? details.profile_image : '',
    candidate_id: details.id,
    first_name: details && details.first_name ? details.first_name : '',
    last_name: details && details.last_name ? details.last_name : '',
    job_title: details && details.job_title ? details.job_title : '',
    id: details && details.id ? details.id : '',
    employee_id: details && details.employee_id ? details.employee_id : '',
    contact_email:
      details && details.contact_email ? details.contact_email : '',
    contact_mobile:
      details && details.contact_mobile ? details.contact_mobile : '',
    contact_alternative_mobile:
      details && details.contact_alternative_mobile
        ? details.contact_alternative_mobile
        : '',
    contact_landline:
      details && details.contact_landline ? details.contact_landline : '',
    emergency_first_name:
      details && details.emergency_first_name
        ? details.emergency_first_name
        : '',
    emergency_last_name:
      details && details.emergency_last_name ? details.emergency_last_name : '',
    emergency_contact_number:
      details && details.emergency_contact_number
        ? details.emergency_contact_number
        : '',
    emergency_relation:
      details && details.emergency_relation ? details.emergency_relation : '',
    address_1: details && details.address_1 ? details.address_1 : '',
    address_2: details && details.address_2 ? details.address_2 : '',
    address_3: details && details.address_3 ? details.address_3 : '',
    address_town: details && details.address_town ? details.address_town : '',
    address_postcode:
      details && details.address_postcode ? details.address_postcode : '',
    address_country:
      details && details.address_country ? details.address_country : '',
    bank_name: details && details.bank_name ? details.bank_name : '',
    bank_account_number:
      details && details.bank_account_number ? details.bank_account_number : '',
    bank_sort_code:
      details && details.bank_sort_code ? details.bank_sort_code : '',
    ni_number: details && details.ni_number ? details.ni_number : '',
    current_status:
      details && details.current_status ? details.current_status : '',
    pre_registration_date: details?.pre_registration_date
      ? getShortDate(details.pre_registration_date)
      : '',
    employment_status:
      details && details.employment_status ? details.employment_status : '',
    start_date:
      details && details.start_date ? getShortDate(details.start_date) : '',
    end_date: details && details.end_date ? getShortDate(details.end_date) : '',
    driving_license_type:
      details && details.driving_license_type
        ? details.driving_license_type
        : '',
    salary_expectation_min:
      details && details.salary_expectation_min
        ? details.salary_expectation_min
        : '',
    salary_expectation_max:
      details && details.salary_expectation_max
        ? details.salary_expectation_max
        : '',
    skill_tags:
      details && details.skill_tags ? transformTags(details.skill_tags) : [],
    work_history:
      details &&
      details.work_history &&
      details.work_history.length > 0 &&
      details.work_history[0].company_name
        ? details.work_history
        : [
            {
              company_name: '',
              role: '',
              start_date: '',
              end_date: '',
            },
          ],
    shift_tags:
      details && details.shift_tags ? transformTags(details.shift_tags) : [],
    right_to_work_file:
      details && details.right_to_work_file ? details.right_to_work_file : null,
    right_to_work_date:
      details && details.right_to_work_date
        ? getShortDate(details.right_to_work_date)
        : '',
    source: details && details.source ? details.source : '',
    cv: details && details.cv_file ? details.cv_file : null,
    cscs_card: details && details.csvs_file ? details.csvs_file : null,
  }
  return initialValues
}
