import styled from 'styled-components'

export const ActivityCardBody = styled.div`
  text-align: left;
  grid-column: 2/3;

  h2 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 0.625rem;
    text-align: left;
    color: black;
  }

  p {
    font-weight: 400;
    line-height: 1.75rem;
    padding-right: 2rem;
  }
`

export const ActivityCardFooter = styled.span`
  display: block;
  grid-column: 2/3;
  text-align: left;
  color: ${p => p.theme.color.text};
`

export const CardType = styled.span`
  display: block;
  margin-top: 0.25rem;

  svg {
    display: block;
    width: ${p => (p.size === 'small' ? '1.375rem' : '1.75rem')};
    fill: ${p => p.theme.color.primary};
  }
`
export const ColumnedActivityCardBody = styled(ActivityCardBody)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0 0.75rem;

  ${p => p.theme.layout.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`

export const WideLabel = styled.div`
  ${p => p.theme.layout.tablet} {
    grid-column: auto / span 2;
  }
`

export { MediaActivityCard } from './media'
export { NoteActivityCard } from './notes'
export { DiaryActivityCard } from './diary'
export { JobActivityCardClient, JobActivityCardCandidate } from './jobActivity'
export { ProfileUpdateActivityCard } from './profileUpdate'
