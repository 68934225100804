import { useState, useEffect } from 'react'
import { useFetchNotNested } from '../../hooks'

const useGetBranches = () => {
  const url = 'v1/branches'
  const [branches, setBranches] = useState()
  let { data, ...rest } = useFetchNotNested(url, 'Unable to fetch branch data')

  useEffect(() => {
    setBranches(data)
  }, [data])

  return { ...rest, data: branches, setBranches }
}

export default useGetBranches
