import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import _ from 'lodash'

import useGetCandidates from './useGetCandidates'
import {
  Button,
  SmallButton,
  ButtonColumn,
  ListHeader,
  ListHeaderSearch,
  LoadingMore,
  SearchResult,
  StatusColumn,
  StatusWithTooltip,
  Table,
  TextLink,
  Title,
  ViewBtn,
  ViewIcon,
  ViewLink,
} from '../ui'
import Layout from '../layout'
import Search from './search'
import Filters from './filters'
import ViewDetailsModal from './profile/modal'
import { AuthContext } from '../../context/auth'
import { useCand } from '../../hooks'

const Candidates = () => {
  let {
    isFetching,
    data,
    fetchMore,
    isFetchingMore,
    from,
    to,
    search,
    addCandidate,
  } = useGetCandidates()
  const [modalVisible, setModalVisibility] = React.useState(false)
  const [filtersVisible, setFiltersVisibility] = React.useState(false)
  const auth = React.useContext(AuthContext)
  const {
    updateSearch,
    updateSort,
    alphaSort,
    searchValue,
    searchTitle,
    searchSkill,
    searchTag,
    searchLocation,
    searchEmail,
  } = useCand()

  const clearSearch = () => {
    updateSearch('')
    search()
  }

  const submitResponse = candidate => {
    addCandidate(candidate)
  }

  return (
    <Layout isFetching={isFetching}>
      <ListHeader list="candidates">
        <Title>Candidates</Title>
        <ListHeaderSearch>
          {data && (
            <Search
              setFiltersVisibility={setFiltersVisibility}
              filtersVisible={filtersVisible}
              alphaSort={alphaSort}
              updateSort={updateSort}
            />
          )}
        </ListHeaderSearch>
        <Button type="button" onClick={() => setModalVisibility(true)}>
          Add New Candidate
        </Button>
      </ListHeader>
      <Filters searchFunction={search} isVisible={filtersVisible} />
      {(searchValue ||
        searchTitle ||
        searchSkill?.length >= 1 ||
        searchTag?.length >= 1 ||
        searchLocation ||
        searchEmail) && (
        <SearchResult>
          Showing:{' '}
          {data && data.length > 0 ? (
            <>
              <span style={{ margin: '0 5px' }}>
                {from} - {to}
              </span>{' '}
            </>
          ) : (
            '0 '
          )}{' '}
          results for {searchValue && `"${searchValue}"`}
          {searchValue &&
            (searchTitle ||
              searchSkill?.length >= 1 ||
              searchTag?.length >= 1 ||
              searchLocation ||
              searchEmail) &&
            ' & '}
          {searchTitle && `"${searchTitle}"`}
          {searchTitle &&
            (searchSkill?.length >= 1 ||
              searchTag?.length >= 1 ||
              searchLocation ||
              searchEmail) &&
            ' & '}
          {searchSkill?.length >= 1 && `"${searchSkill}"`}
          {searchSkill?.length >= 1 &&
            (searchTag?.length >= 1 || searchLocation || searchEmail) &&
            ' & '}
          {searchTag?.length >= 1 && `"${searchTag}"`}
          {searchTag?.length >= 1 && (searchLocation || searchEmail) && ' & '}
          {searchLocation && `"${searchLocation}"`}
          {searchLocation && searchEmail && ' & '}
          {searchEmail && `"${searchEmail}"`}
          <SmallButton
            style={{ margin: '0 10px' }}
            onClick={() => clearSearch('')}
          >
            Clear
          </SmallButton>
        </SearchResult>
      )}
      {!_.isEmpty(data) && data ? (
        <InfiniteScroll
          dataLength={data && data.length}
          next={fetchMore}
          hasMore={!!fetchMore}
          style={{ overflow: 'isVisible' }}
        >
          {data.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th data-web-small>Location</th>
                  <th>Full Name</th>
                  <th>Job Title</th>
                  <th data-tablet>Email</th>
                  <th data-tablet>Mobile No.</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map(
                  ({
                    current_status,
                    id,
                    first_name,
                    last_name,
                    job_title,
                    contact_email,
                    contact_mobile,
                    address_town,
                  }) => (
                    <tr key={id}>
                      <StatusColumn>
                        <StatusWithTooltip status={current_status} />
                      </StatusColumn>
                      <td data-web-small>{address_town ? address_town : ''}</td>
                      <td>
                        {first_name || last_name
                          ? `${first_name} ${last_name}`
                          : ''}
                      </td>
                      <td>{job_title ? job_title : ''}</td>
                      <td data-tablet>
                        <TextLink href={`mailto:${contact_email}`}>
                          {contact_email ? contact_email.toLowerCase() : ''}
                        </TextLink>
                      </td>
                      <td data-tablet>
                        <TextLink href={`tel:${contact_mobile}`}>
                          {contact_mobile ? contact_mobile : ''}
                        </TextLink>
                      </td>
                      <ButtonColumn>
                        <ViewLink to={`/candidates/${id}/activity`}>
                          <ViewIcon data-mobile data-tablet />
                          <ViewBtn data-web>View</ViewBtn>
                        </ViewLink>
                      </ButtonColumn>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
          )}
          {fetchMore ? (
            isFetchingMore ? (
              <LoadingMore>Loading...</LoadingMore>
            ) : (
              <LoadingMore>Scroll to load more</LoadingMore>
            )
          ) : null}
        </InfiniteScroll>
      ) : (
        <LoadingMore>No candidates</LoadingMore>
      )}
      {modalVisible ? (
        <ViewDetailsModal
          isVisible={modalVisible}
          setModalVisibility={setModalVisibility}
          title="Add Candidate"
          submitResponse={submitResponse}
          user={auth && auth.user ? auth.user : {}}
        />
      ) : (
        ''
      )}
    </Layout>
  )
}

export default Candidates
