import React from 'react'

import { AuthContext } from '../context/auth'

const useAuth = () => {
  const auth = React.useContext(AuthContext)

  const login = credentials => auth.login(credentials)
  const logout = () => auth.logout()

  return {
    isAuthenticating: auth.isAuthenticating,
    isAuthenticated: auth.isAuthenticated,
    login,
    logout,
  }
}

export default useAuth
