import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import { WarningModal } from '../../ui'

export const DirectorUserModal = ({
  user,
  isVisible,
  setModalVisibility,
  updateUser,
}) => {
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})

  const grantDirector = async () => {
    axios({
      method: 'post',
      url: `v1/users/director`,
      data: { user_id: user.id },
    })
      .then(response => {
        if (response.data.success) {
          updateUser(response.data.user)
          setModalVisibility(false)
          addToast('User assigned director role', { appearance: 'success' })
        } else {
          addToast('Error granting director role, please try again', {
            appearance: 'error',
          })
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error assigning director role, please try again`, {
            appearance: 'error',
          })
        }
      })
  }

  const removeDirector = async () => {
    axios({
      method: 'post',
      url: `v1/users/director/remove`,
      data: { user_id: user.id },
    })
      .then(response => {
        if (response.data.success) {
          updateUser(response.data.user)
          setModalVisibility(false)
          addToast('User unassigned director role', { appearance: 'success' })
        } else {
          addToast('Error removing director role, please try again', {
            appearance: 'error',
          })
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error removing director role, please try again`, {
            appearance: 'error',
          })
        }
      })
  }

  return (
    <WarningModal
      isVisible={isVisible}
      heading={user?.director ? 'Unassign Director' : 'Assign Director'}
      closeFunc={() => setModalVisibility(false)}
      submitButtonText={user?.director ? 'Unassign' : 'Assign'}
      submitFunc={() => (user?.director ? removeDirector() : grantDirector())}
      backEndErrors={backEndErrors}
    >
      Would you like to {user?.director ? 'unassign' : 'assign'}{' '}
      <b>{user?.name}</b> a director role
    </WarningModal>
  )
}

DirectorUserModal.propTypes = {
  user: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
}
