import styled from 'styled-components'

export const Flex = styled.div.attrs(({ dir, wrap }) => ({
  dir: dir ? 'column' : 'row',
  wrap: wrap ? 'wrap' : 'nowrap',
}))`
  display: flex;
  flex-direction: column;
  flex-wrap: ${p => p.wrap};
  ${p => p.theme.layout.tablet} {
    flex-direction: ${p => p.dir};
  }
`
