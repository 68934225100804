import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import _ from 'lodash'

import { useToasts } from 'react-toast-notifications'
import { Button } from '../ui'
import { Form, FormikInput, FormTitle, Error } from '../form'
import { ReactComponent as Logo } from '../../assets/pinpoint_logo.svg'
import { Container, Box, StyledLink } from './login-ui'
import { handleBackEndErrors } from '../../utilities'

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Must be an email').required('Required'),
})

const ForgotPassword = () => {
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const [emailSent, setEmailSent] = React.useState(false)

  const submit = async ({ email }, actions) => {
    axios({
      method: 'post',
      url: 'v1/forgotten-password',
      data: { email: email },
    })
      .then(response => {
        if (response) {
          setEmailSent(true)
        }
      })
      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error sending email, please try again`, {
            appearance: 'error',
          })
        }
      })
    actions.setSubmitting(false)
  }

  return (
    <Container>
      <Box>
        <Logo height="54" />
        <FormTitle>Reset Your Password</FormTitle>
        {emailSent ? (
          <p>
            If the entered email is registered to an account, you will recieve
            an email shortly.
          </p>
        ) : (
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={forgotPasswordSchema}
            onSubmit={submit}
          >
            {({ isSubmitting }) => (
              <>
                <Form>
                  <FormikInput
                    type="email"
                    name="email"
                    label="Email Address"
                    placeholder="Enter your email address"
                  />
                  {_.isEmpty(backEndErrors) ? (
                    ''
                  ) : (
                    <Error>{handleBackEndErrors(backEndErrors)}</Error>
                  )}
                  <Button fullWidth tall type="submit" disabled={isSubmitting}>
                    Send Password Reset
                  </Button>
                </Form>
              </>
            )}
          </Formik>
        )}
        <StyledLink to="/">Back</StyledLink>
      </Box>
    </Container>
  )
}

export default ForgotPassword
