import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import useGetClients from './useGetClients'
import {
  Button,
  ButtonColumn,
  ListHeader,
  ListHeaderSearch,
  StatusColumn,
  StatusWithTooltip,
  Table,
  TextLink,
  Title,
  ViewBtn,
  ViewIcon,
  ViewLink,
  SearchResult,
  LoadingMore,
  SmallButton,
} from '../ui'
import Layout from '../layout'
import DetailsModal from './profile/modal'
import { useClient } from '../../hooks'
import SearchC from './search'
import FiltersC from './filters'
import { navigate } from '@reach/router'

const ClientsList = () => {
  const {
    isFetching,
    data,
    fetchMore,
    isFetchingMore,
    search,
    from,
    to,
    addClient,
  } = useGetClients()
  const {
    searchValue,
    searchTitle,
    searchTag,
    searchHeadCount,
    searchLocation,
    alphaSort,
    updateSearch,
    updateSort,
  } = useClient()
  const [modalVisible, setModalVisibility] = useState(false)
  const [filtersVisible, setFiltersVisibility] = React.useState(false)

  const clearSearch = () => {
    updateSearch('')
    search()
  }

  const submitResponse = client => {
    addClient(client)
  }

  return (
    <Layout isFetching={isFetching}>
      <ListHeader list="clients">
        <Title>Clients</Title>
        <ListHeaderSearch style={{ display: 'flex' }}>
          <SearchC
            setFiltersVisibility={setFiltersVisibility}
            filtersVisible={filtersVisible}
            alphaSort={alphaSort}
            updateSort={updateSort}
          />
          <Button type="button" onClick={() => navigate('/clients/eshot')}>
            E-shot
          </Button>
        </ListHeaderSearch>
        <Button type="button" onClick={() => setModalVisibility(true)}>
          Add New Client
        </Button>
      </ListHeader>

      <FiltersC searchFunction={search} isVisible={filtersVisible} />
      {(searchValue ||
        searchTitle ||
        searchTag?.length >= 1 ||
        searchHeadCount ||
        searchLocation) && (
        <SearchResult>
          Showing:{' '}
          {data && data.length > 0 ? (
            <>
              <span style={{ margin: '0 5px' }}>
                {from} - {to}
              </span>{' '}
            </>
          ) : (
            '0 '
          )}{' '}
          results for {searchValue && `"${searchValue}"`}
          {searchValue &&
            (searchTitle ||
              searchTag?.length >= 1 ||
              searchHeadCount ||
              searchLocation) &&
            ' & '}
          {searchTitle && `"${searchTitle}"`}
          {searchTitle &&
            (searchTag?.length >= 1 || searchHeadCount || searchLocation) &&
            ' & '}
          {searchTag?.length >= 1 && `"${searchTag}"`}
          {searchTag?.length >= 1 &&
            (searchHeadCount || searchLocation) &&
            ' & '}
          {searchHeadCount && `"${searchHeadCount}"`}
          {searchHeadCount && searchLocation && ' & '}
          {searchLocation && `"${searchLocation}"`}
          <SmallButton
            style={{ margin: '0 10px' }}
            onClick={() => clearSearch('')}
          >
            Clear
          </SmallButton>
        </SearchResult>
      )}
      {data && data.length > 0 ? (
        <InfiniteScroll
          dataLength={data && data.length}
          next={fetchMore}
          hasMore={!!fetchMore}
        >
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Client</th>
                <th>Company Name</th>
                {/* <th data-tablet>Contact Email</th> */}
                <th data-tablet>Tel No.</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.length &&
                data.map(({ status, id, name, contact_telephone }) => (
                  <tr key={id}>
                    <StatusColumn>
                      <StatusWithTooltip status={status} />
                    </StatusColumn>
                    <td>{`#` + id}</td>
                    <td>{name}</td>
                    {/* <td data-tablet>
                        <TextLink href={`mailto:${contact_email}`}>
                          {contact_email ? contact_email.toLowerCase() : ''}
                        </TextLink>
                      </td> */}
                    <td data-tablet>
                      <TextLink href={`tel:${contact_telephone}`}>
                        {contact_telephone}
                      </TextLink>
                    </td>
                    <ButtonColumn>
                      <ViewLink to={`/clients/${id}/activity`}>
                        <ViewIcon data-mobile data-tablet />
                        <ViewBtn data-web>View</ViewBtn>
                      </ViewLink>
                    </ButtonColumn>
                  </tr>
                ))}
            </tbody>
          </Table>
          {fetchMore ? (
            isFetchingMore ? (
              <LoadingMore>Loading...</LoadingMore>
            ) : (
              <LoadingMore>Scroll to load more</LoadingMore>
            )
          ) : null}
        </InfiniteScroll>
      ) : (
        <LoadingMore>No clients found</LoadingMore>
      )}
      {modalVisible && (
        <DetailsModal
          isVisible={modalVisible}
          setModalVisibility={setModalVisibility}
          submitResponse={submitResponse}
        />
      )}
    </Layout>
  )
}

export default ClientsList
