import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'
import styled from 'styled-components'

import Candidate from '../candidateListModal/candidate'
import { Button, PaddedBottomDivider } from '../../../ui'
import {
  ButtonList,
  CandidateWrapper,
  Overlay,
  ISelect,
  ShortBlock,
} from '../styles'
import { Error } from '../../../form'
import { buildUpdateCandidatesRequest } from '../buildUpdateCandidatesRequest'
import { handleBackEndErrors } from '../../../../utilities'
import PostInterviewStage from './postInterviewStage'

const CandidateBlock = styled.div`
  display: flex;
  flex-direction: column;
  button {
    width: fit-content;
    padding: 10px 15px;
    margin: 0 0 0 2rem;
  }
`

export const JobCandidates = ({
  jobId,
  shortlist,
  placedlist,
  setCandidateListVisibility,
  setJob,
  vacancies,
  setUpdatePlacementVisible,
}) => {
  const [
    selectedCandidatesShortlist,
    setSelectedCandidatesShortlist,
  ] = React.useState([])
  const [
    selectedCandidatesPlaced,
    setSelectedCandidatesPlaced,
  ] = React.useState([])
  const {
    postInterview,
    // backEndErrors,
  } = PostInterviewStage()
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const [interviewList, setInterviewList] = React.useState([])
  const [interviewOptions, setInterviewOptions] = React.useState([])
  const { addToast } = useToasts()

  const updateSelection = (candidate, selection, setSelection) => {
    if (selection.includes(candidate)) {
      setSelection(selectedCandidateList =>
        selectedCandidateList.filter(
          selectedCandidate => selectedCandidate !== candidate,
        ),
      )
    } else {
      setSelection([...selection, candidate])
    }
  }

  const updatePlacedCandidates = async (jobId, newCandidates, action) => {
    if (newCandidates.length > 0) {
      if (action === 'remove') {
        // Harry's old code
        // newCandidates = placedlist.filter(
        //   placedCandidate =>
        //     !newCandidates
        //       .map(candidate => candidate.id)
        //       .includes(placedCandidate.id),
        // )
        newCandidates = newCandidates.map(can => can.id)
      } else if (action === 'place') {
        newCandidates = [...newCandidates, ...placedlist]
      }

      await axios({
        method: 'post',
        url:
          action === 'remove'
            ? 'v1/candidates/jobs/detach'
            : 'v1/candidates/jobs/attach',
        data: buildUpdateCandidatesRequest(jobId, true, newCandidates, action),
      })
        .then(response => {
          setJob(response.data)
          setSelectedCandidatesShortlist([])
          setSelectedCandidatesPlaced([])
          setBackEndErrors({})
        })
        .catch(error => {
          if (error.response.status === 422) {
            setBackEndErrors(error.response.data.errors)
            addToast(
              `Error ${
                action === 'remove' ? 'removing' : 'placing'
              } candidates, please fix errors`,
              {
                appearance: 'error',
              },
            )
          } else {
            setBackEndErrors(error.response.data.error)
            addToast(
              `Error ${
                action === 'remove' ? 'removing' : 'placing'
              } candidates, please try again`,
              {
                appearance: 'error',
              },
            )
          }
        })
    } else {
      setBackEndErrors('No candidates selected')
    }
  }

  const selectInterview = (e, index, candidate) => {
    let check1 = false
    let check2 = false
    const temp = interviewList
    temp[index] = e
    setInterviewList(temp)
    postInterview({
      candidate_id: candidate.id,
      job_id: jobId,
      interview_stage: e.value,
    })
    addToast(`Interview stage added to candidate notes`, {
      appearance: 'success',
    })
    shortlist.map(short => {
      if (short.interview_stage) {
        if (candidate.id === short.id) {
          if (e.label === '1st Interview') {
            check1 = true
          } else if (
            e.label === '2nd Interview' ||
            e.label === '3rd Interview'
          ) {
            check2 = true
          }
        } else if (short.interview_stage === '1st Interview') {
          check1 = true
        } else if (
          short.interview_stage === '2nd Interview' ||
          short.interview_stage === '3rd Interview'
        ) {
          check2 = true
        }
      }
      return short
    })
    if (check2) {
      setInterviewOptions([
        { label: '1st Interview', value: '1st Interview' },
        { label: '2nd Interview', value: '2nd Interview' },
        { label: '3rd Interview', value: '3rd Interview' },
      ])
    } else if (check1) {
      setInterviewOptions([
        { label: '1st Interview', value: '1st Interview' },
        { label: '2nd Interview', value: '2nd Interview' },
      ])
    } else {
      setInterviewOptions([{ label: '1st Interview', value: '1st Interview' }])
    }
  }

  React.useEffect(() => {
    if (shortlist && shortlist.length > 0) {
      let array = []
      let check1 = false
      let check2 = false
      shortlist.map(short => {
        if (short.interview_stage) {
          if (short.interview_stage === '1st Interview') {
            check1 = true
          } else if (
            short.interview_stage === '2nd Interview' ||
            short.interview_stage === '3rd Interview'
          ) {
            check2 = true
          }
          array = [
            ...array,
            { label: short.interview_stage, value: short.interview_stage },
          ]
        } else {
          array = [...array, { label: 'Please select interview', value: '' }]
        }
        return array
      })
      if (check2) {
        setInterviewOptions([
          { label: '1st Interview', value: '1st Interview' },
          { label: '2nd Interview', value: '2nd Interview' },
          { label: '3rd Interview', value: '3rd Interview' },
        ])
      } else if (check1) {
        setInterviewOptions([
          { label: '1st Interview', value: '1st Interview' },
          { label: '2nd Interview', value: '2nd Interview' },
        ])
      } else {
        setInterviewOptions([
          { label: '1st Interview', value: '1st Interview' },
        ])
      }
      setInterviewList(array)
    }
  }, [shortlist])

  return (
    <>
      {shortlist && shortlist.length > 0 && (
        <>
          <PaddedBottomDivider />
          <section>
            <h3>Candidate Shortlist</h3>
            <CandidateWrapper>
              {shortlist.map((candidate, index) => (
                <ShortBlock key={candidate.id}>
                  <Candidate
                    id={candidate.id}
                    jobTitle={candidate.job_title}
                    name={`${candidate.first_name} ${candidate.last_name}`}
                    email={candidate.contact_email}
                    onChange={() =>
                      updateSelection(
                        candidate,
                        selectedCandidatesShortlist,
                        setSelectedCandidatesShortlist,
                      )
                    }
                    value={selectedCandidatesShortlist
                      .map(selectedCandidate => selectedCandidate.id)
                      .includes(candidate.id)}
                    status={candidate.current_status}
                    action="select"
                  />
                  <ISelect
                    name="candidate_interview_stage"
                    label=""
                    value={interviewList[index]}
                    options={interviewOptions}
                    onChange={e => selectInterview(e, index, candidate)}
                  />
                </ShortBlock>
              ))}
              {placedlist && placedlist.length >= vacancies ? <Overlay /> : ''}
            </CandidateWrapper>
          </section>
        </>
      )}
      {placedlist &&
      placedlist.length >= vacancies &&
      placedlist.length !== 0 ? (
        <>
          <PaddedBottomDivider />
          <div>
            <h3>
              Placed Candidate
              {placedlist.length > 1 ? 's' : ''}
            </h3>
            <CandidateWrapper>
              {placedlist.map(candidate => (
                <CandidateBlock key={candidate.id}>
                  <Candidate
                    id={candidate.id}
                    jobTitle={candidate.job_title}
                    name={`${candidate.first_name} ${candidate.last_name}`}
                    email={candidate.contact_email}
                    onChange={() =>
                      updateSelection(
                        candidate,
                        selectedCandidatesPlaced,
                        setSelectedCandidatesPlaced,
                      )
                    }
                    value={selectedCandidatesPlaced
                      .map(selectedCandidate => selectedCandidate.id)
                      .includes(candidate.id)}
                    status={candidate.current_status}
                    action="select"
                  />
                  <Button onClick={() => setUpdatePlacementVisible(candidate)}>
                    Update placement
                  </Button>
                </CandidateBlock>
              ))}
            </CandidateWrapper>
            <Button
              onClick={() =>
                updatePlacedCandidates(
                  jobId,
                  selectedCandidatesPlaced,
                  'remove',
                )
              }
            >
              Remove Selected Candidate
              {placedlist && placedlist.length > 1 ? 's' : ''}
            </Button>
          </div>
        </>
      ) : placedlist &&
        placedlist.length < vacancies &&
        placedlist.length !== 0 ? (
        <>
          <ButtonList>
            {shortlist && shortlist.length > 0 && (
              <Button
                onClick={() =>
                  updatePlacedCandidates(
                    jobId,
                    selectedCandidatesShortlist,
                    'place',
                  )
                }
              >
                Place Selected Candidates
              </Button>
            )}
            <Button onClick={() => setCandidateListVisibility(true)}>
              {shortlist && shortlist.length > 0 ? 'Edit ' : 'Generate '}
              Shortlist
            </Button>
          </ButtonList>
          <PaddedBottomDivider />
          <div>
            <h3>
              Placed Candidate
              {placedlist.length > 1 ? 's' : ''}
            </h3>
            <CandidateWrapper>
              {placedlist.map(candidate => (
                <CandidateBlock key={candidate.id}>
                  <Candidate
                    id={candidate.id}
                    jobTitle={candidate.job_title}
                    name={`${candidate.first_name} ${candidate.last_name}`}
                    email={candidate.contact_email}
                    onChange={() =>
                      updateSelection(
                        candidate,
                        selectedCandidatesPlaced,
                        setSelectedCandidatesPlaced,
                      )
                    }
                    value={selectedCandidatesPlaced
                      .map(selectedCandidate => selectedCandidate.id)
                      .includes(candidate.id)}
                    status={candidate.current_status}
                    action="select"
                  />
                  <Button onClick={() => setUpdatePlacementVisible(candidate)}>
                    Update placement
                  </Button>
                </CandidateBlock>
              ))}
            </CandidateWrapper>
            <Button
              onClick={() =>
                updatePlacedCandidates(
                  jobId,
                  selectedCandidatesPlaced,
                  'remove',
                )
              }
            >
              Remove Selected Candidate
              {placedlist && placedlist.length > 1 ? 's' : ''}
            </Button>
          </div>
        </>
      ) : (
        <ButtonList>
          {shortlist && shortlist.length > 0 && (
            <Button
              onClick={() =>
                updatePlacedCandidates(
                  jobId,
                  selectedCandidatesShortlist,
                  'place',
                )
              }
            >
              Place Selected Candidates
            </Button>
          )}
          <Button onClick={() => setCandidateListVisibility(true)}>
            {shortlist && shortlist.length > 0 ? 'Edit ' : 'Generate '}
            Shortlist
          </Button>
        </ButtonList>
      )}
      {_.isEmpty(backEndErrors) ? (
        ''
      ) : (
        <Error>{handleBackEndErrors(backEndErrors)}</Error>
      )}
    </>
  )
}

JobCandidates.defaultProps = {
  jobId: 0,
  shortlist: [],
  placedlist: [],
  vacancies: 1,
}

JobCandidates.propTypes = {
  jobId: PropTypes.number,
  shortlist: PropTypes.array,
  placedlist: PropTypes.array,
  setCandidateListVisibility: PropTypes.func.isRequired,
  setJob: PropTypes.func.isRequired,
  vacancies: PropTypes.number,
  setUpdatePlacementVisible: PropTypes.func.isRequired,
}
