import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ButtonLink, Card, CardGrid, Label, TextLink } from '../..'
import {
  ActivityCardFooter,
  CardType,
  ColumnedActivityCardBody,
  WideLabel,
} from '../'
import { getShortDate, handleData } from '../../../../utilities'

import { ReactComponent as JobIcon } from '../../../../assets/icons/job.svg'

const MarginButtonLink = styled(ButtonLink)`
  margin-top: 1rem;
  width: fit-content;
`

export const JobActivityCardClient = ({ job }) => (
  <Card mb="2rem">
    <CardGrid jobActivityCard={true}>
      <CardType>
        <JobIcon />
      </CardType>
      <ColumnedActivityCardBody>
        <WideLabel>
          <h2>{handleData(job.title)}</h2>
        </WideLabel>
        {job.start_date ? (
          <Label label="Starting">{handleData(job.start_date)}</Label>
        ) : (
          ''
        )}
        {job.end_date ? (
          <Label label="Leaving">{handleData(job.end_date)}</Label>
        ) : (
          ''
        )}
        {job.file ? (
          <Label label="File">
            <TextLink>{job.file}</TextLink>
          </Label>
        ) : (
          ''
        )}
        <Label label="Pay">{handleData(job.salary)}</Label>
        <WideLabel>
          <Label label="Description">
            <div
              dangerouslySetInnerHTML={{ __html: handleData(job.description) }}
            ></div>
          </Label>
        </WideLabel>
        <WideLabel>
          <MarginButtonLink to={`/jobs/${job.id}`}>View Job</MarginButtonLink>
        </WideLabel>
      </ColumnedActivityCardBody>
    </CardGrid>
    <CardGrid activityCard={true}>
      <ActivityCardFooter>
        Placed:{' '}
        {job.placed_timestamp && job.placed_by
          ? `${getShortDate(job.placed_timestamp)} by ${job.placed_by}`
          : 'No candidate placed'}
      </ActivityCardFooter>
    </CardGrid>
  </Card>
)

JobActivityCardClient.propTypes = {
  job: PropTypes.object.isRequired,
}
