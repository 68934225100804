import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

import { ReactComponent as Pencil } from '../../assets/icons/pencil.svg'
import { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg'
import { ReactComponent as BinIcon } from '../../assets/icons/bin.svg'

export const Button = styled.button`
  display: inline-block;
  background-color: ${p => p.theme.color.primary_button};
  border-radius: 1.25rem;
  font: 600 1rem/1rem proxima-nova, sans-serif;
  padding: 0.75rem 1.5rem;
  color: white;
  cursor: pointer;
  line-height: 1rem;
  border: none;
  transition: background-color 200ms ease-in-out;
  outline: none;

  ${p =>
    p.fullWidth
      ? css`
          display: block;
          width: 100%;
        `
      : null}

${p =>
  p.tall
    ? css`
        padding: 1rem 1.5rem;
        border-radius: 1.5rem;
      `
    : null}

  ${p =>
    p.center
      ? css`
          margin-left: auto;
          margin-right: auto;
        `
      : null}

  &:focus {
    background-color: ${p => p.theme.color.primary_dark};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${p => p.theme.color.primary_dark};
    }
  }

  &:disabled {
    background: lightgrey;
  }
`

export const SmallButton = styled(Button)`
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding: 0.5rem 1.5rem;
  background-color: ${p =>
    p.active ? '#446686' : p.theme.color.primary_button};
  &:focus {
    background-color: ${p =>
      p.active ? '#446686' : p.theme.color.primary_dark};
  }
`

export const ButtonLink = styled(Link)`
  display: inline-block;
  background-color: ${p => p.theme.color.primary_button};
  border-radius: 1.25rem;
  font: 600 1rem/1rem proxima-nova, sans-serif;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
  color: white;
  cursor: pointer;
  line-height: 1rem;
  border: none;
  transition: background-color 200ms ease-in-out;
  outline: none;

  ${p =>
    p.center
      ? css`
          margin-left: auto;
          margin-right: auto;
        `
      : null}

  &:focus {
    background-color: ${p => p.theme.color.primary_dark};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${p => p.theme.color.primary_dark};
    }
  }

  &:disabled {
    background: lightgrey;
  }
`

export const NewActivityButton = styled(Button)`
  display: block;
  margin: 0 0 1.5rem auto;
`

const ButtonWithIcon = styled.button`
  display: block;
  width: 1.875rem;
  height: 1.875rem;
  background: white;
  border-radius: 0.375rem;
  border: 1px solid ${p => p.theme.color.grey};
  cursor: pointer;
  transition: background-color 200ms ease-in-out;
  outline: none;
  position: relative;

  &:focus {
    background-color: ${p => p.theme.color.grey_light};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${p => p.theme.color.grey_light};
    }
  }
`

const ButtonIcon = styled.span`
  display: block;
  width: 0.875rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  svg {
    fill: ${p => p.theme.color.primary};
  }
`

export const DeleteButton = styled(Button)`
  background-color: ${p => p.theme.color.fail};
  transition: 200ms ease-in-out;
  &:focus {
    background-color: ${p => p.theme.color.fail};
    filter: brightness(90%);
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${p => p.theme.color.fail};
      filter: brightness(90%);
    }
  }
`

export const IconButton = ({ icon, ...props }) => (
  <ButtonWithIcon {...props}>
    <ButtonIcon>{icon === 'bin' ? <BinIcon /> : <Pencil />}</ButtonIcon>
  </ButtonWithIcon>
)

IconButton.defaultProps = {
  icon: 'pencil',
}

IconButton.propTypes = {
  icon: PropTypes.string,
}

const Close = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  padding: 0.625rem;
  cursor: pointer;
  width: ${p => (p.size === 'large' ? '2.5rem' : '2rem')};
  height: ${p => (p.size === 'large' ? '2.5rem' : '2rem')};
  outline: none;
  transition: background-color 250ms ease-in-out;

  &:focus,
  &:hover {
    background-color: ${p => p.hoverFill};
  }

  svg {
    width: ${p => (p.size === 'large' ? '1.25rem' : '0.75rem')};
    height: ${p => (p.size === 'large' ? '1.25rem' : '0.75rem')};
    fill: ${p => p.fill};
  }
`

export const CloseButton = ({ onClick, size, fill, hoverFill }) => (
  <Close onClick={onClick} size={size} fill={fill} hoverFill={hoverFill}>
    <CrossIcon />
  </Close>
)

CloseButton.defaultProps = {
  size: 'large',
  fill: '#0344B9',
  hoverFill: '#E1E1E1',
}

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string,
  fill: PropTypes.string,
  hoverFill: PropTypes.string,
}
