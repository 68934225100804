import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FileSaver from 'file-saver'
import axios from 'axios'

import {
  Title,
  SubTitle,
  ListHeader,
  PaddedDivider,
  Table,
  LoadingMore,
  ButtonColumn,
  ViewBtn,
  ViewIcon,
  ViewLink,
  Button,
} from '../ui'
import {
  Grid,
  SBox,
  Count,
  SCard,
  Text,
  SDatepicker,
  Split,
  DataContainer,
  TitleHeader,
} from './styles'
import useGetManagement from './useGetManagement'
import useGetBranches from './useGetBranch'
import Layout from '../layout'
import { Form } from '../form'
import { FormikSelect } from './form'
import { getReverseDate } from '../../utilities/dates'
import CardModal from './consultant/modal'
import { AuthContext } from '../../context/auth'
import { navigate } from '@reach/router'
import PDFContainer from './pdf'
import moment from 'moment'
import { useToasts } from 'react-toast-notifications/dist/ToastProvider'
import ExportCandidatesCsv from './exportCandidatesCsv'

const Dashboard = () => {
  const {
    managementData,
    getManagementData,
    // backEndErrors,
  } = useGetManagement()
  const { branches, getBranchesData } = useGetBranches()
  const auth = React.useContext(AuthContext)
  const [consultants, setConsultants] = React.useState([])
  const [modalVisible, setModalVisible] = React.useState(false)
  const [modalTitle, setModalTitle] = React.useState('')
  const [modalType, setModalType] = React.useState('')
  const [modalDates, setModalDates] = React.useState({})
  const [currentBranch, setCurrentBranch] = React.useState('')
  const [loadCheck, setLoadCheck] = React.useState(false)
  const { addToast } = useToasts()

  React.useEffect(() => {
    if (!auth?.user?.admin && !auth?.user?.branch_manager) {
      navigate('/')
    } else {
      if (!loadCheck) {
        getManagementData({
          branch_id: auth.user.branch_id ? auth.user.branch_id : '',
        })
        setCurrentBranch(auth.user.branch_id)
      }
    }
    if ((auth.user.admin || auth.user.director) && !loadCheck) {
      getBranchesData()
    }
    setLoadCheck(true)
  }, [
    auth,
    getBranchesData,
    getManagementData,
    branches,
    managementData,
    loadCheck,
  ])

  React.useEffect(() => {
    if (managementData && managementData.consultants) {
      let array = []
      for (const [key, value] of Object.entries(managementData.consultants)) {
        let obj = { name: key }
        for (const [keyI, valueI] of Object.entries(value)) {
          obj[keyI] = valueI
        }
        array = [...array, obj]
      }
      setConsultants(array)
    }
  }, [managementData])

  const onSubmit = async (values, { setSubmitting }) => {
    const formatData = values
    formatData.date_to = values.date_to ? getReverseDate(values.date_to) : ''
    formatData.date_from = values.date_from
      ? getReverseDate(values.date_from)
      : ''
    setModalDates({
      date_to: formatData.date_to,
      date_from: formatData.date_from,
    })
    getManagementData(formatData)
    setCurrentBranch(values.branch_id)
    setSubmitting(false)
  }

  const onSubmitReport = async (values, { setSubmitting }) => {
    const formatData = {}
    formatData.start_date = values.start_date
      ? moment(values.start_date).toISOString()
      : ''
    formatData.end_date = values.end_date
      ? moment(values.end_date).toISOString()
      : ''
    await axios
      .get(
        `/v1/candidates/report?start_date=${formatData.start_date}&end_date=${formatData.end_date}`,
        { responseType: 'blob' },
      )
      .then(res => {
        FileSaver.saveAs(res.data, 'candidate_report.pdf')
        addToast(`Candidate Report Downloading`, {
          appearance: 'success',
        })
      })
      .catch(err => {
        addToast(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : 'Unable to Download Report',
          {
            appearance: 'error',
          },
        )
      })
    setSubmitting(false)
  }

  const openModal = (title, type) => {
    setModalTitle(title)
    setModalType(type)
    setModalVisible(true)
  }

  return (
    <>
      <Layout>
        <TitleHeader>
          <Title style={{ marginRight: 'auto' }}>Management Information</Title>
        </TitleHeader>
        <TitleHeader>
          <Formik
            initialValues={{
              date_from: '',
              date_to: '',
              branch_id: auth?.user?.branch_id ? auth?.user?.branch_id : '',
            }}
            validationSchema={Yup.object().shape({
              date_from: Yup.date(),
              date_to: Yup.date(),
              branch_id: Yup.string(),
            })}
            onSubmit={onSubmit}
          >
            {({ submitForm, values }) => (
              <Form>
                <SCard style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {auth.user.admin || auth.user.director ? (
                    <div style={{ minWidth: '200px', marginRight: '1rem' }}>
                      <FormikSelect
                        name="branch_id"
                        label="Branch"
                        placeholder="Select Branch"
                        options={[
                          { label: 'All', value: '' },
                          ...branches?.map(branch => {
                            return { label: branch.name, value: branch.id }
                          }),
                        ]}
                        submit={submitForm}
                      />
                    </div>
                  ) : null}
                  <Split>
                    <SDatepicker
                      name="date_from"
                      label="Start date"
                      autoComplete="off"
                      placeholderText="Select a date"
                      maxDate={new Date(values.date_to)}
                      submit={submitForm}
                    />
                    <SDatepicker
                      name="date_to"
                      label="End date"
                      autoComplete="off"
                      placeholderText="Select a date"
                      minDate={new Date(values.date_from)}
                      submit={submitForm}
                    />
                  </Split>
                </SCard>
              </Form>
            )}
          </Formik>
        </TitleHeader>

        <ListHeader>
          <SubTitle longTitle>Client KPIs</SubTitle>
          {managementData?.client && (
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ marginLeft: 'auto', minWidth: '150px' }}>
                <PDFContainer
                  managementData={{ client: managementData?.client }}
                />
              </div>
            </div>
          )}
        </ListHeader>
        <DataContainer>
          <Grid>
            <SBox onClick={() => openModal('Sales Meeting', 'client')}>
              <Text>Sales Meeting</Text>
              <Count>
                {managementData &&
                managementData.client &&
                managementData.client.sales_meeting
                  ? managementData.client.sales_meeting
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Sales Call (Qualified)', 'client')}>
              <Text>Sales Call (Qualified)</Text>
              <Count>
                {managementData &&
                managementData.client &&
                managementData.client.sales_call_qualified
                  ? managementData.client.sales_call_qualified
                  : '0'}
              </Count>
            </SBox>
            {/* <SBox
              onClick={() => openModal('Sales Call (Unqualified)', 'client')}
            >
              <Text>Sales Call (Unqualified)</Text>
              <Count>
                {managementData &&
                  managementData.client &&
                  managementData.client.sales_call_unqualified
                  ? managementData.client.sales_call_unqualified
                  : '0'}
              </Count>
            </SBox> */}
            <SBox onClick={() => openModal('Service Call', 'client')}>
              <Text>Service Call</Text>
              <Count>
                {managementData &&
                managementData.client &&
                managementData.client.service_call
                  ? managementData.client.service_call
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Email Incoming', 'client')}>
              <Text>Email Incoming</Text>
              <Count>
                {managementData &&
                managementData.client &&
                managementData.client.email_incoming
                  ? managementData.client.email_incoming
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Email Outgoing', 'client')}>
              <Text>Email Outgoing</Text>
              <Count>
                {managementData &&
                managementData.client &&
                managementData.client.email_outgoing
                  ? managementData.client.email_outgoing
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('New Job', 'client')}>
              <Text>New Job</Text>
              <Count>
                {managementData &&
                managementData.client &&
                managementData.client.jobs
                  ? managementData.client.jobs
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('General Note', 'client')}>
              <Text>General Note</Text>
              <Count>
                {managementData &&
                managementData.client &&
                managementData.client.general_notes
                  ? managementData.client.general_notes
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Interview', 'client')}>
              <Text>Interview</Text>
              <Count>
                {managementData &&
                managementData.client &&
                managementData.client.interviews
                  ? managementData.client.interviews
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('SMS', 'client')}>
              <Text>SMS</Text>
              <Count>
                {managementData &&
                managementData.client &&
                managementData.client.sms
                  ? managementData.client.sms
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Placed Job', 'client')}>
              <Text>Placed Job</Text>
              <Count>
                {managementData &&
                managementData.client &&
                managementData.client.placed_jobs
                  ? managementData.client.placed_jobs
                  : '0'}
              </Count>
            </SBox>
            {/* <SBox onClick={() => openModal('Service Meeting', 'client')}>
              <Text>Service Meeting</Text>
              <Count>
                {managementData &&
                  managementData.client &&
                  managementData.client.service_meeting
                  ? managementData.client.service_meeting
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('E-Shot', 'client')}>
              <Text>E-Shot</Text>
              <Count>
                {managementData &&
                  managementData.client &&
                  managementData.client.e_shot
                  ? managementData.client.e_shot
                  : '0'}
              </Count>
            </SBox>
            
            <SBox onClick={() => openModal('LinkedIn Message', 'client')}>
              <Text>LinkedIn Message</Text>
              <Count>
                {managementData &&
                  managementData.client &&
                  managementData.client.linkedin
                  ? managementData.client.linkedin
                  : '0'}
              </Count>
            </SBox>
            
            <SBox onClick={() => openModal('Job Live', 'client')}>
              <Text>Jobs live</Text>
              <Count>
                {managementData &&
                  managementData.client &&
                  managementData.client.jobs_live
                  ? managementData.client.jobs_live
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Job Filled', 'client')}>
              <Text>Jobs Filled</Text>
              <Count>
                {managementData &&
                  managementData.client &&
                  managementData.client.jobs_filled
                  ? managementData.client.jobs_filled
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Job Cancelled', 'client')}>
              <Text>Jobs Cancelled</Text>
              <Count>
                {managementData &&
                  managementData.client &&
                  managementData.client.jobs_cancelled
                  ? managementData.client.jobs_cancelled
                  : '0'}
              </Count>
            </SBox> */}
            <SBox isEmpty>
              <Text></Text>
              <Count></Count>
            </SBox>
            <SBox isEmpty>
              <Text></Text>
              <Count></Count>
            </SBox>
          </Grid>
        </DataContainer>

        <PaddedDivider />

        <ListHeader>
          <SubTitle longTitle>Candidates</SubTitle>

          <div
            style={{
              display: 'flex',
              width: '100%',
              whiteSpace: 'nowrap',
              justifyContent: 'flex-end',
            }}
          >
            <ExportCandidatesCsv />
            {managementData?.candidate && (
              <div
                style={{
                  marginLeft: '10px',
                  minWidth: '150px',
                  whiteSpace: 'nowrap',
                }}
              >
                <PDFContainer
                  managementData={{ candidate: managementData.candidate }}
                />
              </div>
            )}
          </div>
        </ListHeader>
        <DataContainer>
          <Grid>
            <SBox onClick={() => openModal('Call Incoming', 'candidate')}>
              <Text>Call Incoming</Text>
              <Count>
                {managementData &&
                managementData.candidate &&
                managementData.candidate.call_incoming
                  ? managementData.candidate.call_incoming
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Call Outgoing', 'candidate')}>
              <Text>Call Outgoing</Text>
              <Count>
                {managementData &&
                managementData.candidate &&
                managementData.candidate.call_outgoing
                  ? managementData.candidate.call_outgoing
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Do Not Use Candidates', '')}>
              <Text>Do Not Use Candidates</Text>
              <Count>
                {managementData && managementData.totals
                  ? managementData.totals.do_not_use_candidates
                  : 0}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Prospected Candidates', '')}>
              <Text>Prospect Candidates</Text>
              <Count>
                {managementData && managementData.totals
                  ? managementData.totals.prospect_candidates
                  : 0}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Live Candidates', '')}>
              <Text>Live Candidates</Text>
              <Count>
                {managementData && managementData.totals
                  ? managementData.totals.live_candidates
                  : 0}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Registration', 'candidate')}>
              <Text>Registration</Text>
              <Count>
                {managementData &&
                managementData.candidate &&
                managementData.candidate.registration
                  ? managementData.candidate.registration
                  : '0'}
              </Count>
            </SBox>
            <SBox isEmpty>
              <Text></Text>
              <Count></Count>
            </SBox>
            <SBox isEmpty>
              <Text></Text>
              <Count></Count>
            </SBox>
          </Grid>
        </DataContainer>

        {/* TODO add report download */}
        <Formik
          initialValues={{
            date_from: '',
            date_to: '',
          }}
          validationSchema={Yup.object().shape({
            date_from: Yup.date(),
            date_to: Yup.date(),
          })}
          onSubmit={onSubmitReport}
        >
          {({ values }) => (
            <Form>
              <SCard
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Split>
                  <SDatepicker
                    name="start_date"
                    label="Start date"
                    autoComplete="off"
                    placeholderText="Select a date"
                    maxDate={new Date(values.end_date)}
                  />
                  <SDatepicker
                    name="end_date"
                    label="End date"
                    autoComplete="off"
                    placeholderText="Select a date"
                    minDate={new Date(values.start_date)}
                  />
                </Split>

                <Button type="submit">Generate Share Code Report</Button>
              </SCard>
            </Form>
          )}
        </Formik>

        <PaddedDivider />
        <ListHeader>
          <Title style={{ gridColumn: '1 / span 2' }}>
            Consultant Information
          </Title>
        </ListHeader>
        {consultants ? (
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>SM</th>
                <th>SCQ</th>
                <th>SC</th>
                <th>EI</th>
                <th>EO</th>
                <th data-tablet>R</th>
                <th data-tablet>GN</th>
                <th data-tablet>I</th>
                <th data-tablet>SMS</th>
                <th data-tablet>PJ</th>
                <th data-tablet></th>
              </tr>
            </thead>
            <tbody>
              {consultants && consultants.length > 0
                ? consultants.map((consult, idx) => (
                    <tr key={`${consult.name}_${idx}`}>
                      <td>{consult.name}</td>
                      <td>{consult.sales_meeting}</td>
                      <td>{consult.sales_call_qualified}</td>
                      <td>{consult.service_call}</td>
                      <td>{consult.email_incoming}</td>
                      <td>{consult.email_outgoing}</td>
                      <td data-tablet>{consult.registration}</td>
                      <td data-tablet>{consult.general_notes}</td>
                      <td data-tablet>{consult.interviews}</td>
                      <td data-tablet>{consult.sms}</td>
                      <td data-tablet>{consult.placed_job}</td>

                      <ButtonColumn>
                        <ViewLink to={`/management/consultant/${consult.id}`}>
                          <ViewIcon data-mobile data-tablet />
                          <ViewBtn data-web>View</ViewBtn>
                        </ViewLink>
                      </ButtonColumn>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        ) : (
          <LoadingMore>No Consultant found</LoadingMore>
        )}
        {managementData && (
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ marginLeft: 'auto', minWidth: '150px' }}>
              <PDFContainer managementData={managementData} />
            </div>
          </div>
        )}
      </Layout>
      {modalVisible ? (
        <CardModal
          title={modalTitle}
          modalVisible={modalVisible}
          onClose={() => setModalVisible(false)}
          date_to={modalDates ? modalDates.date_to : ''}
          date_from={modalDates ? modalDates.date_from : ''}
          type={modalType}
          branchID={currentBranch}
        />
      ) : null}
    </>
  )
}

export default Dashboard
