import { useState } from 'react'

import { usePaginatedFetch } from '../../../../hooks'

const useGetCandidateList = () => {
  let base = 'v1/candidates/search'
  const [url, setUrl] = useState(base)
  const { data, ...rest } = usePaginatedFetch(url, 'Unable to fetch candidates')

  const search = (query, location) => {
    let queryUrl = ''
    if (query) queryUrl += 'name=' + query
    if (location && query) queryUrl += '&'
    if (location) queryUrl += 'location=' + location
    setUrl(queryUrl ? base + '?' + queryUrl : base)
  }

  const formattedData =
    data &&
    data.map(candidate => ({
      current_status: candidate.current_status,
      id: candidate.id,
      employee_id: candidate.employee_id,
      first_name: candidate.first_name,
      last_name: candidate.last_name,
      job_title: candidate.job_title,
      contact_email: candidate.contact_email,
      contact_mobile: candidate.contact_mobile,
    }))

  return { ...rest, data: formattedData, search }
}

export default useGetCandidateList
