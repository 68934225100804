import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import _ from 'lodash'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import {
  Modal,
  ModalFooter,
  ModalColumns,
  Button,
  PaddedDivider,
} from '../../../ui'
import { FormikText, Form, Error, FormikDatepicker } from '../../../form'
import { jsonToFormData, handleBackEndErrors } from '../../../../utilities'
import { getReverseDate } from '../../../../utilities/dates'

const WideFormikText = styled(FormikText)`
  ${p => p.theme.layout.tablet} {
    grid-column: 1/3;
  }
`

const DiaryModal = ({
  isVisible,
  diary,
  setModalVisibility,
  submitResponse,
  setDiaryToEdit,
  client,
}) => {
  const newDiary = _.isEmpty(diary)
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})

  const onSubmit = async (values, { setSubmitting }) => {
    const newValues = jsonToFormData({
      ...values,
      note_date: getReverseDate(values.note_date),
      client_id: client,
    })
    axios({
      method: 'post',
      url: newDiary ? 'v1/diary' : `v1/diary/${values.id}`,
      data: newValues,
    })
      .then(response => {
        submitResponse(
          { ...response.data.note, type: 'Diary' },
          newDiary ? 'create' : 'edit',
        )
        if (!newDiary) setDiaryToEdit({})
        addToast(`Diary ${newDiary ? 'created' : 'updated'} successfully`, {
          appearance: 'success',
        })
        setModalVisibility(false)
      })

      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(
            `Error ${
              newDiary ? 'creating' : 'updating'
            } Diary, please try again`,
            {
              appearance: 'error',
            },
          )
        }
      })

    setSubmitting(false)
  }

  return (
    <Modal
      isVisible={isVisible}
      heading={newDiary ? 'Create New Diary' : 'Update Diary'}
      onClose={() => {
        setModalVisibility(false)
        setDiaryToEdit({})
      }}
    >
      <Formik
        initialValues={{
          id: diary?.id ? diary.id : '',
          note: diary?.note ? diary.note : '',
          note_date: diary?.note_date ? new Date(diary.note_date) : new Date(),
        }}
        validationSchema={Yup.object().shape({
          note: Yup.string().required('Required'),
          note_date: Yup.string().required('Required'),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalColumns>
              <FormikDatepicker name="note_date" label="Date" />
              <WideFormikText name="note" label="Description" rows={5} />
            </ModalColumns>
            {_.isEmpty(backEndErrors) ? (
              ''
            ) : (
              <>
                <PaddedDivider />
                <Error>{handleBackEndErrors(backEndErrors)}</Error>
              </>
            )}
            <ModalFooter>
              <Button type="submit" disabled={isSubmitting}>
                {newDiary ? 'Save' : 'Save changes'}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

DiaryModal.defaultProps = {
  diary: {},
}

DiaryModal.propTypes = {
  diary: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  submitResponse: PropTypes.func.isRequired,
  setDiaryToEdit: PropTypes.func.isRequired,
  client: PropTypes.string.isRequired,
}

export default DiaryModal
