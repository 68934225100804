import React from 'react'
import PropTypes from 'prop-types'

import {
  Modal,
  ModalColumns,
  PaddedDivider,
  CardProfileImage,
  Label,
} from '../../../ui'
import { FormGroupHeading, TagWrapper, Tag } from '../../../form'
import { getShortDate } from '../../../../utilities'

const ViewDetailsModal = ({
  isVisible,
  details,
  setModalVisibility,
  title,
  user,
}) => {
  const checkValue = value => {
    if (value) return value
    else return ''
  }
  return (
    <Modal
      isVisible={isVisible}
      heading={title}
      onClose={() => setModalVisibility(false)}
      size="large"
    >
      <div>
        <FormGroupHeading>Candidate Profile Details</FormGroupHeading>
        <ModalColumns>
          <div style={{ width: '72px', marginBottom: '1rem' }}>
            <CardProfileImage imageURL={details.profile_image} />
          </div>
        </ModalColumns>
        <ModalColumns>
          <Label label="First name">{details.first_name}</Label>
          <Label label="Last name">{details.last_name}</Label>
          <Label label="Employee ID">{checkValue(details.employee_id)}</Label>
          <Label label="Job title">{checkValue(details.job_title)}</Label>
          <Label label="Date Registered">
            {details.date_registered
              ? getShortDate(details.date_registered)
              : ''}
          </Label>
          <Label label="Pre-registered Date">
            {details.pre_registration_date
              ? getShortDate(details.pre_registration_date)
              : ''}
          </Label>
        </ModalColumns>
        <PaddedDivider />
        <FormGroupHeading>Candidate Contact Details</FormGroupHeading>
        <ModalColumns>
          <Label label="Email">{checkValue(details.contact_email)}</Label>
          <Label label="Mobile">{checkValue(details.contact_mobile)}</Label>
          <Label label="Alternative mobile">
            {checkValue(details.contact_alternative_mobile)}
          </Label>
          <Label label="Landline">{checkValue(details.contact_landline)}</Label>
        </ModalColumns>
        <PaddedDivider />
        <FormGroupHeading>Emergency Contact Details</FormGroupHeading>
        <ModalColumns>
          <Label label="First name">
            {checkValue(details.emergency_first_name)}
          </Label>
          <Label label="Last name">
            {checkValue(details.emergency_last_name)}
          </Label>
          <Label label="Contact number">
            {checkValue(details.emergency_contact_number)}
          </Label>
          <Label label="Relation to the candidate">
            {checkValue(details.emergency_relation)}
          </Label>
        </ModalColumns>
        <PaddedDivider />
        <FormGroupHeading>Address Details</FormGroupHeading>
        <ModalColumns>
          <Label label="Address Line 1">{checkValue(details.address_1)}</Label>
          <Label label="Address Line 2">{checkValue(details.address_2)}</Label>
          <Label label="Address Line 3">{checkValue(details.address_3)}</Label>
          <Label label="Town/City">{checkValue(details.address_town)}</Label>
          <Label label="Postcode">{checkValue(details.address_postcode)}</Label>
          <Label label="Country">{checkValue(details.address_country)}</Label>
        </ModalColumns>
        <PaddedDivider />
        {user && user.admin ? (
          <>
            <FormGroupHeading>Bank &amp; NI Details</FormGroupHeading>
            <ModalColumns>
              <Label label="Name of Bank">
                {checkValue(details.bank_name)}
              </Label>
              <Label label="Account Number">
                {checkValue(details.bank_account_number)}
              </Label>
              <Label label="Sort Code">
                {checkValue(details.bank_sort_code)}
              </Label>
              <Label label="National Insurance Number">
                {checkValue(details.ni_number)}
              </Label>
            </ModalColumns>
          </>
        ) : (
          <>
            <FormGroupHeading>NI Details</FormGroupHeading>
            <ModalColumns>
              <Label label="National Insurance Number">
                {checkValue(details.ni_number)}
              </Label>
            </ModalColumns>
          </>
        )}
        <PaddedDivider />
        <FormGroupHeading>Status</FormGroupHeading>
        <ModalColumns>
          <Label label="Current Status">
            {checkValue(details.current_status)}
          </Label>
          <Label label="Employment Status">
            {checkValue(details.employment_status)}
          </Label>
          <Label label="Start date">
            {details && details.start_date
              ? getShortDate(details.start_date)
              : ''}
          </Label>
          <Label label="End date">
            {details && details.end_date ? getShortDate(details.end_date) : ''}
          </Label>
        </ModalColumns>
        <PaddedDivider />
        <FormGroupHeading>Qualifications</FormGroupHeading>
        <ModalColumns>
          <Label label="Main skill">{checkValue(details.main_skill)}</Label>
          <Label label="Driving License Type">
            <TagWrapper>
              {details &&
              details.driving_license_type &&
              details.driving_license_type.length >= 1
                ? details.driving_license_type.map(tag => (
                    <Tag key={tag}>{tag}</Tag>
                  ))
                : ''}
            </TagWrapper>
          </Label>
        </ModalColumns>
        <PaddedDivider />
        <FormGroupHeading>Skill Tags</FormGroupHeading>
        <TagWrapper>
          {details && details.skill_tags && details.skill_tags.length >= 1
            ? details.skill_tags.map(tag => <Tag key={tag.id}>{tag.tag}</Tag>)
            : ''}
        </TagWrapper>
        <PaddedDivider />
        <FormGroupHeading>Work History</FormGroupHeading>
        {details && details.work_history && details.work_history.length >= 1
          ? details.work_history.map((work, idx) => (
              <React.Fragment key={`work_history_${idx}`}>
                <ModalColumns>
                  <Label label="Name of company">
                    {checkValue(work.company_name)}
                  </Label>
                  <Label label="Role">{checkValue(work.role)}</Label>
                  <Label label="Start date">
                    {work.start_date ? getShortDate(work.start_date) : ''}
                  </Label>
                  <Label label="Leaving date">
                    {work.end_date ? getShortDate(work.end_date) : ''}
                  </Label>
                </ModalColumns>
                <PaddedDivider />
              </React.Fragment>
            ))
          : ''}
        <FormGroupHeading>Preferred Shift Patterns</FormGroupHeading>
        <TagWrapper>
          {details && details.shift_tags && details.shift_tags.length >= 1
            ? details.shift_tags.map(shift => (
                <Tag key={shift.id}>{shift.tag}</Tag>
              ))
            : ''}
        </TagWrapper>
        <PaddedDivider />
        <FormGroupHeading>Salary Expectations</FormGroupHeading>
        <ModalColumns>
          <Label label="">
            {details.salary_expectation_min && details.salary_expectation_max
              ? `£${details.salary_expectation_min} - £${details.salary_expectation_max}`
              : details.salary_expectation_min &&
                !details.salary_expectation_max
              ? `£${details.salary_expectation_min}`
              : !details.salary_expectation_min &&
                details.salary_expectation_max
              ? `£${details.salary_expectation_max}`
              : ``}
          </Label>
        </ModalColumns>
        <PaddedDivider />
        <FormGroupHeading>Right to Work Document</FormGroupHeading>
        <ModalColumns>
          {details.right_to_work_document ? (
            <Label label="">{details.right_to_work_document}</Label>
          ) : (
            <div>No file</div>
          )}
          <Label label="Right to work date">
            {details && details.right_to_work_date
              ? getShortDate(details.right_to_work_date)
              : ''}
          </Label>
        </ModalColumns>
        <PaddedDivider />
        <FormGroupHeading>Other Documents</FormGroupHeading>
        <ModalColumns>
          {details.cv ? (
            <Label label="">{details.cv}</Label>
          ) : (
            <div>No file</div>
          )}
          {details.cscs_card ? (
            <Label label="">{details.cscs_card}</Label>
          ) : (
            <div></div>
          )}
        </ModalColumns>
        <PaddedDivider />
        <FormGroupHeading>Candidate Source</FormGroupHeading>
        <ModalColumns>
          <Label label="">{checkValue(details.source)}</Label>
        </ModalColumns>
      </div>
    </Modal>
  )
}

ViewDetailsModal.defaultProps = {
  details: {},
  user: {},
}

ViewDetailsModal.propTypes = {
  details: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  user: PropTypes.object,
}

export default ViewDetailsModal
