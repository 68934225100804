import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import _ from 'lodash'

import { useLockBodyScroll } from '../../hooks'

import { Button, DeleteButton, Card, Title, CloseButton } from './'
import { Error } from '../form'
import { handleBackEndErrors } from '../../utilities'

const ModalOverlay = styled.div`
  position: fixed;
  top: ${p => p.theme.layout.header_height};
  bottom: ${p => p.theme.layout.nav_height_mobile};
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;

  ${p => p.theme.layout.tablet} {
    bottom: 0;
    left: ${p => p.theme.layout.nav_width_tablet};
  }

  ${p => p.theme.layout.web} {
    left: ${p => p.theme.layout.nav_width_web};
  }
`

const ModalContainer = styled(Card)`
  position: absolute;
  padding: 1rem;
  width: calc(100% - 3rem);
  margin: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  ${p => p.theme.layout.phone_large} {
    padding: 1.5rem;
    width: 90%;
    max-width: ${p =>
      p.size === 'large' ? '1024px' : p.size === 'medium' ? '720px' : '600px'};
  }
`
const Header = styled.header`
  display: grid;
  align-items: top;
  text-align: left;
  grid-template-columns: 1fr 2.5rem;

  ${p => p.theme.layout.phone_large} {
    text-align: center;
    grid-template-columns: 2.5rem 1fr 2.5rem;
  }
`

const ModalCloseButton = styled(CloseButton)`
  ${p => p.theme.layout.phone_large} {
    grid-column: 3/4;
  }
`

const ModalTitle = styled(Title)`
  margin: 0;
  line-height: 2.5rem;

  ${p => p.theme.layout.phone_large} {
    grid-column: 2/3;
  }
`

const Section = styled.section`
  margin: 2rem 0 1.5rem;
  overflow: auto;
  max-height: calc(
    100vh -
      (
        (${p => p.theme.layout.nav_height_mobile} + 7rem) +
          (${p => p.theme.layout.header_height} + 4rem)
      )
  ); /* 100vh - ((Header + 2rem overlay gap, 1rem padding, 2.5rem header, 1.5rem margin) + (BottomBar + 1.5rem overlay gap, 1rem padding, 1.5rem margin)) */

  ${p =>
    p.fixed &&
    `height: calc(
    100vh -
      (
        (${p.theme.layout.nav_height_mobile} + 7rem) +
          (${p.theme.layout.header_height} + 4rem)
      )
  );`}

  ${p => p.theme.layout.phone_large} {
    padding: 0 1.5rem;
  }

  ${p => p.theme.layout.tablet} {
    max-height: calc(
      100vh - (${p => p.theme.layout.header_height} + 14rem)
    ); /* 100vh - ((Header + 3rem overlay gap (top) + 2.5rem overlay gap (bottom), 1.5rem padding (x2), 2.5rem header, 1.5rem margin (x2)) */

    ${p =>
      p.fixed &&
      `height: calc(
      100vh - (${p.theme.layout.header_height} + 12rem)
    );`}
  }
`

export const ModalFooter = styled.footer`
  text-align: center;
  padding-top: 1rem;

  button {
    display: block;
    margin: 0.75rem auto 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
`

export const ModalColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${p => p.theme.layout.tablet} {
    grid-gap: 0 1.875rem;
    gap: 0 1.875rem;
    grid-template-columns: 1fr 1fr;
  }
`

export const ModalText = styled.p`
  font: 400 1.125rem/1.75rem proxima-nova, sans-serif;
  margin: 0 auto 1.5rem auto;
  max-width: 25rem;
  text-align: center;
`

export const Modal = ({
  children,
  heading,
  isVisible,
  onClose,
  size,
  fixed,
}) => {
  useLockBodyScroll()

  return (
    <CSSTransition in={isVisible} timeout={200} classNames="fade" unmountOnExit>
      <ModalOverlay>
        <ModalContainer size={size}>
          <Header>
            <ModalTitle>{heading}</ModalTitle>
            <ModalCloseButton onClick={onClose} />
          </Header>
          <Section fixed={fixed}>{children}</Section>
        </ModalContainer>
      </ModalOverlay>
    </CSSTransition>
  )
}

Modal.defaultProps = {
  size: 'medium',
  fixed: false,
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  size: PropTypes.string,
  fixed: PropTypes.bool,
}

export const WarningModal = ({
  children,
  closeFunc,
  heading,
  isVisible,
  submitFunc,
  submitButtonText,
  backEndErrors,
}) => (
  <Modal
    isVisible={isVisible}
    heading={heading}
    onClose={closeFunc}
    size="small"
  >
    <ModalText>{children}</ModalText>
    {_.isEmpty(backEndErrors) ? (
      ''
    ) : (
      <Error>{handleBackEndErrors(backEndErrors)}</Error>
    )}
    <ModalFooter>
      <DeleteButton onClick={submitFunc}>{submitButtonText}</DeleteButton>
      <Button onClick={closeFunc}>Cancel</Button>
    </ModalFooter>
  </Modal>
)

WarningModal.propTypes = {
  children: PropTypes.node.isRequired,
  closeFunc: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  submitFunc: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  backEndErrors: PropTypes.object.isRequired,
}
