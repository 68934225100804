import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import _ from 'lodash'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import {
  DeleteButton,
  Modal,
  ModalFooter,
  ModalColumns,
  Button,
  PaddedDivider,
  PaddedBottomDivider,
} from '../../../ui'
import {
  Error,
  Form,
  FormGroupHeading,
  FormikCheckbox,
  FormikInput,
  FormikProfilePhoto,
  FormikSelect,
  TagSelector,
} from '../../../form'
import { validationSchema } from './Validation'
import { initialValues } from './InitialValues'
import { handleBackEndErrors } from '../../../../utilities'
import { useGetClientTags } from './useGetClientTags'

const DetailsModal = ({
  isVisible,
  details,
  setModalVisibility,
  submitResponse,
  setParentState,
}) => {
  const { tags, isFetching } = useGetClientTags()
  const newClient = _.isEmpty(details)
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})

  const onSubmit = async (values, { setSubmitting }) => {
    axios({
      method: 'post',
      url: newClient ? '/v1/clients/create' : 'v1/clients/update',
      data: values,
    })
      .then(response => {
        if (newClient) submitResponse(response.data)
        else setParentState(response.data)
        setModalVisibility(false)
        addToast(`Client ${newClient ? 'created' : 'updated'} successfully`, {
          appearance: 'success',
        })
      })
      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(
            `Error ${
              newClient ? 'creating' : 'updating'
            } client, please try again`,
            {
              appearance: 'error',
            },
          )
        }
      })

    setSubmitting(false)
  }

  const addRow = async (
    rep_type,
    values,
    validateForm,
    setFieldTouched,
    setFieldValue,
  ) => {
    const errors = await validateForm()
    const currentReps = values[rep_type]

    const rep_obj = {
      first_name: '',
      last_name: '',
      email: '',
      landline: '',
      mobile: '',
      eshot_contact: 0,
    }
    if (errors[rep_type]) {
      // touch all fields of last rep
      const fields = Object.keys(rep_obj)
      return fields.forEach(field => {
        const currentRep = currentReps.length - 1
        const fieldPath = `[${rep_type}][${currentRep}][${field}]`
        setFieldTouched(fieldPath)
      })
    } else {
      // add new rep
      setFieldValue(`[${rep_type}]`, [...currentReps, rep_obj])
    }
  }

  const removeRow = (
    rep_type,
    repIndexToRemove,
    values,
    setFieldTouched,
    setFieldValue,
  ) => {
    const reps = values[rep_type]
    // filter out rep by index
    const updatedReps = reps.filter((_, idx) => idx !== repIndexToRemove)
    // clear any touched errors
    setFieldTouched(`[${rep_type}][${repIndexToRemove}]`, false)
    setFieldValue(`[${rep_type}]`, updatedReps)
  }

  const dot = value => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor:
        value === 'active'
          ? '#07BB8C'
          : value === 'Do not use'
          ? '#db3e57'
          : value === 'healthcare'
          ? '#008578'
          : value === 'Live'
          ? '#07BB8C'
          : '#F3692A',
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 10,
      height: 10,
      width: 10,
    },
  })

  const colourStyles = {
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.value) }),
  }

  return (
    <Modal
      isVisible={isVisible}
      heading={newClient ? 'Add New Client' : 'Edit Client'}
      onClose={() => setModalVisibility(false)}
      size="large"
    >
      <Formik
        initialValues={initialValues(details)}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          values,
          setFieldTouched,
          setFieldValue,
          validateForm,
        }) => (
          <Form>
            <FormGroupHeading>Client Details</FormGroupHeading>
            <FormikProfilePhoto
              name="profile_image"
              endpointUrl="v1/clients/profile-image/create"
              id={values.client_id ? values.client_id : ''}
            />
            <ModalColumns>
              <FormikInput type="text" name="name" label="Client name" />
              <FormikInput type="text" name="type" label="Client type" />
              <FormikInput type="text" name="client_id" label="Client ID" />
              <FormikSelect
                name="head_count"
                label="Temp Head Count"
                placeholder="Set head count"
                options={[
                  {
                    value: '1-5',
                    label: '1-5',
                  },
                  {
                    value: '5-15',
                    label: '5-15',
                  },
                  {
                    value: '15-30',
                    label: '15-30',
                  },
                  {
                    value: '30-50',
                    label: '30-50',
                  },
                  {
                    value: '50-75',
                    label: '50-75',
                  },
                  {
                    value: '100',
                    label: '100+',
                  },
                ]}
              />
            </ModalColumns>
            <PaddedBottomDivider />
            <FormGroupHeading>Client Address Details</FormGroupHeading>
            <ModalColumns>
              <FormikInput
                type="text"
                name="address_1"
                label="Address Line 1"
              />
              <FormikInput
                type="text"
                name="address_2"
                label="Address Line 2"
              />
              <FormikInput
                type="text"
                name="address_3"
                label="Address Line 3"
              />
              <FormikInput type="text" name="address_town" label="Town/City" />
              <FormikInput
                type="text"
                name="address_postcode"
                label="Postcode"
              />
              <FormikInput type="text" name="address_country" label="Country" />
            </ModalColumns>
            <FormGroupHeading>Client Contact Details</FormGroupHeading>
            <ModalColumns>
              <FormikInput
                type="text"
                name="contact_telephone"
                label="Telephone"
              />
              <FormikInput type="text" name="contact_website" label="Website" />
            </ModalColumns>
            <PaddedBottomDivider />
            <FormGroupHeading>Tags</FormGroupHeading>
            <TagSelector
              name="tags"
              label="Create new or select from existing tags"
              options={isFetching ? ['Loading...'] : tags}
            />
            <PaddedBottomDivider />
            <FormGroupHeading>Status</FormGroupHeading>
            <ModalColumns>
              <FormikSelect
                name="status"
                label="Current Status"
                placeholder="Set current status"
                options={[
                  {
                    value: 'Prospect',
                    label: 'Prospect',
                  },
                  {
                    value: 'Live',
                    label: 'Live',
                  },
                  {
                    value: 'Do not use',
                    label: 'Do not use',
                  },
                  {
                    value: 'healthcare',
                    label: 'Healthcare',
                  },
                ]}
                styles={colourStyles}
              />
            </ModalColumns>
            <PaddedBottomDivider />
            <FormGroupHeading>Invoice Details</FormGroupHeading>
            <ModalColumns>
              <FormikInput
                type="text"
                name="invoice_address"
                label="Invoice Address"
              />
              <FormikInput
                type="text"
                name="invoice_email"
                label="Invoice Email"
              />
            </ModalColumns>
            <PaddedBottomDivider />
            <FormGroupHeading>Client Contacts</FormGroupHeading>
            {values
              ? values.contacts.map((_, idx) => (
                  <React.Fragment key={`contacts_${idx}`}>
                    <ModalColumns>
                      <FormikInput
                        name={`contacts[${idx}].first_name`}
                        type="text"
                        label="First name"
                      />
                      <FormikInput
                        name={`contacts[${idx}].last_name`}
                        type="text"
                        label="Last name"
                      />
                      <FormikInput
                        name={`contacts[${idx}].email`}
                        type="text"
                        label="Email"
                      />
                      <FormikInput
                        name={`contacts[${idx}].job_title`}
                        type="text"
                        label="Job title"
                      />
                      <FormikInput
                        name={`contacts[${idx}].landline`}
                        type="text"
                        label="Landline"
                      />
                      <FormikInput
                        name={`contacts[${idx}].mobile`}
                        type="text"
                        label="Mobile"
                      />
                      <FormikCheckbox
                        name={`contacts[${idx}].eshot_contact`}
                        label="Primary E-shot contact"
                        style={{ marginBottom: '1rem' }}
                      />
                    </ModalColumns>
                    {values.contacts.length > 1 && idx !== 0 ? (
                      <>
                        <DeleteButton
                          type="button"
                          name="remove_row"
                          onClick={() =>
                            removeRow(
                              'contacts',
                              idx,
                              values,
                              setFieldTouched,
                              setFieldValue,
                            )
                          }
                        >
                          Delete Contact
                        </DeleteButton>
                      </>
                    ) : null}
                    <PaddedDivider />
                  </React.Fragment>
                ))
              : null}
            <Button
              onClick={() =>
                addRow(
                  'contacts',
                  values,
                  validateForm,
                  setFieldTouched,
                  setFieldValue,
                )
              }
              type="button"
            >
              Add another contact
            </Button>
            <PaddedDivider />
            {_.isEmpty(backEndErrors) ? (
              ''
            ) : (
              <Error>{handleBackEndErrors(backEndErrors)}</Error>
            )}
            <ModalFooter>
              <Button type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

DetailsModal.defaultProps = {
  details: {},
  submitResponse: () => {},
  setParentState: () => {},
}

DetailsModal.propTypes = {
  details: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  submitResponse: PropTypes.func,
  setParentState: PropTypes.func,
}

export default DetailsModal
