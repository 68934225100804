import React from 'react'
import { Router } from '@reach/router'

import List from './list'
import JobDetails from './jobDetails'

const Jobs = () => {
  return (
    <Router>
      <List default path="/" />
      <JobDetails path="/:id" />
    </Router>
  )
}

export default Jobs
