import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { navigate } from '@reach/router'
import { useToasts } from 'react-toast-notifications'

import { WarningModal } from '../../../ui'

export const DeleteCandidateModal = ({
  id,
  name,
  isVisible,
  setModalVisibility,
}) => {
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})

  const DeleteCandidate = async id => {
    axios({
      method: 'post',
      url: `/v1/candidates/${id}/delete`,
    })
      .then(response => {
        if (response.data.success) {
          setModalVisibility(false)
          navigate('/candidates')
          addToast('Candidate deleted successfully', { appearance: 'success' })
        } else {
          addToast('Error deleting candidate, please try again', {
            appearance: 'error',
          })
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error deleting candidate, please try again`, {
            appearance: 'error',
          })
        }
        addToast('Error deleting candidate, please try again', {
          appearance: 'error',
        })
      })
  }

  return (
    <WarningModal
      isVisible={isVisible}
      heading="Delete Candidate?"
      closeFunc={() => setModalVisibility(false)}
      submitButtonText="Delete Candidate"
      submitFunc={() => DeleteCandidate(id)}
      backEndErrors={backEndErrors}
    >
      <b>{name}</b> will no longer appear in searches or be able to be placed in
      client jobs
    </WarningModal>
  )
}

DeleteCandidateModal.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
}
