import styled from 'styled-components'

export { default as Form } from './formHelper'
export { InputWithLabel, FormikInput } from './input'
export { Select, FormikSelect } from './select'
export { SearchInput } from './search'
export { FormikFileInput } from './fileUpload'
export { FormikProfilePhoto } from './profilePhoto'
export {
  CheckboxWithLabel,
  CheckboxWithoutLabel,
  Checkbox,
  Check,
  CheckboxWrapper,
  FormikCheckbox,
} from './checkbox'
export { TextareaWithLabel, FormikText } from './textarea'
export { TagSelector, Tag, TagWrapper } from './tags'
export { Datepicker, FormikDatepicker } from './datepicker'
export { FormikEditor } from './FormikEditor'

export const InputWrapper = styled.div.attrs(props => ({
  className: props.hasError ? 'has-error' : '',
}))`
  position: relative;
  display: flex;
  cursor: pointer;

  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 0.125rem solid
      ${p => (p.hasError ? p.theme.color.fail : 'transparent')};
    transition: border-color 250ms ease-in-out;
  }
`

export const FormTitle = styled.h1`
  margin: ${p => (p.mt ? p.mt : '2rem')} 0 ${p => (p.mb ? p.mb : '2rem')};
  font-size: 24px;
  letter-spacing: -0.8px;
`

export const FormGroupHeading = styled.h3`
  margin-bottom: 1rem;
`

export const Label = styled.label`
  font-size: 1rem;
  color: ${p => p.theme.color.text};
  display: inline-block;
  margin-bottom: 0.5rem;
`

export const Error = styled.div`
  min-height: 30px;
  font-size: 16px;
  font-weight: 300;
  line-height: 30px;
  color: ${p => p.theme.color.fail};
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`
