import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import CandidateList from './candidateListModal'
// import EshotList from './eshotModal'
import Layout from '../../layout'
import useGetJobDetails from './useGetJobDetails'
import { DeleteJobModal } from './deleteJobModal'
import { EditJobModal } from './editJobModal'
import { Tag, TagWrapper } from '../../form'

import { JobCandidates } from './jobCandidates'
import { createFileText, handleData } from '../../../utilities'
import { TextLink, Label, ViewBtn, ViewLink, IconButton } from '../../ui'
import {
  JobBox,
  JobBoxHeader,
  JobDetailsGrid,
  JobDetailsHeader,
  SubTitle,
} from './styles'
import { UpdatePlacementModal } from './updatePlacementModal'
import { EshotCandidates } from './eshotCandidates'

const JobDetails = ({ id }) => {
  const { details, isFetching, updateJob } = useGetJobDetails(id)
  const [candidateListVisible, setCandidateListVisibility] = React.useState(
    false,
  )
  // const [eshotVisible, setEshotVisibility] = React.useState(false)
  const [editJobVisible, setEditJobVisibility] = React.useState(false)
  const [deleteJobVisible, setDeleteJobVisibility] = React.useState(false)
  const [updatePlacementVisible, setUpdatePlacementVisible] = React.useState(
    false,
  )
  const [placementCandidate, setPlacementCandidate] = React.useState({})

  const selectPlacement = candidate => {
    setPlacementCandidate(candidate)
    setUpdatePlacementVisible(true)
  }

  const closeSelectPlacement = () => {
    setPlacementCandidate({})
    setUpdatePlacementVisible(false)
  }

  return (
    <Layout isFetching={isFetching}>
      {!_.isEmpty(details) && (
        <>
          <JobDetailsHeader>
            <ViewLink to={`/clients/${details.client_id}/jobs`}>
              <ViewBtn>Go to Client</ViewBtn>
            </ViewLink>
          </JobDetailsHeader>
          <JobBox>
            <JobBoxHeader>
              <SubTitle>{handleData(details.title)}</SubTitle>
              <IconButton onClick={() => setEditJobVisibility(true)} />
              <IconButton
                onClick={() => setDeleteJobVisibility(true)}
                icon="bin"
              />
            </JobBoxHeader>
            <JobDetailsGrid>
              <Label label="Client">{handleData(details.client_name)}</Label>
              <Label label="Pay">{handleData(details.salary)}</Label>
              <Label label="Start Date">{handleData(details.start_date)}</Label>
              <Label label="End Date">{handleData(details.end_date)}</Label>
            </JobDetailsGrid>
            <JobDetailsGrid>
              <Label label="Job status">{handleData(details.status)}</Label>
              <Label label="Job type">{handleData(details.type)}</Label>
              <Label label="Location">{handleData(details.location)}</Label>
              <Label label="Region">{handleData(details.region)}</Label>
            </JobDetailsGrid>
            <JobDetailsGrid>
              <Label label="Permanent rate">
                {handleData(details.permanent_rate)}
              </Label>
              <Label label="Contract rate">
                {handleData(details.contract_rate)}
              </Label>
              <Label label="Hourly rate agreed">
                {details.hourly_rate_agreed ? 'Yes' : 'No'}
              </Label>
              <Label label="Pay Rate">{handleData(details.pay_rate)}</Label>
            </JobDetailsGrid>
            <JobDetailsGrid>
              <Label label="Shown on website">
                {details.show_on_site ? 'Yes' : 'No'}
              </Label>
              {details.show_on_site ? (
                <Label label="Website display rates">
                  {details.display_competitive_rates_website
                    ? 'Competitive'
                    : 'Pay'}
                </Label>
              ) : null}
              <Label label="Total Vacancies">
                {handleData(details.quantity)}
              </Label>
            </JobDetailsGrid>
            <Label label="File">
              {details.file && (
                <TextLink
                  href={details.file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {createFileText(details.file)}
                </TextLink>
              )}
            </Label>
            <Label label="Description">
              <div
                dangerouslySetInnerHTML={{
                  __html: handleData(details.description),
                }}
              ></div>
            </Label>
            <Label label="Tags">
              {details.tags && details.tags.length > 0 ? (
                <TagWrapper>
                  {details.tags.map(tag => (
                    <Tag key={tag.id}>{tag.tag}</Tag>
                  ))}
                </TagWrapper>
              ) : (
                'No tags'
              )}
            </Label>
            <JobCandidates
              jobId={details.id}
              shortlist={details.candidates_shortlist}
              placedlist={details.candidates_placed}
              setCandidateListVisibility={setCandidateListVisibility}
              setJob={updateJob}
              vacancies={details.quantity}
              setUpdatePlacementVisible={selectPlacement}
            />
            <EshotCandidates
              jobId={details.id}
              shortlist={details.candidates_shortlist}
              setCandidateListVisibility={setCandidateListVisibility}
              setJob={updateJob}
            />
          </JobBox>
          {/* Left in if eshot list is to become its own separate list */}
          {/* {eshotVisible && (
            <>
              <EshotList
                candidateShortlist={details.eshot_shortlist}
                jobId={details.id}
                candidateListVisible={eshotVisible}
                onClose={() => setEshotVisibility(false)}
                setJob={updateJob}
                candidateSuggestions={details.eshot_suggestions}
              />
            </>
          )} */}
          {candidateListVisible && (
            <CandidateList
              candidateShortlist={details.candidates_shortlist}
              jobId={details.id}
              candidateListVisible={candidateListVisible}
              onClose={() => setCandidateListVisibility(false)}
              setJob={updateJob}
              candidateSuggestions={details.candidates_suggestions}
            />
          )}
          {editJobVisible && (
            <EditJobModal
              setJob={updateJob}
              job={details}
              isVisible={editJobVisible}
              setModalVisibility={setEditJobVisibility}
            />
          )}
          {deleteJobVisible && (
            <DeleteJobModal
              job={details}
              isVisible={deleteJobVisible}
              setModalVisibility={setDeleteJobVisibility}
            />
          )}
          {updatePlacementVisible && (
            <UpdatePlacementModal
              candidate={placementCandidate}
              job_id={id}
              isVisible={updatePlacementVisible}
              setModalVisibility={closeSelectPlacement}
            />
          )}
        </>
      )}
    </Layout>
  )
}

JobDetails.defaultProps = {
  id: '',
}

JobDetails.propTypes = {
  id: PropTypes.string,
}

export default JobDetails
