import styled from 'styled-components'

export const Box = styled.div.attrs(({ mr, mb }) => ({
  mr: mr ? mr : '0',
  mb: mb ? mb : '0',
}))`
  background: white;
  ${p => p.theme.style.box_shadow};
  padding: 1.5rem;
  margin-bottom: ${p => p.mb};
  margin-right: ${p => p.mr};
`

export const ListHeader = styled.div`
  display: grid;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1.5rem;

  ${p => p.theme.layout.phone_medium} {
    grid-template-columns: ${p =>
      p.list === 'clients' ? '1fr 10rem' : '1fr 12rem'};

    h1 {
      grid-row: 1;
      grid-column: 1/2;
    }

    > button {
      grid-row: 1;
      grid-column: 2/3;
      width: fit-content;
      margin-left: auto;
    }
  }

  ${p => p.theme.layout.tablet} {
    grid-template-columns: ${p =>
      p.list === 'clients' ? '6rem 1fr 10rem' : '9.5rem 1fr 12rem'};

    > button {
      grid-column: 3/4;
    }
  }

  ${p => p.theme.layout.web_small} {
    gap: 1.875rem;
  }
`

export const ListHeaderSearch = styled.div`
  ${p => p.theme.layout.phone_medium} {
    grid-row: 2;
    grid-column: 1/3;
  }

  ${p => p.theme.layout.tablet} {
    grid-row: 1;
    grid-column: 2/3;
  }
`
