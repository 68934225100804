import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { getInitials } from '../../utilities'

const TextAvatar = styled.span`
  display: block;
  border-radius: 100%;
  background-color: #707c99;
  width: 3rem;
  height: 3rem;
  color: white;
  font-size: 1.125rem;
  text-transform: uppercase;
  position: relative;
`
const Initials = styled.span`
  display: block;
  text-decoration: none;
  position: absolute;
  letter-spacing: 0.5px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const ImageAvatar = styled.img`
  border-radius: 100%;
  background-color: #707c99;
  width: 3rem;
  height: 3rem;
`

export const Avatar = ({ name, photo }) => {
  if (photo === '' || undefined) {
    return (
      <TextAvatar>
        <Initials>{getInitials(name)}</Initials>
      </TextAvatar>
    )
  } else {
    return <ImageAvatar src={photo} alt={name} />
  }
}

Avatar.defaultProps = {
  photo: '',
}

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  photo: PropTypes.string,
}
