import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  profile_image: Yup.string(),
  name: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  head_count: Yup.string().required('Required'),
  client_id: Yup.number()
    .required('Required')
    .typeError('Client id must be a number'),
  address_1: Yup.string().required('Required'),
  address_2: Yup.string(),
  address_3: Yup.string(),
  address_town: Yup.string().required('Required'),
  address_postcode: Yup.string().required('Required'),
  address_country: Yup.string().required('Required'),
  contact_telephone: Yup.string().required('Required'),
  contact_website: Yup.string().required('Required'),
  invoice_address: Yup.string(),
  invoice_email: Yup.string(),
  status: Yup.string().required('Required'),
  tags: Yup.array()
    .of(Yup.string())
    .min(1, 'Must have at least 1 tags')
    .required('Required'),
  contacts: Yup.array()
    .of(
      Yup.object().shape({
        first_name: Yup.string().required('Required'),
        last_name: Yup.string().required('Required'),
        email: Yup.string().required('Required'),
        job_title: Yup.string(),
        landline: Yup.string(),
        mobile: Yup.string(),
        eshot_contact: Yup.bool(),
      }),
    )
    .min(1, 'Must have at least 1 contact')
    .required('Required'),
})
