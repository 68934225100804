import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import { WarningModal } from '../../../ui'

export const DeleteFileModal = ({
  file,
  isVisible,
  setModalVisibility,
  submitResponse,
  setFileToDelete,
}) => {
  const { addToast } = useToasts()

  const DeleteMedia = async id => {
    axios({
      method: 'post',
      url: `/v1/clients/media/${id}/delete`,
    })
      .then(response => {
        if (response.data.success) {
          submitResponse(file, 'delete')
          setModalVisibility(false)
          addToast('Media deleted successfully', { appearance: 'success' })
          setFileToDelete({})
        } else {
          addToast('Error deleting media, please try again', {
            appearance: 'error',
          })
        }
      })
      .catch(error => {
        console.log(error)
        addToast('Error deleting media, please try again', {
          appearance: 'error',
        })
      })
  }

  return (
    <WarningModal
      isVisible={isVisible}
      heading="Delete file?"
      closeFunc={() => setModalVisibility(false)}
      submitButtonText="Delete file"
      submitFunc={() => DeleteMedia(file.id)}
    >
      <b>{file.name}</b> will no longer exist and will no longer be downloadable
    </WarningModal>
  )
}

DeleteFileModal.propTypes = {
  file: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  submitResponse: PropTypes.func.isRequired,
  setFileToDelete: PropTypes.func.isRequired,
}
