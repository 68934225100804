import React from 'react'

export const CandidateContext = React.createContext()

const INITAL_STATE = {
  search: '',
  searchTitle: '',
  searchTag: [],
  searchSkill: [],
  searchLocation: '',
  searchEmail: '',
  alphaSort: false,
}

const CandidateReducer = (state, action) => {
  switch (action.type) {
    case 'update search':
      return {
        ...state,
        search: action.payload?.name,
        searchTitle: action.payload?.job_title,
        searchTag: action.payload?.tags,
        searchSkill: action.payload?.skills,
        searchLocation: action.payload?.location,
        searchEmail: action.payload?.email,
      }
    case 'update sort':
      return {
        ...state,
        alphaSort: action.payload,
      }
    default:
      return state
  }
}

const CandidateProvider = props => {
  const [state, dispatch] = React.useReducer(CandidateReducer, INITAL_STATE)
  // const { addToast } = useToasts()

  const updateSearch = async search => {
    dispatch({ type: 'update search', payload: search })
  }

  const updateSort = async sort => {
    dispatch({ type: 'update sort', payload: sort })
  }

  return (
    <CandidateContext.Provider
      value={{
        ...state,
        updateSearch,
        updateSort,
      }}
      {...props}
    />
  )
}

export default CandidateProvider
