import React, { useState } from 'react'
import { Button } from '../ui'
import axios from 'axios'
import FileSaver from 'file-saver'
import { useToasts } from 'react-toast-notifications'

const ExportCandidatesCsv = () => {
  const { addToast } = useToasts()
  const [loading, setLoading] = useState(false)
  const downloadCsv = async () => {
    setLoading(true)
    axios
      .get(`/v1/management-information/candidates`, { responseType: 'blob' })
      .then(res => {
        FileSaver.saveAs(res.data, 'candidates_export.csv')
        addToast(`Candidate Report Downloading`, {
          appearance: 'success',
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <Button disabled={loading} onClick={() => downloadCsv()}>
      {loading ? 'Loading' : 'Export All'}
    </Button>
  )
}

export default ExportCandidatesCsv
