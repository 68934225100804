import React from 'react'
import { Router } from '@reach/router'

import { default as EditAccountForm } from './EditAccount'

const EditAccount = () => (
  <Router>
    <EditAccountForm default path="/" />
  </Router>
)

export default EditAccount
