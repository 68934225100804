import { useFetch } from '../../../../hooks'

export const useGetSkillTags = () => {
  const url = `v1/all-skill-tags`
  const { data, isFetching } = useFetch(
    url,
    'Unable to fetch skill tag options',
  )
  const formattedData = data ? data.map(tag => tag) : null
  return { skillTags: formattedData, isFetchingSkill: isFetching }
}

export const useGetShiftTags = () => {
  const url = `v1/all-shift-tags`
  const { data, isFetching } = useFetch(
    url,
    'Unable to fetch shift tag options',
  )
  const formattedData = data ? data.map(tag => tag) : null
  return { shiftTags: formattedData, isFetchingShift: isFetching }
}
