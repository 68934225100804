import { useEffect, useState } from 'react'
import { usePaginatedFetch } from '../../../../hooks'

const useGetFiles = id => {
  const url = `v1/clients/${id}/media`
  const errMsg = 'Unable to fetch notes'
  const { data, ...rest } = usePaginatedFetch(url, errMsg)
  const [files, setFiles] = useState()

  useEffect(() => {
    setFiles(data)
  }, [data])

  const formattedData =
    files &&
    files.map(file => ({
      id: file.id,
      candidate_id: file.candidate_id,
      user_id: file.user_id,
      file: file.file,
      type: file.type,
      title: file.title,
      user_name: file.user_name,
      description: file.description,
      created_at: file.created_at,
      updated_at: file.updated_at,
      deleted_at: file.deleted_at,
    }))

  return { data: formattedData, ...rest, files, setFiles }
}

export default useGetFiles
