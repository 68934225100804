import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'

import {
  Loader,
  TabContent,
  Table,
  ButtonColumn,
  ViewLink,
  ViewIcon,
  ViewBtn,
  LoadingMore,
  SubTitle,
  Divider,
} from '../../../ui'
import useGetTempWorkers from './useGetTempWorkers'
import useGetPermWorkers from './useGetPermWorkers'
import { getShortDate } from '../../../../utilities'

const Workers = ({ id }) => {
  const {
    permWorkers,
    isFetching: isPermFetching,
    fetchMore: permFetchMore,
    isFetchingMore: isPermFetchingMore,
  } = useGetPermWorkers(id)

  const {
    tempWorkers,
    isFetching: isTempFetching,
    fetchMore: tempFetchMore,
    isFetchingMore: isTempFetchingMore,
  } = useGetTempWorkers(id)

  return (
    <>
      <Loader isFetching={isPermFetching}>
        <TabContent>
          <SubTitle>Permanent</SubTitle>
          {permWorkers && permWorkers.length > 0 ? (
            <InfiniteScroll
              dataLength={permWorkers.length}
              next={permFetchMore}
              hasMore={!!permFetchMore}
            >
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Job title</th>
                    <th data-tablet>Start Date</th>
                    <th data-tablet>End Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {permWorkers.map(worker => (
                    <tr key={worker.id}>
                      <td>{worker.first_name + ' ' + worker.last_name}</td>
                      <td>{worker.job_title}</td>
                      <td data-tablet>{getShortDate(worker.start_date)}</td>
                      <td data-tablet>{getShortDate(worker.end_date)}</td>
                      <ButtonColumn>
                        <ViewLink to={`/candidates/${worker.id}`}>
                          <ViewIcon data-mobile data-tablet />
                          <ViewBtn data-web>View</ViewBtn>
                        </ViewLink>
                      </ButtonColumn>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {permFetchMore ? (
                isPermFetchingMore ? (
                  <LoadingMore>Loading...</LoadingMore>
                ) : (
                  <LoadingMore>Scroll to load more</LoadingMore>
                )
              ) : null}
            </InfiniteScroll>
          ) : (
            <LoadingMore>
              No permanent placed candidates in jobs with active dates
            </LoadingMore>
          )}
        </TabContent>
      </Loader>
      <Divider style={{ margin: '2rem 0' }} />
      <Loader isFetching={isTempFetching}>
        <TabContent>
          <SubTitle>Temp</SubTitle>
          {tempWorkers && tempWorkers.length > 0 ? (
            <InfiniteScroll
              dataLength={tempWorkers.length}
              next={tempFetchMore}
              hasMore={!!tempFetchMore}
            >
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Job title</th>
                    <th data-tablet>Start Date</th>
                    <th data-tablet>End Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {tempWorkers.map(worker => (
                    <tr key={worker.id}>
                      <td>{worker.first_name + ' ' + worker.last_name}</td>
                      <td>{worker.job_title}</td>
                      <td data-tablet>{getShortDate(worker.start_date)}</td>
                      <td data-tablet>{getShortDate(worker.end_date)}</td>
                      <ButtonColumn>
                        <ViewLink to={`/candidates/${worker.id}`}>
                          <ViewIcon data-mobile data-tablet />
                          <ViewBtn data-web>View</ViewBtn>
                        </ViewLink>
                      </ButtonColumn>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {tempFetchMore ? (
                isTempFetchingMore ? (
                  <LoadingMore>Loading...</LoadingMore>
                ) : (
                  <LoadingMore>Scroll to load more</LoadingMore>
                )
              ) : null}
            </InfiniteScroll>
          ) : (
            <LoadingMore>
              No temp placed candidates in jobs with active dates
            </LoadingMore>
          )}
        </TabContent>
      </Loader>
    </>
  )
}

Workers.propTypes = {
  id: PropTypes.string.isRequired,
}

export default Workers
