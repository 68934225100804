import React from 'react'
import { Button, ListHeader, LoadingMore, Title } from '../ui'
import Layout from '../layout'
import { navigate } from '@reach/router'
import { EshotClients } from './eshotClients'
import { ClientShortlist } from './eshotClients/setList'
import { InputWithLabel } from '../form'
import useGetClients from './useGetClients'
import styled from 'styled-components'

const InputWrapper = styled.div`
  position: relative;
  display: grid;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
`

const Eshot = () => {
  const [searchValue, setSearchValue] = React.useState('')
  const [location, setLocation] = React.useState('')
  const [shortlist, setShortlist] = React.useState([])
  const { data, eshotSearch, fetchMore, isFetchingMore } = useGetClients()

  React.useEffect(() => {
    eshotSearch(searchValue, location)
  }, [searchValue, location, eshotSearch])

  return (
    <Layout>
      <ListHeader list="clients">
        <Title>E-shot</Title>
        <Button type="button" onClick={() => navigate('/clients')}>
          Back
        </Button>
      </ListHeader>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <InputWrapper style={{ marginRight: '1rem' }}>
          <InputWithLabel
            name="search"
            label="Name"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            placeholder="Search candidate"
          />
        </InputWrapper>
        <InputWrapper>
          <InputWithLabel
            name="location"
            label="Location"
            value={location}
            onChange={e => setLocation(e.target.value)}
            placeholder="Search location"
          />
        </InputWrapper>
      </div>
      <ClientShortlist
        clients={data}
        shortlist={shortlist}
        setShortlist={setShortlist}
      />
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginBottom: '1.5rem',
        }}
      >
        {fetchMore ? (
          isFetchingMore ? (
            <LoadingMore>Loading...</LoadingMore>
          ) : (
            <Button onClick={() => fetchMore()}>Load more</Button>
          )
        ) : null}
      </div>
      <EshotClients shortlist={shortlist} setShortlist={setShortlist} />
    </Layout>
  )
}

export default Eshot
