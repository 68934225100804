import { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useToasts } from 'react-toast-notifications'

import api from '../services'

const TOAST_ERROR = { appearance: 'error' }

const INITIAL_STATE = {
  isFetching: false,
  data: null,
  next: null,
  total: null,
  to: null,
  from: null,
  errors: null,
  isFetchingMore: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'fetch start':
      return { ...state, isFetching: true }
    case 'fetch success':
      return { ...INITIAL_STATE, ...action.payload }
    case 'fetch more start':
      return { ...state, isFetchingMore: true }
    case 'fetch more success':
      return {
        ...INITIAL_STATE,
        next: action.payload.next,
        total: action.payload.total,
        data: [...state.data, ...action.payload.data],
        from: action.payload.from,
        to: action.payload.to,
      }
    case 'set errors':
      return { ...state, errors: action.payload, isFetching: false }
    default:
      return state
  }
}

const usePaginatedFetch = (url, defaultError) => {
  const { addToast } = useToasts()
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  const { errors, next, ...rest } = state

  useEffect(() => {
    if (errors && errors.length) {
      errors.forEach(error => addToast(error, TOAST_ERROR))
      dispatch({ type: 'set errors', payload: null })
    }
  }, [errors, addToast])

  useEffect(() => {
    const paginatedFetch = async () => {
      dispatch({ type: 'fetch start' })
      const res = await api.fetch(url, defaultError)

      if (res.error) dispatch({ type: 'set errors', payload: res.errors })
      else
        dispatch({
          type: 'fetch success',
          payload: {
            data: res.data.data,
            next: res.data.next_page_url,
            to: res.data.to,
            from: res.data.from,
          },
        })
    }
    paginatedFetch()
  }, [url, defaultError])

  const fetchMore = async () => {
    dispatch({ type: 'fetch more start' })
    // url = next + '?mocky-delay=1000ms'
    const res = await api.fetch(next, defaultError)

    if (res.error) dispatch({ type: 'set errors', payload: res.errors })
    else
      dispatch({
        type: 'fetch more success',
        payload: {
          data: res.data.data,
          next: res.data.next_page_url,
          total: res.data.total,
          to: res.data.to,
          from: res.data.from,
        },
      })
  }

  return {
    ...rest,
    fetchMore: next && fetchMore,
  }
}

usePaginatedFetch.propTypes = {
  url: PropTypes.string.isRequired,
}

export default usePaginatedFetch
