import React from 'react'
import { Form, useFormikContext } from 'formik'

const FormHelper = props => {
  const { isValidating, isValid, setValues } = useFormikContext()

  // scroll to errors on formik validation
  React.useEffect(() => {
    if (!isValidating && !isValid) {
      const element = document.querySelector('.has-error')
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        })
      }
    }
  }, [isValidating, isValid])

  try {
    if (window && window.Cypress) {
      // only when in Cypress testing env
      window.cy_setSignupData = setValues
    }
  } catch (e) {
    return null
  }

  return (
    <Form>
      <React.Fragment {...props} />
    </Form>
  )
}

export default FormHelper
