import React from 'react'
import PropTypes from 'prop-types'

import Candidate from '../candidateListModal/candidate'
import { PaddedBottomDivider } from '../../../ui'
import { CandidateWrapper, ShortBlock } from '../styles'
import EshotModal from './modal'

export const EshotCandidates = ({
  jobId,
  shortlist,
  setCandidateListVisibility,
  setJob,
}) => {
  const [
    selectedCandidatesShortlist,
    setSelectedCandidatesShortlist,
  ] = React.useState([])
  const [showModal, setShowModal] = React.useState(false)

  const updateSelection = (candidate, selection, setSelection) => {
    if (selection.includes(candidate)) {
      setSelection(selectedCandidateList =>
        selectedCandidateList.filter(
          selectedCandidate => selectedCandidate !== candidate,
        ),
      )
    } else {
      setSelection([...selection, candidate])
    }
  }

  return (
    <>
      {shortlist && shortlist.length > 0 ? (
        <>
          <PaddedBottomDivider />
          <section>
            <h3>E-Shot Shortlist</h3>
            <CandidateWrapper>
              {shortlist.map((candidate, index) => (
                <ShortBlock key={candidate.id}>
                  <Candidate
                    id={candidate.id}
                    jobTitle={candidate.job_title}
                    name={`${candidate.first_name} ${candidate.last_name}`}
                    email={candidate.contact_email}
                    onChange={() =>
                      updateSelection(
                        candidate,
                        selectedCandidatesShortlist,
                        setSelectedCandidatesShortlist,
                      )
                    }
                    value={selectedCandidatesShortlist
                      .map(selectedCandidate => selectedCandidate.id)
                      .includes(candidate.id)}
                    status={candidate.current_status}
                    action="select"
                  />
                </ShortBlock>
              ))}
            </CandidateWrapper>
          </section>
          {selectedCandidatesShortlist?.length >= 1 ? (
            <EshotModal
              job={jobId}
              onClose={() => setShowModal(false)}
              modalVisible={showModal}
              candidateShortlist={shortlist}
              setSelectedCandidatesShortlist={setSelectedCandidatesShortlist}
            />
          ) : (
            <p style={{ marginTop: '1rem', fontWeight: '400' }}>
              Select candidates to create e-shot
            </p>
          )}
        </>
      ) : (
        <>
          <PaddedBottomDivider />
          <section>
            <h3>E-Shot Shortlist</h3>
            <p style={{ marginTop: '1rem', fontWeight: '400' }}>
              To send out E-Shots you must first create a shortlist
            </p>
          </section>
        </>
      )}
    </>
  )
}

EshotCandidates.defaultProps = {
  jobId: 0,
  shortlist: [],
}

EshotCandidates.propTypes = {
  jobId: PropTypes.number,
  shortlist: PropTypes.array,
  setCandidateListVisibility: PropTypes.func.isRequired,
  setJob: PropTypes.func.isRequired,
}
