import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { navigate } from '@reach/router'
import { useToasts } from 'react-toast-notifications'

import { WarningModal } from '../../../ui'

export const DeleteClientModal = ({
  id,
  name,
  isVisible,
  setModalVisibility,
}) => {
  const { addToast } = useToasts()

  const DeleteClient = async id => {
    axios({
      method: 'post',
      url: `/v1/clients/${id}/delete`,
    })
      .then(response => {
        if (response.data.success) {
          setModalVisibility(false)
          navigate('/clients')
          addToast('Client deleted successfully', { appearance: 'success' })
        } else {
          addToast('Error deleting client, please try again', {
            appearance: 'error',
          })
        }
      })
      .catch(error => {
        console.log(error)
        addToast('Error deleting client, please try again', {
          appearance: 'error',
        })
      })
  }

  return (
    <WarningModal
      isVisible={isVisible}
      heading="Delete Client?"
      closeFunc={() => setModalVisibility(false)}
      submitButtonText="Delete Client"
      submitFunc={() => DeleteClient(id)}
    >
      <b>{name}</b> will no longer appear in searches, be able to create jobs or
      have current workers
    </WarningModal>
  )
}

DeleteClientModal.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
}
