import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button } from '../ui'
// import { SearchInput } from '../form'

const SearchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: 1fr 7.5rem; */
  /* gap: 1rem; */
  align-items: center;

  /* @media (min-width: 960px) {
    grid-template-columns: 22rem 7.5rem;
  } */
`

const FilterButton = styled(Button)`
  background-color: ${p => (p.filtersVisible ? 'black' : p.theme.color.text)};
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 1rem;
  width: fit-content;

  &:focus {
    background-color: ${p => (p.filtersVisible ? 'black' : '#5A637A')};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${p => (p.filtersVisible ? 'black' : '#5A637A')};
    }
  }
`

// const SortButton = styled(Button)`
//   padding-left: 1rem;
//   padding-right: 1rem;
//   width: fit-content;
// `

const SearchC = ({
  filtersVisible,
  setFiltersVisibility,
  // alphaSort,
  // updateSort
}) => {
  return (
    <SearchWrapper>
      <FilterButton
        filtersVisible={filtersVisible}
        onClick={() => setFiltersVisibility(v => !v)}
      >
        {filtersVisible ? 'Hide Filters' : 'Filters'}
      </FilterButton>
      {/* Sort alphabetically not supported on client */}
      {/* <SortButton
        onClick={() => updateSort(!alphaSort)}
        active={alphaSort}
      >
        Sort alphabetical
      </SortButton> */}
    </SearchWrapper>
  )
}

SearchC.propTypes = {
  setFiltersVisibility: PropTypes.func.isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  // updateSort: PropTypes.func.isRequired,
  // alphaSort: PropTypes.bool.isRequired,
}
export default SearchC
