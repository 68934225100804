import React from 'react'

export const ClientContext = React.createContext()

const INITAL_STATE = {
  search: '',
  searchTitle: '',
  searchTag: [],
  searchSkill: [],
  searchHeadCount: '',
  searchLocation: '',
  alphaSort: false,
}

const ClientReducer = (state, action) => {
  switch (action.type) {
    case 'update search':
      return {
        ...state,
        search: action.payload?.name,
        searchTitle: action.payload?.job_title,
        searchTag: action.payload?.tags,
        searchSkill: action.payload?.skills,
        searchHeadCount: action.payload?.head_count,
        searchLocation: action.payload?.location,
      }
    case 'update sort':
      return {
        ...state,
        alphaSort: action.payload,
      }
    default:
      return state
  }
}

const ClientProvider = props => {
  const [state, dispatch] = React.useReducer(ClientReducer, INITAL_STATE)

  const updateSearch = async search => {
    dispatch({ type: 'update search', payload: search })
  }

  const updateSort = async sort => {
    dispatch({ type: 'update sort', payload: sort })
  }

  return (
    <ClientContext.Provider
      value={{
        ...state,
        updateSearch,
        updateSort,
      }}
      {...props}
    />
  )
}

export default ClientProvider
