import React from 'react'

import { ClientContext } from '../context/client-search'

const useClientSearch = () => {
  const auth = React.useContext(ClientContext)

  const updateSearch = search => auth.updateSearch(search)
  const updateSort = sort => auth.updateSort(sort)

  return {
    searchValue: auth?.search,
    alphaSort: auth.alphaSort,
    searchTitle: auth.searchTitle,
    searchTag: auth.searchTag,
    searchSkill: auth.searchSkill,
    searchHeadCount: auth.searchHeadCount,
    searchLocation: auth.searchLocation,
    updateSearch,
    updateSort,
  }
}

export default useClientSearch
