import { useState, useEffect } from 'react'
import { useFetch } from '../../../hooks'

const useGetJobDetails = id => {
  const url = `v1/jobs/${id}`
  const [job, setJob] = useState()
  const { data, isFetching } = useFetch(url, 'Unable to fetch job details')

  const updateJob = details => {
    setJob(details)
  }

  useEffect(() => {
    setJob(data)
  }, [data])

  const formattedData = job && {
    id: job.id,
    client_id: job.client_id,
    client_name: job.client_name,
    description: job.description,
    end_date: job.end_date,
    file: job.file,
    industry: job.industry,
    salary: job.salary,
    start_date: job.start_date,
    status: job.status,
    type: job.type,
    tags: job.tags,
    title: job.title,
    permanent_rate: job.permanent_rate,
    contract_rate: job.contract_rate,
    hourly_rate_agreed: job.hourly_rate_agreed,
    quantity: job.quantity,
    candidates_shortlist: job.candidates_shortlist,
    candidates_placed: job.candidates_placed,
    candidates_suggestions: job.candidates_suggestions,
    eshot_shortlist: job.candidates_shortlist,
    eshot_suggestions: job.candidates_suggestions,
    search_salary: job.search_salary,
    show_on_site: job.show_on_site,
    location: job.location,
    display_competitive_rates_website: job.display_competitive_rates_website,
    display_pay_rate_website: job.display_pay_rate_website,
    pay_rate: job.pay_rate,
    region: job.region,
    consultant_email: job.consultant_email,
  }

  return { details: formattedData, isFetching, updateJob }
}

export default useGetJobDetails
