import { useEffect, useState } from 'react'
import { usePaginatedFetch } from '../../../../hooks'

const useNotes = id => {
  const url = `/v1/clients/${id}/notes`
  const errMsg = 'Unable to fetch notes'
  const { data, ...rest } = usePaginatedFetch(url, errMsg)
  const [notes, setNotes] = useState()

  useEffect(() => {
    setNotes(data)
  }, [data])

  const formattedData =
    notes &&
    notes.map(note => ({
      id: note.id,
      user_id: note.user_id,
      note: note.note,
      file: note.file,
      file_original_name: note.file_original_name,
      type: note.type,
      user_name: note.user_name,
      marked_as: note.marked_as,
      updated_at: note.updated_at,
      note_date: note.note_date,
    }))

  return { data: formattedData, ...rest, notes, setNotes }
}

export default useNotes
