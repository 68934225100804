import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Label, Error, InputWrapper } from '../'

export const Input = styled.input`
  font-size: 1rem;
  font-weight: 400;
  height: 48px;
  width: 100%;
  padding: 0 1rem;
  border: 1px solid ${p => p.theme.color.border};
  appearance: none;
  outline: none;

  &:focus {
    border-color: ${p => p.theme.color.primary};
  }
`

export const InputWithLabel = ({
  className,
  label,
  name,
  errorText,
  ...props
}) => {
  return (
    <div className={className}>
      <Label htmlFor={name}>{label}</Label>
      <InputWrapper hasError={!!errorText}>
        <Input {...props} id={name} />
      </InputWrapper>
      <Error>{errorText}</Error>
    </div>
  )
}

InputWithLabel.defaultProps = {
  type: 'text',
  errorText: null,
  className: '',
}

InputWithLabel.propTypes = {
  type: PropTypes.oneOf(['text', 'date', 'number', 'password', 'email', 'tel']),
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  className: PropTypes.string,
}
