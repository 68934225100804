import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  Button,
  CardGrid,
  CardHeaderContent,
  CardHeading,
  CardProfileImage,
  CardSection,
  CardSubtitle,
  CardTitle,
  DeleteButton,
  DetailsPanel,
  IconButton,
  Label,
  Status,
  TextLink,
} from '../../../ui'
import { getShortDate, transformUnderscoredData } from '../../../../utilities'
import { Tag, TagWrapper } from '../../../form'
import { DeleteCandidateModal } from '../delete'

const StatusWrapper = styled.div`
  margin-bottom: 0.875rem;
`

const MarginDeleteButton = styled(DeleteButton)`
  margin: 0.5rem 0 0 1rem;
  ${p => p.theme.layout.tablet} {
    margin: 0.5rem 0 0 0;
  }
`

const Profile = ({
  id,
  profile_image,
  first_name,
  last_name,
  employee_id,
  job_title,
  current_status,
  start_date,
  end_date,
  employment_status,
  contact_email,
  contact_mobile,
  salary_expectation_min,
  salary_expectation_max,
  main_skill,
  driving_license_type,
  skill_tags,
  setModalEditVisibility,
  setModalViewVisibility,
  date_registered,
  pre_registration_date,
  user,
}) => {
  const [DeleteModalVisible, setDeleteModalVisibility] = React.useState(false)

  return (
    <>
      <DetailsPanel>
        <CardGrid>
          <CardHeaderContent>
            <CardProfileImage imageURL={profile_image} />
            <CardTitle>{first_name + ' ' + last_name}</CardTitle>
            <CardSubtitle>{job_title}</CardSubtitle>
            <CardSubtitle>{employee_id ? '#' + employee_id : ''}</CardSubtitle>
          </CardHeaderContent>
          <IconButton onClick={() => setModalEditVisibility(true)} />
        </CardGrid>
        <CardSection>
          <CardHeading>Contact</CardHeading>
          <StatusWrapper>
            <Status status={transformUnderscoredData(current_status)} />
          </StatusWrapper>
          {start_date ? (
            <Label label="Start Date">{getShortDate(start_date)}</Label>
          ) : (
            ''
          )}
          {end_date ? (
            <Label label="Leaving Date">{getShortDate(end_date)}</Label>
          ) : (
            ''
          )}
          {employment_status ? (
            <Label label="Employment Status">{employment_status}</Label>
          ) : (
            ''
          )}
          {date_registered ? (
            <Label label="Date Registered">
              {getShortDate(date_registered)}
            </Label>
          ) : (
            ''
          )}
          {pre_registration_date ? (
            <Label label="Pre-registered Date">
              {getShortDate(pre_registration_date)}
            </Label>
          ) : (
            ''
          )}
        </CardSection>
        {contact_email || contact_mobile ? (
          <CardSection>
            <CardHeading>Contact Details</CardHeading>
            {contact_email ? (
              <Label label="Email">
                <TextLink href={`mailto:${contact_email}`}>
                  {contact_email.toLowerCase()}
                </TextLink>
              </Label>
            ) : (
              ''
            )}
            {contact_mobile ? (
              <Label label="Mobile">
                <TextLink href={`tel:${contact_mobile}`}>
                  {contact_mobile}
                </TextLink>
              </Label>
            ) : (
              ''
            )}
          </CardSection>
        ) : (
          ''
        )}
        {main_skill ||
        driving_license_type ||
        (skill_tags && skill_tags.length > 0) ? (
          <CardSection>
            <CardHeading>Qualifications</CardHeading>
            <Label label="">{main_skill}</Label>
            <TagWrapper>
              {driving_license_type.map(tag => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </TagWrapper>
            <TagWrapper>
              {skill_tags.map(tag => (
                <Tag key={tag.id}>{tag.tag}</Tag>
              ))}
            </TagWrapper>
          </CardSection>
        ) : (
          ''
        )}
        {salary_expectation_min || salary_expectation_max ? (
          <CardSection>
            <CardHeading>Salary Expectations</CardHeading>
            <Label label="">
              {salary_expectation_min && salary_expectation_max
                ? `${salary_expectation_min} - ${salary_expectation_max}`
                : salary_expectation_min && !salary_expectation_max
                ? `${salary_expectation_min}`
                : !salary_expectation_min && salary_expectation_max
                ? `${salary_expectation_max}`
                : ``}
            </Label>
          </CardSection>
        ) : (
          ''
        )}
        <CardSection>
          <Button onClick={() => setModalViewVisibility(true)}>
            View Full Profile
          </Button>
          {user?.admin ? (
            <MarginDeleteButton onClick={() => setDeleteModalVisibility(true)}>
              Delete Candidate
            </MarginDeleteButton>
          ) : null}
        </CardSection>
      </DetailsPanel>
      {DeleteModalVisible ? (
        <DeleteCandidateModal
          id={id}
          name={first_name + ' ' + last_name}
          isVisible={DeleteModalVisible}
          setModalVisibility={setDeleteModalVisibility}
        />
      ) : (
        ''
      )}
    </>
  )
}

Profile.defaultProps = {
  id: 0,
  profile_image: '',
  first_name: '',
  last_name: '',
  job_title: '',
  employee_id: '',
  current_status: '',
  start_date: '',
  end_date: '',
  employment_status: '',
  contact_email: '',
  contact_mobile: '',
  salary_expectation_min: '',
  salary_expectation_max: '',
  main_skill: '',
  driving_license_type: [],
  skill_tags: [],
  pre_registration_date: '',
  date_registered: '',
  user: {},
}

Profile.propTypes = {
  id: PropTypes.number,
  profile_image: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  job_title: PropTypes.string,
  employee_id: PropTypes.string,
  // status
  current_status: PropTypes.string,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  employment_status: PropTypes.string,
  // contact
  contact_email: PropTypes.string,
  contact_mobile: PropTypes.string,
  setModalEditVisibility: PropTypes.func.isRequired,
  setModalViewVisibility: PropTypes.func.isRequired,
  // qualifications
  salary_expectation_min: PropTypes.string,
  salary_expectation_max: PropTypes.string,
  main_skill: PropTypes.string,
  driving_license_type: PropTypes.array,
  skill_tags: PropTypes.array,
  pre_registration_date: PropTypes.string,
  date_registered: PropTypes.string,
  user: PropTypes.object,
}
export default Profile
