import React from 'react'
import { Router } from '@reach/router'

import { default as AdminView } from './adminView'

const Admin = () => (
  <Router>
    <AdminView default path="/" />
  </Router>
)

export default Admin
