import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { CloseButton } from '../../ui'

const UploadedFile = styled.span`
  display: grid;
  grid-template-columns: 1fr 2rem;
  background-color: ${p => p.theme.color.grey_light};
  border-radius: 0.375rem;
  padding: 0.125rem 0.25rem 0.125rem 0.75rem;
  margin-top: 0.75rem;
`

const FileName = styled.span`
  display: block;
  padding: 0.375rem 0;
  color: black;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
`

export const File = ({ name, remove }) => {
  return (
    <UploadedFile>
      <FileName>{name}</FileName>
      <CloseButton
        onClick={remove}
        size="small"
        fill="black"
        hoverFill="#ccc"
      />
    </UploadedFile>
  )
}

File.propTypes = {
  name: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
}
