import React from 'react'
import { Router } from '@reach/router'

import List from './list'
import Profile from './profile'
import Eshot from './eshot'

const Candidates = () => {
  return (
    <Router>
      <List default path="/" />
      <Profile path="/:id/*" />
      <Eshot path="/eshot/" />
    </Router>
  )
}

export default Candidates
