import { format } from 'date-fns'
import moment from 'moment'

const getShortMonth = (year, month) => {
  try {
    return format(new Date(year, month - 1), 'MMM')
  } catch (e) {
    return 'Invalid date'
  }
}

const getShortDate = date => {
  try {
    return format(new Date(date), 'dd/MM/YYY')
  } catch (e) {
    return date
  }
}

const getFormattedDate = (date, format) => {
  try {
    return format(new Date(date), format)
  } catch (e) {
    return date
  }
}

const getReverseDate = date => {
  try {
    return moment(date).format('YYYY-MM-DD')
  } catch (e) {
    return ''
  }
}

const getSubmitDate = date => {
  try {
    return moment(date).format('DD/MM/YYYY')
  } catch (e) {
    return ''
  }
}

export {
  getShortMonth,
  getShortDate,
  getReverseDate,
  getSubmitDate,
  getFormattedDate,
}
