import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import CKEditor from 'ckeditor4-react'

import { Error, Label } from './'

export const FormikEditor = props => {
  const [field, meta, form] = useField(props)
  return (
    <div {...props}>
      <Label>{props.label}</Label>
      <CKEditor
        {...field}
        id={props.name}
        data={field.value}
        onChange={e => form.setValue(e.editor.getData())}
        onBlur={() => form.setTouched(true)}
        config={{
          toolbar: [
            ['Undo', 'Redo'],
            ['Bold'],
            ['Format', 'List'],
            ['HorizontalRule'],
            ['NumberedList', 'BulletedList'],
            ['Link', 'Unlink'],
            ['Image'],
            ['Source'],
            ['Maximize'],
          ],
        }}
      />
      {meta.touched ? <Error>{meta.error}</Error> : null}
    </div>
  )
}

FormikEditor.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default FormikEditor
