import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PDFDownloadLink } from '@react-pdf/renderer'
import styled, { css } from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import ManagementPDF from './PDF'

const style = css`
  display: inline-block;
  background: ${p => p.theme.style.primary_gradient};
  border-radius: 27px;
  font-size: 15px;
  padding: 11px 8px;
  color: white;
  height: 40px;
  max-width: 140px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  &:hover,
  &:focus {
    background: ${p => p.theme.style.primary_gradient__active};
  }
`
const GeneratePDFButton = styled.div`
  ${style}
`
const StyledDownloadLink = styled(PDFDownloadLink)`
  ${style}
  text-decoration: none;
  position: relative;
  background: ${p =>
    p.isReady ? p.theme.color.accent : p.theme.style.primary_gradient};
  &:hover,
  &:focus {
    background: ${p =>
      p.isReady ? '#c2243d' : p.theme.style.primary_gradient__active};
  }
`

const ResetOverlay = styled.span`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

// Fetch data for payslip pdf
const PDFContainer = ({ managementData }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [PDFData, setPDFData] = useState(null)
  const [isReady, setIsReady] = useState(false)
  const { addToast } = useToasts()

  async function generatePDF() {
    if (!isFetching && !isReady) {
      setIsFetching(true)
      try {
        let array = []
        if (managementData?.consultants) {
          for (const [key, value] of Object.entries(
            managementData.consultants,
          )) {
            let obj = { name: key }
            for (const [keyI, valueI] of Object.entries(value)) {
              obj[keyI] = valueI
            }
            obj = { name: key, ...obj }
            array = [...array, obj]
          }
        }

        let data =
          array?.length > 0
            ? {
                candidate: { ...managementData.candidate },
                client: { ...managementData.client },
                totals: { ...managementData.totals },
                consultants: array,
              }
            : managementData
        setPDFData(data)
      } catch (e) {
        addToast(e.message || 'Error', { appearance: 'error' })
      }
    }
  }
  return PDFData ? (
    <StyledDownloadLink
      document={<ManagementPDF data={PDFData} />}
      fileName={`Pin-Point-management-report.pdf`}
      isReady={isReady}
    >
      {({ loading }) => {
        setIsReady(!loading)
        return (
          <ResetOverlay
            onClick={() => {
              if (!loading) {
                setIsFetching(false)
                setIsReady(false)
                setPDFData(null)
              }
            }}
          >
            {loading ? 'Generating...' : 'Download'}
          </ResetOverlay>
        )
      }}
    </StyledDownloadLink>
  ) : (
    <GeneratePDFButton
      onClick={generatePDF}
      className="generate-payslip"
      disabled={isFetching}
    >
      {isFetching ? 'Fetching...' : 'Export to PDF'}
    </GeneratePDFButton>
  )
}

PDFContainer.propTypes = {
  managementData: PropTypes.object.isRequired,
}
export default PDFContainer
