import React from 'react'
import { useToasts } from 'react-toast-notifications'

import API from '../services'

export const AuthContext = React.createContext()

const TOAST_ERROR = { appearance: 'error' }
const INITAL_STATE = {
  isAuthenticating: false,
  isAuthenticated: null,
  errors: null,
  user: null,
}

const authReducer = (state, action) => {
  switch (action.type) {
    case 'login':
      return {
        ...state,
        isAuthenticating: true,
        isAuthenticated: null,
        errors: null,
      }
    case 'login success':
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        errors: null,
        user: action.payload,
      }
    case 'login fail':
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        errors: action.payload,
      }
    case 'logout success':
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        errors: null,
        user: null,
      }
    case 'logout fail':
      return {
        ...state,
        errors: action.payload,
      }
    case 'clear errors':
      return {
        ...state,
        errors: null,
      }
    default:
      return state
  }
}

const AuthProvider = props => {
  const [state, dispatch] = React.useReducer(authReducer, INITAL_STATE)
  const { addToast } = useToasts()

  // intial mount
  React.useEffect(() => {
    const reauth = async token => {
      dispatch({ type: 'login' })
      const res = await API.reauth(token)
      if (!res.error) dispatch({ type: 'login success', payload: res.res })
      else dispatch({ type: 'login fail', payload: res.errors })
    }
    const access_token = localStorage.getItem('auth')
    if (access_token) reauth(access_token)
  }, [])

  // Toast if there are errors
  React.useEffect(() => {
    if (state.errors) {
      state.errors.forEach(error => addToast(error, TOAST_ERROR))
      dispatch({ type: 'clear errors' })
    }
  }, [state.errors, addToast])

  const login = async credentials => {
    dispatch({ type: 'login' })
    const res = await API.login(credentials)
    if (!res.error)
      dispatch({ type: 'login success', payload: res.res.data.user })
    else dispatch({ type: 'login fail', payload: res.errors })
  }

  const logout = async () => {
    const res = await API.logout()
    if (!res.error) dispatch({ type: 'logout success' })
    else dispatch({ type: 'logout fail', payload: res.errors })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
      }}
      {...props}
    />
  )
}

export default AuthProvider
