import React from 'react'
import { Router } from '@reach/router'

import CVUpload from './upload'

const CVParser = () => {
  return (
    <Router>
      <CVUpload default path="/" />
    </Router>
  )
}

export default CVParser
