import { useEffect, useState } from 'react'
import { usePaginatedFetch } from '../../../../hooks'

const useGetJobActivity = id => {
  const url = `v1/clients/${id}/jobs-activity`
  const errMsg = 'Unable to fetch job activity'
  const { data, ...rest } = usePaginatedFetch(url, errMsg)
  const [jobs, setJobs] = useState()

  useEffect(() => {
    setJobs(data)
  }, [data])

  const formattedData =
    jobs &&
    jobs.map(job => ({
      id: job.id,
      title: job.title,
      company: job.company,
      description: job.description,
      start_date: job.start_date,
      salary: job.salary,
      end_date: job.end_date,
      updated_at: job.updated_at,
      placed_timestamp: job.placed_timestamp,
      placed_by: job.placed_by,
    }))

  return {
    activity: formattedData,
    ...rest,
    jobs,
    setJobs,
  }
}

export default useGetJobActivity
