import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Chart } from 'react-charts'

// import {
//   Title,
//   Box,
//   ListHeader,
//   PaddedDivider,
//   SubTitle,
//   Button,
// } from '../../ui'

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 250px;
  max-height: 250px;
  margin: 0 0 2rem 1rem;
  .ReactChart {
    svg {
      overflow: visible !important;
    }
  }
  ${p => p.theme.layout.phone} {
    min-height: 300px;
    max-height: 300px;
    margin: 0 0 2rem 1rem;
  }
  ${p => p.theme.layout.tablet} {
    margin: 0 0 2rem 2rem;
    min-height: 600px;
    max-height: 600px;
  }
`
const Container = styled.div`
  padding-top: 10px;
  min-height: 282px;
  max-height: 282px;
  overflow: hidden;
  margin-bottom: 2rem;
  ${p => p.theme.layout.phone} {
    min-height: 332px;
    max-height: 332px;
  }
  ${p => p.theme.layout.tablet} {
    min-height: 632px;
    max-height: 632px;
  }
`

const Graph = ({ dataRaw, labels }) => {
  const [chartData, setChartData] = React.useState({})

  React.useEffect(() => {
    let array = []
    let index = 0
    for (const [key, value] of Object.entries(dataRaw)) {
      if (key) {
        index++
        let obj = [labels[index - 1], value]
        array = [...array, obj]
      }
    }
    setChartData(array)
  }, [dataRaw, labels])

  const data = React.useMemo(
    () => [
      {
        label: 'Pinpoint',
        data: chartData,
      },
    ],
    [chartData],
  )
  const getSeriesStyle = React.useCallback(
    () => ({
      color: `#1F5ACC`,
    }),
    [],
  )

  const axes = React.useMemo(
    () => [
      {
        primary: true,
        position: 'bottom',
        type: 'ordinal',
      },
      { position: 'left', type: 'linear', min: 0 },
    ],
    [],
  )

  return (
    <Container>
      <ChartContainer>
        <Chart data={data} axes={axes} getSeriesStyle={getSeriesStyle} />
      </ChartContainer>
    </Container>
  )
}

Graph.propTypes = {
  dataRaw: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
}

export default Graph
