import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import _ from 'lodash'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'
import InfiniteScroll from 'react-infinite-scroll-component'

import useGetUsers from '../useGetUsers'
import { Error } from '../../../form'
import { handleBackEndErrors } from '../../../../utilities'
import { Table, Button } from '../../../ui'

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${p => p.theme.layout.phone_medium} {
    justify-content: flex-end;
  }
`

const STR = styled.tr`
  display: grid;
  width: 100%;
  ${p => p.theme.layout.phone_medium} {
    display: table-row;
  }
`

export const AddConsultModal = ({
  isVisible,
  onClose,
  addUser,
  branch,
  isConsult,
  currentUsers,
}) => {
  const { isFetching, data, fetchMore } = useGetUsers()
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const [filteredUsers, setFilteredUsers] = React.useState([])

  React.useEffect(() => {
    if (data?.length > 0 && !isFetching) {
      const filtered = data.filter(
        user => !currentUsers.find(({ id }) => user.id === id),
      )
      setFilteredUsers(filtered)
    }
  }, [data, currentUsers, isFetching])

  const addConsultant = async (user, branchID) => {
    axios({
      method: 'post',
      url: isConsult ? 'v1/branches/assign' : 'v1/branches/assign/manager',
      data: { user_id: user.id, branch_id: branchID },
    })
      .then(response => {
        if (response) {
          addToast(
            `${isConsult ? 'Consultant' : 'Manager'} added successfully`,
            {
              appearance: 'success',
            },
          )

          addUser({
            ...branch,
            consultants: [
              { ...user, branch_manager: isConsult ? 0 : 1 },
              ...branch.consultants,
            ],
          })

          onClose(false)
        }
      })
      .catch(error => {
        console.log(error)
        setBackEndErrors(error?.response?.data?.error)
        addToast(
          `Error adding ${
            isConsult ? 'consultant' : 'manager'
          }, please try again`,
          {
            appearance: 'error',
          },
        )
      })
  }
  return (
    <>
      <h1>{`Add ${isConsult ? 'Consultant' : 'Manager'}`}</h1>
      {isFetching ? (
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th data-tablet>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <STR>
              <td>Loading...</td>
              <td data-tablet></td>
              <td></td>
            </STR>
          </tbody>
        </Table>
      ) : (
        <>
          {filteredUsers && filteredUsers.length > 0 && (
            <InfiniteScroll
              dataLength={filteredUsers && filteredUsers.length}
              next={fetchMore}
              hasMore={!!fetchMore}
            >
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th data-tablet>Email</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.length &&
                    filteredUsers.map(user => (
                      <STR key={user.id}>
                        <td>{user.name}</td>
                        <td data-tablet>{user.email}</td>
                        <td>
                          <ButtonContainer>
                            <Button
                              onClick={() => addConsultant(user, branch.id)}
                            >
                              Add
                            </Button>
                          </ButtonContainer>
                        </td>
                      </STR>
                    ))}
                </tbody>
              </Table>
            </InfiniteScroll>
          )}
        </>
      )}
      {_.isEmpty(backEndErrors) ? (
        ''
      ) : (
        <Error>{handleBackEndErrors(backEndErrors)}</Error>
      )}
    </>
  )
}

AddConsultModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  addUser: PropTypes.func.isRequired,
  branch: PropTypes.object.isRequired,
  isConsult: PropTypes.bool.isRequired,
  currentUsers: PropTypes.array.isRequired,
}
