import { useEffect, useState } from 'react'
import { usePaginatedFetch } from '../../../../hooks'

const useGetPermWorkers = id => {
  const url = `/v1/clients/${id}/get-placed-candidates/permanent`
  const errMsg = 'Unable to fetch workers'
  const { data, ...rest } = usePaginatedFetch(url, errMsg)
  const [workers, setWorkers] = useState()

  useEffect(() => {
    setWorkers(data)
  }, [data])

  const formattedData =
    workers &&
    workers.map(worker => ({
      id: worker.id,
      first_name: worker.first_name,
      last_name: worker.last_name,
      job_title: worker.job_title,
      start_date: worker.start_date,
    }))

  return { permWorkers: formattedData, ...rest }
}

export default useGetPermWorkers
