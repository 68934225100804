import React from 'react'

import { CandidateContext } from '../context/candidate-search'

const useCandidateSearch = () => {
  const auth = React.useContext(CandidateContext)

  const updateSearch = search => auth.updateSearch(search)
  const updateSort = sort => auth.updateSort(sort)

  return {
    searchValue: auth.search,
    alphaSort: auth.alphaSort,
    searchTitle: auth.searchTitle,
    searchTag: auth.searchTag,
    searchSkill: auth.searchSkill,
    searchLocation: auth.searchLocation,
    searchEmail: auth.searchEmail,
    updateSearch,
    updateSort,
  }
}

export default useCandidateSearch
