import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'

import {
  Loader,
  TabContent,
  Table,
  ButtonColumn,
  ViewLink,
  ViewIcon,
  ViewBtn,
  LoadingMore,
} from '../../../ui'
import useGetJobOpportunities from './useGetJobOpportunities'

const Opportunities = ({ id }) => {
  const {
    opportunities,
    isFetching,
    fetchMore,
    isFetchingMore,
  } = useGetJobOpportunities(id)

  return (
    <Loader isFetching={isFetching}>
      <TabContent>
        {opportunities && opportunities.length > 0 ? (
          <InfiniteScroll
            dataLength={opportunities.length}
            next={fetchMore}
            hasMore={!!fetchMore}
          >
            <Table>
              <thead>
                <tr>
                  <th data-tablet>Client</th>
                  <th className="mobile-first">Client Name</th>
                  <th>Job Title</th>
                  <th data-tablet>Salary</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {opportunities.map(opportunity => (
                  <tr key={opportunity.id}>
                    <td data-tablet>{'#' + opportunity.client_id}</td>
                    <td className="mobile-first">{opportunity.client_name}</td>
                    <td>{opportunity.title}</td>
                    <td data-tablet>{opportunity.salary}</td>
                    <ButtonColumn>
                      <ViewLink to={`/jobs/${opportunity.id}`}>
                        <ViewIcon data-mobile data-tablet />
                        <ViewBtn data-web>View</ViewBtn>
                      </ViewLink>
                    </ButtonColumn>
                  </tr>
                ))}
              </tbody>
            </Table>
            {fetchMore ? (
              isFetchingMore ? (
                <LoadingMore>Loading...</LoadingMore>
              ) : (
                <LoadingMore>Scroll to load more</LoadingMore>
              )
            ) : null}
          </InfiniteScroll>
        ) : (
          <LoadingMore>
            No jobs with tags matching candidate skill tags
          </LoadingMore>
        )}
      </TabContent>
    </Loader>
  )
}

Opportunities.propTypes = {
  id: PropTypes.string.isRequired,
}

export default Opportunities
