export const handleBackEndErrors = backEndErrors => {
  let errors = []
  if (typeof backEndErrors === 'object') {
    Object.keys(backEndErrors).forEach(key => {
      backEndErrors[key].map(error => errors.push(error))
    })
  } else {
    errors = [backEndErrors]
  }
  return errors.join(', ')
}
