import React from 'react'
import axios from 'axios'
import { jsonToFormData } from '../../../utilities'
import { useToasts } from 'react-toast-notifications'

const useGetManagementDetails = () => {
  const [managementDetailsData, setManagementDetailsData] = React.useState()
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const { addToast } = useToasts()

  const getManagementDetailsData = async rawData => {
    const data = rawData ? jsonToFormData(rawData) : rawData
    axios({
      method: 'post',
      url: `v1/management-information/details`,
      data: data,
    })
      .then(response => {
        if (response.status === 200) {
          setManagementDetailsData(response.data)
        } else {
          setBackEndErrors({
            error: 'Error receiving management data, please try again',
            appearance: 'error',
          })
          addToast(`Error getting Management information, please try again`, {
            appearance: 'error',
          })
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
          addToast(`Error getting Management information, please try again`, {
            appearance: 'error',
          })
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error getting Management information, please try again`, {
            appearance: 'error',
          })
        }
      })
  }

  return {
    managementDetailsData,
    setManagementDetailsData,
    getManagementDetailsData,
    backEndErrors,
  }
}

export default useGetManagementDetails
