import styled from 'styled-components'
import { Link } from '@reach/router'
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg'

export const Table = styled.table`
  width: 100%;
  border-spacing: 0 15px;
  margin-bottom: 26px;
  font-size: 1rem;
  ${p => (p.fixed ? 'table-layout: fixed;' : '')}

  ${p => p.theme.layout.tablet} {
    border-spacing: 0 8px;
    margin-bottom: 50px;
    font-size: 15px;
  }

  thead {
    th {
      color: ${p => p.theme.color.text};
      font-size: 15px;
      text-align: left;
      font-weight: 600;
      padding-right: 20px;

      &:first-of-type,
      &.mobile-first {
        padding-left: 20px;
      }

      ${p => p.theme.layout.tablet} {
        padding-bottom: 8px;
        padding-right: 30px;

        &:first-of-type {
          padding-left: 30px;
        }
      }

      ${p => p.theme.utils.tablet(`display: table-cell`)}
      ${p => p.theme.utils.web_small(`display: table-cell`)}
    }
  }

  tbody {
    tr {
      background: white;
      ${p => p.theme.style.box_shadow};
    }

    td {
      padding: 15px 20px 15px 0;
      font-weight: 400;

      &:first-of-type,
      &.mobile-first {
        padding-left: 20px;
      }

      ${p => p.theme.layout.tablet} {
        padding-right: 30px;

        &:first-of-type {
          padding-left: 30px;
        }
      }

      ${p => p.theme.utils.tablet(`display: table-cell`)}
      ${p => p.theme.utils.web_small(`display: table-cell`)}
    }
  }
`

export const StatusColumn = styled.td`
  width: 4rem;

  ${p => p.theme.layout.tablet} {
    width: 5.25rem;
  }
`

export const ButtonColumn = styled.td`
  width: 2.875rem;

  ${p => p.theme.layout.tablet} {
    width: 3.5rem;
  }

  ${p => p.theme.layout.web} {
    width: 8rem;
  }
`

export const ViewLink = styled(Link)`
  /* default */
  display: flex;
  background: none;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  text-decoration: none;
  border: none;
  cursor: pointer;

  [data-mobile],
  [data-tablet],
  [data-web] {
    display: none;
  }

  [data-mobile] {
    display: inline-block;

    ${p => p.theme.layout.tablet} {
      display: none;
    }
    ${p => p.theme.layout.web} {
      display: none;
    }
  }

  [data-tablet] {
    ${p => p.theme.layout.tablet} {
      display: inline-block;
    }
    ${p => p.theme.layout.web} {
      display: none;
    }
  }

  [data-web] {
    ${p => p.theme.layout.web} {
      display: inline-block;
    }
  }
`
export const ViewIcon = styled(ArrowIcon)`
  width: 25px;
  height: 25px;
  stroke: none;
  transform: rotateZ(-90deg);
  fill: ${p => p.theme.color.primary};
`

export const ViewBtn = styled.div`
  display: inline-block;
  background: ${p => p.theme.color.primary_button};
  border-radius: 1.25rem;
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.75rem 1.75rem;
  color: white;
  height: 2.5rem;
  margin-left: auto;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;

  &:focus {
    background-color: ${p => p.theme.color.primary_dark};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${p => p.theme.color.primary_dark};
    }
  }
`

export const SearchResult = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.75rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span {
    color: ${p => p.theme.color.primary};
  }
`
