import { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useToasts } from 'react-toast-notifications'

import api from '../services'

const TOAST_ERROR = { appearance: 'error' }

const INITIAL_STATE = {
  isFetching: false,
  data: null,
  errors: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'fetch start':
      return { ...INITIAL_STATE, isFetching: true }
    case 'fetch success':
      return { ...INITIAL_STATE, data: action.payload }
    case 'set errors':
      return { ...state, errors: action.payload }
    default:
      return state
  }
}
const useFetch = (url, defaultError) => {
  const { addToast } = useToasts()
  const [{ isFetching, data, errors }, dispatch] = useReducer(
    reducer,
    INITIAL_STATE,
  )

  useEffect(() => {
    if (errors && errors.length) {
      errors.forEach(error => addToast(error, TOAST_ERROR))
      dispatch({ type: 'set errors', payload: null })
    }
  }, [errors, addToast])

  useEffect(() => {
    const fetch = async () => {
      dispatch({ type: 'fetch start' })
      const res = await api.fetch(url, defaultError)
      if (res.error) dispatch({ type: 'set errors', payload: res.errors })
      else dispatch({ type: 'fetch success', payload: res.data })
    }
    fetch()
  }, [url, defaultError])

  return {
    isFetching,
    data,
  }
}

useFetch.propTypes = {
  url: PropTypes.string.isRequired,
}

export default useFetch
