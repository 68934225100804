import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

export const SLabel = styled.label`
  font-size: 1rem;
  color: ${p => p.theme.color.text};
  display: block;
  margin-bottom: 0.5rem;
`
const Content = styled.span`
  display: block;
  font-weight: 400;
  ul,
  ol {
    margin-left: 1rem;
  }
`

const LabelContentWrapper = styled.div`
  margin-bottom: ${p => (p.mb ? p.mb : '')};

  &:last-of-type {
    margin-bottom: 0;
  }
`

export const Label = ({ label, children, mb }) => (
  <LabelContentWrapper mb={mb}>
    <SLabel>{label}</SLabel>
    {children && <Content>{children}</Content>}
  </LabelContentWrapper>
)

Label.defaultProps = {
  children: null,
  mb: '1rem',
}

Label.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
  mb: PropTypes.string,
}
