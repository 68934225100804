import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import { Button } from '../../ui'
import { ReactComponent as FileSVG } from '../../../assets/icons/file.svg'

const FileInput = styled.input`
  display: none;
`
// Container
const Dropzone = styled.div`
  display: flex;
  flex-direction: column;
  background: ${p => (p.highlight ? '#E5F1F8' : 'white')};
  border-radius: 0.25rem;
  min-height: 300px;
  width: 100%;
  position: relative;
  font-size: 1rem;

  /* dashed border */
  overflow: hidden;
  :before {
    content: '';
    position: absolute;
    border: 3px dashed #ccc;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    pointer-events: none;
  }
`
// When no images added
const NoFilesContainer = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Prompt = styled.p`
  color: ${p => p.theme.color.grey};
  font-size: 17px;
  font-weight: 500;
  margin-top: 0.5rem;
  padding: 0 10px;
  text-align: center;
`

const FilesContainer = styled.div`
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const File = styled.div`
  padding: 1rem;
  border: 1px solid grey;
  position: relative;
  max-width: 300px;
`
const DeleteFile = styled.button`
  position: absolute;
  border-radius: 50%;
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  top: -0.65rem;
  right: -0.65rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  cursor: pointer;
  background: ${p => p.theme.color.primary};
  transition: background 200ms ease-in-out;
  :hover {
    background: ${p => p.theme.color.accent};
  }
`
const StatusBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
`

const AddFilesBtn = styled.button`
  font-weight: 600;
  color: ${p => p.theme.color.primary};
  font-size: 1rem;
  border: none;
  background: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
`

const FileIcon = styled(FileSVG)`
  width: 30px;
  fill: ${p => p.theme.color.primary};
`

const FileUploader = ({
  onUpload,
  promptText,
  maxSize, //fileTypes
  setIsUploading,
  isUploading,
}) => {
  const fileInputRef = useRef()
  const [file, setFile] = useState()
  const [highlight, setHighlight] = useState(false)
  const { addToast } = useToasts()

  const toMB = size => (size / 1024 / 1024).toFixed(2)
  const openFileDialog = () => fileInputRef.current.click()

  const handleFiles = addedFiles => {
    const validFile = addedFiles.filter(f => {
      const isValidSize = toMB(f.size) < maxSize
      // const isValidFileTypes = fileTypes.includes(f.type)
      // return isValidSize && isValidFileTypes
      return isValidSize
    })[0]
    if (validFile) {
      setFile(validFile)
    } else {
      addToast('Must be less than 20mb', {
        appearance: 'error',
        autoDismiss: false,
      })
    }
  }

  const onDragOver = e => {
    e.preventDefault()
    setHighlight(true)
  }

  const onDragLeave = () => setHighlight(false)

  const onDrop = e => {
    e.preventDefault()
    handleFiles(Array.from(e.dataTransfer.files))
    setHighlight(false)
  }

  const onFilesAdded = e => handleFiles(Array.from(e.target.files))

  const uploadFile = async () => {
    // setIsUploading(true)
    const res = await onUpload(file)
    if (res?.error) {
      addToast(`File upload failed. ${res.message}`, {
        appearance: 'error',
        autoDismiss: true,
      })
    } else {
      setFile(null)
    }
    // setIsUploading(false)
  }

  return (
    <>
      <Dropzone
        highlight={highlight}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <FileInput ref={fileInputRef} type="file" onChange={onFilesAdded} />
        {!file ? (
          <NoFilesContainer>
            <AddFilesBtn type="button" onClick={openFileDialog}>
              <FileIcon />
            </AddFilesBtn>
            <Prompt>{promptText}</Prompt>
          </NoFilesContainer>
        ) : (
          <>
            <FilesContainer>
              <File>
                <DeleteFile onClick={() => setFile(null)}>X</DeleteFile>
                <div>
                  <p style={{ wordBreak: 'break-word' }}>{file.name}</p>
                  <p>{toMB(file.size)}mb</p>
                </div>
              </File>
            </FilesContainer>
            <StatusBar>
              <Button onClick={uploadFile} disabled={isUploading}>
                {isUploading ? 'Uploading...' : 'Upload file'}
              </Button>
            </StatusBar>
          </>
        )}
      </Dropzone>
    </>
  )
}

FileUploader.defaultProps = {
  promptText: 'Drag files here',
  maxSize: 20,
  setIsUploading: () => {},
  isUploading: false,
  //fileTypes: ['text/csv'],
}
FileUploader.propTypes = {
  onUpload: PropTypes.func.isRequired,
  promptText: PropTypes.string,
  maxSize: PropTypes.number,
  setIsUploading: PropTypes.func,
  isUploading: PropTypes.bool,
  //fileTypes: PropTypes.array,
}

export default FileUploader
