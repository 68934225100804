import axios from 'axios'

import * as auth from './auth'
import * as general from './general'

axios.defaults.baseURL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API
    : process.env.REACT_APP_DEV_API
// 'https://pp-industrial-crm.ne6.dev/api'

export default {
  ...general,
  ...auth,
}
