import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import axios from 'axios'
import _ from 'lodash'

import { useToasts } from 'react-toast-notifications'
import { Modal, ModalFooter, ModalText, Button } from '../../ui'
import { FormikInput, Form, Error } from '../../form'
import { handleBackEndErrors } from '../../../utilities'

const WideModalText = styled(ModalText)`
  max-width: none;
`

export const InviteUserModal = ({ isVisible, onClose, addUser }) => {
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const onSubmit = async (values, { setSubmitting }) => {
    axios({
      method: 'post',
      url: 'v1/users/invite-user',
      data: values,
    })
      .then(response => {
        if (response) {
          addToast(`User account created successfully`, {
            appearance: 'success',
          })
          addUser(response.data.success)
          onClose(true)
        }
      })
      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error creating user account, please try again`, {
            appearance: 'error',
          })
        }
      })
    setSubmitting(false)
  }
  return (
    <Modal
      isVisible={isVisible}
      heading="Invite New User"
      onClose={onClose}
      size="small"
    >
      <Formik
        initialValues={{
          email: '',
          name: '',
          // user_type: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .required('Required'),
          name: Yup.string().required('Required'),
          // user_type: Yup.string().required('Required'),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <WideModalText>
              The user will be sent an email notification with a temporary
              password so they can log in and set up their account
            </WideModalText>
            {/* <FormikSelect
              name="user_type"
              label="User Type"
              placeholder="Please select..."
              options={[
                {
                  value: 'Admin',
                  label: 'Admin',
                },
                {
                  value: 'Standard User',
                  label: 'Standard User',
                },
              ]}
            /> */}
            <FormikInput label="Full Name" name="name" />
            <FormikInput label="Email" name="email" />
            {_.isEmpty(backEndErrors) ? (
              ''
            ) : (
              <Error>{handleBackEndErrors(backEndErrors)}</Error>
            )}
            <ModalFooter>
              <Button type="submit" disabled={isSubmitting}>
                Send Email Invite
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

InviteUserModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  addUser: PropTypes.func.isRequired,
}
