import { Link } from '@reach/router'
import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${p => p.theme.color.bg};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Box = styled.div`
  height: 100%;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;

  ${p => p.theme.layout.tablet} {
    height: fit-content;
    padding: 40px;
    max-width: 480px;
    ${p => p.theme.style.box_shadow}
  }

  form {
    width: 100%;
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  margin: 27px 0 0 0;
  color: ${p => p.theme.color.text};
  ${p => p.theme.layout.tablet} {
    align-self: flex-start;
  }
`
