import React from 'react'
import PropTypes from 'prop-types'
import { Router, Link } from '@reach/router'
import styled from 'styled-components'

import { TabsPanel } from '../ui'

const Nav = styled.nav`
  position: relative;
  margin-bottom: 1.5rem;

  ${p => p.theme.layout.web_small} {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
    background: ${p => p.theme.color.border};
    height: 1px;
  }
`

const TabLink = styled(Link)`
  position: relative;
  display: inline-block;
  color: ${p => p.theme.color.text};
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  padding-bottom: 0.25rem;
  border-bottom: 3px solid transparent;
  z-index: 1;
  white-space: nowrap;
  margin: 0 0.5rem;

  &[data-current='true'] {
    color: ${p => p.theme.color.primary};
    border-bottom-color: ${p => p.theme.color.primary};
  }
  transition: border-bottom-color 200ms, color 200ms;
`

const isMatch = props => {
  const current =
    props.isCurrent || props.href + '/' === props.location.pathname
  return { 'data-current': current }
}

const Tabs = ({ tabs, basepath, ...props }) => {
  return (
    <TabsPanel>
      <Nav>
        {tabs.map(({ path, name }) => (
          <TabLink key={path} to={path} getProps={isMatch}>
            {name}
          </TabLink>
        ))}
      </Nav>
      <Router primary={false} basepath={basepath}>
        {tabs.map(({ component: Component, path, isDefault }) => (
          <Component key={path} path={path} default={isDefault} {...props} />
        ))}
      </Router>
    </TabsPanel>
  )
}

Tabs.defaultProps = {
  basepath: '/',
}
Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  basepath: PropTypes.string,
}

export default Tabs
