import React from 'react'
import PropTypes from 'prop-types'

import { Label, Card, CardGrid, IconButton, TextLink } from '../..'
import {
  ActivityCardBody,
  ColumnedActivityCardBody,
  ActivityCardFooter,
  CardType,
} from '../'
import { getShortDate, handleData } from '../../../../utilities'

import { ReactComponent as NoteIcon } from '../../../../assets/icons/note.svg'

export const NoteActivityCard = ({ note, edit, deleteNote, user }) => (
  <Card mb="2rem" urgent={note.marked_as === 'Urgent'}>
    <CardGrid activityCard={true}>
      <CardType>
        <NoteIcon />
      </CardType>
      <ActivityCardBody>
        <h2>New note</h2>
      </ActivityCardBody>
      <ColumnedActivityCardBody>
        {note && note.file && note.file.url && note.file.name ? (
          <Label label="File">
            <TextLink
              href={note.file.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {note.original_name_file
                ? note.original_name_file
                : note.file.name}
            </TextLink>
          </Label>
        ) : (
          ''
        )}
        {note.type ? (
          <Label label="Type">
            <p>{note.type}</p>
          </Label>
        ) : (
          ''
        )}
      </ColumnedActivityCardBody>
      <ActivityCardBody>
        <Label label="Description">
          <p dangerouslySetInnerHTML={{ __html: handleData(note.note) }} />
        </Label>
      </ActivityCardBody>
      {edit && <IconButton onClick={() => edit(note)} />}
      {deleteNote && user && user.admin && (
        <IconButton icon="bin" onClick={() => deleteNote(note)} />
      )}
    </CardGrid>
    <CardGrid activityCard={true}>
      <ActivityCardFooter>
        Last updated: {getShortDate(note.updated_at)}{' '}
        {note && note.user_name ? 'by ' + note.user_name : ''}
      </ActivityCardFooter>
    </CardGrid>
  </Card>
)

NoteActivityCard.defaultProps = {
  edit: null,
  deleteNote: null,
  user: {},
}

NoteActivityCard.propTypes = {
  note: PropTypes.object.isRequired,
  user: PropTypes.object,
  edit: PropTypes.func,
  deleteNote: PropTypes.func,
}
