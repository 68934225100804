import { useState, useEffect } from 'react'
import { usePaginatedFetch } from '../../../../hooks'

const useGetActivity = id => {
  const url = `/v1/candidates/${id}/activity`
  const errMsg = 'Unable to fetch activity'
  const [activity, setActivity] = useState()
  const { data, ...rest } = usePaginatedFetch(url, errMsg)

  useEffect(() => {
    setActivity(data)
  }, [data])

  const formattedData =
    activity &&
    activity.map(activity => ({
      id: activity.id,
      client_id: activity.client_id,
      user_id: activity.user_id,
      type: activity.type,
      marked_as: activity.marked_as,
      description: activity.description,
      title: activity.title,
      original_file_name: activity.original_file_name,
      user_name: activity.user_name,
      model_key: activity.model_key,
      file: activity.file,
      note: activity.note,
      created_at: activity.created_at,
      updated_at: activity.updated_at,
      deleted_at: activity.deleted_at,
      note_date: activity.note_date,
    }))

  return {
    activity: formattedData,
    ...rest,
    setActivity,
  }
}

export default useGetActivity
