import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import _ from 'lodash'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import {
  Modal,
  ModalFooter,
  ModalColumns,
  Button,
  PaddedDivider,
} from '../../../ui'
import {
  FormikEditor,
  FormikSelect,
  Form,
  FormikFileInput,
  Error,
} from '../../../form'
import { jsonToFormData, handleBackEndErrors } from '../../../../utilities'

const WideFormikEditor = styled(FormikEditor)`
  ${p => p.theme.layout.tablet} {
    grid-column: 1/3;
  }
`

const NoteModal = ({
  isVisible,
  note,
  setModalVisibility,
  candidate,
  user,
  submitResponse,
  setNoteToEdit,
}) => {
  const newNote = _.isEmpty(note)
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})

  const onSubmit = async (values, { setSubmitting }) => {
    const newValues = jsonToFormData(values)
    axios({
      method: 'post',
      url: newNote
        ? 'v1/candidates/notes/create'
        : 'v1/candidates/notes/update',
      data: newValues,
    })
      .then(response => {
        submitResponse(response.data, newNote ? 'create' : 'edit')
        if (!newNote) setNoteToEdit({})
        addToast(`Note ${newNote ? 'created' : 'updated'} successfully`, {
          appearance: 'success',
        })
        setModalVisibility(false)
      })

      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(
            `Error ${newNote ? 'creating' : 'updating'} note, please try again`,
            {
              appearance: 'error',
            },
          )
        }
      })

    setSubmitting(false)
  }

  return (
    <Modal
      isVisible={isVisible}
      heading={newNote ? 'Create New Note' : 'Update Note'}
      onClose={() => {
        setModalVisibility(false)
        setNoteToEdit({})
      }}
    >
      <Formik
        initialValues={{
          candidate_id: candidate ? candidate : '',
          user_id: user ? user : '',
          note_id: note && note.id ? note.id : '',
          marked_as: 'Urgent',
          type: note && note.type ? note.type : '',
          note: note && note.note ? note.note : '',
          file: note && note.file ? note.file : null,
        }}
        validationSchema={Yup.object().shape({
          marked_as: Yup.string(),
          type: Yup.string().required('Required'),
          note: Yup.string().required('Required'),
          file: Yup.mixed(),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalColumns>
              <div>
                {/* Removed 15/06/2020 */}
                {/* <FormikSelect
                  name="marked_as"
                  label="Mark note as"
                  placeholder="Please select..."
                  options={[
                    {
                      value: 'Urgent',
                      label: 'Urgent',
                    },
                    {
                      value: 'Not Urgent',
                      label: 'Not Urgent',
                    },
                  ]}
                /> */}
                <FormikSelect
                  name="type"
                  label="Note type"
                  placeholder="Please select..."
                  options={[
                    {
                      value: 'Call Incoming',
                      label: 'Call Incoming',
                    },
                    {
                      value: 'Call Outgoing',
                      label: 'Call Outgoing',
                    },
                    {
                      value: 'Email Incoming',
                      label: 'Email Incoming',
                    },
                    {
                      value: 'Email Outgoing',
                      label: 'Email Outgoing',
                    },
                    {
                      value: 'Registration',
                      label: 'Registration',
                    },
                    {
                      value: 'General Note',
                      label: 'General Note',
                    },
                    {
                      value: 'SMS',
                      label: 'SMS',
                    },
                    {
                      value: 'LinkedIn Message',
                      label: 'LinkedIn Message',
                    },
                    {
                      value: 'Interview',
                      label: 'Interview',
                    },
                  ]}
                />
              </div>
              <FormikFileInput label="Attach a file" name="file" />
              <WideFormikEditor name="note" label="Description" />
            </ModalColumns>
            {_.isEmpty(backEndErrors) ? (
              ''
            ) : (
              <>
                <PaddedDivider />
                <Error>{handleBackEndErrors(backEndErrors)}</Error>
              </>
            )}
            <ModalFooter>
              <Button type="submit" disabled={isSubmitting}>
                {newNote ? 'Save' : 'Save changes'}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

NoteModal.defaultProps = {
  note: {},
  candidate: null,
  user: null,
}

NoteModal.propTypes = {
  note: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  submitResponse: PropTypes.func.isRequired,
  candidate: PropTypes.string,
  user: PropTypes.number,
  setNoteToEdit: PropTypes.func.isRequired,
}

export default NoteModal
