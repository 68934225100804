import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import { WarningModal } from '../../ui'

export const DeleteBranch = ({
  id,
  name,
  isVisible,
  setModalVisibility,
  branchDelete,
}) => {
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})

  const Delete = async id => {
    axios({
      method: 'DELETE',
      url: `v1/branches/delete/${id}`,
    })
      .then(response => {
        branchDelete()
        setModalVisibility(false)
        addToast(`branch created successfully`, {
          appearance: 'success',
        })
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 422) {
          setBackEndErrors(error?.response?.data?.errors)
        } else {
          setBackEndErrors(error?.response?.data?.error)
          addToast(`Error deleting branch, please try again`, {
            appearance: 'error',
          })
        }
      })
  }

  return (
    <WarningModal
      isVisible={isVisible}
      heading="Delete Branch?"
      closeFunc={() => setModalVisibility(false)}
      submitButtonText="Delete Branch"
      submitFunc={() => Delete(id)}
      backEndErrors={backEndErrors}
    >
      <b>{name}</b> will be completely removed
    </WarningModal>
  )
}

DeleteBranch.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
}
