import React from 'react'
import PropTypes from 'prop-types'
import {
  Font,
  StyleSheet,
  Document,
  Page,
  View,
  Text,
  Image,
} from '@react-pdf/renderer'

import logo from '../../../assets/pinpoint2.jpg'

import ProximaNovaAlt from '../../../assets/fonts/ProximaNovaAlt.ttf'
import ProximaNovaAltSemi from '../../../assets/fonts/ProximaNovaAlt-Semi.ttf'
import ProximaNovaAltBold from '../../../assets/fonts/ProximaNovaAlt-Bold.ttf'

Font.register({
  family: 'ProximaNovaAlt',
  fonts: [
    { src: ProximaNovaAlt },
    { src: ProximaNovaAltSemi, fontWeight: 600 },
    { src: ProximaNovaAltBold, fontWeight: 700 },
  ],
})

const styles = StyleSheet.create({
  semi: {
    fontWeight: 600,
  },
  label: {
    fontWeight: 600,
    marginTop: 1,
    fontSize: 8,
    padding: 4,
    lineHeight: 1,
    width: '100%',
    textAlign: 'left',
    alignItems: 'center',
    display: 'flex',
    minHeight: '18px',
  },
  tableHead: {
    fontWeight: 600,
    fontSize: 8,
    padding: 1,
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  hidden: {
    fontWeight: 600,
    fontSize: 8,
    padding: 1,
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    color: '#F2F6FE',
  },
  bold: {
    fontWeight: 700,
  },
  textSmall: {
    fontSize: 8,
  },
  body: {
    fontFamily: 'ProximaNovaAlt',
    fontSize: 13,
  },
  header: {
    general: {
      padding: '12px 24px',
    },
    logo: {
      width: '106px',
      marginBottom: '20px',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  main: {
    general1: {
      padding: '12px 15px',
      width: '100%',
    },
    general2: {
      padding: '12px 15px',
      width: '100%',
    },
    content: {
      width: '100%',
    },
    contentColumn: {
      flexDirection: 'column',
      width: '100%',
    },
    section: {
      flexBasis: '24%',
      fontSize: 9,
    },
    sectionSmall: {
      flexBasis: '30%',
      fontSize: 9,
      marginRight: '5px',
    },
    sectionHalf: {
      // flexBasis: '45%',
      width: '45%',
      fontSize: 9,
    },
    sectionQuarter: {
      flexBasis: '25%',
      fontSize: 9,
    },
    subSectionHalf: {
      width: '47%',
    },
    title: {
      width: '100%',
      marginBottom: 6,
    },
    subTitle: {
      width: '100%',
      flex: '1',
      marginLeft: '10px',
      fontSize: 12,
    },
    box: {
      marginTop: 3,
      backgroundColor: '#ffffff',
      fontSize: 8,
      borderWidth: 1,
      borderColor: '#DEDEDE',
      padding: 7,
      minHeight: 50,
      width: '100%',
    },
    boxSmall: {
      marginTop: 1,
      backgroundColor: '#ffffff',
      fontSize: 8,
      lineHeight: 1,
      padding: 4,
      width: '100%',
      minHeight: '18px',
      alignItems: 'center',
      textAlign: 'center',
      display: 'flex',
    },
    boxHalf: {
      marginTop: 1,
      backgroundColor: '#ffffff',
      fontSize: 8,
      lineHeight: 1,
      borderWidth: 1,
      borderColor: '#DEDEDE',
      padding: 4,
      width: '50%',
      minHeight: '18px',
      alignItems: 'center',
      display: 'flex',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 3,
    },
    newline: {
      width: '100%',
      flexDirection: 'row',
    },
  },
  table: {
    width: '100%',
    head: {
      display: 'flex',
      alignItems: 'flex-end',
      fontSize: 7,
      fontWeight: 500,
      marginTop: 2,
      marginBottom: 1,
      textAlign: 'center',
      padding: 2,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    line: {
      width: '100%',
      height: 1,
      backgroundColor: '#DEDEDE',
    },
    rowHead: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 1,
      width: '100%',
    },
    thin: {
      width: '5%',
    },
    medium: {
      width: '5%',
    },
    large: {
      width: '8%',
    },
  },
})

// Payslips view
const ManagementPDF = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.header.general}>
          <Image src={logo} style={styles.header.logo} />
        </View>
        <View>
          {data?.candidate ? (
            <View style={styles.main.general1}>
              <Text style={styles.main.title}>Candidate</Text>
              <View style={styles.main.content}>
                <View style={styles.main.row}>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>
                        Call incoming: {data.candidate?.call_incoming}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>
                        Call outgoing: {data.candidate?.call_outgoing}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>
                        Email incoming: {data.candidate?.email_incoming}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>
                        Registration: {data.candidate?.registration}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>
                        Do not use candidates:{' '}
                        {data.totals?.do_not_use_candidates}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>
                        Prospect candidates: {data.totals?.prospect_candidates}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>
                        Live candidates: {data.totals?.live_candidates}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          ) : null}

          {data?.client ? (
            <View style={styles.main.general2}>
              <Text style={styles.main.title}>Client</Text>
              <View style={styles.main.contentColumn}>
                <View style={styles.main.content}>
                  <View style={styles.main.row}>
                    <View style={styles.main.sectionHalf}>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Sales Meeting: {data.client?.sales_meeting}
                        </Text>
                      </View>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Sales call (qualified):{' '}
                          {data.client?.sales_call_qualified}
                        </Text>
                      </View>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Service call: {data.client?.service_call}
                        </Text>
                      </View>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Email incoming: {data.client?.email_incoming}
                        </Text>
                      </View>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Email outgoing: {data.client?.email_outgoing}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.main.sectionHalf}>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          General notes: {data.client?.general_notes}
                        </Text>
                      </View>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          SMS: {data.client?.sms}
                        </Text>
                      </View>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          LinkedIn: {data.client?.linkedin}
                        </Text>
                      </View>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Interviews: {data.client?.interviews}
                        </Text>
                      </View>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Placed jobs: {data.client?.placed_jobs}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.main.content}>
                  <View style={styles.main.row}>
                    <View style={styles.main.sectionHalf}>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Sales call qualified:{' '}
                          {data.client?.sales_call_qualified}
                        </Text>
                      </View>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Sales call unqualified:{' '}
                          {data.client?.sales_call_unqualified}
                        </Text>
                      </View>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Service call: {data.client?.service_call}
                        </Text>
                      </View>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Placed jobs: {data.client?.placed_jobs}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.main.sectionHalf}>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Jobs: {data.client?.jobs}
                        </Text>
                      </View>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Jobs live: {data.client?.jobs_live}
                        </Text>
                      </View>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Jobs Cancelled: {data.client?.jobs_cancelled}
                        </Text>
                      </View>
                      <View style={styles.main.row}>
                        <Text style={styles.label}>
                          Jobs filled: {data.client?.jobs_filled}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          ) : null}

          {data?.consultants?.length && data?.consultants?.length > 0 ? (
            <View style={styles.main.general2} wrap={true}>
              <Text style={styles.main.title}>Consultants</Text>
              <View>
                <View style={styles.table.rowHead}>
                  <View style={styles.table.large}>
                    <Text style={styles.table.head}>Name</Text>
                  </View>
                  <View style={styles.table.medium}>
                    <Text style={styles.table.head}>CI</Text>
                  </View>
                  <View style={styles.table.medium}>
                    <Text style={styles.table.head}>CO</Text>
                  </View>
                  <View style={styles.table.medium}>
                    <Text style={styles.table.head}>SM</Text>
                  </View>
                  <View style={styles.table.medium}>
                    <Text style={styles.table.head}>SCQ</Text>
                  </View>
                  <View style={styles.table.medium}>
                    <Text style={styles.table.head}>SC</Text>
                  </View>
                  <View style={styles.table.medium}>
                    <Text style={styles.table.head}>EI</Text>
                  </View>
                  <View style={styles.table.medium}>
                    <Text style={styles.table.head}>EO</Text>
                  </View>
                  <View style={styles.table.medium}>
                    <Text style={styles.table.head}>R</Text>
                  </View>
                  <View style={styles.table.medium}>
                    <Text style={styles.table.head}>GN</Text>
                  </View>
                  <View style={styles.table.medium}>
                    <Text style={styles.table.head}>I</Text>
                  </View>
                  <View style={styles.table.medium}>
                    <Text style={styles.table.head}>SMS</Text>
                  </View>
                  <View style={styles.table.medium}>
                    <Text style={styles.table.head}>PJ</Text>
                  </View>
                </View>

                {data?.consultants.map((row, idx) => (
                  <View key={idx}>
                    <View style={styles.table.row}>
                      <View style={styles.table.large}>
                        <Text style={styles.main.boxSmall}>{row.name}</Text>
                      </View>
                      <View style={styles.table.medium}>
                        <Text style={styles.main.boxSmall}>
                          {row.call_incoming}
                        </Text>
                      </View>
                      <View style={styles.table.medium}>
                        <Text style={styles.main.boxSmall}>
                          {row.call_outgoing}
                        </Text>
                      </View>
                      <View style={styles.table.medium}>
                        <Text style={styles.main.boxSmall}>
                          {row.sales_meeting}
                        </Text>
                      </View>
                      <View style={styles.table.medium}>
                        <Text style={styles.main.boxSmall}>
                          {row.sales_call_qualified}
                        </Text>
                      </View>
                      <View style={styles.table.medium}>
                        <Text style={styles.main.boxSmall}>
                          {row.service_call}
                        </Text>
                      </View>
                      <View style={styles.table.medium}>
                        <Text style={styles.main.boxSmall}>
                          {row.email_incoming}
                        </Text>
                      </View>
                      <View style={styles.table.medium}>
                        <Text style={styles.main.boxSmall}>
                          {row.email_outgoing}
                        </Text>
                      </View>
                      <View style={styles.table.medium}>
                        <Text style={styles.main.boxSmall}>
                          {row.registration}
                        </Text>
                      </View>
                      <View style={styles.table.medium}>
                        <Text style={styles.main.boxSmall}>
                          {row.general_notes}
                        </Text>
                      </View>
                      <View style={styles.table.medium}>
                        <Text style={styles.main.boxSmall}>
                          {row.interviews}
                        </Text>
                      </View>
                      <View style={styles.table.medium}>
                        <Text style={styles.main.boxSmall}>{row.sms}</Text>
                      </View>
                      <View style={styles.table.medium}>
                        <Text style={styles.main.boxSmall}>
                          {row.placed_job}
                        </Text>
                      </View>
                    </View>
                    <View style={styles.table.line}></View>
                  </View>
                ))}
              </View>
            </View>
          ) : null}
        </View>
      </Page>
    </Document>
  )
}

ManagementPDF.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ManagementPDF
