import { useState, useEffect } from 'react'
import { useFetch } from './index'

export const useGetAllCandidateSkillTags = () => {
  const [skills, setSkills] = useState()
  const url = `v1/all-skill-tags`

  const { data, isFetching } = useFetch(url, 'Unable to fetch skill tags')
  useEffect(() => {
    setSkills(data)
  }, [data])

  const addSkill = skill => {
    setSkills(skl => [skill, ...skl])
  }
  const formattedData = skills && skills.map(tag => tag)
  return { tags: formattedData, isFetching, addSkill, setSkills }
}
