import { useState, useEffect } from 'react'
import { usePaginatedFetch } from '../../hooks'

const useGetJobs = () => {
  const base = 'v1/jobs'
  const [url, setUrl] = useState(base)
  const [jobs, setJobs] = useState()
  const { data, ...rest } = usePaginatedFetch(url, 'Unable to fetch jobs data')

  const search = query => {
    setUrl(query ? 'v1/job-search?term=' + query : base)
  }

  useEffect(() => {
    setJobs(data)
  }, [data])

  const formattedData =
    jobs &&
    jobs.map(job => ({
      client_id: job.client_id,
      client_name: job.client_name,
      id: job.id,
      job_title: job.title,
      salary: job.salary,
      created_at: job.created_at,
      status: job.placed_by && job.placed_timestamp ? 'placed' : 'not placed',
    }))

  return { ...rest, data: formattedData, search }
}

export default useGetJobs
