import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeContext } from 'styled-components'
import RSelect from 'react-select'

import { Label, Error, InputWrapper, Row } from '../'

const SSelect = styled(RSelect)`
  width: 100%;

  input:focus {
    box-shadow: none;
  }
  .react-select__control {
    border-radius: 0;
    padding-left: 8px;
    padding-right: 8px;

    .react-select__value-container {
      min-height: 46px;

      .react-select__single-value {
        font-weight: 400;
      }
    }
    .react-select__indicator-separator {
      display: none;
    }
  }

  .react-select__control {
    z-index: 10;
  }

  .react-select__option {
    font-weight: 400;
  }
`
const Note = styled.span`
  font-style: italic;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.25px;
  color: ${p => p.theme.color.grey};
  margin-bottom: 0.5rem;
`

export const Select = ({
  label,
  name,
  errorText,
  options,
  placeholder,
  onChange,
  note,
  ...props
}) => {
  const theme = useContext(ThemeContext)

  return (
    <div className={props.className}>
      {label || note ? (
        <Row>
          {label ? <Label htmlFor={name}>{label}</Label> : <div />}
          {note ? <Note>{note}</Note> : null}
        </Row>
      ) : null}

      <InputWrapper hasError={!!errorText}>
        <SSelect
          {...props}
          inputId={name}
          type="select"
          classNamePrefix="react-select"
          placeholder={placeholder}
          options={options}
          onChange={onChange}
          theme={selectTheme => ({
            ...selectTheme,
            borderRadius: '4px',
            colors: {
              ...selectTheme.colors,
              primary: theme.color.primary,
            },
          })}
        />
      </InputWrapper>
      <Error>{errorText}</Error>
    </div>
  )
}

Select.defaultProps = {
  errorText: null,
  placeholder: 'Please select...',
  note: null,
  className: '',
  options: [],
}

Select.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  note: PropTypes.string,
  errorText: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
}
