import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Label, Error, InputWrapper, Row } from '../'

const Textarea = styled.textarea`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  width: 100%;
  padding: 1rem 1.5rem;
  border: 1px solid ${p => p.theme.color.border};
  border-radius: 0;
  appearance: none;
  resize: none;
  outline: none;

  &:focus {
    border-color: ${p => p.theme.color.primary};
  }
`

export const TextareaWithLabel = ({
  className,
  label,
  name,
  errorText,
  rows,
  ...props
}) => {
  return (
    <div className={className}>
      <Row>
        <Label htmlFor={name}>{label}</Label>
      </Row>
      <InputWrapper hasError={!!errorText}>
        <Textarea {...props} id={name} rows={rows} />
      </InputWrapper>
      <Error>{errorText}</Error>
    </div>
  )
}

TextareaWithLabel.defaultProps = {
  errorText: null,
  className: '',
  rows: 3,
}

TextareaWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  className: PropTypes.string,
  rows: PropTypes.number,
}
