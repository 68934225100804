import styled from 'styled-components'

export const TextLink = styled.a`
  color: ${p => p.theme.color.primary};
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
    }
  }
`
