import { useState, useEffect } from 'react'
import { usePaginatedFetch } from '../../../../hooks'

const useNotes = id => {
  const url = `/v1/candidates/${id}/notes`
  const errMsg = 'Unable to fetch notes'
  const [notes, setNotes] = useState()
  const { data, ...rest } = usePaginatedFetch(url, errMsg)

  useEffect(() => {
    setNotes(data)
  }, [data])

  return { notes: notes, ...rest, setNotes }
}

export default useNotes
