import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import _ from 'lodash'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import { ModalColumns, Button, PaddedDivider, LoadingIndicator } from '../ui'
import {
  FormikText,
  Form,
  Error,
  FormikDatepicker,
  FormikSelect,
  SearchInput,
} from '../form'
import { jsonToFormData, handleBackEndErrors } from '../../utilities'
import { getReverseDate } from '../../utilities/dates'
import useGetCandidateList from './useGetCandidateList'
import {
  CandidateGroupHeader,
  NoResultsText,
} from '../jobs/jobDetails/candidateListModal/style'
import InfiniteScroll from 'react-infinite-scroll-component'
import { LoadingContainer } from '../ui/loader'
import Candidate from '../jobs/jobDetails/candidateListModal/candidate'
import { CandidateWrapper } from '../jobs/jobDetails/styles'
import useClientSearch from './useGetClients'

const WideFormikText = styled(FormikText)`
  ${p => p.theme.layout.tablet} {
    grid-column: 1/3;
  }
`

const DiaryModal = ({ date, submitResponse, setDiaryVisibility }) => {
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const [searchQuery, setSearchQuery] = React.useState('')
  const [searchClientQuery, setSearchClientQuery] = React.useState('')
  const [selectedCandidate, setSelectedCandidate] = React.useState({})
  const [selectedClient, setSelectedClient] = React.useState({})
  let { isFetching, candData, fetchMore, search } = useGetCandidateList()
  const { data, eshotSearch } = useClientSearch()

  const onSubmit = async (values, { setSubmitting }) => {
    const newValues =
      values?.diary_type === 'Client'
        ? jsonToFormData({
            note: values.note,
            note_date: getReverseDate(values.note_date),
            client_id: values.id,
          })
        : jsonToFormData({
            note: values.note,
            note_date: getReverseDate(values.note_date),
            candidate_id: values.id,
          })
    axios({
      method: 'post',
      url: 'v1/diary',
      data: newValues,
    })
      .then(response => {
        submitResponse({ ...response.data.note })
        addToast(`Diary created successfully`, {
          appearance: 'success',
        })
      })

      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error creating Diary, please try again`, {
            appearance: 'error',
          })
        }
      })

    setSubmitting(false)
  }

  const handleSearch = e => {
    setSearchQuery(e.target.value)
    search(e.target.value)
  }

  const handleClientSearch = e => {
    setSearchClientQuery(e.target.value)
    eshotSearch(e.target.value)
  }

  return (
    <Formik
      initialValues={{
        id: '',
        note: '',
        note_date: date ? date : new Date(),
        diary_type: '',
      }}
      validationSchema={Yup.object().shape({
        note: Yup.string().required('Required'),
        note_date: Yup.string().required('Required'),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <ModalColumns>
            <FormikSelect
              name="diary_type"
              label="Diary type"
              options={[
                { label: 'Candidate', value: 'Candidate' },
                { label: 'Client', value: 'Client' },
              ]}
            />
          </ModalColumns>
          {values?.diary_type === 'Candidate' && !values?.id ? (
            <>
              <ModalColumns style={{ marginBottom: '1rem' }}>
                <SearchInput
                  name="search"
                  onChange={() => handleSearch}
                  placeholder="Search all candidates"
                />
              </ModalColumns>
              {!isFetching ? (
                <>
                  {candData?.length >= 1 && searchQuery ? (
                    <InfiniteScroll
                      dataLength={candData?.length}
                      next={fetchMore}
                      hasMore={!!fetchMore}
                    >
                      <CandidateWrapper>
                        {candData?.length >= 1 &&
                          candData?.map(candidate => (
                            <Candidate
                              key={candidate.id}
                              id={candidate.id}
                              jobTitle={candidate.job_title}
                              name={`${candidate.first_name} ${candidate.last_name}`}
                              email={candidate.contact_email}
                              onChange={() => {
                                setSelectedCandidate(candidate)
                                setFieldValue('id', candidate.id)
                              }}
                              status={candidate.current_status}
                              action="select"
                            />
                          ))}
                      </CandidateWrapper>
                    </InfiniteScroll>
                  ) : searchQuery && candData?.length === 0 ? (
                    <>
                      <CandidateGroupHeader>
                        Search results
                      </CandidateGroupHeader>
                      <NoResultsText style={{ marginBottom: '2rem' }}>
                        No candidate names found
                      </NoResultsText>
                    </>
                  ) : null}
                </>
              ) : (
                <LoadingContainer>
                  <LoadingIndicator />
                </LoadingContainer>
              )}
            </>
          ) : values?.diary_type === 'Candidate' && selectedCandidate?.id ? (
            <>
              <CandidateGroupHeader>Candidate</CandidateGroupHeader>
              <Candidate
                id={selectedCandidate.id}
                jobTitle={selectedCandidate.job_title}
                name={`${selectedCandidate.first_name} ${selectedCandidate.last_name}`}
                email={selectedCandidate.email}
                onChange={() => {
                  setFieldValue('id', '')
                  setSelectedCandidate({})
                }}
                value={true}
                status={selectedCandidate.current_status}
                action="select"
              />
            </>
          ) : values?.diary_type === 'Client' && !values?.id ? (
            <>
              <ModalColumns style={{ marginBottom: '1rem' }}>
                <SearchInput
                  name="search"
                  onChange={() => handleClientSearch}
                  placeholder="Search all client"
                />
              </ModalColumns>
              {!isFetching ? (
                <>
                  {data?.length > 0 && searchClientQuery ? (
                    <InfiniteScroll
                      dataLength={data?.length}
                      next={fetchMore}
                      hasMore={!!fetchMore}
                    >
                      <CandidateWrapper>
                        {data.map(client => (
                          <Candidate
                            key={client.id}
                            id={client.id}
                            jobTitle={client.job_title}
                            name={client.name}
                            onChange={() => {
                              setSelectedClient(client)
                              setFieldValue('id', client.id)
                            }}
                            status={client.status}
                            action="select"
                            isClient={true}
                          />
                        ))}
                      </CandidateWrapper>
                    </InfiniteScroll>
                  ) : searchClientQuery && data?.length === 0 ? (
                    <>
                      <CandidateGroupHeader>
                        Search results
                      </CandidateGroupHeader>
                      <NoResultsText style={{ marginBottom: '2rem' }}>
                        No client names found
                      </NoResultsText>
                    </>
                  ) : null}
                </>
              ) : (
                <LoadingContainer>
                  <LoadingIndicator />
                </LoadingContainer>
              )}
            </>
          ) : values?.diary_type === 'Client' && selectedClient?.id ? (
            <>
              <CandidateGroupHeader>Client</CandidateGroupHeader>
              <Candidate
                id={selectedClient.id}
                jobTitle={selectedClient.job_title}
                name={selectedClient.name}
                onChange={() => {
                  setFieldValue('id', '')
                  setSelectedClient({})
                }}
                value={true}
                status={selectedClient.status}
                action="select"
                isClient={true}
              />
            </>
          ) : null}
          <ModalColumns>
            <FormikDatepicker name="note_date" label="Date" />
            <WideFormikText name="note" label="Description" rows={5} />
          </ModalColumns>
          {_.isEmpty(backEndErrors) ? (
            ''
          ) : (
            <>
              <PaddedDivider />
              <Error>{handleBackEndErrors(backEndErrors)}</Error>
            </>
          )}
          <Button
            type="button"
            style={{ marginRight: '1rem' }}
            onClick={() => setDiaryVisibility(false)}
          >
            Back
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </Form>
      )}
    </Formik>
  )
}

DiaryModal.defaultProps = {
  date: null,
}

DiaryModal.propTypes = {
  date: PropTypes.object,
  submitResponse: PropTypes.func.isRequired,
  setDiaryVisibility: PropTypes.func.isRequired,
}

export default DiaryModal
