import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import {
  Modal,
  ModalFooter,
  ModalColumns,
  Button,
  PaddedBottomDivider,
  Label,
  Divider,
} from '../../../ui'
import {
  Form,
  FormikFileInput,
  FormikInput,
  FormikCheckbox,
  TagSelector,
  Error,
  FormikDatepicker,
  FormikSelect,
  FormikEditor,
} from '../../../form'

import { handleBackEndErrors, getSubmitDate } from '../../../../utilities'
import { useGetAllCandidateSkillTags } from '../../../../hooks'
import { SIndustry, SRegions } from '../../../../constants'

const JobModal = ({
  clientId,
  isVisible,
  setModalVisibility,
  setJobs,
  jobs,
}) => {
  const { tags, isFetching } = useGetAllCandidateSkillTags()
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const [radioError, setRadioError] = React.useState(false)

  const onSubmit = async (values, { setSubmitting }) => {
    if (
      values.display_competitive_rates_website ===
      values.display_pay_rate_website
    ) {
      setRadioError(true)
      return
    } else {
      setRadioError(false)
    }
    const formatValues = { ...values }
    formatValues.start_date = values.start_date
      ? getSubmitDate(values.start_date)
      : ''
    formatValues.end_date = values.end_date
      ? getSubmitDate(values.end_date)
      : ''
    axios({
      method: 'post',
      url: '/v1/jobs/create',
      data: formatValues,
    })
      .then(response => {
        addToast(`Job created successfully`, {
          appearance: 'success',
        })

        setModalVisibility(false)
        setJobs([response.data, ...jobs])
      })
      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
          addToast(`Error creating job, please try again`, {
            appearance: 'error',
          })
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error creating job, please try again`, {
            appearance: 'error',
          })
        }
      })

    setSubmitting(false)
  }
  return (
    <Modal
      isVisible={isVisible}
      heading="Add New Job"
      onClose={() => setModalVisibility(false)}
      size="large"
    >
      <Formik
        initialValues={{
          client_id: clientId,
          file: null,
          title: '',
          industry: '',
          start_date: '',
          end_date: '',
          salary: '',
          permanent_rate: '',
          contract_rate: '',
          hourly_rate_agreed: 0,
          quantity: '',
          status: '',
          type: '',
          tags: [],
          description: '',
          terms_and_conditions_agreed: 0,
          rates_agreed: 0,
          search_salary: '',
          show_on_site: 0,
          location: '',
          display_competitive_rates_website: 0,
          display_pay_rate_website: 0,
          pay_rate: '',
          region: '',
          consultant_email: '',
        }}
        validationSchema={Yup.object().shape({
          file: Yup.mixed(),
          title: Yup.string().required('Required'),
          industry: Yup.string(),
          start_date: Yup.date(),
          end_date: Yup.date(),
          salary: Yup.string().required('Required'),
          permanent_rate: Yup.string(),
          contract_rate: Yup.string(),
          hourly_rate_agreed: Yup.bool(),
          status: Yup.string().required('Required'),
          type: Yup.string().required('Required'),
          quantity: Yup.number().required('Required'),
          tags: Yup.array()
            .of(Yup.string())
            .min(1, 'Must have at least 1 tag')
            .required('Required'),
          description: Yup.string().required('Required'),
          terms_and_conditions_agreed: Yup.bool()
            .required('Required')
            .oneOf([true, 1], 'Must Accept Terms and Conditions'),
          rates_agreed: Yup.bool()
            .required('Required')
            .oneOf([true, 1], 'Must accept Agreed rates'),
          search_salary: Yup.string().required('Required'),
          show_on_site: Yup.bool(),
          location: Yup.string().required('Required'),
          display_competitive_rates_website: Yup.bool(),
          display_pay_rate_website: Yup.bool(),
          pay_rate: Yup.string().required('Required'),
          region: Yup.string().required('Required'),
          consultant_email: Yup.string()
            .email('Must be a valid email')
            .required('Required'),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <FormikFileInput label="Attach a file" name="file" />
            <ModalColumns>
              <FormikInput label="Title" name="title" />
              <FormikSelect
                name="type"
                label="Job Type"
                placeholder="Set job type"
                options={[
                  {
                    value: 'Permanent',
                    label: 'Permanent',
                  },
                  {
                    value: 'Temp',
                    label: 'Temp',
                  },
                ]}
              />
              <FormikDatepicker
                name="start_date"
                label="Start date"
                autoComplete="off"
                placeholderText="Select a date"
              />
              <FormikDatepicker
                name="end_date"
                label="End date"
                autoComplete="off"
                placeholderText="Select a date"
                minDate={values.start_date}
              />
              <FormikSelect
                name="status"
                label="Job Status"
                placeholder="Set job status"
                options={[
                  {
                    value: 'Live',
                    label: 'Live',
                  },
                  {
                    value: 'Cancelled',
                    label: 'Cancelled',
                  },
                  {
                    value: 'Filled',
                    label: 'Filled',
                  },
                ]}
              />
              <FormikSelect
                name="industry"
                label="Industry"
                placeholder="Select Industry"
                options={SIndustry.map((ind, idx) => {
                  return { value: ind, label: ind }
                })}
              />
              <FormikInput label="Pay" name="salary" />
              <FormikInput label="Consultant Email" name="consultant_email" />
            </ModalColumns>
            <PaddedBottomDivider />
            <ModalColumns>
              <FormikInput label="Location" name="location" />
              <FormikInput label="Search Salary" name="search_salary" />
              <FormikSelect
                name="region"
                label="Region"
                placeholder="Set Region"
                options={SRegions.map((reg, idx) => {
                  return { value: reg, label: reg }
                })}
              />
              <FormikSelect
                name="pay_rate"
                label="Pay Rate"
                placeholder="Set Pay Rate"
                options={[
                  {
                    value: 'Per Day',
                    label: 'Per Day',
                  },
                  {
                    value: 'Per Annum',
                    label: 'Per Annum',
                  },
                  {
                    value: 'Per Hour',
                    label: 'Per Hour',
                  },
                ]}
              />
            </ModalColumns>
            <PaddedBottomDivider />
            <ModalColumns>
              <FormikInput label="Permanent Rate" name="permanent_rate" />
              <FormikInput label="Contract Rate" name="contract_rate" />
              <FormikInput
                label="Total Vacancies"
                name="quantity"
                placeholder="0"
              />
              <FormikCheckbox
                style={{ display: 'flex' }}
                name="hourly_rate_agreed"
                label="Hourly Rate agreed"
              />
            </ModalColumns>
            <PaddedBottomDivider />
            <TagSelector
              name="tags"
              label="Job skill tags"
              options={isFetching ? ['Loading...'] : tags}
            />
            <PaddedBottomDivider />
            <FormikEditor name="description" label="Description" />
            <PaddedBottomDivider />
            <Label label="">You must select one of the choices below</Label>
            <FormikCheckbox
              name="display_competitive_rates_website"
              label="Display 'Competitive Rates' on the Pin Point Recruitment website"
            />
            <FormikCheckbox
              name="display_pay_rate_website"
              label="Display the pay rate on the Pin Point Recruitment website"
            />
            {radioError && <Error>You must select one option</Error>}
            <Divider />
            <FormikCheckbox
              style={{ marginTop: '1rem' }}
              name="show_on_site"
              label="Do you want this job to appear on the Pin Point Recruitment website?"
            />
            <FormikCheckbox
              name="terms_and_conditions_agreed"
              label="You must confirm you agree to the clients terms and conditions before creating a new job"
            />
            <FormikCheckbox
              name="rates_agreed"
              label="You must confirm that you accept the clients agreed rates before creating a new job"
            />
            <PaddedBottomDivider />
            <ModalFooter>
              {_.isEmpty(backEndErrors) ? (
                ''
              ) : (
                <Error>{handleBackEndErrors(backEndErrors)}</Error>
              )}
              <Button type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
JobModal.defaultProps = {
  jobs: [],
}

JobModal.propTypes = {
  clientId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  setJobs: PropTypes.func.isRequired,
  jobs: PropTypes.array,
}

export default JobModal
