import { css } from 'styled-components'

const accent = '#db3e57'
const bg = '#F6F8FB'
const border = '#bdbdbd'
const border_light = '#dce1e8'
const disabled = '#595959'
const fail = accent
const grey = '#979797'
const grey_dark = '#707C99'
const grey_light = '#E1E1E1'
const orange = '#F3692A'
const primary = '#0344B9'
const primary_button = '#1F5ACC'
const primary_dark = '#1447A3'
const purple = '#C417D2'
const success = '#07BB8C'
const text = '#446686'
const healthcare = '#008578'

export default {
  color: {
    accent,
    bg,
    border,
    border_light,
    disabled,
    fail,
    grey,
    grey_dark,
    grey_light,
    orange,
    primary,
    primary_button,
    primary_dark,
    purple,
    success,
    text,
    healthcare,
  },
  style: {
    primary_gradient: 'linear-gradient(135deg, #0368B9, #2842DE)',
    primary_gradient__active: 'linear-gradient(135deg, #0368B9, #2137b9)',
    mono_gradient: 'linear-gradient(135deg, #393C3F, black)',
    box_shadow: `box-shadow: 2px 1px 4px 0 rgba(0,0,0,0.05)`,
    nav_and_header_shadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
  },
  layout: {
    phone_medium: `@media (min-width: 400px)`,
    phone_large: `@media (min-width: 600px)`,
    tablet: `@media (min-width: 800px)`,
    web_small: `@media (min-width: 1100px)`,
    web: `@media (min-width: 1300px)`,
    header_height: '80px',
    nav_height_mobile: '65px',
    nav_width_tablet: '80px',
    nav_width_web: '250px',
    nav_link_height_web: '60px',
  },
  utils: {
    nav_border: none => `4px solid ${none ? 'transparent' : accent}`,
    tablet: displayElement => css`
      &[data-tablet] {
        display: none;
      }
      ${p => p.theme.layout.tablet} {
        &[data-tablet] {
          ${displayElement};
        }
      }
    `,
    web_small: displayElement => css`
      &[data-web-small] {
        display: none;
      }
      ${p => p.theme.layout.web_small} {
        &[data-web-small] {
          ${displayElement};
        }
      }
    `,
    web: displayElement => css`
      &[data-web] {
        display: none;
      }
      ${p => p.theme.layout.web} {
        &[data-web] {
          ${displayElement};
        }
      }
    `,
  },
}
