import styled from 'styled-components'

export const ProfileLayout = styled.main`
  display: grid;
  gap: 2rem;
  padding-bottom: 1.5rem;

  ${p => p.theme.layout.tablet} {
    grid-template-columns: 20rem 1fr;
  }
`
export const DetailsPanel = styled.aside`
  background: white;
  ${p => p.theme.style.box_shadow};
  height: fit-content;
`

export const TabsPanel = styled.section``

export const TabContent = styled.div`
  width: 100%;
`
