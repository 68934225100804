import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import { WarningModal } from '../../ui'

export const EmailUserModal = ({ email, isVisible, setModalVisibility }) => {
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})

  const EmailUser = async () => {
    axios({
      method: 'post',
      url: `v1/resend-invite`,
      data: { email: email },
    })
      .then(response => {
        if (response.data.success) {
          setModalVisibility(false)
          addToast('User invite Sent', { appearance: 'success' })
        } else {
          addToast('Error sending user invite, please try again', {
            appearance: 'error',
          })
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error sending user invite, please try again`, {
            appearance: 'error',
          })
        }
      })
  }

  return (
    <WarningModal
      isVisible={isVisible}
      heading="Confirmation email"
      closeFunc={() => setModalVisibility(false)}
      submitButtonText="Resend email"
      submitFunc={() => EmailUser()}
      backEndErrors={backEndErrors}
    >
      Would you like to resend a confirmation email to <b>{email}</b>
    </WarningModal>
  )
}

EmailUserModal.propTypes = {
  email: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
}
