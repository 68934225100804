import styled from 'styled-components'

import { ReactComponent as Chevron } from '../../assets/icons/arrow.svg'

export const Header = styled.header`
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  height: ${p => p.theme.layout.header_height};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 20px 15px 20px;
  box-shadow: ${p => p.theme.style.nav_and_header_shadow};
  z-index: 3;
`

export const DropDown = styled.section`
  position: relative;

  ${p => p.theme.layout.tablet} {
    padding-right: 0.75rem;
  }
`

export const DropDownButton = styled.button`
  outline: none;
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-items: center;
`

export const DropDownIcon = styled(Chevron)`
  margin-left: 0.625rem;
  fill: ${p => p.theme.color.accent};
  width: 0.75rem;
  height: 0.5rem;
`

export const DropDownOptions = styled.ul`
  list-style-type: none;
  background: white;
  width: 10rem;
  ${p => p.theme.style.box_shadow};
  position: absolute;
  right: 0;
  padding: 0.5rem 0;
  opacity: 0;
  top: 4rem;
  pointer-events: none;
  transition: top 350ms ease-in, opacity 250ms ease-in-out;

  ${p => (p.active ? 'opacity: 1; top: 3rem; pointer-events: all;' : '')}

  li {
    font: 400 1rem/1.5rem proxima-nova, sans-serif;

    & a,
    button {
      display: block;
      cursor: pointer;
      width: 100%;
      appearance: none;
      background-color: white;
      border: none;
      text-align: left;
      padding: 0.5rem 1.5rem;
      font: 400 1rem/1.5rem proxima-nova, sans-serif;
      transition: background-color 200ms ease-in-out;
      text-decoration: none;
      color: black;
      outline: none;

      &:focus {
        background-color: ${p => p.theme.color.grey_light};
      }

      @media (hover: hover) {
        &:hover {
          background-color: ${p => p.theme.color.grey_light};
        }
      }
    }
  }
`
