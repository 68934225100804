import { useFetch } from '../../hooks'

const useGetDash = () => {
  const url = 'v1/dashboard'
  const { data, isFetching } = useFetch(url, 'Unable to fetch dash data')

  return { data, isFetching }
}

export default useGetDash
