import React from 'react'
import styled from 'styled-components'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import {
  Title,
  Box,
  ListHeader,
  ListHeaderSearch,
  PaddedDivider,
  Button,
} from '../ui'
import useGetDash from './useGetDash'
import Layout from '../layout'

import { CalendarStyle } from './style'
import { Select } from '../form'
import { AuthContext } from '../../context/auth'
import useGetBranches from '../management/useGetBranch'
import { Link, navigate } from '@reach/router'
import { getShortDate, handleData } from '../../utilities'
import DiaryModal from './diaryForm'
import { getReverseDate } from '../../utilities/dates'

const CalContainer = styled(Box)`
  display: none;
  text-align: center;
  padding: 1rem;
  min-height: 220px;
  ${p => p.theme.layout.tablet} {
    display: block;
  }
`

const MobContainer = styled(Box)`
  display: block;
  ${p => p.theme.layout.tablet} {
    display: none;
  }
  a {
    text-decoration: none;
    color: black;
  }
  .date {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
`
const MobileDate = styled.div`
  width: 100%;
  grid-column: 1/-1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  ${p => p.theme.layout.tablet} {
    display: none;
  }
  h3 {
    font-size: 22px;
    margin-bottom: 1rem;
  }
`

const ButtonContainer = styled.div`
  grid-column: 1/-1;
  display: flex;
  background-color: 'none';
  ${p => p.theme.layout.tablet} {
    display: none;
  }
`

const SelectContainer = styled.div`
  margin: 1rem 0 0;
  ${p => p.theme.layout.phone_medium} {
    max-width: 300px;
    margin: 0 0 0 auto;
  }
`

const SListHeaderSearch = styled(ListHeaderSearch)`
  grid-column: 1;

  ${p => p.theme.layout.phone_medium} {
    grid-column: 2;
    grid-row: 1;
  }
  ${p => p.theme.layout.tablet} {
    grid-column: 2/-1;
  }
`

const Dashboard = () => {
  const { isFetching, data } = useGetDash()
  const { branches, getBranchesData } = useGetBranches()
  const localizer = momentLocalizer(moment)
  const [branch, setBranch] = React.useState(null)
  const [diaryList, setDiaryList] = React.useState([])
  const [loadCheck, setLoadCheck] = React.useState(false)
  const [month, setMonth] = React.useState(new Date())
  const [modalVisible, setDiaryVisibility] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState(null)
  const auth = React.useContext(AuthContext)
  const { addToast } = useToasts()

  const getDiaryData = async (branchID, date) => {
    await axios({
      method: 'get',
      url: `v1/diary?user_id=${auth.user.id}&branch_id=${branchID}&date=${
        date ? getReverseDate(date) : getReverseDate(month)
      }`,
    })
      .then(res => {
        let formattedEvents = res.data.map(ev => {
          return {
            id: ev.id,
            title: ev.client_id
              ? 'Client Note - ' + ev.name
              : 'Candidate Note - ' + ev.name,
            start: new Date(ev.note_date),
            end: new Date(ev.note_date),
            client_id: ev.client_id,
            candidate_id: ev.candidate_id,
            note: ev.note,
          }
        })
        setDiaryList(formattedEvents)
      })
      .catch(error => {
        addToast(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : `Error loading diary, please try again`,
          {
            appearance: 'error',
          },
        )
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectBranch = e => {
    setBranch(e)
    getDiaryData(e)
  }

  const changeMonth = e => {
    setMonth(e)
    getDiaryData(branch, e)
  }

  React.useEffect(() => {
    if ((auth?.user?.admin || auth?.user?.director) && !loadCheck) {
      getBranchesData()
    }
    if (auth?.user?.branch_id && !loadCheck) {
      selectBranch(auth.user.branch_id)
    }
    setLoadCheck(true)
  }, [branches, getBranchesData, auth, loadCheck, branch, selectBranch])

  const submitResponse = () => {
    getDiaryData(branch)
    setDiaryVisibility(false)
  }

  return (
    <>
      <CalendarStyle />
      <Layout isFetching={isFetching}>
        {!modalVisible && data ? (
          <>
            <ListHeader style={{ alignItems: 'baseline' }}>
              <Title>Dashboard</Title>
              {(auth?.user?.admin || auth?.user?.director) && (
                <SListHeaderSearch>
                  <SelectContainer>
                    <Select
                      name="branches"
                      label=""
                      value={branch?.value}
                      onChange={e => selectBranch(e.value)}
                      options={branches?.map(branch => {
                        return { label: branch.name, value: branch.id }
                      })}
                    />
                  </SelectContainer>
                </SListHeaderSearch>
              )}
              <ButtonContainer>
                <Button
                  style={{ margin: '0 0 1rem auto' }}
                  onClick={() => {
                    setSelectedDate(new Date())
                    setDiaryVisibility(true)
                  }}
                >
                  Create Diary Entry
                </Button>
              </ButtonContainer>
              <MobileDate>
                <div>
                  <h3>{moment(month).format('MMMM yyyy')}</h3>
                </div>
                <ButtonContainer>
                  <Button
                    style={{ margin: '0 0 1rem auto' }}
                    onClick={() =>
                      changeMonth(
                        new Date(month.setMonth(month.getMonth() - 1)),
                      )
                    }
                  >
                    Previous
                  </Button>
                  <Button
                    style={{ margin: '0 0 1rem 1rem' }}
                    onClick={() =>
                      changeMonth(
                        new Date(month.setMonth(month.getMonth() + 1)),
                      )
                    }
                  >
                    Next
                  </Button>
                </ButtonContainer>
              </MobileDate>
            </ListHeader>
            <CalContainer>
              <Calendar
                selectable
                localizer={localizer}
                style={{ height: '100%', minHeight: '80vh' }}
                events={diaryList}
                date={month}
                onSelectSlot={e => {
                  setSelectedDate(e.start)
                  setDiaryVisibility(true)
                }}
                onSelectEvent={e => {
                  navigate(
                    e.client_id
                      ? `/clients/${e.client_id}`
                      : `/candidates/${e.candidate_id}`,
                  )
                }}
                onNavigate={e => changeMonth(e)}
                views={['month']}
                popup={true}
              />
            </CalContainer>
            <MobContainer>
              {diaryList?.length >= 1 ? (
                <>
                  {diaryList?.map((note, idx) => (
                    <div key={idx}>
                      <Link
                        to={
                          note.client_id
                            ? `/clients/${note.client_id}`
                            : `/candidates/${note.candidate_id}`
                        }
                      >
                        <h3 style={{ marginBottom: '1rem' }}>{note.title}</h3>
                        <p className="date">{getShortDate(note.start)}</p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: handleData(note.note),
                          }}
                        />
                      </Link>
                      <PaddedDivider />
                    </div>
                  ))}
                </>
              ) : (
                <p>No diary notes available</p>
              )}
            </MobContainer>
          </>
        ) : modalVisible ? (
          <>
            <ListHeader style={{ alignItems: 'baseline' }}>
              <Title>Dashboard</Title>
            </ListHeader>
            <DiaryModal
              date={selectedDate}
              submitResponse={submitResponse}
              setDiaryVisibility={setDiaryVisibility}
            />
          </>
        ) : (
          <p>Loading...</p>
        )}
      </Layout>
    </>
  )
}

export default Dashboard
