import React from 'react'
import PropTypes from 'prop-types'
import { Router, Location } from '@reach/router'
import styled from 'styled-components'

import Header from './components/header'
import Nav from './components/nav'
import Dashboard from './components/dashboard'
import Candidates from './components/candidates'
import Clients from './components/clients'
import Jobs from './components/jobs'
import EditAccount from './components/edit-account'
import AdminView from './components/admin'
import Management from './components/management'
import CVParser from './components/cv-parser'
import Branch from './components/branches'

const SRouter = styled(Router)`
  --header-height: ${p => p.theme.layout.header_height};
  --nav-height-mobile: ${p => p.theme.layout.nav_height_mobile};
  --nav-width-tablet: ${p => p.theme.layout.nav_width_tablet};
  --nav-width-web: ${p => p.theme.layout.nav_width_web};

  /* default */
  position: relative;
  background: ${p => p.theme.color.bg};
  z-index: 1;

  /* mobile */
  padding: 1rem;
  padding-top: calc(var(--header-height) + 1rem);
  min-height: 100vh;
  width: 100vw;

  /* tablet */
  ${p => p.theme.layout.tablet} {
    padding: calc(var(--header-height) + 1.5rem) 1.5rem 0 1.5rem;
    margin-left: var(--nav-width-tablet);
    width: calc(100vw - var(--nav-width-tablet));
  }

  /* web */
  ${p => p.theme.layout.web} {
    padding: 2rem;
    padding-top: calc(var(--header-height) + 2rem);
    margin-left: var(--nav-width-web);
    width: calc(100vw - var(--nav-width-web));
  }
`
class OnRouteChangeWorker extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.action()
    }
  }

  render() {
    return null
  }
}

OnRouteChangeWorker.propTypes = {
  location: PropTypes.object.isRequired,
  action: PropTypes.func.isRequired,
}

const OnRouteChange = ({ action }) => (
  <Location>
    {({ location }) => (
      <OnRouteChangeWorker location={location} action={action} />
    )}
  </Location>
)

OnRouteChange.propTypes = {
  action: PropTypes.func.isRequired,
}

const ProtectedApp = () => (
  <>
    <Nav />
    <Header />
    <SRouter>
      <Dashboard default path="/" />
      <Candidates path="/candidates/*" />
      <Clients path="/clients/*" />
      <Jobs path="/jobs/*" />
      <EditAccount path="/edit-account/*" />
      <AdminView path="/admin/*" />
      <Branch path="/branches/*" />
      <Management path="/management/*" />
      <CVParser path="/cv-parser/*" />
    </SRouter>
    <OnRouteChange
      action={() => {
        window.scrollTo(0, 0)
      }}
    />
  </>
)

ProtectedApp.propTypes = {}

export default ProtectedApp
