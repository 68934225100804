export const buildUpdateCandidatesRequest = (
  jobId,
  placed,
  candidates,
  action,
) => {
  let attachJobObject = {}
  attachJobObject.job_id = jobId
  attachJobObject.placed = placed
  if (action === 'remove') {
    attachJobObject.candidate_ids = candidates
  } else {
    attachJobObject.candidates = candidates
  }
  return attachJobObject
}
