import React from 'react'
import styled from 'styled-components'

import { UpdateDetails } from './UpdateDetails'
import { UpdatePassword } from './UpdatePassword'

import { Box } from '../ui'

export const FormBox = styled(Box)`
  max-width: 30rem;
  margin: 0 auto ${p => (p.mb ? p.mb : 0)};

  ${p => p.theme.layout.tablet} {
    padding: 2rem;
  }
`

export const FormCancelButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  font: 500 1.125rem/1.25rem proxima-nova, sans-serif;
  margin: 1.5rem 0 0 0;
  color: ${p => p.theme.color.text};
  cursor: pointer;
  outline: none;

  &:focus {
    text-decoration: underline;
  }
  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`
/* Get User object from context and pass in as props */

const EditAccount = () => {
  return (
    <>
      <UpdateDetails />
      <UpdatePassword />
    </>
  )
}

export default EditAccount
