import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import _ from 'lodash'
import { useToasts } from 'react-toast-notifications'

import { FormTitle, FormikInput, Form, Error } from '../form'
import { Button, Label, PaddedDivider } from '../ui'
import { FormBox, FormCancelButton } from './EditAccount'
import { AuthContext } from '../../context/auth'
import { createFormData, handleBackEndErrors } from '../../utilities'

export const UpdateDetails = () => {
  const { addToast } = useToasts()
  const auth = React.useContext(AuthContext)
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const [editVisible, setEditVisibility] = React.useState(false)
  const [user, setUser] = React.useState(auth.user)

  const onSubmit = async (values, { setSubmitting }) => {
    const newValues = createFormData(values)
    axios({
      method: 'post',
      url: 'v1/users/update',
      data: newValues,
    })
      .then(response => {
        setUser(response.data)
        setEditVisibility(false)
        addToast(`Account details updated successfully`, {
          appearance: 'success',
        })
      })
      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error updating account details, please try again`, {
            appearance: 'error',
          })
        }
      })

    setSubmitting(false)
  }
  return (
    <FormBox mb="2rem">
      {!editVisible ? (
        <>
          <FormTitle mt="0" mb="1.5rem">
            Your account
          </FormTitle>
          <Label label="Your name" mb="1.5rem">
            {user?.name ? user.name : 'Could not retrieve name'}
          </Label>
          <Label label="Email" mb="2rem !important">
            {user?.email ? user.email : 'Could not retrieve email'}
          </Label>
          <Label label="Contact number" mb="2rem !important">
            {user.contact_number
              ? user.contact_number
              : 'No contact number set'}
          </Label>
          <Button fullWidth tall onClick={() => setEditVisibility(true)}>
            Edit Details
          </Button>
        </>
      ) : (
        <Formik
          initialValues={{
            user_id: user && user.id ? user.id : 0,
            name: user && user.name ? user.name : '',
            email: user && user.email ? user.email : '',
            contact_number: user?.contact_number ? user.contact_number : '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string(),
            email: Yup.string().email('Must be a valid email'),
            contact_number: Yup.string(),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormTitle mt="0" mb="1.5rem">
                Edit Account Details
              </FormTitle>
              <FormikInput name="name" label="Your name" />
              <FormikInput name="email" label="Email" />
              <FormikInput name="contact_number" label="Contact number" />
              {_.isEmpty(backEndErrors) ? (
                ''
              ) : (
                <>
                  <PaddedDivider />
                  <Error>{handleBackEndErrors(backEndErrors)}</Error>
                </>
              )}
              <Button fullWidth tall type="submit" disabled={isSubmitting}>
                Save Changes
              </Button>
              <FormCancelButton
                onClick={() => setEditVisibility(false)}
                type="button"
              >
                Cancel
              </FormCancelButton>
            </Form>
          )}
        </Formik>
      )}
    </FormBox>
  )
}
