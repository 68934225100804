const createFormData = raw => {
  const formData = new FormData()
  Object.entries(raw).forEach(
    ([k, v]) => (v || v === false || v === 0) && formData.append(k, v),
  )
  return formData
}

const appendItemsToFormDataArray = (formData, array, fieldName) =>
  array.forEach((item, i) => formData.append(`${fieldName}[${i}]`, item))

const dataURItoBlob = dataURI => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

const buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach(key => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
      )
    })
  } else {
    const value = data == null ? '' : data

    formData.append(parentKey, value)
  }
}

const jsonToFormData = data => {
  const formData = new FormData()

  buildFormData(formData, data)

  return formData
}

export {
  jsonToFormData,
  buildFormData,
  dataURItoBlob,
  appendItemsToFormDataArray,
  createFormData,
}
