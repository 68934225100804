import { transformTags } from '../../../../utilities'

export const initialValues = details => {
  const initialValues = {
    profile_image:
      details && details.profile_image ? details.profile_image : '',
    name: details && details.name ? details.name : '',
    type: details && details.type ? details.type : '',
    head_count: details && details.head_count ? details.head_count : '',
    client_id: details && details.id ? details.id : '',
    address_1: details && details.address_1 ? details.address_1 : '',
    address_2: details && details.address_2 ? details.address_2 : '',
    address_3: details && details.address_3 ? details.address_3 : '',
    address_town: details && details.town_city ? details.town_city : '',
    address_postcode: details && details.postcode ? details.postcode : '',
    address_country: details && details.country ? details.country : '',
    contact_telephone: details && details.tel ? details.tel : '',
    contact_website: details && details.website ? details.website : '',
    status: details && details.status ? details.status : '',
    tags: details && details.tags ? transformTags(details.tags) : [],
    invoice_address:
      details && details.invoice_address ? details.invoice_address : '',
    invoice_email:
      details && details.invoice_email ? details.invoice_email : '',
    contacts:
      details && details.contacts && details.contacts.length > 0
        ? details.contacts
        : [
            {
              first_name: '',
              last_name: '',
              email: '',
              landline: '',
              mobile: '',
              job_title: '',
              eshot_contact: 0,
            },
          ],
  }
  return initialValues
}
