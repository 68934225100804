import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'

import Layout from '../layout'
import useGetBranches from './useGetBranch'
// import { AuthContext } from '../../../context/auth'
import { DeleteBranch } from './delete'
import { AddBranchModal } from './addBranch'
import { Table, Button, DeleteButton, Title, ListHeader } from '../ui'
import { navigate } from '@reach/router'

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${p => p.theme.layout.phone_medium} {
    justify-content: flex-end;
  }
`

const STR = styled.tr`
  display: grid;
  width: 100%;
  ${p => p.theme.layout.phone_medium} {
    display: table-row;
  }
`

const STable = styled(Table)`
  tbody {
    td {
      padding: 15px 20px 15px 20px;
    }
  }
`

export const BranchList = () => {
  const { isFetching, data, fetchMore, setBranches } = useGetBranches()
  // const auth = React.useContext(AuthContext)

  const [modalVisible, setModalVisibility] = React.useState(false)
  const [addBranchVisible, setAddBranchVisibility] = React.useState(false)
  const [selectedBranch, setSelectedBranch] = React.useState({})

  const openModel = branch => {
    setSelectedBranch(branch)
    setModalVisibility(true)
  }

  const closeModel = () => {
    setModalVisibility(false)
    setSelectedBranch({})
  }

  const addBranch = branch => {
    setBranches(u => [branch, ...u])
  }

  const deleteBranch = branch => {
    if (data.includes(branch)) {
      setBranches(currentBranch =>
        currentBranch.filter(activeFilter => activeFilter !== branch),
      )
    }
  }

  return (
    <>
      <Layout isFetching={isFetching}>
        <ListHeader>
          <Title style={{ marginBottom: '1rem' }}>Branches</Title>
          <Button onClick={() => setAddBranchVisibility(true)}>
            Add Branch
          </Button>
        </ListHeader>
        {data && data.length > 0 && (
          <InfiniteScroll
            dataLength={data && data.length}
            next={fetchMore}
            hasMore={!!fetchMore}
          >
            <STable>
              <thead>
                <tr>
                  <th>Name</th>
                  <th data-tablet></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map(branch => (
                  <STR key={branch.id}>
                    <td>{branch.name}</td>
                    <td data-tablet></td>
                    <td>
                      <ButtonContainer>
                        <Button
                          style={{ marginRight: '10px' }}
                          onClick={() => navigate(`/branches/${branch.id}`)}
                        >
                          View
                        </Button>
                        <DeleteButton onClick={() => openModel(branch)}>
                          Delete
                        </DeleteButton>
                      </ButtonContainer>
                    </td>
                  </STR>
                ))}
              </tbody>
            </STable>
          </InfiniteScroll>
        )}
      </Layout>
      {modalVisible ? (
        <DeleteBranch
          id={selectedBranch.id}
          name={selectedBranch.name}
          isVisible={modalVisible}
          setModalVisibility={closeModel}
          branchDelete={() => deleteBranch(selectedBranch)}
        />
      ) : (
        ''
      )}
      {addBranchVisible ? (
        <AddBranchModal
          isVisible={addBranchVisible}
          onClose={() => setAddBranchVisibility(false)}
          addBranch={addBranch}
        />
      ) : (
        ''
      )}
    </>
  )
}
