import styled from 'styled-components'

const Title = styled.h1`
  font-size: 32px;
  letter-spacing: -1px;
`

const SubTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.8px;
  margin-bottom: 0;

  ${p => p.theme.layout.tablet} {
    margin-bottom: 8px;
    grid-column: ${p => (p.longTitle ? '1 / span 2' : '1')};
  }
`

export { Title, SubTitle }
