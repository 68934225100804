import styled from 'styled-components'

import { LoadingMore } from '../../../ui'

export const SearchWrapper = styled.section`
  max-width: 20rem;
  width: 100%;
  margin: 0 auto;
`
export const CandidatesWrapper = styled.section`
  display: grid;
  gap: 1rem;
  grid-auto-rows: max-content;
  overflow: auto;
  margin-bottom: 1.5rem;

  ${p => p.theme.layout.phone_large} {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-bottom: 2rem;
  }

  ${p => p.theme.layout.web_small} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export const LoadingContainer = styled.div`
  margin: 0 auto;
  width: 5rem;
`
export const CandidateGroupHeader = styled.h3`
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  color: ${p => p.theme.color.text};
  display: block;
`

export const LoadingMoreGird = styled(LoadingMore)`
  grid-column: 1/3;

  ${p => p.theme.layout.phone_large} {
    grid-column: 1/3;
  }

  ${p => p.theme.layout.web_small} {
    grid-column: 1/4;
  }
`

export const CandidateGroupsWrapper = styled.section`
  height: calc(100% - 6.5rem);
  overflow: auto;
`

export const NoResultsText = styled.p`
  font-weight: 400;
`

export const Query = styled.span`
  color: ${p => p.theme.color.text};
  font-weight: 600;
`
