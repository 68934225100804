import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Activity from './activity'
import Details from './details'
import Jobs from './jobs'
import Layout from '../../layout'
import Media from './media'
import Notes from './notes'
import Opportunities from './opportunities'
import useGetCandidateDetails from './useGetCandidateDetails'
import { Tabs, ProfileLayout } from '../../ui'
import DetailsModal from './modal'
import ViewDetailsModal from './view'
import { AuthContext } from '../../../context/auth'

const TABS = [
  {
    name: 'Activity',
    path: `activity`,
    component: Activity,
    isDefault: true,
  },
  {
    name: 'Notes',
    path: `notes`,
    component: Notes,
  },
  {
    name: 'Attachments',
    path: `media`,
    component: Media,
  },
  {
    name: 'Job Activity',
    path: `jobs`,
    component: Jobs,
  },
  {
    name: 'Job Opportunities',
    path: `opportunities`,
    component: Opportunities,
  },
]

const Profile = ({ id }) => {
  const { details, isFetching, updateCandidate } = useGetCandidateDetails(id)
  const auth = React.useContext(AuthContext)
  const [modalEditVisible, setModalEditVisibility] = React.useState(false)
  const [modalViewVisible, setModalViewVisibility] = React.useState(false)

  return (
    <Layout isFetching={isFetching}>
      {!_.isEmpty(details) ? (
        <ProfileLayout>
          <Details
            {...details}
            setModalEditVisibility={setModalEditVisibility}
            setModalViewVisibility={setModalViewVisibility}
            user={auth?.user ? auth.user : {}}
          />
          <Tabs tabs={TABS} basepath={`candidates/${id}`} id={id} />
        </ProfileLayout>
      ) : (
        ''
      )}
      {modalEditVisible ? (
        <DetailsModal
          details={details}
          setCandidateDetails={updateCandidate}
          isVisible={modalEditVisible}
          setModalVisibility={setModalEditVisibility}
          user={auth && auth.user ? auth.user : {}}
          title="Edit Candidate"
        />
      ) : modalViewVisible ? (
        <ViewDetailsModal
          details={details}
          isVisible={modalViewVisible}
          setModalVisibility={setModalViewVisibility}
          title="Candidate Profile"
          user={auth && auth.user ? auth.user : {}}
        />
      ) : (
        ''
      )}
    </Layout>
  )
}

Profile.defaultProps = {
  id: '',
}
Profile.propTypes = {
  id: PropTypes.string,
}
export default Profile
