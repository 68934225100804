import React from 'react'
import { Router } from '@reach/router'

import { BranchList } from './branchView'
import { ViewBranch } from './branch'

const Branch = () => (
  <Router>
    <BranchList default path="/" />
    <ViewBranch path="/:id/*" />
  </Router>
)

export default Branch
