import * as Yup from 'yup'

export const dateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/

export const validationSchema = Yup.object().shape({
  candidate_id: Yup.string(),
  profile_image: Yup.string(),
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  job_title: Yup.string().required('Required'),
  employee_id: Yup.string(),
  contact_email: Yup.string().required('Required'),
  contact_mobile: Yup.string().required('Required'),
  contact_alternative_mobile: Yup.string(),
  contact_landline: Yup.string(),
  emergency_first_name: Yup.string().required('Required'),
  emergency_last_name: Yup.string().required('Required'),
  emergency_contact_number: Yup.string().required('Required'),
  emergency_relation: Yup.string().required('Required'),
  address_1: Yup.string().required('Required'),
  address_2: Yup.string(),
  address_3: Yup.string(),
  address_town: Yup.string().required('Required'),
  address_postcode: Yup.string().required('Required'),
  address_country: Yup.string().required('Required'),
  bank_name: Yup.string(),
  bank_account_number: Yup.number(),
  bank_sort_code: Yup.string(),
  ni_number: Yup.string().required('Required'),
  current_status: Yup.string().required('Required'),
  pre_registration_date: Yup.string().matches(
    dateRegex,
    'Must be a valid date in the format dd/mm/yyyy',
  ),
  employment_status: Yup.string(),
  start_date: Yup.string().matches(
    dateRegex,
    'Must be a valid date in the format dd/mm/yyyy',
  ),
  end_date: Yup.string().matches(
    dateRegex,
    'Must be a valid date in the format dd/mm/yyyy',
  ),
  driving_license_type: Yup.string(),
  salary_expectation_min: Yup.string().required('Required'),
  salary_expectation_max: Yup.string(),
  skill_tags: Yup.array()
    .min(1, 'Must have at least 1 tags')
    .required('Required'),
  work_history: Yup.array().of(
    Yup.object().shape({
      company_name: Yup.string(),
      role: Yup.string(),
      start_date: Yup.string().matches(
        dateRegex,
        'Must be a valid date in the format dd/mm/yyyy',
      ),
      end_date: Yup.string().matches(
        dateRegex,
        'Must be a valid date in the format dd/mm/yyyy',
      ),
    }),
  ),
  shift_tags: Yup.array()
    .of(Yup.string())
    .min(1, 'Must have at least 1 tags')
    .required('Required'),
  right_to_work_file: Yup.array()
    .of(Yup.string())
    .nullable()
    .max(1, 'You can only have one document'),
  right_to_work_date: Yup.string().matches(
    dateRegex,
    'Must be a valid date in the format dd/mm/yyyy',
  ),
  source: Yup.string().required('Required'),
  cv: Yup.array()
    .of(Yup.string())
    .nullable()
    .max(1, 'You can only have one document'),
  cscs_card: Yup.array()
    .of(Yup.string())
    .nullable()
    .max(1, 'You can only have one document'),
})
