import styled from 'styled-components'

import { Box } from '../ui'
import { FormikDatepicker } from './form'

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const SBox = styled(Box)`
  text-align: center;
  padding: 1.5rem;
  min-height: 200px;
  min-width: 200px;
  width: 100%;
  box-shadow: none;
  display: ${p => (p.isEmpty ? 'none' : 'block')};
  border-bottom: 1px solid #e5e7ed;
  &:last-of-type {
    border-bottom: none;
  }
  @media (min-width: 975px) {
    padding: 2.5rem;
    width: 25%;
    max-width: 1200px;
    display: block;
    border-bottom: none;
    &:nth-child(n + 2) {
      border-left: 1px solid #e5e7ed;
    }
    &:nth-child(n + 4) {
      border-top: 1px solid #e5e7ed;
    }
    &:nth-child(-n + 4) {
      border-top: 1px none;
    }
    &:nth-child(5),
    &:nth-child(9),
    &:nth-child(13),
    &:nth-child(17) {
      border-left: none;
    }
  }
  cursor: ${p => (!p.isEmpty ? 'pointer' : 'normal')};
  transition: 0.3s ease-in-out;
  &:hover {
    background-color: ${p =>
      !p.isEmpty ? 'rgba(0, 0, 0, 0.02)' : 'transparent'};
    box-shadow: ${p =>
      !p.isEmpty ? '2px 1px 4px 0 rgba(0, 0, 0, 0.05)' : 'none'};
  }
`
export const TitleHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const DataContainer = styled(Box)`
  text-align: center;
  width: 100%;
`

export const Text = styled.p`
  color: ${p => p.theme.color.text};
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 0.5rem;
`

export const Count = styled.p`
  color: black;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
`

export const SDatepicker = styled(FormikDatepicker)`
  max-width: 250px;
  margin-right: 2rem;
  width: 100%;
  label {
    width: 100%;
    text-align: initial;
  }
  input {
    padding-right: 2rem;
  }
`

export const SCard = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 2rem;
  ${p => p.theme.layout.tablet} {
    margin: 2rem 0 2rem 0;
  }
`

export const Split = styled.div`
  display: flex;
`
