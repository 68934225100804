import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import _ from 'lodash'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import {
  Modal,
  ModalFooter,
  ModalColumns,
  Button,
  PaddedDivider,
  PaddedBottomDivider,
  DeleteButton,
} from '../../../ui'
import {
  FormikSelect,
  Form,
  FormikInput,
  FormGroupHeading,
  TagSelector,
  FormikFileInput,
  FormikProfilePhoto,
  Error,
} from '../../../form'
import { validationSchema } from './Validation'
import { initialValues } from './InitialValues'
import { jsonToFormData, handleBackEndErrors } from '../../../../utilities'
import { useGetShiftTags, useGetSkillTags } from './useGetTags'

const DetailsModal = ({
  isVisible,
  details,
  setModalVisibility,
  setCandidateDetails,
  submitResponse,
  user,
}) => {
  const newCandidate = _.isEmpty(details)
  const { addToast } = useToasts()
  const { skillTags, isFetchingSkill } = useGetSkillTags()
  const { shiftTags, isFetchingShift } = useGetShiftTags()
  const [backEndErrors, setBackEndErrors] = React.useState({})

  const onSubmit = async (values, { setSubmitting }) => {
    values.driving_license_type = values.driving_license_type.toString()
    if (!values.pre_registration_date) {
      values.pre_registration_date = '01/01/1970'
    }
    const newValues = jsonToFormData(values)
    axios({
      method: 'post',
      url: newCandidate ? 'v1/candidates/create' : 'v1/candidates/update',
      data: newValues,
    })
      .then(response => {
        if (newCandidate) submitResponse(response.data, 'add')
        else setCandidateDetails(response.data)
        addToast(
          `Candidate ${newCandidate ? 'created' : 'updated'} successfully`,
          {
            appearance: 'success',
          },
        )
        setModalVisibility(false)
      })

      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(
            `Error ${
              newCandidate ? 'creating' : 'updating'
            } candidate, please try again`,
            {
              appearance: 'error',
            },
          )
        }
      })
    setSubmitting(false)
  }

  const addRow = async (
    rep_type,
    values,
    validateForm,
    setFieldTouched,
    setFieldValue,
    initialValues,
  ) => {
    const errors = await validateForm()
    const currentReps = values[rep_type]

    const rep_obj = initialValues.work_history[0]
    if (errors[rep_type]) {
      // touch all fields of last rep
      const fields = Object.keys(rep_obj)
      return fields.forEach(field => {
        const currentRep = currentReps.length - 1
        const fieldPath = `[${rep_type}][${currentRep}][${field}]`
        setFieldTouched(fieldPath)
      })
    } else {
      // add new rep
      setFieldValue(`[${rep_type}]`, [...currentReps, rep_obj])
    }
  }

  const removeRow = (
    rep_type,
    repIndexToRemove,
    values,
    setFieldTouched,
    setFieldValue,
  ) => {
    const reps = values[rep_type]
    // filter out rep by index
    const updatedReps = reps.filter((_, idx) => idx !== repIndexToRemove)
    // clear any touched errors
    setFieldTouched(`[${rep_type}][${repIndexToRemove}]`, false)
    setFieldValue(`[${rep_type}]`, updatedReps)
  }

  const dot = value => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor:
        value === 'active'
          ? '#07BB8C'
          : value === 'do not use'
          ? '#db3e57'
          : value === 'live placed'
          ? '#C417D2'
          : value === 'healthcare'
          ? '#008578'
          : value === 'pre-registration'
          ? '#F3692A'
          : '#707C99',
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 10,
      height: 10,
      width: 10,
    },
  })

  const colourStyles = {
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.value) }),
  }

  return (
    <Modal
      isVisible={isVisible}
      heading={newCandidate ? 'Add New Candidate' : 'Edit Candidate'}
      onClose={() => setModalVisibility(false)}
      size="large"
    >
      <Formik
        initialValues={initialValues(details)}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          values,
          setFieldTouched,
          setFieldValue,
          validateForm,
          initialValues,
          errors,
        }) => (
          <Form>
            <FormGroupHeading>Candidate Profile Details</FormGroupHeading>
            <FormikProfilePhoto
              name="profile_image"
              endpointUrl="v1/candidates/profile-image/create"
              id={values.candidate_id ? values.candidate_id : ''}
            />
            <ModalColumns>
              <FormikInput type="text" name="first_name" label="First name" />
              <FormikInput type="text" name="last_name" label="Last name" />
              <FormikInput type="text" name="employee_id" label="Employee ID" />
              <FormikInput type="text" name="job_title" label="Job title" />
            </ModalColumns>
            <PaddedBottomDivider />
            <FormGroupHeading>Candidate Contact Details</FormGroupHeading>
            <ModalColumns>
              <FormikInput type="text" name="contact_email" label="Email" />
              <FormikInput type="text" name="contact_mobile" label="Mobile" />
              <FormikInput
                type="text"
                name="contact_alternative_mobile"
                label="Alternative Mobile"
              />
              <FormikInput
                type="text"
                name="contact_landline"
                label="Landline"
              />
            </ModalColumns>
            <PaddedBottomDivider />
            <FormGroupHeading>Emergency Contact Details</FormGroupHeading>
            <ModalColumns>
              <FormikInput
                type="text"
                name="emergency_first_name"
                label="First name"
              />
              <FormikInput
                type="text"
                name="emergency_last_name"
                label="Last name"
              />
              <FormikInput
                type="text"
                name="emergency_contact_number"
                label="Contact number"
              />
              <FormikInput
                type="text"
                name="emergency_relation"
                label="Relation to the candidate"
              />
            </ModalColumns>
            <PaddedBottomDivider />
            <FormGroupHeading>Address Details</FormGroupHeading>
            <ModalColumns>
              <FormikInput
                type="text"
                name="address_1"
                label="Address Line 1"
              />
              <FormikInput
                type="text"
                name="address_2"
                label="Address Line 2"
              />
              <FormikInput
                type="text"
                name="address_3"
                label="Address Line 3"
              />
              <FormikInput type="text" name="address_town" label="Town/City" />
              <FormikInput
                type="text"
                name="address_postcode"
                label="Postcode"
              />
              <FormikInput type="text" name="address_country" label="Country" />
            </ModalColumns>
            {user && user.admin ? (
              <>
                <FormGroupHeading>Bank &amp; NI Details</FormGroupHeading>
                <ModalColumns>
                  <FormikInput
                    type="text"
                    name="bank_name"
                    label="Name of Bank"
                  />
                  <FormikInput
                    type="number"
                    name="bank_account_number"
                    label="Account Number"
                  />
                  <FormikInput
                    type="text"
                    name="bank_sort_code"
                    label="Sort Code"
                  />
                  <FormikInput
                    type="text"
                    name="ni_number"
                    label="National Insurance Number"
                  />
                </ModalColumns>
              </>
            ) : (
              <>
                <FormGroupHeading>NI Details</FormGroupHeading>
                <ModalColumns>
                  <FormikInput
                    type="text"
                    name="ni_number"
                    label="National Insurance Number"
                  />
                </ModalColumns>
              </>
            )}
            <PaddedBottomDivider />
            <FormGroupHeading>Status</FormGroupHeading>
            <ModalColumns>
              <FormikSelect
                name="current_status"
                label="Current Status"
                placeholder="Set current status"
                options={[
                  {
                    value: 'active',
                    label: 'Active',
                  },
                  {
                    value: 'do not use',
                    label: 'Do not use',
                  },
                  {
                    value: 'live placed',
                    label: 'Live placed',
                  },
                  {
                    value: 'pre-registration',
                    label: 'Pre-registration',
                  },
                  {
                    value: 'prospect',
                    label: 'Prospect',
                  },
                  {
                    value: 'healthcare',
                    label: 'Healthcare',
                  },
                  {
                    value: 'Found another job',
                    label: 'Found another job',
                  },
                ]}
                styles={colourStyles}
              />
              <FormikSelect
                name="employment_status"
                label="Employment Status"
                placeholder="Set employment status"
                options={[
                  {
                    value: 'Permanent',
                    label: 'Permanent',
                  },
                  {
                    value: 'Temporary',
                    label: 'Temporary',
                  },
                  {
                    value: 'Both',
                    label: 'Both',
                  },
                ]}
              />
              <FormikInput
                type="text"
                name="pre_registration_date"
                label="Pre-Registration Date"
                placeholder="dd/mm/yyyy"
              />
              <FormikInput
                type="text"
                name="start_date"
                label="Start date"
                placeholder="dd/mm/yyyy"
              />
              <FormikInput
                type="text"
                name="end_date"
                label="End date"
                placeholder="dd/mm/yyyy"
              />
            </ModalColumns>
            <PaddedBottomDivider />
            <FormGroupHeading>Qualifications</FormGroupHeading>
            <ModalColumns>
              <TagSelector
                name="driving_license_type"
                label="Driving License Types"
                placeholder="Select driving license"
                options={[
                  'UK Driving License',
                  '7.5 Tonne License',
                  'HGV Class 1',
                  'HGV Class 2',
                  'FLT Counter Balance',
                  'FLT Reach',
                  'Hiab',
                  'ADR',
                  'Moffett',
                ]}
              />
            </ModalColumns>
            <FormGroupHeading>Pay Expectations</FormGroupHeading>
            <ModalColumns>
              <FormikInput
                type="text"
                name="salary_expectation_min"
                label="Minimum"
                placeholder="£7 per hour"
              />
              <FormikInput
                type="text"
                name="salary_expectation_max"
                label="Maximum"
                placeholder="£11 per hour"
              />
            </ModalColumns>
            <PaddedDivider />
            <FormGroupHeading>Skill Tags</FormGroupHeading>
            {user && user.admin ? (
              <TagSelector
                name="skill_tags"
                label=""
                options={isFetchingSkill ? ['Loading...'] : skillTags}
              />
            ) : (
              <ModalColumns>
                <TagSelector
                  name="skill_tags"
                  label=""
                  placeholder="Select..."
                  noOptionsMessage={() => 'No skills available'}
                  options={isFetchingSkill ? ['Loading...'] : skillTags}
                />
              </ModalColumns>
            )}
            <PaddedBottomDivider />
            <FormGroupHeading>Work History</FormGroupHeading>
            {values
              ? values.work_history.map((_, idx) => (
                  <React.Fragment key={`work_history_${idx}`}>
                    <ModalColumns>
                      <FormikInput
                        name={`work_history[${idx}].company_name`}
                        type="text"
                        label="Name of company"
                      />
                      <FormikInput
                        name={`work_history[${idx}].role`}
                        type="text"
                        label="Role"
                      />
                      <FormikInput
                        name={`work_history[${idx}].start_date`}
                        type="text"
                        label="Start date"
                        placeholder="dd/mm/yyyy"
                      />
                      <FormikInput
                        name={`work_history[${idx}].end_date`}
                        type="text"
                        label="Leaving date"
                        placeholder="dd/mm/yyyy"
                      />
                    </ModalColumns>
                    {values.work_history.length > 1 && idx !== 0 ? (
                      <>
                        <DeleteButton
                          type="button"
                          name="remove_row"
                          onClick={() =>
                            removeRow(
                              'work_history',
                              idx,
                              values,
                              setFieldTouched,
                              setFieldValue,
                            )
                          }
                        >
                          Delete Contact
                        </DeleteButton>
                      </>
                    ) : null}
                    <PaddedDivider />
                  </React.Fragment>
                ))
              : null}
            <Button
              onClick={() =>
                addRow(
                  'work_history',
                  values,
                  validateForm,
                  setFieldTouched,
                  setFieldValue,
                  initialValues,
                )
              }
              type="button"
            >
              Add another
            </Button>
            <PaddedDivider />
            <FormGroupHeading>Preferred Shift Patterns</FormGroupHeading>
            <TagSelector
              name="shift_tags"
              label=""
              options={isFetchingShift ? ['Loading...'] : shiftTags}
            />
            <PaddedDivider />
            <FormGroupHeading>Candidate Source</FormGroupHeading>
            <ModalColumns>
              <FormikSelect
                name="source"
                label=""
                placeholder="Select..."
                options={[
                  {
                    value: 'Application – CV Library',
                    label: 'Application – CV Library',
                  },
                  {
                    value: 'Application – Indeed',
                    label: 'Application – Indeed',
                  },
                  {
                    value: 'Application – Totaljobs',
                    label: 'Application – Totaljobs',
                  },
                  {
                    value: 'Application – Reed',
                    label: 'Application – Reed',
                  },
                  {
                    value: 'CV Search – CV Library',
                    label: 'CV Search – CV Library',
                  },
                  {
                    value: 'CV Search – Indeed',
                    label: 'CV Search – Indeed',
                  },
                  {
                    value: 'CV Search – Totaljobs',
                    label: 'CV Search – Totaljobs',
                  },
                  {
                    value: 'CV Search – Reed',
                    label: 'CV Search – Reed',
                  },
                  {
                    value: 'LinkedIn',
                    label: 'LinkedIn',
                  },
                  {
                    value: 'LinkedIn Recruiter',
                    label: 'LinkedIn Recruiter',
                  },
                  {
                    value: 'Facebook',
                    label: 'Facebook',
                  },
                  {
                    value: 'Candidate Referral',
                    label: 'Candidate Referral',
                  },
                  {
                    value: 'Client Referral',
                    label: 'Client Referral',
                  },
                  {
                    value: 'Radio Advert',
                    label: 'Radio Advert',
                  },
                  {
                    value: 'Walk In',
                    label: 'Walk In',
                  },
                  {
                    value: 'Pin Point Website',
                    label: 'Pin Point Website',
                  },
                  {
                    value: 'Pin Point Training Course',
                    label: 'Pin Point Training Course',
                  },
                ]}
              />
            </ModalColumns>
            <PaddedBottomDivider />
            <FormGroupHeading>Right to Work Document</FormGroupHeading>
            <ModalColumns>
              <FormikFileInput label="File upload" name="right_to_work_file" />
              <FormikInput
                type="text"
                name="right_to_work_date"
                label="Right to work date"
                placeholder="dd/mm/yyyy"
              />
            </ModalColumns>
            <PaddedBottomDivider />
            <FormGroupHeading>Other Documents</FormGroupHeading>
            <ModalColumns>
              <FormikFileInput label="CV upload" name="cv" />
              <FormikFileInput label="CSCS upload" name="cscs_card" />
            </ModalColumns>
            {_.isEmpty(backEndErrors) ? (
              ''
            ) : (
              <>
                <PaddedDivider />
                <Error>{handleBackEndErrors(backEndErrors)}</Error>
              </>
            )}
            <ModalFooter>
              <Button type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

DetailsModal.defaultProps = {
  details: {},
  user: {},
  submitResponse: () => {},
  setCandidateDetails: () => {},
}

DetailsModal.propTypes = {
  details: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  setCandidateDetails: PropTypes.func,
  submitResponse: PropTypes.func,
  user: PropTypes.object,
}

export default DetailsModal
