import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import _ from 'lodash'
import { useToasts } from 'react-toast-notifications'

import { FormTitle, FormikInput, Form, Error } from '../form'
import { Button, PaddedDivider } from '../ui'
import { FormBox, FormCancelButton } from './EditAccount'
import { AuthContext } from '../../context/auth'
import { createFormData, handleBackEndErrors } from '../../utilities'

export const UpdatePassword = () => {
  const { addToast } = useToasts()
  const auth = React.useContext(AuthContext)
  const [updatePasswordFormVisible, setFormVisibility] = React.useState(false)
  const [backEndErrors, setBackEndErrors] = React.useState({})

  const onSubmit = async (values, { setSubmitting }) => {
    const newValues = createFormData(values)
    axios({
      method: 'post',
      url: 'v1/users/update_password',
      data: newValues,
    })
      .then(response => {
        if (response) {
          setFormVisibility(false)
          addToast(`Password updated successfully`, {
            appearance: 'success',
          })
        }
      })
      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error updating password, please try again`, {
            appearance: 'error',
          })
        }
      })

    setSubmitting(false)
  }

  return (
    <FormBox mb={'4rem'}>
      {!updatePasswordFormVisible ? (
        <Button fullWidth tall onClick={() => setFormVisibility(true)}>
          New Password
        </Button>
      ) : (
        <Formik
          initialValues={{
            user_id: auth && auth.user && auth.user.id ? auth.user.id : 0,
            currentPassword: '',
            password: '',
            password_confirmation: '',
          }}
          validationSchema={Yup.object().shape({
            currentPassword: Yup.string(),
            password: Yup.string()
              .min(8, 'Password must be 8 characters or longer')
              .max(50, 'Password must be less than 50 characters')
              .required('Required'),
            password_confirmation: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Passwords must match')
              .required('Must confirm password'),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormTitle mt="0" mb="1.5rem">
                Create New Password
              </FormTitle>
              {/* <FormikInput label="Current Password" name="currentPassword" /> */}
              <FormikInput
                type="password"
                label="New Password"
                name="password"
              />
              <FormikInput
                type="password"
                label="Re-type New Password"
                name="password_confirmation"
              />
              {_.isEmpty(backEndErrors) ? (
                ''
              ) : (
                <>
                  <PaddedDivider />
                  <Error>{handleBackEndErrors(backEndErrors)}</Error>
                </>
              )}
              <Button fullWidth tall type="submit" disabled={isSubmitting}>
                Update Password
              </Button>
              <FormCancelButton
                onClick={() => setFormVisibility(false)}
                type="button"
              >
                Cancel
              </FormCancelButton>
            </Form>
          )}
        </Formik>
      )}
    </FormBox>
  )
}
