import React from 'react'
import { CSSTransition } from 'react-transition-group'
import _ from 'lodash'
import { useToasts } from 'react-toast-notifications'
import axios from 'axios'

import { Title, LoadingIndicator } from '../ui'
import Layout from '../layout'
import FileUploader from '../form/file_uploader'
import styled from 'styled-components'
import { createFormData } from '../../utilities'
import CVForm from './form'

const LoadingContainer = styled.div`
  position: absolute;
  right: 50%;
  bottom: 50%;
  padding: 1rem;
  background: white;
  transform: translate(50%);
  ${p => p.theme.style.box_shadow};
`

const CVUpload = () => {
  const [fileLoading, setFileLoading] = React.useState(false)
  const [cvData, setCVData] = React.useState({})
  const [isUploading, setIsUploading] = React.useState(false)
  const { addToast } = useToasts()

  const fileUpload = async file => {
    setFileLoading(true)
    const cv = createFormData({ cv: file })
    const res = await axios({
      method: 'post',
      url: 'v1/parse-cv',
      data: cv,
    })
      .then(response => {
        const data = response.data?.data
        setCVData({ ...data, cv_id: response.data.cv_id })
        setFileLoading(false)
        return response
      })
      .catch(error => {
        setFileLoading(false)
        addToast(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : `Error uploading cv, please try again`,
          {
            appearance: 'error',
          },
        )
      })
    return res
  }
  return (
    <>
      <Layout>
        <Title style={{ marginBottom: '2rem' }}>CV Parser</Title>
        {fileLoading ? (
          <CSSTransition
            in={fileLoading}
            timeout={200}
            classNames="fade"
            unmountOnExit
          >
            <LoadingContainer>
              <LoadingIndicator />
            </LoadingContainer>
          </CSSTransition>
        ) : (
          <>
            {!_.isEmpty(cvData) && !isUploading ? (
              <CVForm cvData={cvData} setCVData={setCVData} />
            ) : (
              <FileUploader
                onUpload={fileUpload}
                promptText="Drag & Drop your document in or click the file icon to Upload"
                isUploading={isUploading}
                setIsUploading={setIsUploading}
              />
            )}
          </>
        )}
      </Layout>
    </>
  )
}

export default CVUpload
