import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from '@reach/router'

import { Avatar, Status } from '../../../../ui'
import { CheckboxWithoutLabel } from '../../../../form'
import { CandidateDetails, CandidateName, CandidateJobTitle } from './style'

const SLink = styled(Link)`
  text-decoration: none;
  color: #000;
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

const Candidate = ({
  id,
  action,
  jobTitle,
  name,
  email,
  photo,
  onChange,
  status,
  value,
  isClient,
  ...props
}) => (
  <Container>
    <CheckboxWithoutLabel
      name={name}
      value={value}
      onChange={onChange}
      {...props}
    />
    <SLink to={`${isClient ? '/clients/' : '/candidates/'}${id}`}>
      <CandidateDetails>
        <Avatar name={name} photo={photo} />
        <div>
          <CandidateName>{name}</CandidateName>
          {email && <CandidateJobTitle>{email}</CandidateJobTitle>}
          <CandidateJobTitle>{jobTitle}</CandidateJobTitle>
          <Status status={status} />
        </div>
      </CandidateDetails>
    </SLink>
  </Container>
)

Candidate.defaultProps = {
  photo: '',
  action: '',
  jobTitle: '',
  email: '',
  value: false,
  onChange: () => {},
  disabled: false,
  isClient: false,
}

Candidate.propTypes = {
  id: PropTypes.number.isRequired,
  jobTitle: PropTypes.string,
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  photo: PropTypes.string,
  action: PropTypes.string,
  onChange: PropTypes.func,
  status: PropTypes.string.isRequired,
  value: PropTypes.bool,
  isClient: PropTypes.bool,
}

export default Candidate
