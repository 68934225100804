import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ThemeProvider } from 'styled-components'
import * as Sentry from '@sentry/browser'

import { Toaster } from './components/ui'
import AuthProvider from './context/auth'
import theme from './style/theme'
import './style/index.css'
import CandidateProvider from './context/candidate-search'
import ClientProvider from './context/client-search'

Sentry.init({
  dsn:
    'https://0302fc2fd5154567b2d6906e092728d8@o380479.ingest.sentry.io/5267979',
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Toaster>
      <AuthProvider>
        <CandidateProvider>
          <ClientProvider>
            <App />
          </ClientProvider>
        </CandidateProvider>
      </AuthProvider>
    </Toaster>
  </ThemeProvider>,
  document.getElementById('root'),
)
