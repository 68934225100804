export const dateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
export const phoneRegex = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const SRegions = [
  'Scotland',
  'Northern Ireland',
  'Wales',
  'North East',
  'North West',
  'Yorkshire and the Humber',
  'West Midlands',
  'East Midlands',
  'South West',
  'South East',
  'East of England',
  'Greater London',
]

export const SIndustry = [
  'Automotive',
  'Commercial',
  'Construction',
  'Education',
  'Healthcare',
  'Industrial',
  'IT',
  'Logistics',
  'Public Sector',
  'Technical',
  'Training',
  'Management Services',
]
