import React from 'react'
import { Router } from '@reach/router'

import Details from './consultant'
import Dashboard from './management'

const Clients = () => {
  return (
    <Router>
      <Dashboard default path="/" />
      <Details path="/consultant/*" />
    </Router>
  )
}

export default Clients
