import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Field } from 'formik'

import { Label, Error } from '..'
import { File } from '.'
import { Button } from '../../ui'

const HiddenInput = styled.input`
  display: none;
  width: 0;
  height: 0;
`

const UploadButton = styled(Button)`
  display: block;
  margin: 0.25rem 0;
  padding: 0.6375rem 1.125rem;
  transition: all 200ms ease-in-out;
  border: 2px solid
    ${p => (p.error ? p.theme.color.fail : p.theme.color.primary_button)};

  &:hover {
    border: 2px solid ${p => p.theme.color.primary_dark};
  }
`

export const FormikFileInput = ({
  label,
  name,
  fileType,
  maxSize,
  ...props
}) => {
  const inputEl = useRef(null)

  const triggerInput = e => {
    e.preventDefault()
    inputEl.current.click()
  }

  async function handleFileChange(e, formik) {
    e.preventDefault()
    let file = e.target.files[0]
    // clear value so can provide feedback if they try to add duplicate file
    e.target.value = null

    if (file) {
      // check exists
      // if (formik.meta.value.find(f => f.name === file.name)) {
      //   return
      // }
      // check size vs maxSize
      if (file.size / 1000 / 1000 > maxSize) {
        formik.meta.error = 'File too large, maximum file size 10MB'
        return
      } else {
        formik.form.setFieldValue(formik.field.name, file)
      }
    }
  }

  const handleRemove = formik => {
    formik.form.setFieldValue(formik.field.name, null)
  }

  return (
    <Field name={name}>
      {formik => (
        <div>
          <Label htmlFor={name}>{label}</Label>
          <HiddenInput
            type="file"
            id={name}
            name={name}
            accept={fileType}
            onChange={e => handleFileChange(e, formik)}
            {...props}
            tabIndex={-1}
            ref={inputEl}
            multiple={false}
          />
          <UploadButton
            type="button"
            onClick={triggerInput}
            error={formik.meta.touched && formik.meta.error}
          >
            {formik.meta.value ? 'Replace' : 'Upload'}
          </UploadButton>
          {formik.meta.value ? (
            <File
              name={formik.meta.value.name}
              remove={() => handleRemove(formik)}
            />
          ) : (
            ''
          )}
          <Error>{formik.meta.touched ? formik.meta.error : ''}</Error>
        </div>
      )}
    </Field>
  )
}

FormikFileInput.defaultProps = {
  fileType: '.jpg,.png,.pdf,.doc,.DOCX',
  maxSize: 10,
}

FormikFileInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fileType: PropTypes.oneOf([
    '.jpg,.png,.pdf,.doc,.DOCX',
    '.jpg,.png,.pdf',
    '.jpg,.png',
    '.pdf',
    '.doc,.DOCX',
    '.doc',
    '.DOCX',
  ]),
  maxSize: PropTypes.number,
}
