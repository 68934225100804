import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import PropTypes from 'prop-types'

import {
  Loader,
  NewActivityButton,
  TabContent,
  MediaActivityCard,
  LoadingMore,
} from '../../../ui'
import useGetFiles from './useGetFiles'
import FileModal from './fileModal'
import { DeleteFileModal } from './delete'

const Media = ({ id }) => {
  const {
    files,
    isFetching,
    fetchMore,
    setMedia,
    isFetchingMore,
  } = useGetFiles(id)

  const [modalVisible, setModalVisibility] = useState(false)
  const [DeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [fileToEdit, setFileToEdit] = useState({})
  const [fileToDelete, setFileToDelete] = useState({})

  const prepareEditFile = file => {
    setModalVisibility(true)
    setFileToEdit(file)
  }

  const prepareDeleteFile = file => {
    setDeleteModalVisible(true)
    setFileToDelete(file)
  }

  const submitResponse = (note, type) => {
    if (type === 'delete') {
      if (files.includes(note)) {
        setMedia(currentNote =>
          currentNote.filter(activeFilter => activeFilter !== note),
        )
      }
    } else if (type === 'edit') {
      let editList = files.map(n => {
        if (n.id === note.id) return note
        else return n
      })
      setMedia(editList)
    } else setMedia([...files, note])
  }

  return (
    <>
      <NewActivityButton onClick={() => setModalVisibility(true)}>
        New File
      </NewActivityButton>
      <Loader isFetching={isFetching}>
        <TabContent>
          {files && files.length > 0 ? (
            <InfiniteScroll
              dataLength={files.length}
              next={fetchMore}
              hasMore={!!fetchMore}
            >
              {files.map(file => (
                <MediaActivityCard
                  key={file.id}
                  file={file}
                  edit={prepareEditFile}
                  deleteFile={prepareDeleteFile}
                />
              ))}
              {fetchMore ? (
                isFetchingMore ? (
                  <LoadingMore>Loading...</LoadingMore>
                ) : (
                  <LoadingMore>Scroll to load more</LoadingMore>
                )
              ) : null}
            </InfiniteScroll>
          ) : (
            <LoadingMore>No files uploaded</LoadingMore>
          )}
        </TabContent>
        {modalVisible ? (
          <FileModal
            isVisible={modalVisible}
            setModalVisibility={setModalVisibility}
            setFileToEdit={setFileToEdit}
            file={fileToEdit}
            candidate={id}
            submitResponse={submitResponse}
          />
        ) : (
          ''
        )}
        {DeleteModalVisible ? (
          <DeleteFileModal
            candidate={id}
            file={fileToDelete}
            setFileToDelete={setFileToDelete}
            isVisible={DeleteModalVisible}
            setModalVisibility={setDeleteModalVisible}
            submitResponse={submitResponse}
          />
        ) : (
          ''
        )}
      </Loader>
    </>
  )
}

Media.defaultProps = {
  id: '',
}
Media.propTypes = {
  id: PropTypes.string,
}
export default Media
