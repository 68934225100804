import { useState, useEffect } from 'react'
import { usePaginatedFetch } from '../../../../hooks'

const useNotes = id => {
  const url = `v1/candidates/${id}/job-activity`
  const errMsg = 'Unable to fetch job activity'
  const [jobs, setJobs] = useState()
  const { data, ...rest } = usePaginatedFetch(url, errMsg)

  useEffect(() => {
    setJobs(data)
  }, [data])

  const formattedData =
    jobs &&
    jobs.map(job => ({
      id: job.id,
      job_title: job.title,
      company: job.client_name,
      desc: job.description,
      start_date: job.start_date,
      leave_date: job.end_date,
      salary: job.salary,
      industry: job.industry,
      tags: job.tags,
      updated_at: job.updated_at,
      placed_timestamp: job.placed_timestamp,
      placed_by: job.placed_by,
    }))

  return {
    activity: formattedData,
    ...rest,
  }
}

export default useNotes
