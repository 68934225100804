import React from 'react'
import PropTypes from 'prop-types'

import { Label, Card, CardGrid, IconButton } from '../..'
import {
  ActivityCardBody,
  ColumnedActivityCardBody,
  ActivityCardFooter,
  CardType,
} from '../'
import { getShortDate, handleData } from '../../../../utilities'

import { ReactComponent as NoteIcon } from '../../../../assets/icons/note.svg'

export const DiaryActivityCard = ({ note, edit, deleteNote, user }) => (
  <Card mb="2rem" urgent={note.marked_as === 'Urgent'}>
    <CardGrid activityCard={true}>
      <CardType>
        <NoteIcon />
      </CardType>
      <ActivityCardBody>
        <h2>New diary note</h2>
      </ActivityCardBody>
      <ColumnedActivityCardBody></ColumnedActivityCardBody>
      <ActivityCardBody>
        <Label label="Date">
          <p>{getShortDate(note.note_date)}</p>
        </Label>
        <Label label="Description">
          <p dangerouslySetInnerHTML={{ __html: handleData(note.note) }} />
        </Label>
      </ActivityCardBody>
      {edit && (user.admin || user.director || user.id === note.user_id) && (
        <IconButton onClick={() => edit(note)} />
      )}
      {deleteNote && user && user.admin && (
        <IconButton icon="bin" onClick={() => deleteNote(note)} />
      )}
    </CardGrid>
    <CardGrid activityCard={true}>
      <ActivityCardFooter>
        Last updated: {getShortDate(note.updated_at)}{' '}
        {note && note.user_name ? 'by ' + note.user_name : ''}
      </ActivityCardFooter>
    </CardGrid>
  </Card>
)

DiaryActivityCard.defaultProps = {
  edit: null,
  deleteNote: null,
  user: {},
}

DiaryActivityCard.propTypes = {
  note: PropTypes.object.isRequired,
  user: PropTypes.object,
  edit: PropTypes.func,
  deleteNote: PropTypes.func,
}
