import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import _ from 'lodash'

import { useToasts } from 'react-toast-notifications'
import { Modal, ModalFooter, Button } from '../../ui'
import { FormikInput, Form, Error } from '../../form'
import { handleBackEndErrors } from '../../../utilities'

export const AddBranchModal = ({ isVisible, onClose, addBranch }) => {
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const onSubmit = async (values, { setSubmitting }) => {
    axios({
      method: 'post',
      url: 'v1/branches',
      data: values,
    })
      .then(response => {
        if (response) {
          addToast(`branch created successfully`, {
            appearance: 'success',
          })
          addBranch(response?.data)
          onClose(true)
        }
      })
      .catch(error => {
        setBackEndErrors(error?.response?.data?.error)
        addToast(`Error creating branch, please try again`, {
          appearance: 'error',
        })
      })
    setSubmitting(false)
  }
  return (
    <Modal
      isVisible={isVisible}
      heading="Create Branch"
      onClose={onClose}
      size="small"
    >
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormikInput label="Branch Name" name="name" />
            {_.isEmpty(backEndErrors) ? (
              ''
            ) : (
              <Error>{handleBackEndErrors(backEndErrors)}</Error>
            )}
            <ModalFooter>
              <Button type="submit" disabled={isSubmitting}>
                Create Branch
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

AddBranchModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  addBranch: PropTypes.func.isRequired,
}
