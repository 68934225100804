import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const statusColours = p =>
  p.status === 'active' || p.status === 'placed'
    ? p.theme.color.success
    : p.status === 'do not use'
    ? p.theme.color.accent
    : p.status === 'live placed'
    ? p.theme.color.purple
    : p.status === 'live'
    ? p.theme.color.success
    : p.status === 'healthcare'
    ? p.theme.color.healthcare
    : p.status === 'prospect'
    ? p.theme.color.orange
    : p.status === 'pre-registration' || p.status === 'not placed'
    ? p.theme.color.orange
    : p.theme.color.grey_dark

const StatusCircleTooltip = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    display: block;
    height: 0.75rem;
    width: 0.75rem;
    left: 0.375rem;
    top: 0.375rem;
    border-radius: 50%;
    background-color: ${p => statusColours(p)};
    z-index: 1;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    background-color: #eee;
    border-radius: 50%;
    z-index: 0;
    transform: scale(0);
    transition: transform 200ms ease-in;
  }

  @media (hover: hover) {
    &:hover {
      &:after {
        transform: scale(1);
      }
    }
    &:hover + span {
      opacity: 1;
    }
  }
`

const StatusTooltipWrapper = styled.div`
  position: relative;
  width: 1.5rem;
`

const Tooltip = styled.span`
  opacity: 0;
  white-space: nowrap;
  background-color: #333;
  color: white;
  display: block;
  padding: 0.125rem 0.25rem;
  border-radius: 0.125rem;
  text-transform: capitalize;
  position: absolute;
  top: 1.5rem;
  left: 50%;
  transform: translate(-50%, 0);
  transition: opacity 100ms ease-in;
  transition-delay: 400ms;
`

const StatusWithTooltip = ({ status }) => {
  const transformedStatus = status ? status.toLowerCase() : 'Unknown'

  return (
    <StatusTooltipWrapper>
      <StatusCircleTooltip status={transformedStatus} />
      <Tooltip>{transformedStatus}</Tooltip>
    </StatusTooltipWrapper>
  )
}

StatusWithTooltip.propTypes = {
  status: PropTypes.string.isRequired,
}

const StatusWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.75rem 1fr;
  gap: 0.375rem;
`

const StatusCircle = styled.span`
  display: block;
  height: 0.75rem;
  width: 0.75rem;
  margin: 0.25rem 0;
  border-radius: 50%;
  background-color: ${p => statusColours(p)};
`

const StatusName = styled.span`
  display: block;
  font: 400 1rem/1.25rem proxima-nova, sans-serif;
  text-transform: capitalize;
`

const Status = ({ status }) => {
  const transformedStatus = status ? status.toLowerCase() : 'Unknown'

  return (
    <StatusWrapper>
      <StatusCircle status={transformedStatus} />
      <StatusName>{transformedStatus}</StatusName>
    </StatusWrapper>
  )
}

Status.propTypes = {
  status: PropTypes.string.isRequired,
}

export { Status, StatusWithTooltip }
