import React from 'react'
import PropTypes from 'prop-types'
import { ToastProvider, DefaultToast } from 'react-toast-notifications'
import styled from 'styled-components'

import { ReactComponent as Check } from '../../assets/icons/check.svg'
import { ReactComponent as Cross } from '../../assets/icons/cross.svg'

const Toast = styled(({ wide, ...props }) => <DefaultToast {...props} />)`
  width: ${p => (p.wide ? '600px !important' : '360px')};
  .react-toast-notifications__toast__content {
    padding: 0;
    background: transparent;
  }
  .react-toast-notifications__toast__icon-wrapper {
    display: none;
  }
  .react-toast-notifications__toast__dismiss-button {
    position: absolute;
    padding: 0;
    top: 4px;
    right: 4px;
    color: ${p => p.theme.color[p.color]};
  }
`

const ToastBox = styled.div`
  display: flex;
  align-items: center;
  color: ${p => p.theme.color.text};
  font-weight: 400;
  font-size: 14px;
  border: 1px solid ${p => p.theme.color[p.color]};
  padding: 15px 30px;
  min-height: 70px;
  background: white;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
  border: 2px solid ${p => p.theme.color[p.color]};
  margin-right: 30px;
  padding: 8px;
`

const CheckIcon = styled(Check)`
  width: 100%;
  fill: ${p => p.theme.color.primary};
`

const CrossIcon = styled(Cross)`
  width: 100%;
  fill: ${p => p.theme.color.fail};
`

const MyCustomToast = ({ ...props }) => {
  const icons = {
    success: { icon: <CheckIcon />, color: 'primary' },
    error: { icon: <CrossIcon />, color: 'accent' },
  }
  const type = icons[props.appearance] || icons.default

  const wide = props.appearance === 'error' && props.autoDismiss === false
  return (
    <Toast {...props} color={type.color} wide={wide}>
      <ToastBox color={type.color}>
        <IconContainer color={type.color}>{type.icon}</IconContainer>
        {props.children}
      </ToastBox>
    </Toast>
  )
}

MyCustomToast.defaultProps = {
  appearance: 'success',
  autoDismiss: true,
}
MyCustomToast.propTypes = {
  appearance: PropTypes.string,
  autoDismiss: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default props => (
  <ToastProvider
    placement="bottom-center"
    components={{ Toast: MyCustomToast }}
    autoDismiss
    autoDismissTimeout={3000}
    {...props}
  />
)
