import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Title, ListHeader, PaddedDivider, SubTitle, Button } from '../../ui'
import {
  Grid,
  SBox,
  Count,
  Text,
  SDatepicker,
  DataContainer,
  TitleHeader,
  SCard,
  Split,
} from '../styles'
import useGetManagement from './useGetManagement'
import Layout from '../../layout'
import { Form } from '../../form'
import { getReverseDate } from '../../../utilities/dates'
import Graph from './chart'
import { navigate } from '@reach/router'
import CardModal from './modal'

const Details = ({ location }) => {
  const {
    managementData,
    getManagementData,
    // backEndErrors,
  } = useGetManagement()
  const [client, setClient] = React.useState({})
  const [candidate, setCandidate] = React.useState({})
  const [userID, setUserID] = React.useState()
  const [modalVisible, setModalVisible] = React.useState(false)
  const [modalTitle, setModalTitle] = React.useState('')
  const [modalType, setModalType] = React.useState('')
  const [modalDates, setModalDates] = React.useState({})
  const [fetching, setFetching] = React.useState(false)

  React.useEffect(() => {
    let url = location.pathname.split('/')
    let user_id = url[url.length - 1]
    if (user_id === 'consultant' || user_id === '') navigate('/management')
    else setUserID(user_id)
  }, [location])

  React.useEffect(() => {
    if (!fetching && userID) {
      getManagementData({ user_id: userID })
      setFetching(true)
    }
  }, [userID, getManagementData, fetching])

  React.useEffect(() => {
    if (managementData && managementData.client) {
      setClient(managementData.client)
    }
    if (managementData && managementData.candidate) {
      setCandidate(managementData.candidate)
    }
    if (managementData && managementData.csv) {
      var win = window.open(managementData.csv, '_blank')
      win.focus()
    }
  }, [managementData])

  const onSubmit = async (values, { setSubmitting }) => {
    const formatValues = {
      date_from: values.date_from ? getReverseDate(values.date_from) : '',
      date_to: values.date_to ? getReverseDate(values.date_to) : '',
      user_id: userID,
    }
    getManagementData(formatValues)
    setModalDates(formatValues)
    setSubmitting(false)
  }

  const openModal = (title, type) => {
    setModalTitle(title)
    setModalType(type)
    setModalVisible(true)
  }
  return (
    <>
      <Layout>
        <TitleHeader>
          <Title style={{ marginRight: 'auto' }}>Management Information</Title>
          <Formik
            initialValues={{
              date_from: '',
              date_to: '',
            }}
            validationSchema={Yup.object().shape({
              date_from: Yup.string(),
              date_to: Yup.string(),
            })}
            onSubmit={onSubmit}
          >
            {({ submitForm }) => (
              <Form>
                <SCard>
                  <Split>
                    <SDatepicker
                      name="date_from"
                      label="Start date"
                      autoComplete="off"
                      placeholderText="Select a date"
                      submit={submitForm}
                    />
                    <SDatepicker
                      name="date_to"
                      label="End date"
                      autoComplete="off"
                      placeholderText="Select a date"
                      submit={submitForm}
                    />
                  </Split>
                </SCard>
              </Form>
            )}
          </Formik>
        </TitleHeader>

        <ListHeader>
          <SubTitle longTitle>Candidate KPIs</SubTitle>
          <Button
            onClick={() =>
              getManagementData({
                withExport: true,
                exportType: 'candidate',
                user_id: userID,
              })
            }
          >
            Download
          </Button>
        </ListHeader>

        <Graph
          dataRaw={candidate}
          labels={['CI', 'CO', 'EI', 'EO', 'GN', 'SMS', 'LI', 'R', 'I']}
        />

        <DataContainer>
          <Grid>
            <SBox onClick={() => openModal('Call Incoming', 'candidate')}>
              <Text>Call Incoming</Text>
              <Count>
                {candidate && candidate.call_incoming
                  ? candidate.call_incoming
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Call Outgoing', 'candidate')}>
              <Text>Call Outgoing</Text>
              <Count>
                {candidate && candidate.call_outgoing
                  ? candidate.call_outgoing
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Email Incoming', 'candidate')}>
              <Text>Email Incoming</Text>
              <Count>
                {candidate && candidate.email_incoming
                  ? candidate.email_incoming
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Email Outgoing', 'candidate')}>
              <Text>Email Outgoing</Text>
              <Count>
                {candidate && candidate.email_outgoing
                  ? candidate.email_outgoing
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('General Note', 'candidate')}>
              <Text>General Note</Text>
              <Count>
                {candidate && candidate.general_notes
                  ? candidate.general_notes
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('SMS', 'candidate')}>
              <Text>SMS</Text>
              <Count>{candidate && candidate.sms ? candidate.sms : '0'}</Count>
            </SBox>
            <SBox onClick={() => openModal('LinkedIn Message', 'candidate')}>
              <Text>LinkedIn Message</Text>
              <Count>
                {candidate && candidate.linkedin ? candidate.linkedin : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Registration', 'candidate')}>
              <Text>Registration</Text>
              <Count>
                {candidate && candidate.registration
                  ? candidate.registration
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Interview', 'candidate')}>
              <Text>Interview</Text>
              <Count>
                {candidate && candidate.interviews ? candidate.interviews : '0'}
              </Count>
            </SBox>
            <SBox isEmpty>
              <Text></Text>
              <Count></Count>
            </SBox>
            <SBox isEmpty>
              <Text></Text>
              <Count></Count>
            </SBox>
            <SBox isEmpty>
              <Text></Text>
              <Count></Count>
            </SBox>
          </Grid>
        </DataContainer>

        <PaddedDivider />

        <ListHeader longTitle>
          <SubTitle>Client KPIs</SubTitle>
          <Button
            onClick={() =>
              getManagementData({
                withExport: true,
                exportType: 'client',
                user_id: userID,
              })
            }
          >
            Download
          </Button>
        </ListHeader>

        <Graph
          dataRaw={client}
          labels={[
            'I',
            'SCQ',
            'SCU',
            'SC',
            'SM',
            'SVM',
            'EI',
            'EO',
            'ES',
            'GN',
            'SMS',
            'LI',
            'PJ',
            'NJ',
            'JL',
            'JF',
            'JC',
          ]}
        />

        <DataContainer>
          <Grid>
            <SBox onClick={() => openModal('Interview', 'client')}>
              <Text>Interview</Text>
              <Count>
                {client && client.interviews ? client.interviews : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Sales Call (Qualified)', 'client')}>
              <Text>Sale Call (Qualified)</Text>
              <Count>
                {client && client.sales_call_qualified
                  ? client.sales_call_qualified
                  : '0'}
              </Count>
            </SBox>
            <SBox
              onClick={() => openModal('Sales Call (Unqualified)', 'client')}
            >
              <Text>Sale Call (Unqualified)</Text>
              <Count>
                {client && client.sales_call_unqualified
                  ? client.sales_call_unqualified
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Service Call', 'client')}>
              <Text>Service Call</Text>
              <Count>
                {client && client.service_call ? client.service_call : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Sales Meeting', 'client')}>
              <Text>Sales Meeting</Text>
              <Count>
                {client && client.sales_meeting ? client.sales_meeting : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Service Meeting', 'client')}>
              <Text>Service Meeting</Text>
              <Count>
                {client && client.service_meeting
                  ? client.service_meeting
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Email Incoming', 'client')}>
              <Text>Email Incoming</Text>
              <Count>
                {client && client.emails_incoming
                  ? client.emails_incoming
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Email Outgoing', 'client')}>
              <Text>Email Outgoing</Text>
              <Count>
                {client && client.emails_outgoing
                  ? client.emails_outgoing
                  : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('E-Shot', 'client')}>
              <Text>E-Shot</Text>
              <Count>{client && client.e_shot ? client.e_shot : '0'}</Count>
            </SBox>
            <SBox onClick={() => openModal('General Note', 'client')}>
              <Text>General Note</Text>
              <Count>
                {client && client.general_notes ? client.general_notes : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('SMS', 'client')}>
              <Text>SMS</Text>
              <Count>{client && client.sms ? client.sms : '0'}</Count>
            </SBox>
            <SBox onClick={() => openModal('LinkedIn Message', 'client')}>
              <Text>LinkedIn Message</Text>
              <Count>{client && client.linkedin ? client.linkedin : '0'}</Count>
            </SBox>
            <SBox onClick={() => openModal('Placed Job', 'client')}>
              <Text>Placed Job</Text>
              <Count>
                {client && client.placed_jobs ? client.placed_jobs : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('New Job', 'client')}>
              <Text>New Job</Text>
              <Count>{client && client.jobs ? client.jobs : '0'}</Count>
            </SBox>
            <SBox onClick={() => openModal('Job Live', 'client')}>
              <Text>Jobs live</Text>
              <Count>
                {client && client.jobs_live ? client.jobs_live : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Job Filled', 'client')}>
              <Text>Jobs Filled</Text>
              <Count>
                {client && client.jobs_filled ? client.jobs_filled : '0'}
              </Count>
            </SBox>
            <SBox onClick={() => openModal('Job Cancelled', 'client')}>
              <Text>Jobs Cancelled</Text>
              <Count>
                {client && client.jobs_cancelled ? client.jobs_cancelled : '0'}
              </Count>
            </SBox>
            <SBox isEmpty>
              <Text></Text>
              <Count></Count>
            </SBox>
            <SBox isEmpty>
              <Text></Text>
              <Count></Count>
            </SBox>
            <SBox isEmpty>
              <Text></Text>
              <Count></Count>
            </SBox>
          </Grid>
        </DataContainer>
      </Layout>
      {modalVisible ? (
        <CardModal
          title={modalTitle}
          modalVisible={modalVisible}
          onClose={() => setModalVisible(false)}
          user_id={userID}
          date_to={modalDates ? modalDates.date_to : ''}
          date_from={modalDates ? modalDates.date_from : ''}
          type={modalType}
        />
      ) : null}
    </>
  )
}

Details.defaultProps = {
  location: {},
}

Details.propTypes = {
  location: PropTypes.object,
}

export default Details
