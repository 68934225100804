import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'
import _ from 'lodash'

import {
  // useGetAllCandidateLocationTags,
  useGetAllCandidateSkillTags,
} from '../../hooks'
import { Error, InputWrapper, InputWithLabel } from '../form'
import { handleBackEndErrors } from '../../utilities'
// import { AddLocationModal } from './addLocation'
import {
  Title,
  SubTitle,
  ListHeader,
  Button,
  // CloseButton,
  PaddedDivider,
  // Divider,
} from '../ui'

export const FilterList = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem 1rem;
  max-height: 610px;
  overflow-y: scroll;
  ${p => p.theme.layout.phone_medium} {
    grid-template-columns: 1fr 1fr;
  }

  ${p => p.theme.layout.tablet} {
    gap: 1rem 1.5rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${p => p.theme.layout.web_small} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  ${p => p.theme.layout.web} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`

export const ActiveFilter = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem;
  background-color: ${p => p.theme.color.grey_light};
  border-radius: 0.375rem;
  padding: 0.125rem 0.25rem 0.125rem 0.75rem;
  text-transform: capitalize;
`

export const ActiveFilterName = styled.span`
  display: block;
  padding: 0.375rem 0;
  color: black;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
`

export const UpdateWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  input {
    margin-right: 1rem;
  }
`

const FilterView = () => {
  const { addToast } = useToasts()
  // const {
  //   tags: locationTags,
  //   isFetching: isLocationFetching,
  //   addLocation,
  //   setLocations,
  // } = useGetAllCandidateLocationTags()
  const {
    tags: skillTags,
    isFetching: isSkillFetching,
    setSkills,
  } = useGetAllCandidateSkillTags()
  // const [modalVisible, setModalVisibility] = React.useState(false)
  const [selectedTag, setSelectedTag] = React.useState('')
  const [oldTag, setOldTag] = React.useState('')
  const [backEndErrors, setBackEndErrors] = React.useState({})

  // const DeleteFilter = async tag => {
  //   axios({
  //     method: 'delete',
  //     url: `v1/candidate/location/${tag.id}`,
  //   })
  //     .then(response => {
  //       if (response.data.success) {
  //         addToast('tag deleted successfully', { appearance: 'success' })
  //         if (locationTags.includes(tag)) {
  //           setLocations(loc =>
  //             loc.filter(activeFilter => activeFilter !== tag),
  //           )
  //         }
  //       } else {
  //         addToast('Error deleting tag, please try again', {
  //           appearance: 'error',
  //         })
  //       }
  //     })
  //     .catch(error => {
  //       console.log(error)
  //       if (error.response.status === 422) {
  //         setBackEndErrors(error.response.data.errors)
  //       } else {
  //         setBackEndErrors(error.response.data.error)
  //         addToast(`Error deleting tag, please try again`, {
  //           appearance: 'error',
  //         })
  //       }
  //     })
  // }

  const updateSkill = async () => {
    axios({
      method: 'post',
      url: `v1/candidates/skill-tags/update`,
      data: { old_tag: oldTag, new_tag: selectedTag },
    })
      .then(response => {
        if (response.data.success) {
          addToast('tag updated successfully', { appearance: 'success' })
          if (skillTags.includes(oldTag)) {
            const temp = skillTags.map(tag => {
              if (tag === oldTag) return selectedTag
              else return tag
            })
            setSkills(temp)
          }
          setSelectedTag('')
          setOldTag('')
        } else {
          addToast('Error updating tag, please try again', {
            appearance: 'error',
          })
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error updating tag, please try again`, {
            appearance: 'error',
          })
        }
      })
  }

  return (
    <>
      <ListHeader>
        <Title>Filters</Title>
      </ListHeader>
      <ListHeader>
        <SubTitle>Skill tags</SubTitle>
      </ListHeader>
      <FilterList isFetching={isSkillFetching}>
        {skillTags && skillTags.length > 0 ? (
          skillTags.map((filter, index) => (
            <ActiveFilter
              key={filter}
              onClick={() => {
                setSelectedTag(filter)
                setOldTag(filter)
              }}
              style={{ cursor: 'pointer' }}
            >
              <ActiveFilterName>{filter}</ActiveFilterName>
            </ActiveFilter>
          ))
        ) : (
          <p>No skill tags to show</p>
        )}
      </FilterList>
      {selectedTag ? (
        <UpdateWrapper>
          <InputWrapper>
            <InputWithLabel
              type="text"
              name="new_tag"
              label="Update tag"
              value={selectedTag}
              onChange={e => setSelectedTag(e.target.value)}
            />
          </InputWrapper>
          <Button onClick={() => updateSkill()}>Update</Button>
        </UpdateWrapper>
      ) : null}
      {/* Commented because the functionality is no longer used for the search */}
      {/* <Divider style={{ margin: '2rem 0' }} />
      <ListHeader>
        <SubTitle>Location</SubTitle>
        <Button onClick={() => setModalVisibility(true)}>Add location</Button>
      </ListHeader> */}
      {/* <FilterList isFetching={isLocationFetching}>
        {locationTags && locationTags.length > 0 ? (
          locationTags.map(filter => (
            <ActiveFilter key={filter.id}>
              <ActiveFilterName>{filter.location}</ActiveFilterName>
              <CloseButton
                onClick={() => DeleteFilter(filter)}
                size="small"
                fill="black"
                hoverFill="#ccc"
              />
            </ActiveFilter>
          ))
        ) : (
          <p>No location filter to show</p>
        )}
      </FilterList> */}
      {_.isEmpty(backEndErrors) ? (
        ''
      ) : (
        <Error>{handleBackEndErrors(backEndErrors)}</Error>
      )}
      <PaddedDivider />
      {/* {modalVisible ? (
        <AddLocationModal
          isVisible={modalVisible}
          onClose={() => setModalVisibility(false)}
          addLocation={addLocation}
        />
      ) : (
        ''
      )} */}
    </>
  )
}

export default FilterView
