import { useState, useEffect } from 'react'
import { useFetch } from '../../../hooks'

const useGetBranchDetails = id => {
  const url = `v1/branches/${id}`
  const { data, isFetching } = useFetch(url, 'Unable to fetch branch data')
  const [branch, setBranch] = useState()

  const updateBranch = details => {
    setBranch(details)
  }

  useEffect(() => {
    setBranch(data)
  }, [data])

  return { details: branch, isFetching, updateBranch }
}

export default useGetBranchDetails
