import React from 'react'
import PropTypes from 'prop-types'

import { Avatar, Status } from '../../ui'
import {
  CandidateDetails,
  CandidateName,
  CandidateJobTitle,
} from '../../jobs/jobDetails/candidateListModal/candidate/style'

const Consultant = ({ jobTitle, name, photo, status }) => (
  <CandidateDetails>
    <Avatar name={name} photo={photo} />
    <div>
      <CandidateName>{name}</CandidateName>
      <CandidateJobTitle>{jobTitle}</CandidateJobTitle>
      <Status status={status} />
    </div>
  </CandidateDetails>
)

Consultant.defaultProps = {
  photo: '',
  jobTitle: '',
}

Consultant.propTypes = {
  jobTitle: PropTypes.string,
  name: PropTypes.string.isRequired,
  photo: PropTypes.string,
  status: PropTypes.string.isRequired,
}

export default Consultant
