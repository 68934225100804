import { useState, useEffect } from 'react'
import { useFetch } from '../../../hooks'

const useGetClientDetails = id => {
  const url = `v1/clients/${id}`
  const { data, isFetching } = useFetch(url, 'Unable to fetch candidate data')
  const [client, setClient] = useState()

  const updateClient = details => {
    setClient(details)
  }

  useEffect(() => {
    setClient(data)
  }, [data])

  const formattedData = client && {
    profile_image: client.profile_image ? client.profile_image : '',
    name: client.name,
    type: client.type,
    id: client.id,
    head_count: client.head_count,
    // contact
    contacts: client.contacts,
    address_1: client.address_1,
    address_2: client.address_2,
    address_3: client.address_town,
    town_city: client.address_town,
    postcode: client.address_postcode,
    country: client.address_country,
    tel: client.contact_telephone,
    website: client.contact_website,
    email: client.contact_email,
    invoice_address: client.invoice_address,
    invoice_email: client.invoice_email,
    // status
    status: client.status,
    // tags
    tags: client.tags,
  }

  return { details: formattedData, isFetching, updateClient }
}

export default useGetClientDetails
