import { useState, useEffect } from 'react'

import { useCand, usePaginatedFetch } from '../../hooks'

const checkParams = (query, title, skill, tag, sort, location, email) => {
  let params = ''
  if (query) {
    params += `name=${query}`
  }
  if (title) {
    params += `&job_title=${title}`
  }
  if (skill?.length >= 1) {
    params += `&skills=${skill}`
  }
  if (tag?.length >= 1) {
    params += `&tags=${tag}`
  }
  if (sort) {
    params += `&orderBy=name`
  }
  if (location) {
    params += `&location=${location}`
  }
  if (email) {
    params += `&email=${email}`
  }
  return params
}

const useGetCandidates = () => {
  const {
    alphaSort,
    searchValue,
    searchTitle,
    searchTag,
    searchSkill,
    searchLocation,
    searchEmail,
  } = useCand()
  let base = 'v1/candidates/search'
  let urlParam =
    searchValue ||
    searchTitle ||
    searchTag ||
    searchSkill ||
    alphaSort ||
    searchLocation ||
    searchEmail
      ? base +
        `?${checkParams(
          searchValue,
          searchTitle,
          searchSkill,
          searchTag,
          alphaSort,
          searchLocation,
          searchEmail,
        )}`
      : base
  const [url, setUrl] = useState(urlParam)
  const [candidates, setCandidates] = useState()
  const { data, ...rest } = usePaginatedFetch(url, 'Unable to fetch candidates')

  useEffect(() => {
    setCandidates(data)
  }, [data])

  const search = () => {
    setUrl(
      searchValue ||
        searchTitle ||
        searchTag ||
        searchSkill ||
        searchLocation ||
        searchEmail ||
        alphaSort
        ? base +
            `?${checkParams(
              searchValue,
              searchTitle,
              searchSkill,
              searchTag,
              alphaSort,
              searchLocation,
              searchEmail,
            )}`
        : base,
    )
  }

  const addCandidate = candidate => {
    setCandidates(cur => [candidate, ...cur])
  }

  const formattedData =
    candidates &&
    candidates.map(candidate => ({
      current_status: candidate.current_status,
      id: candidate.id,
      employee_id: candidate.employee_id,
      first_name: candidate.first_name,
      last_name: candidate.last_name,
      job_title: candidate.job_title,
      contact_email: candidate.contact_email,
      contact_mobile: candidate.contact_mobile,
      address_town: candidate.address_town,
    }))

  return {
    ...rest,
    data: formattedData,
    search,
    addCandidate,
  }
}

export default useGetCandidates
