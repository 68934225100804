import styled from 'styled-components'

export const CandidateDetails = styled.section`
  align-items: center;
  display: grid;
  grid-template-columns: 3rem 1fr;
  gap: 0.75rem;
  margin-bottom: 10px;
`

export const CandidateName = styled.label`
  font-weight: 400;
  display: block;
  pointer-events: none;
`
export const CandidateJobTitle = styled.span`
  margin: 0.125rem 0;
  font-weight: 400;
  display: block;
  color: #3d5a7a;
`
