import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ReactComponent as MagnifyingGlass } from '../../../assets/icons/search.svg'

const SearchBar = styled.div`
  position: relative;
  width: 100%;

  ${p => p.theme.layout.tablet} {
    max-width: 22rem;
  }
`

const SearchIcon = styled(MagnifyingGlass)`
  width: 1.375rem;
  height: 1.375rem;
  position: absolute;
  top: 0.875rem;
  left: 1.125rem;
`

const Input = styled.input`
  font-size: 1rem;
  height: 3rem;
  width: 100%;
  padding: 0 1rem 0 3.25rem;
  border: 1px solid ${p => p.theme.color.border};
  appearance: none;
  position: relative;
  outline: none;
  font: 500 1rem/1.25rem proxima-nova, sans-serif;

  &:focus {
    border-color: ${p => p.theme.color.primary};
  }
`

export const SearchInput = ({ onChange, placeholder }) => {
  return (
    <SearchBar>
      <Input
        name="search"
        type="search"
        placeholder={placeholder}
        onChange={onChange(e => e.target.value)}
      ></Input>
      <SearchIcon />
    </SearchBar>
  )
}

SearchInput.defaultProps = {
  placeholder: 'Search',
}

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}
