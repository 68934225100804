import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import { ReactComponent as SVG } from '../../assets/pinpoint_logo_sm.svg'

export const LoadingMore = styled.span`
  display: block;
  font: 500 1rem/1.25rem proxima-nova, sans-serif;
  text-align: center;
  margin: 0 auto 2rem;
  color: ${p => p.theme.color.text};
`

export const LoadingIndicator = styled(SVG)`
  width: 50px;
  animation: spin 2s infinite ease;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: center;
`

export const LoadingContainer = styled.div`
  margin-top: 5rem;
  position: absolute;
  z-index: 1;
  transform: translateY(-25px);
  padding: 1rem;
  background: white;
  ${p => p.theme.style.box_shadow};
`

export const Loader = ({ children, isFetching }) => (
  <Container>
    <CSSTransition
      in={isFetching}
      timeout={200}
      classNames="fade"
      unmountOnExit
    >
      <LoadingContainer>
        <LoadingIndicator />
      </LoadingContainer>
    </CSSTransition>
    {!isFetching && (
      <CSSTransition in={!!children} timeout={200} classNames="fade">
        <>{children}</>
      </CSSTransition>
    )}
  </Container>
)

Loader.defaultProps = {
  children: null,
  isFetching: false,
}

Loader.propTypes = {
  isFetching: PropTypes.bool,
  children: PropTypes.node,
}
