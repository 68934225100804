import React from 'react'
import axios from 'axios'
import { jsonToFormData } from '../../../../utilities'

const PostInterviewStage = () => {
  const [interviewData, setInterviewData] = React.useState()
  const [backEndErrors, setBackEndErrors] = React.useState({})

  const postInterview = async rawData => {
    const data = rawData ? jsonToFormData(rawData) : rawData
    axios({
      method: 'post',
      url: `v1/candidates/jobs/interview`,
      data: data,
    })
      .then(response => {
        if (response.status === 200) {
          setInterviewData(response.data)
        } else {
          setBackEndErrors({
            error: 'Error receiving management data, please try again',
            appearance: 'error',
          })
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
        }
      })
  }

  return { interviewData, setInterviewData, postInterview, backEndErrors }
}
export default PostInterviewStage
