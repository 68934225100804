import React, { useEffect, useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import _ from 'lodash'

import useGetJobs from './useGetJobs'
import {
  ButtonColumn,
  ListHeader,
  ListHeaderSearch,
  LoadingMore,
  StatusColumn,
  StatusWithTooltip,
  Table,
  Title,
  ViewBtn,
  ViewIcon,
  ViewLink,
  SearchResult,
} from '../ui'
import { SearchInput } from '../form'
import Layout from '../layout'
import { getShortDate } from '../../utilities'
import useDebounce from '../../hooks/useDebounce'

const Jobs = () => {
  const {
    isFetching,
    data,
    fetchMore,
    isFetchingMore,
    search,
    from,
    to,
    total,
  } = useGetJobs()
  const [searchQuery, setSearchQuery] = React.useState(search)
  const debouncedSearch = useDebounce(searchQuery, 500)

  const onSearch = useCallback(debouncedSearch => search(debouncedSearch), [
    search,
  ])

  useEffect(() => {
    onSearch(debouncedSearch)
  }, [debouncedSearch, onSearch])

  const handleSearch = e => {
    setSearchQuery(e.target.value)
  }

  return (
    <Layout isFetching={isFetching}>
      <ListHeader list="clients">
        <Title>Jobs</Title>
        <ListHeaderSearch>
          <SearchInput name="search" onChange={() => handleSearch} />
        </ListHeaderSearch>
      </ListHeader>
      {debouncedSearch && (
        <SearchResult>
          Showing:{' '}
          {data && data.length > 0 ? (
            <>
              <span>
                {from} - {to}
              </span>{' '}
              of <span>{total}</span>
            </>
          ) : (
            '0 '
          )}
          results for &quot;{debouncedSearch}&quot;
        </SearchResult>
      )}
      {!_.isEmpty(data) ? (
        <>
          <InfiniteScroll
            dataLength={data && data.length} //This is important field to render the next data
            next={fetchMore}
            hasMore={!!fetchMore}
          >
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th data-tablet>Client</th>
                  <th>Client Name</th>
                  <th>Job Title</th>
                  <th>Job Creation</th>
                  <th data-tablet>Pay</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map(
                  ({
                    id,
                    client_id,
                    client_name,
                    job_title,
                    status,
                    salary,
                    created_at,
                  }) => (
                    <tr key={id}>
                      <StatusColumn>
                        <StatusWithTooltip status={status} />
                      </StatusColumn>
                      <td data-tablet>{'#' + client_id}</td>
                      <td>{client_name}</td>
                      <td>{job_title}</td>
                      <td>{getShortDate(created_at)}</td>
                      <td data-tablet>{salary}</td>
                      <ButtonColumn>
                        <ViewLink to={`/jobs/${id}`}>
                          <ViewIcon data-mobile data-tablet />
                          <ViewBtn data-web>View</ViewBtn>
                        </ViewLink>
                      </ButtonColumn>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
            {fetchMore ? (
              isFetchingMore ? (
                <LoadingMore>Loading...</LoadingMore>
              ) : (
                <LoadingMore>Scroll to load more</LoadingMore>
              )
            ) : null}
          </InfiniteScroll>
        </>
      ) : (
        <LoadingMore>No jobs found</LoadingMore>
      )}
    </Layout>
  )
}

export default Jobs
