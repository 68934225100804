import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Modal } from '../../ui'
import Consultant from './consultant'
import useGetManagementDetails from './useGetManagementDetails'
import {
  CandidateGroupsWrapper,
  CandidatesWrapper,
} from '../../jobs/jobDetails/candidateListModal/style'
import { Link } from '@reach/router'

const SLink = styled(Link)`
  text-decoration: none;
  color: #000;
`

const CardModal = ({
  modalVisible,
  onClose,
  title,
  user_id,
  date_to,
  date_from,
  type,
  branchID,
}) => {
  const {
    managementDetailsData,
    getManagementDetailsData,
    // backEndErrors,
  } = useGetManagementDetails()
  const [list, setList] = React.useState()
  const [fetching, setFetching] = React.useState(false)

  React.useEffect(() => {
    if (!fetching && title) {
      let data = {
        kpi: title,
      }
      if (type) {
        data = { ...data, type: type }
      }
      if (user_id) {
        data = { ...data, user_id: user_id }
      }
      if (date_to) {
        data = { ...data, date_to: date_to }
      }
      if (date_from) {
        data = { ...data, date_from: date_from }
      }
      if (branchID) {
        data = { ...data, branch_id: branchID }
      }
      getManagementDetailsData(data)
      setFetching(true)
    }
  }, [
    getManagementDetailsData,
    managementDetailsData,
    fetching,
    type,
    date_from,
    date_to,
    title,
    user_id,
    branchID,
  ])

  React.useEffect(() => {
    if (managementDetailsData && managementDetailsData.length > 0) {
      setList(managementDetailsData)
    }
  }, [managementDetailsData])

  return (
    <Modal
      isVisible={modalVisible}
      onClose={onClose}
      heading={title}
      size="large"
      fixed
    >
      <CandidateGroupsWrapper>
        {list && list.length > 0 ? (
          <>
            <CandidatesWrapper>
              {list.map(candidate => (
                <SLink
                  key={candidate.id}
                  to={
                    candidate.first_name && candidate.last_name
                      ? `/candidates/${candidate.id}/activity`
                      : `/clients/${candidate.id}/activity`
                  }
                >
                  <Consultant
                    id={candidate.id}
                    jobTitle={
                      candidate.job_title
                        ? candidate.job_title
                        : candidate.client_name
                    }
                    name={
                      candidate.first_name && candidate.last_name
                        ? candidate.first_name + ' ' + candidate.last_name
                        : candidate.name
                        ? candidate.name
                        : candidate.title
                    }
                    status={
                      candidate.status
                        ? candidate.status
                        : candidate.current_status
                    }
                  />
                </SLink>
              ))}
            </CandidatesWrapper>
          </>
        ) : (
          <p style={{ textAlign: 'center' }}>No {type} list to show</p>
        )}
      </CandidateGroupsWrapper>
    </Modal>
  )
}

CardModal.defaultProps = {
  date_from: '',
  date_to: '',
  user_id: '',
  type: '',
  branchID: '',
}

CardModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  user_id: PropTypes.string,
  date_from: PropTypes.string,
  date_to: PropTypes.string,
  type: PropTypes.string,
  branchID: PropTypes.string,
}

export default CardModal
