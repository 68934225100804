import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

import { Select } from './'

export const FormikSelect = props => {
  const [field, meta, form] = useField(props)
  return (
    <Select
      {...field}
      {...props}
      value={props.options.find(opt => opt.value === field.value)}
      errorText={meta.touched ? meta.error : ''}
      onChange={opt => form.setValue(opt.value)}
      onBlur={() => form.setTouched(true)}
    />
  )
}

FormikSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
}
