import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Card, CardGrid, Label, TextLink, ButtonLink } from '../..'
import {
  ActivityCardBody,
  ActivityCardFooter,
  CardType,
  ColumnedActivityCardBody,
  WideLabel,
} from '../'
import { getShortDate } from '../../../../utilities'
import { Tag, TagWrapper } from '../../../form'

import { ReactComponent as JobIcon } from '../../../../assets/icons/job.svg'

const MarginButtonLink = styled(ButtonLink)`
  margin-top: 1rem;
  width: fit-content;
`

export const JobActivityCardCandidate = ({ job }) => (
  <Card mb="2rem" style={{ marginTop: '2rem' }}>
    <CardGrid activityCard={true}>
      <CardType>
        <JobIcon />
      </CardType>
      <ActivityCardBody>
        <h2>
          {job.job_title} {job && job.company ? '- ' + job.company : ''}
        </h2>
        {job.file ? (
          <Label label="File">
            <TextLink>{job.file}</TextLink>
          </Label>
        ) : (
          <p></p>
        )}
        {job.desc ? <Label label="Description">{job.desc}</Label> : ''}
      </ActivityCardBody>
    </CardGrid>
    <CardGrid jobActivityCard={true}>
      <ColumnedActivityCardBody>
        <Label label="Started">{getShortDate(job.start_date)}</Label>
        <Label label="Leaving">{getShortDate(job.leave_date)}</Label>
        <Label label="Industry">{job.industry}</Label>
        <Label label="Pay">{job.salary}</Label>
        <WideLabel>
          <Label label="Tags">
            <TagWrapper>
              {job.tags.map(tag => (
                <Tag key={tag.id}>{tag.tag}</Tag>
              ))}
            </TagWrapper>
          </Label>
        </WideLabel>
        <WideLabel>
          <MarginButtonLink to={`/jobs/${job.id}`}>View Job</MarginButtonLink>
        </WideLabel>
      </ColumnedActivityCardBody>
    </CardGrid>
    <CardGrid activityCard={true}>
      <ActivityCardFooter>
        Placed: {getShortDate(job.placed_timestamp)} by {job.placed_by}
      </ActivityCardFooter>
    </CardGrid>
  </Card>
)

JobActivityCardCandidate.propTypes = {
  job: PropTypes.object.isRequired,
}
