import { useState, useEffect } from 'react'
import { usePaginatedFetch } from '../../../../hooks'

const useGetFiles = id => {
  const url = `v1/candidates/${id}/media`
  const errMsg = 'Unable to fetch notes'
  const [media, setMedia] = useState()
  const { data, ...rest } = usePaginatedFetch(url, errMsg)

  useEffect(() => {
    setMedia(data)
  }, [data])

  return { files: media, ...rest, setMedia }
}

export default useGetFiles
