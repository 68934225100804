import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import { WarningModal } from '../../../ui'

export const DeleteDiaryModal = ({
  diary,
  name,
  isVisible,
  setModalVisibility,
  submitResponse,
  setDiaryToDelete,
}) => {
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})

  const DeleteNote = async id => {
    axios({
      method: 'post',
      url: `/v1/diary/${id}/delete`,
    })
      .then(response => {
        if (response.data?.message === 'Note deleted') {
          submitResponse(diary, 'delete')
          setModalVisibility(false)
          addToast('Diary deleted successfully', { appearance: 'success' })
          setDiaryToDelete({})
        } else {
          addToast('Error deleting diary, please try again', {
            appearance: 'error',
          })
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error deleting diary, please try again`, {
            appearance: 'error',
          })
        }
      })
  }

  return (
    <WarningModal
      isVisible={isVisible}
      heading="Delete Diary?"
      closeFunc={() => {
        setModalVisibility(false)
        setDiaryToDelete({})
      }}
      submitButtonText="Delete Diary"
      submitFunc={() => DeleteNote(diary.id)}
      backEndErrors={backEndErrors}
    >
      <b>{name}</b> will no longer exist
    </WarningModal>
  )
}

DeleteDiaryModal.propTypes = {
  diary: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  submitResponse: PropTypes.func.isRequired,
  setDiaryToDelete: PropTypes.func.isRequired,
}
