import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import _ from 'lodash'
//import styled from 'styled-components'

import { useToasts } from 'react-toast-notifications'
import { ReactComponent as Logo } from '../../assets/pinpoint_logo.svg'
import { Button } from '../ui'
import { Form, FormikInput, FormTitle, Error } from '../form'
import { Container, Box } from './login-ui'
import { handleBackEndErrors } from '../../utilities'
import { navigate } from '@reach/router'
//import Terms from './terms'

// const TermsBtn = styled.button`
//   border: none;
//   background: none;
//   display: block;
//   cursor: pointer;
//   font-weight: normal;
//   color: ${p => p.theme.color.primary};
//   text-decoration: underline;
//   margin-bottom: 2rem;
// `

const updatePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(7, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  accept_terms: Yup.bool().oneOf([true], 'Required'),
})

const UpdatePassword = ({ hash }) => {
  //const [showTerms, setShowTerms] = useState(false)
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const [resetState, setResetState] = React.useState(false)

  React.useEffect(() => {
    if (resetState) navigate('/')
    if (!hash) navigate('/')
  }, [resetState, hash])

  const submitPassword = async (
    { password, password_confirmation },
    actions,
  ) => {
    axios({
      method: 'post',
      url: 'v1/reset-password',
      data: {
        reset_token: hash,
        password: password,
        password_confirmation: password_confirmation,
      },
    })
      .then(response => {
        if (response) {
          addToast(`Password updated successfully`, {
            appearance: 'success',
          })
          setResetState(true)
        }
      })
      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error resetting password, please try again`, {
            appearance: 'error',
          })
        }
      })
    actions.setSubmitting(false)
  }

  return (
    <Container>
      <Box>
        <Logo height="54" />
        <FormTitle>Create New Password</FormTitle>
        <Formik
          initialValues={{
            password: '',
            password_confirmation: '',
          }}
          validationSchema={updatePasswordSchema}
          onSubmit={submitPassword}
        >
          {({ isSubmitting }) => (
            <>
              <Form>
                <FormikInput
                  type="password"
                  name="password"
                  label="New Password"
                  placeholder="Enter new password"
                ></FormikInput>
                <FormikInput
                  type="password"
                  name="password_confirmation"
                  label="Re-enter New Password"
                  placeholder="Confirm new password"
                />
                {_.isEmpty(backEndErrors) ? (
                  ''
                ) : (
                  <Error>{handleBackEndErrors(backEndErrors)}</Error>
                )}
                <Button fullwidth tall type="submit" disabled={isSubmitting}>
                  Confirm
                </Button>
              </Form>
            </>
          )}
        </Formik>
      </Box>
    </Container>
  )
}

UpdatePassword.defaultProps = {
  hash: '',
}
UpdatePassword.propTypes = {
  hash: PropTypes.string,
}

export default UpdatePassword
