import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'

import Candidate from './candidate'
import useGetCandidateList from './useGetCandidateList'
import { SearchInput, Error } from '../../../form'
import { Button, Modal, ModalFooter, LoadingIndicator } from '../../../ui'
import { handleBackEndErrors } from '../../../../utilities'
import { buildUpdateCandidatesRequest } from '../buildUpdateCandidatesRequest'
import {
  CandidateGroupHeader,
  CandidateGroupsWrapper,
  CandidatesWrapper,
  LoadingContainer,
  LoadingMoreGird,
  NoResultsText,
  Query,
  SearchWrapper,
} from './style'

const CandidateList = ({
  candidateListVisible,
  candidateSuggestions,
  jobId,
  onClose,
  setJob,
  candidateShortlist,
}) => {
  const [selectedCandidates, setSelectedCandidates] = React.useState(
    candidateShortlist,
  )
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const { addToast } = useToasts()
  const [searchQuery, setSearchQuery] = React.useState('')

  let { isFetching, data, fetchMore, search } = useGetCandidateList()

  const saveShortlist = () => {
    axios({
      method: 'post',
      url: 'v1/candidates/jobs/attach',
      data: buildUpdateCandidatesRequest(jobId, false, selectedCandidates),
    })
      .then(response => {
        setJob(response.data)
        onClose()
      })
      .catch(error => {
        if (error.response && error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
          addToast(`Error updating shortlist, please fix errors`, {
            appearance: 'error',
          })
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error updating shortlist, please try again`, {
            appearance: 'error',
          })
        }
      })
  }

  const updateShortlist = candidate => {
    if (selectedCandidates.includes(candidate)) {
      setSelectedCandidates(currentSelectedCandidates =>
        currentSelectedCandidates.filter(
          selectedCandidate => selectedCandidate !== candidate,
        ),
      )
    } else {
      setSelectedCandidates([...selectedCandidates, candidate])
    }
  }

  const getAvailableCandidates = candidateList =>
    candidateList.filter(
      suggestion =>
        !selectedCandidates
          .map(candidate => candidate.id)
          .includes(suggestion.id),
    )

  const handleSearch = e => {
    setSearchQuery(e.target.value)
    search(e.target.value)
  }

  return (
    <Modal
      isVisible={candidateListVisible}
      onClose={onClose}
      heading="Create Candidate Shortlist"
      size="large"
      fixed
    >
      <SearchWrapper>
        <SearchInput
          name="search"
          onChange={() => handleSearch}
          placeholder="Search all candidates"
        />
      </SearchWrapper>
      <CandidateGroupsWrapper>
        {selectedCandidates.length > 0 && (
          <>
            <CandidateGroupHeader>Selected candidates</CandidateGroupHeader>
            <CandidatesWrapper>
              {selectedCandidates.map(candidate => (
                <Candidate
                  key={candidate.id}
                  id={candidate.id}
                  jobTitle={candidate.job_title}
                  name={`${candidate.first_name} ${candidate.last_name}`}
                  email={candidate.contact_email}
                  onChange={() => updateShortlist(candidate)}
                  status={candidate.current_status}
                  value={true}
                  action="select"
                />
              ))}
            </CandidatesWrapper>
          </>
        )}
        {searchQuery === '' ? (
          <>
            {candidateSuggestions.length > 0 ||
            getAvailableCandidates(candidateSuggestions).length > 0 ? (
              <>
                <CandidateGroupHeader>
                  Suggested candidates
                </CandidateGroupHeader>
                <CandidatesWrapper>
                  {candidateSuggestions.length > 0 ||
                  getAvailableCandidates(candidateSuggestions).length > 0
                    ? getAvailableCandidates(
                        candidateSuggestions,
                      ).map(candidate => (
                        <Candidate
                          key={candidate.id}
                          id={candidate.id}
                          jobTitle={candidate.job_title}
                          name={`${candidate.first_name} ${candidate.last_name}`}
                          onChange={() => updateShortlist(candidate)}
                          status={candidate.current_status}
                          value={false}
                          action="select"
                        />
                      ))
                    : ''}
                </CandidatesWrapper>
              </>
            ) : (
              ''
            )}
          </>
        ) : (
          <>
            <CandidateGroupHeader>Search results</CandidateGroupHeader>
            {!isFetching ? (
              data.length > 0 ? (
                <InfiniteScroll
                  dataLength={data && data.length}
                  next={fetchMore}
                  hasMore={!!fetchMore}
                >
                  <CandidatesWrapper>
                    {getAvailableCandidates(data).map(candidate => (
                      <Candidate
                        key={candidate.id}
                        id={candidate.id}
                        jobTitle={candidate.job_title}
                        name={`${candidate.first_name} ${candidate.last_name}`}
                        onChange={() => updateShortlist(candidate)}
                        status={candidate.current_status}
                        action="select"
                      />
                    ))}
                    {fetchMore && (
                      <LoadingMoreGird>Loading more...</LoadingMoreGird>
                    )}
                  </CandidatesWrapper>
                </InfiniteScroll>
              ) : (
                <NoResultsText>
                  No candidate names found including{' '}
                  <Query>&apos;{searchQuery}&apos;</Query>
                </NoResultsText>
              )
            ) : (
              <LoadingContainer>
                <LoadingIndicator />
              </LoadingContainer>
            )}
          </>
        )}
      </CandidateGroupsWrapper>
      <ModalFooter>
        {_.isEmpty(backEndErrors) ? (
          ''
        ) : (
          <Error>{handleBackEndErrors(backEndErrors)}</Error>
        )}
        <Button onClick={saveShortlist}>Save Shortlist</Button>
      </ModalFooter>
    </Modal>
  )
}

CandidateList.defaultProps = {
  candidateSuggestions: [],
  candidateShortlist: [],
}

CandidateList.propTypes = {
  candidateListVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  candidateSuggestions: PropTypes.array,
  setJob: PropTypes.func.isRequired,
  jobId: PropTypes.number.isRequired,
  candidateShortlist: PropTypes.array,
}

export default CandidateList
