import { useState, useEffect } from 'react'
import { useClient, usePaginatedFetch } from '../../hooks'

const checkParams = (query, title, skill, tag, head_count, sort, location) => {
  let params = ''
  if (query) {
    params += `name=${query}`
  }
  if (title) {
    params += `&job_title=${title}`
  }
  if (skill?.length >= 1) {
    params += `&skills=${skill}`
  }
  if (tag?.length >= 1) {
    params += `&tags=${tag}`
  }
  if (head_count) {
    params += `&head_count=${head_count}`
  }
  if (location) {
    params += `&location=${location}`
  }
  if (sort) {
    params += `&orderBy=name`
  }
  return params
}

const useGetClients = () => {
  const {
    searchValue,
    searchTitle,
    searchSkill,
    searchTag,
    searchHeadCount,
    alphaSort,
    searchLocation,
  } = useClient()
  const base = 'v1/clients/search'
  let urlParam =
    searchValue ||
    searchTitle ||
    searchTag ||
    searchSkill ||
    searchHeadCount ||
    alphaSort ||
    searchLocation
      ? base +
        `?${checkParams(
          searchValue,
          searchTitle,
          searchSkill,
          searchTag,
          searchHeadCount,
          alphaSort,
          searchLocation,
        )}`
      : base
  const [url, setUrl] = useState(urlParam)
  const [clients, setClients] = useState()
  let { data, ...rest } = usePaginatedFetch(url, 'Unable to fetch client data')

  const search = () => {
    setUrl(
      searchValue ||
        searchTitle ||
        searchTag ||
        searchSkill ||
        searchHeadCount ||
        searchLocation ||
        alphaSort
        ? base +
            `?${checkParams(
              searchValue,
              searchTitle,
              searchSkill,
              searchTag,
              searchHeadCount,
              alphaSort,
              searchLocation,
            )}`
        : base,
    )
  }

  const eshotSearch = (query, location) => {
    let queryUrl = ''
    if (query) queryUrl += 'name=' + query
    if (location && query) queryUrl += '&'
    if (location) queryUrl += 'location=' + location
    setUrl(queryUrl ? base + '?' + queryUrl : base)
  }

  const addClient = client => {
    setClients(cur => [client, ...cur])
  }

  useEffect(() => {
    setClients(data)
  }, [data])

  const formattedData =
    clients &&
    clients.map(client => ({
      status: client.status,
      id: client.id,
      name: client.name,
      contact_email: client.contact_email,
      contact_telephone: client.contact_telephone,
    }))

  return { ...rest, data: formattedData, search, addClient, eshotSearch }
}

export default useGetClients
