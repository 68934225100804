import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'

import useGetJobActivity from './useGetJobActivity'
import {
  JobActivityCardClient,
  Loader,
  NewActivityButton,
  TabContent,
  LoadingMore,
} from '../../../ui'

import JobModal from './jobModal'
const JobActivity = ({ id }) => {
  const {
    activity,
    isFetching,
    fetchMore,
    jobs,
    setJobs,
    isFetchingMore,
  } = useGetJobActivity(id)
  const [modalVisible, setModalVisibility] = React.useState(false)

  return (
    <>
      <NewActivityButton onClick={() => setModalVisibility(true)}>
        Add New Job
      </NewActivityButton>
      <Loader isFetching={isFetching}>
        <TabContent>
          {activity && activity.length > 0 ? (
            <InfiniteScroll
              dataLength={activity.length}
              next={fetchMore}
              hasMore={!!fetchMore}
            >
              {activity.map(job => (
                <JobActivityCardClient key={job.id} job={job} />
              ))}
              {fetchMore ? (
                isFetchingMore ? (
                  <LoadingMore>Loading...</LoadingMore>
                ) : (
                  <LoadingMore>Scroll to load more</LoadingMore>
                )
              ) : null}
            </InfiniteScroll>
          ) : (
            <LoadingMore>No jobs added</LoadingMore>
          )}
        </TabContent>
      </Loader>
      {modalVisible ? (
        <JobModal
          clientId={id}
          isVisible={modalVisible}
          setModalVisibility={setModalVisibility}
          setJobs={setJobs}
          jobs={jobs}
        />
      ) : (
        ''
      )}
    </>
  )
}

JobActivity.propTypes = {
  id: PropTypes.string.isRequired,
}

export default JobActivity
