import React from 'react'
import PropTypes from 'prop-types'
import { useField, useFormikContext } from 'formik'

import { Datepicker } from '../../form'

export const FormikDatepicker = props => {
  const { setFieldValue } = useFormikContext()
  const [field, meta, form] = useField(props)
  return (
    <Datepicker
      {...field}
      {...props}
      errorText={meta.touched ? meta.error : ''}
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, val)
        props.submit()
      }}
      onBlur={() => form.setTouched(true)}
    />
  )
}

FormikDatepicker.defaultProps = {}

FormikDatepicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
}
