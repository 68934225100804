import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import DefaultProfile from '../../assets/default_profile.png'
export const Card = styled.div.attrs(({ mr = 0, mb = 0 }) => ({
  mr,
  mb,
}))`
  background: white;
  ${p => p.theme.style.box_shadow};
  margin-bottom: 1rem;
  height: fit-content;

  ${p => p.theme.layout.tablet} {
    margin-right: ${p => p.mr};
    margin-bottom: ${p => p.mb};
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  /* Urgent Activity Cards */
  ${p =>
    p.urgent
      ? `
    border-left: solid 0.25rem ${p.theme.color.primary};

    section {
      padding-left: 1.25rem;
    }
    `
      : ''}
`

export const CardSection = styled.section`
  padding: 1.5rem;
  border-bottom: 2px solid ${p => p.theme.color.bg};

  &:last-of-type {
    border-bottom: none;
  }
`

export const CardGrid = styled(CardSection)`
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: 1.875rem 1fr 1.875rem;

  ${p =>
    p.activityCard &&
    `gap: 0.75rem; 
        grid-template-columns: 1.875rem 1fr 1.875rem 1.875rem;`}
  ${p =>
    p.jobActivityCard &&
    `gap: 0.75rem; 
        grid-template-columns: 1.875rem 1fr 1.875rem `}
`

export const CardHeaderContent = styled.div`
  grid-column: 2/3;
`

export const ProfileImage = styled.div`
  height: 4.5rem;
  width: 4.5rem;
  margin: auto;

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
`

export const CardProfileImage = ({ imageURL }) => {
  return (
    <ProfileImage>
      <img
        src={imageURL ? imageURL : DefaultProfile}
        alt="Profile"
        title="Profile photo"
      />
    </ProfileImage>
  )
}

CardProfileImage.defaultProps = {
  imageURL: '',
}

CardProfileImage.propTypes = {
  imageURL: PropTypes.string,
}

export const CardTitle = styled.h1`
  font-weight: 400;
  font-size: 26px;
  color: black;
  margin: 0.25rem 0 0.125rem;
`

export const CardSubtitle = styled.h3`
  font-size: 16px;
  line-height: 20px;
  color: ${p => p.theme.color.text};
  margin-bottom: 0.25rem;
`

// Within a details section
export const CardHeading = styled.h2`
  font-size: 18px;
  color: black;
  margin-bottom: 0.625rem;
`

export const CardText = styled.p`
  color: ${p => p.theme.color.text};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
`
// Activity cards (in tabs)
