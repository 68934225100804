import React from 'react'
import PropTypes from 'prop-types'

import Candidate from '../../jobs/jobDetails/candidateListModal/candidate'
import { PaddedBottomDivider } from '../../ui'
import { CandidateWrapper, ShortBlock } from '../../jobs/jobDetails/styles'
import EshotModal from './modal'

export const EshotClients = ({ shortlist, setShortlist }) => {
  const updateSelection = (client, selection, setSelection) => {
    if (selection.includes(client)) {
      setSelection(selectedClientList =>
        selectedClientList.filter(selectedClient => selectedClient !== client),
      )
    } else {
      setSelection([...selection, client])
    }
  }

  return (
    <>
      {shortlist && shortlist.length > 0 ? (
        <>
          <PaddedBottomDivider />
          <section>
            <h3>E-Shot Shortlist</h3>
            <CandidateWrapper>
              {shortlist.map((client, index) => (
                <ShortBlock key={client.id}>
                  <Candidate
                    id={client.id}
                    jobTitle={''}
                    name={`${client.name}`}
                    onChange={() =>
                      updateSelection(client, shortlist, setShortlist)
                    }
                    value={shortlist
                      .map(selectedClient => selectedClient.id)
                      .includes(client.id)}
                    status={client.status}
                    action="select"
                    isClient={true}
                  />
                </ShortBlock>
              ))}
            </CandidateWrapper>
          </section>
          {shortlist?.length >= 1 ? (
            <EshotModal
              clientsShortlist={shortlist}
              setSelectedClientsShortlist={setShortlist}
            />
          ) : (
            <p style={{ marginTop: '1rem', fontWeight: '400' }}>
              Select candidates to create e-shot
            </p>
          )}
        </>
      ) : (
        <>
          <PaddedBottomDivider />
          <section>
            <h3>E-Shot Shortlist</h3>
            <p style={{ marginTop: '1rem', fontWeight: '400' }}>
              To send out E-Shots you must first create a shortlist
            </p>
          </section>
        </>
      )}
    </>
  )
}

EshotClients.defaultProps = {
  shortlist: [],
}

EshotClients.propTypes = {
  shortlist: PropTypes.array,
  setShortlist: PropTypes.func.isRequired,
}
