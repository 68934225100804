import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'

import {
  Loader,
  NewActivityButton,
  TabContent,
  NoteActivityCard,
  DiaryActivityCard,
  LoadingMore,
} from '../../../ui'
import useGetNotes from './useGetNotes'
import NoteModal from './noteModal'
import { AuthContext } from '../../../../context/auth'
import { DeleteNoteModal } from './delete'
import DiaryModal from './diaryModal'
import { DeleteDiaryModal } from './diaryDelete'
import styled from 'styled-components'

const ButtonHeader = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
`

const Notes = ({ id }) => {
  const auth = React.useContext(AuthContext)
  const {
    notes,
    isFetching,
    fetchMore,
    setNotes,
    isFetchingMore,
  } = useGetNotes(id)

  const [modalVisible, setModalVisibility] = useState(false)
  const [DeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [diaryVisible, setDiaryVisibility] = useState(false)
  const [deleteDiaryVisible, setDeleteDiaryVisible] = useState(false)
  const [noteToEdit, setNoteToEdit] = useState({})
  const [noteToDelete, setNoteToDelete] = useState({})
  const [diaryToEdit, setDiaryToEdit] = useState({})
  const [diaryToDelete, setDiaryToDelete] = useState({})

  const prepareModalNote = note => {
    setModalVisibility(true)
    setNoteToEdit(note)
  }

  const prepareDeleteNote = file => {
    setNoteToDelete(file)
    setDeleteModalVisible(true)
  }

  const prepareModalDiary = note => {
    setDiaryVisibility(true)
    setDiaryToEdit(note)
  }

  const prepareDeleteDiary = file => {
    setDiaryToDelete(file)
    setDeleteDiaryVisible(true)
  }

  const submitResponse = (note, type) => {
    if (type === 'delete') {
      if (notes.includes(note)) {
        setNotes(currentNote =>
          currentNote.filter(activeFilter => activeFilter !== note),
        )
      }
    } else if (type === 'edit') {
      let editList = notes.map(n => {
        if (n.id === note.id) return note
        else return n
      })
      setNotes(editList)
    } else setNotes(n => [note, ...n])
  }

  const submitResponseDiary = (diary, type) => {
    if (type === 'delete') {
      setNotes(currentNote =>
        currentNote.filter(
          activeFilter =>
            activeFilter.id !== diary.id &&
            (diary.model_key === 'diary_note' || diary.type === 'Diary'),
        ),
      )
    } else if (type === 'edit') {
      let editList = notes.map(n => {
        if (n.id === diary.id) return diary
        else return n
      })
      setNotes(editList)
    } else setNotes(n => [diary, ...n])
  }

  return (
    <>
      <ButtonHeader>
        <NewActivityButton
          onClick={() => setModalVisibility(true)}
          style={{ margin: '0 0 1rem 0' }}
        >
          Create New Note
        </NewActivityButton>
        <NewActivityButton
          onClick={() => setDiaryVisibility(true)}
          style={{ margin: '0 0 1rem 1rem' }}
        >
          Create Diary Entry
        </NewActivityButton>
      </ButtonHeader>
      <Loader isFetching={isFetching}>
        <TabContent>
          {notes && notes.length > 0 ? (
            <InfiniteScroll
              dataLength={notes.length}
              next={fetchMore}
              hasMore={!!fetchMore}
            >
              {notes.map(note =>
                note.type === 'Diary' || note.model_key === 'diary_note' ? (
                  <DiaryActivityCard
                    key={`diary${note.id}`}
                    note={note}
                    edit={() => prepareModalDiary(note)}
                    deleteNote={() => prepareDeleteDiary(note)}
                    user={auth && auth.user ? auth.user : null}
                  />
                ) : (
                  <NoteActivityCard
                    key={note.id}
                    note={note}
                    edit={() => prepareModalNote(note)}
                    deleteNote={() => prepareDeleteNote(note)}
                    user={auth && auth.user ? auth.user : null}
                  />
                ),
              )}

              {fetchMore ? (
                isFetchingMore ? (
                  <LoadingMore>Loading...</LoadingMore>
                ) : (
                  <LoadingMore>Scroll to load more</LoadingMore>
                )
              ) : null}
            </InfiniteScroll>
          ) : (
            <LoadingMore>No notes created</LoadingMore>
          )}
        </TabContent>
        {modalVisible ? (
          <NoteModal
            isVisible={modalVisible}
            setModalVisibility={setModalVisibility}
            setNoteToEdit={setNoteToEdit}
            note={noteToEdit}
            candidate={id}
            user={auth && auth.user ? auth.user.id : null}
            submitResponse={submitResponse}
          />
        ) : (
          ''
        )}
        {DeleteModalVisible ? (
          <DeleteNoteModal
            note={noteToDelete}
            setNoteToDelete={setNoteToDelete}
            name={'This note'}
            isVisible={DeleteModalVisible}
            setModalVisibility={setDeleteModalVisible}
            submitResponse={submitResponse}
          />
        ) : (
          ''
        )}
        {diaryVisible && (
          <DiaryModal
            isVisible={diaryVisible}
            setModalVisibility={setDiaryVisibility}
            setDiaryToEdit={setDiaryToEdit}
            diary={diaryToEdit}
            candidate={id}
            submitResponse={submitResponseDiary}
          />
        )}
        {deleteDiaryVisible && (
          <DeleteDiaryModal
            diary={diaryToDelete}
            setDiaryToDelete={setDiaryToDelete}
            name={'This diary'}
            isVisible={deleteDiaryVisible}
            setModalVisibility={setDeleteDiaryVisible}
            submitResponse={submitResponseDiary}
          />
        )}
      </Loader>
    </>
  )
}

Notes.propTypes = {
  id: PropTypes.string.isRequired,
}
export default Notes
