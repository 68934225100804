import styled from 'styled-components'

export const Tag = styled.span`
  background-color: ${p => p.theme.color.grey_light};
  font-weight: 400;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: block;
  text-transform: capitalize;

  &:last-of-type {
    margin-right: 0;
  }
`

export const TagWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
`
