import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'
import _ from 'lodash'
import * as Yup from 'yup'

import {
  Error,
  Form,
  FormikText,
  FormikInput,
  FormikFileInput,
  Label,
} from '../../../form'
import { Button, ModalFooter } from '../../../ui'
import { handleBackEndErrors, jsonToFormData } from '../../../../utilities'
import { Formik } from 'formik'

const EshotModal = ({
  job,
  candidateShortlist,
  setSelectedCandidatesShortlist,
}) => {
  const [backEndErrors, setBackEndErrors] = React.useState({})
  const [preview, setPreview] = React.useState()
  const { addToast } = useToasts()

  const sendEshot = async (values, { setSubmitting }) => {
    const formatData = {
      job_id: job,
      candidates: candidateShortlist.map(can => {
        return can.id
      }),
      title: values?.title,
      body: values?.body,
      file: values?.file,
    }
    const newValues = jsonToFormData(formatData)
    await axios({
      method: 'post',
      url: 'v1/eshot',
      data: newValues,
    })
      .then(response => {
        addToast(
          response.data?.message
            ? response.data?.message
            : 'Sending e-shot to selected candidates',
          {
            appearance: 'success',
          },
        )
        setSelectedCandidatesShortlist([])
        setBackEndErrors({})
      })
      .catch(error => {
        setBackEndErrors(error?.response?.data?.error)
        addToast(`Error sending E-shot, please try again`, {
          appearance: 'error',
        })
      })
    setSubmitting(false)
  }

  const sendPreview = async values => {
    await axios({
      method: 'get',
      url: `v1/eshot/preview?job_id=${job}&title=${values?.title}&body=${values?.body}`,
    })
      .then(response => {
        setPreview(response.data)
        setBackEndErrors({})
      })
      .catch(error => {
        setBackEndErrors(error?.response?.data?.error)
        addToast(`Error sending E-shot preview, please try again`, {
          appearance: 'error',
        })
      })
  }

  return (
    <>
      <Formik
        initialValues={{ title: '', body: '', file: null }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Required'),
          body: Yup.string().required('Required'),
          file: Yup.array()
            .of(Yup.string())
            .nullable()
            .max(1, 'You can only have one document'),
        })}
        onSubmit={sendEshot}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <FormikInput label="Title" name="title" type="text" />
            <FormikText label="Description" name="body" />
            <FormikFileInput
              label="File upload"
              name="file"
              fileType={'.pdf'}
              maxSize={8}
            />
            <Label style={{ paddingBottom: '1rem', width: '100%' }}>
              File must be a PDF and not bigger than 8mb
            </Label>
            <Button
              type="submit"
              disabled={isSubmitting}
              style={{ marginRight: '1rem', marginBottom: '1rem' }}
            >
              Send E-shot
            </Button>
            <Button type="button" onClick={() => sendPreview(values)}>
              Preview E-shot
            </Button>
          </Form>
        )}
      </Formik>
      <ModalFooter>
        {_.isEmpty(backEndErrors) ? (
          ''
        ) : (
          <Error>{handleBackEndErrors(backEndErrors)}</Error>
        )}
      </ModalFooter>
      {preview && (
        <iframe
          style={{ width: '100%', height: '50vh' }}
          title="preview-eshot"
          srcDoc={preview}
          sandbox="allow-same-origin"
        ></iframe>
      )}
    </>
  )
}

EshotModal.defaultProps = {
  job: '',
  candidateShortlist: [],
}

EshotModal.propTypes = {
  job: PropTypes.number.isRequired,
  candidateShortlist: PropTypes.array,
  setSelectedCandidatesShortlist: PropTypes.func.isRequired,
}

export default EshotModal
