import React from 'react'
// import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import styled from 'styled-components'

import { AuthContext } from '../../context/auth'
import { ReactComponent as DashIcon } from '../../assets/icons/dashboard.svg'
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg'
import { ReactComponent as ClientIcon } from '../../assets/icons/client.svg'
import { ReactComponent as JobIcon } from '../../assets/icons/job.svg'
import { ReactComponent as ManagementIcon } from '../../assets/icons/management.svg'
import { ReactComponent as CVIcon } from '../../assets/icons/cv.svg'

const Nav = styled.nav`
  --header-height: ${p => p.theme.layout.header_height};
  --nav-height-mobile: ${p => p.theme.layout.nav_height_mobile};
  --nav-width-tablet: ${p => p.theme.layout.nav_width_tablet};
  --nav-width-web: ${p => p.theme.layout.nav_width_web};

  /* defaults */
  position: fixed;
  display: flex;
  background: white;
  left: 0;
  z-index: 2;

  /* mobile */
  bottom: 0;
  width: 100%;
  height: var(--nav-height-mobile);
  justify-content: space-between;
  box-shadow: ${p => p.theme.style.nav_and_header_shadow};

  /*  tablet */
  ${p => p.theme.layout.tablet} {
    flex-direction: column;
    width: var(--nav-width-tablet);
    top: var(--header-height);
    padding-top: 2rem;
    bottom: 0;
    height: unset;
    justify-content: flex-start;
    box-shadow: none;
  }

  /* web */
  ${p => p.theme.layout.web} {
    width: var(--nav-width-web);
  }
`

const SLink = styled(Link)`
  /* defaults */
  flex: 1;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  transition: border 200ms ease-in-out, background-color 200ms ease-in-out;

  &[data-active='true'] {
    background: ${p => p.theme.color.bg};
  }

  /* mobile */
  border-top: ${p => p.theme.utils.nav_border('transparent')};
  justify-content: center;

  &[data-active='true'] {
    border-top: ${p => p.theme.utils.nav_border()};
  }

  /* tablet */
  ${p => p.theme.layout.tablet} {
    max-height: ${p => p.theme.layout.nav_link_height_web};
    border-top: none !important;
    border-left: ${p => p.theme.utils.nav_border('transparent')};
    justify-content: flex-start;

    &[data-active='true'] {
      border-left: ${p => p.theme.utils.nav_border()};
    }
  }
`
const LinkText = styled.span`
  display: none;
  ${p => p.theme.layout.web} {
    display: inline-block;
  }
`

const IconWrapper = styled.div`
  svg {
    height: 25px;
    width: 25px;
    stroke: ${p => p.theme.color.primary};
    fill: ${p => p.theme.color.primary};
    ${p => p.theme.layout.tablet} {
      margin-left: 1.5rem;
      margin-right: 1rem;
    }
  }
`

// Reach Router: getProps func for styling active link
// adds data-active attribute when link matches isCurrent/isPartiallyCurrent type
const isActive = type => props => ({ 'data-active': props[type] })

const NavContainer = () => {
  const auth = React.useContext(AuthContext)
  const links =
    auth && auth.user && (auth.user.admin || auth.user.director)
      ? [
          {
            route: '/',
            name: 'Dashboard',
            match: 'isCurrent',
            icon: DashIcon,
          },
          {
            route: '/candidates',
            name: 'Candidates',
            match: 'isPartiallyCurrent',
            icon: UserIcon,
          },
          {
            route: '/clients',
            name: 'Clients',
            match: 'isPartiallyCurrent',
            icon: ClientIcon,
          },
          {
            route: '/jobs',
            name: 'Jobs',
            match: 'isPartiallyCurrent',
            icon: JobIcon,
          },
          {
            route: '/management',
            name: 'Management',
            match: 'isPartiallyCurrent',
            icon: ManagementIcon,
          },
          {
            route: '/cv-parser',
            name: 'CV Parser',
            match: 'isPartiallyCurrent',
            icon: CVIcon,
          },
        ]
      : auth?.user?.branch_manager
      ? [
          {
            route: '/',
            name: 'Dashboard',
            match: 'isCurrent',
            icon: DashIcon,
          },
          {
            route: '/candidates',
            name: 'Candidates',
            match: 'isPartiallyCurrent',
            icon: UserIcon,
          },
          {
            route: '/clients',
            name: 'Clients',
            match: 'isPartiallyCurrent',
            icon: ClientIcon,
          },
          {
            route: '/jobs',
            name: 'Jobs',
            match: 'isPartiallyCurrent',
            icon: JobIcon,
          },
          {
            route: '/management',
            name: 'Management',
            match: 'isPartiallyCurrent',
            icon: ManagementIcon,
          },
          {
            route: '/cv-parser',
            name: 'CV Parser',
            match: 'isPartiallyCurrent',
            icon: CVIcon,
          },
        ]
      : [
          {
            route: '/',
            name: 'Dashboard',
            match: 'isCurrent',
            icon: DashIcon,
          },
          {
            route: '/candidates',
            name: 'Candidates',
            match: 'isPartiallyCurrent',
            icon: UserIcon,
          },
          {
            route: '/clients',
            name: 'Clients',
            match: 'isPartiallyCurrent',
            icon: ClientIcon,
          },
          {
            route: '/jobs',
            name: 'Jobs',
            match: 'isPartiallyCurrent',
            icon: JobIcon,
          },
          {
            route: '/cv-parser',
            name: 'CV Parser',
            match: 'isPartiallyCurrent',
            icon: CVIcon,
          },
        ]
  return (
    <Nav>
      {links?.map(({ route, name, match, icon: Icon }) => (
        <SLink
          key={name}
          to={route}
          getProps={isActive(match)}
          title={name}
          aria-label={name}
        >
          <IconWrapper>
            <Icon />
          </IconWrapper>
          <LinkText>{name}</LinkText>
        </SLink>
      ))}
    </Nav>
  )
}

NavContainer.propTypes = {}
export default NavContainer
