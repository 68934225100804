import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import * as Yup from 'yup'
import styled from 'styled-components'

import { Button } from '../../ui'
import { FiltersContainer } from './style'
import { useClient } from '../../../hooks'
import { Formik } from 'formik'
import { Form, FormikInput, FormikSelect, TagSelector } from '../../form'

const FilterColumn = styled.div`
  display: grid;
  grid-gap: 0 1rem;
  grid-template-columns: 1fr;
  ${p => p.theme.layout.phone_medium} {
    grid-template-columns: 1fr 1fr;
  }
  ${p => p.theme.layout.web_small} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const FiltersC = ({ isVisible, searchFunction }) => {
  const {
    searchValue,
    // searchSkill,
    searchTag,
    searchTitle,
    searchHeadCount,
    searchLocation,
    updateSearch,
  } = useClient()

  React.useEffect(() => {
    searchFunction(searchValue)
  })

  const onSubmit = (values, actions) => {
    updateSearch(values)
    actions.setSubmitting(false)
  }

  return (
    <CSSTransition in={isVisible} timeout={200} classNames="fade" unmountOnExit>
      <FiltersContainer>
        <Formik
          initialValues={{
            name: searchValue ? searchValue : '',
            job_title: searchTitle ? searchTitle : '',
            // skills: searchSkill ? searchSkill : [],
            tags: searchTag ? searchTag : [],
            head_count: searchHeadCount ? searchHeadCount : '',
            location: searchLocation ? searchLocation : '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string(),
            job_title: Yup.string(),
            // skills: Yup.array(),
            tags: Yup.array(),
            head_count: Yup.string(),
            location: Yup.string(),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FilterColumn>
                <FormikInput
                  type="text"
                  name="name"
                  label="Name"
                  placeholder="Search name"
                />
                <FormikInput
                  type="text"
                  name="job_title"
                  label="Job title"
                  placeholder="Search job title"
                />
                {/* <TagSelector name="skills" label="Skills" placeholder="Search skills" noOptionsMessage={() => 'Type a comma at the end to input a skill'} /> */}
                <TagSelector
                  name="tags"
                  label="Tags"
                  placeholder="Search tags"
                  noOptionsMessage={() =>
                    'Type a comma at the end to input a tag'
                  }
                />
                <FormikSelect
                  name="head_count"
                  label="Temp Head Count"
                  placeholder="Search head count"
                  options={[
                    {
                      value: '',
                      label: 'All',
                    },
                    {
                      value: '1-5',
                      label: '1-5',
                    },
                    {
                      value: '5-15',
                      label: '5-15',
                    },
                    {
                      value: '15-30',
                      label: '15-30',
                    },
                    {
                      value: '30-50',
                      label: '30-50',
                    },
                    {
                      value: '50-75',
                      label: '50-75',
                    },
                    {
                      value: '100',
                      label: '100+',
                    },
                  ]}
                />
                <FormikInput
                  type="text"
                  name="location"
                  label="Location"
                  placeholder="Search location"
                />
              </FilterColumn>
              <Button type="submit" disabled={isSubmitting}>
                Search
              </Button>
            </Form>
          )}
        </Formik>
      </FiltersContainer>
    </CSSTransition>
  )
}

FiltersC.propTypes = {
  searchFunction: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
}

export default FiltersC
