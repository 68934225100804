import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'

import useGetJobActivity from './useGetJobActivity'
import {
  Loader,
  TabContent,
  JobActivityCardCandidate,
  LoadingMore,
} from '../../../ui'
import { toCsv, pivot } from '../../../../utilities'

export const CSVButton = styled(CSVLink)`
  display: inline-block;
  background-color: ${p => p.theme.color.primary_button};
  border-radius: 1.25rem;
  font: 600 1rem/1rem proxima-nova, sans-serif;
  padding: 0.75rem 1.5rem;
  color: white;
  cursor: pointer;
  line-height: 1rem;
  border: none;
  transition: background-color 200ms ease-in-out;
  outline: none;
  text-decoration: none;
  margin-left: auto;

  &:focus {
    background-color: ${p => p.theme.color.primary_dark};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${p => p.theme.color.primary_dark};
    }
  }

  &:disabled {
    background: lightgrey;
  }
`

const CSVWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`

const JobActivity = ({ id }) => {
  const [formattedCSV, setFormattedCSV] = React.useState([])
  const { activity, isFetching, fetchMore, isFetchingMore } = useGetJobActivity(
    id,
  )

  React.useEffect(() => {
    if (activity?.length >= 1) {
      setFormattedCSV(toCsv(pivot(activity)))
    }
  }, [activity])

  return (
    <Loader isFetching={isFetching}>
      <TabContent>
        {activity && activity.length > 0 ? (
          <>
            <CSVWrapper>
              <CSVButton data={formattedCSV} disabled={activity.length === 0}>
                Export as CSV
              </CSVButton>
            </CSVWrapper>
            <InfiniteScroll
              dataLength={activity.length}
              next={fetchMore}
              hasMore={!!fetchMore}
            >
              {activity.map(job => (
                <JobActivityCardCandidate key={job.id} job={job} />
              ))}
              {fetchMore ? (
                isFetchingMore ? (
                  <LoadingMore>Loading...</LoadingMore>
                ) : (
                  <LoadingMore>Scroll to load more</LoadingMore>
                )
              ) : null}
            </InfiniteScroll>
          </>
        ) : (
          <LoadingMore>Candidate has not been placed in any jobs</LoadingMore>
        )}
      </TabContent>
    </Loader>
  )
}

JobActivity.propTypes = {
  id: PropTypes.string.isRequired,
}
export default JobActivity
