import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'

import Layout from '../layout'
import useGetUsers from './useGetUsers'
import { AuthContext } from '../../context/auth'
import { DeleteUserModal } from './delete'
import { EmailUserModal } from './email'
import { DirectorUserModal } from './director'
import { InviteUserModal } from './inviteUser'
import { Table, Button, DeleteButton, Title, ListHeader } from '../ui'

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${p => p.theme.layout.phone_medium} {
    justify-content: flex-end;
  }
`

const SButton = styled(Button)`
  margin-right: 1rem;
`

const STR = styled.tr`
  display: grid;
  width: 100%;
  ${p => p.theme.layout.phone_medium} {
    display: table-row;
  }
`

export const UserList = () => {
  const { isFetching, data, fetchMore, setUsers } = useGetUsers()
  const auth = React.useContext(AuthContext)

  const [modalVisible, setModalVisibility] = React.useState(false)
  const [emailModalVisible, setEmailModalVisibility] = React.useState(false)
  const [inviteUserVisible, setInviteUserVisibility] = React.useState(false)
  const [directorModalVisible, setDirectorModalVisibility] = React.useState(
    false,
  )
  const [selectedUser, setSelectedUser] = React.useState({})

  const openModel = user => {
    setSelectedUser(user)
    setModalVisibility(true)
  }

  const closeModel = () => {
    setModalVisibility(false)
    setSelectedUser({})
  }

  const openEmailModel = user => {
    setSelectedUser(user)
    setEmailModalVisibility(true)
  }

  const closeEmailModel = () => {
    setEmailModalVisibility(false)
    setSelectedUser({})
  }

  const openDirectModel = user => {
    setSelectedUser(user)
    setDirectorModalVisibility(true)
  }

  const closeDirectorModel = () => {
    setDirectorModalVisibility(false)
    setSelectedUser({})
  }

  const addUser = user => {
    setUsers(u => [user, ...u])
  }

  const deleteUser = user => {
    if (data.includes(user)) {
      setUsers(currentUser =>
        currentUser.filter(activeFilter => activeFilter !== user),
      )
    }
  }

  const updateUser = user => {
    setUsers(users =>
      users.map(currentUser =>
        currentUser.id === user.id ? user : currentUser,
      ),
    )
  }

  return (
    <>
      <Layout isFetching={isFetching}>
        <ListHeader>
          <Title style={{ marginBottom: '1rem' }}>Users</Title>
          <Button onClick={() => setInviteUserVisibility(true)}>
            Invite user
          </Button>
        </ListHeader>
        {data && data.length > 0 && (
          <InfiniteScroll
            dataLength={data && data.length}
            next={fetchMore}
            hasMore={!!fetchMore}
          >
            <Table>
              <thead>
                <tr>
                  <th data-tablet>Name</th>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.length &&
                  data.map(user => (
                    <STR key={user.id}>
                      <td data-tablet>{user.name}</td>
                      <td>{user.email}</td>
                      <td>
                        <ButtonContainer>
                          {user.id === auth.user.id ? null : (
                            <>
                              {!user.has_logged_in ? (
                                <SButton onClick={() => openEmailModel(user)}>
                                  Resend email
                                </SButton>
                              ) : user.has_logged_in && !user.director ? (
                                <SButton onClick={() => openDirectModel(user)}>
                                  Assign Director
                                </SButton>
                              ) : user.has_logged_in && user.director ? (
                                <DeleteButton
                                  onClick={() => openDirectModel(user)}
                                >
                                  Unassign Director
                                </DeleteButton>
                              ) : null}
                              {!user.director ? (
                                <DeleteButton onClick={() => openModel(user)}>
                                  Delete
                                </DeleteButton>
                              ) : null}
                            </>
                          )}
                        </ButtonContainer>
                      </td>
                    </STR>
                  ))}
              </tbody>
            </Table>
          </InfiniteScroll>
        )}
      </Layout>
      {modalVisible ? (
        <DeleteUserModal
          id={selectedUser.id}
          name={selectedUser.name}
          isVisible={modalVisible}
          setModalVisibility={closeModel}
          userDelete={() => deleteUser(selectedUser)}
        />
      ) : (
        ''
      )}
      {emailModalVisible ? (
        <EmailUserModal
          email={selectedUser.email}
          isVisible={emailModalVisible}
          setModalVisibility={closeEmailModel}
        />
      ) : (
        ''
      )}
      {inviteUserVisible ? (
        <InviteUserModal
          isVisible={inviteUserVisible}
          onClose={() => setInviteUserVisibility(false)}
          addUser={addUser}
        />
      ) : (
        ''
      )}
      {directorModalVisible ? (
        <DirectorUserModal
          user={selectedUser}
          isVisible={directorModalVisible}
          setModalVisibility={closeDirectorModel}
          updateUser={updateUser}
        />
      ) : (
        ''
      )}
    </>
  )
}
