import React from 'react'
import { navigate } from '@reach/router'
import onClickOutside from 'react-onclickoutside'

import { useAuth } from '../../hooks'
import { AuthContext } from '../../context/auth'
import { ReactComponent as Logo } from '../../assets/pinpoint_logo.svg'
import { Avatar } from '../ui'
import {
  DropDown,
  DropDownButton,
  DropDownIcon,
  DropDownOptions,
  Header,
} from './style'

const HeaderContainer = () => {
  const { logout } = useAuth()
  const auth = React.useContext(AuthContext)
  const [dropDownVisible, setDropDownVisibility] = React.useState(false)

  const GoToEditAccount = () => {
    setDropDownVisibility(false)
    navigate('/edit-account')
  }

  const GoToAdminView = () => {
    setDropDownVisibility(false)
    navigate('/admin')
  }

  const GoToBranchesView = () => {
    setDropDownVisibility(false)
    navigate('/branches')
  }

  const GoToBranchMangerView = id => {
    setDropDownVisibility(false)
    navigate(`/branches/${id}`)
  }

  HeaderContainer.handleClickOutside = () => setDropDownVisibility(false)
  return (
    <>
      <Header>
        <Logo height="48" />
        <DropDown>
          <DropDownButton
            onClick={() => setDropDownVisibility(!dropDownVisible)}
          >
            <Avatar name={auth && auth.user ? auth.user.name : ''} />
            <DropDownIcon />
          </DropDownButton>
          <DropDownOptions active={dropDownVisible}>
            <li>
              <button
                tabIndex={dropDownVisible ? 0 : -1}
                onClick={GoToEditAccount}
              >
                Edit Account
              </button>
            </li>
            {auth && auth.user && auth.user.admin ? (
              <>
                <li>
                  <button
                    tabIndex={dropDownVisible ? 0 : -1}
                    onClick={GoToAdminView}
                  >
                    Admin
                  </button>
                </li>
                <li>
                  <button
                    tabIndex={dropDownVisible ? 0 : -1}
                    onClick={() => GoToBranchesView()}
                  >
                    Branches
                  </button>
                </li>
              </>
            ) : null}
            {auth?.user &&
            !auth.user.admin &&
            (auth.user.branch_manager || auth.user.director) ? (
              <>
                <li>
                  <button
                    tabIndex={dropDownVisible ? 0 : -1}
                    onClick={() => GoToBranchMangerView(auth.user.branch_id)}
                  >
                    Branches
                  </button>
                </li>
              </>
            ) : null}
            <li>
              <button onClick={logout} tabIndex={dropDownVisible ? 0 : -1}>
                Logout
              </button>
            </li>
          </DropDownOptions>
        </DropDown>
      </Header>
    </>
  )
}

HeaderContainer.prototype = {}

const clickOutsideConfig = {
  handleClickOutside: () => HeaderContainer.handleClickOutside,
}

export default onClickOutside(HeaderContainer, clickOutsideConfig)
