import { useState } from 'react'
import axios from 'axios'

const useGetBranches = () => {
  const [branches, setBranches] = useState([])
  const [backEndErrors, setBackEndErrors] = useState({})

  const getBranchesData = async () => {
    axios({
      method: 'get',
      url: `v1/branches`,
    })
      .then(response => {
        if (response.status === 200) {
          setBranches(response.data)
        } else {
          setBackEndErrors({
            error: 'Error receiving management data, please try again',
            appearance: 'error',
          })
        }
      })
      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
        }
      })
  }

  return { branches, setBranches, getBranchesData, backEndErrors }
}
export default useGetBranches
