import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Error } from '../'

export const Wrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  outline: none;
  cursor: pointer;

  &:focus {
    .check {
      background: ${p => p.theme.color[p.isChecked ? 'primary' : 'border']};
    }
  }

  @media (hover: hover) {
    &:hover {
      .check {
        background: ${p => p.theme.color[p.isChecked ? 'primary' : 'border']};
      }
    }
  }
`

const Label = styled.label`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.25px;
  color: ${p => p.theme.color.text};
`

export const Checkbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 0.25rem;
  border: 1px solid ${p => p.theme.color[p.isChecked ? 'primary' : 'border']};
  margin-right: 1rem;
  background: ${p =>
    p.theme.color[p.isChecked ? 'primary' : p.disabled ? 'border' : 'white']};
  transition: background-color 200ms ease-in-out, border 200ms ease-in-out;
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
`

export const Check = styled.svg`
  width: 14px;
  height: 11px;
  stroke: #fff;
  stroke-width: 2;
  fill: none;
  stroke-linecap: 'square';
  opacity: ${p => (p.isChecked ? 1 : 0)};
  transition: opacity 200ms ease-in-out;
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
  cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
`

export const CheckboxWithoutLabel = ({
  name,
  value,
  onChange,
  children,
  errorText,
  disabled,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(value)

  React.useEffect(() => {
    if (value !== isChecked) setIsChecked(value)
  }, [value, isChecked])

  const update = newValue => {
    onChange(newValue)
    setIsChecked(newValue)
  }

  const handleKeyDown = (e, isChecked) => {
    const keys = [13]
    if (keys.includes(e.keyCode)) {
      update(!isChecked)
    }
  }

  return (
    <div {...props}>
      <Wrapper
        onClick={() => update(!isChecked)}
        tabIndex={0}
        onKeyDown={e => handleKeyDown(e, isChecked)}
        isChecked={isChecked}
        disabled={disabled}
      >
        <Checkbox isChecked={isChecked} disabled={disabled} className="check">
          <Check
            width="14"
            height="11"
            xmlns="http://www.w3.org/2000/svg"
            isChecked={isChecked}
            role="checkbox"
            aria-checked={isChecked}
            aria-labelledby={name}
            disabled={disabled}
          >
            <path d="M12.25 1.75L5.0472 8.9528 2.25 6.1556" />
          </Check>
        </Checkbox>
        {children}
      </Wrapper>
      {errorText && <Error>{errorText}</Error>}
    </div>
  )
}

export const CheckboxWithLabel = ({
  name,
  label,
  value,
  onChange,
  errorText,
  ...props
}) => (
  <>
    <CheckboxWithoutLabel
      name={name}
      value={value}
      onChange={onChange}
      {...props}
    >
      <Label id={name}>{label}</Label>
    </CheckboxWithoutLabel>
    {errorText && <Error>{errorText}</Error>}
  </>
)

CheckboxWithLabel.defaultProps = {
  value: false,
}

CheckboxWithoutLabel.defaultProps = {
  value: false,
  disabled: false,
  children: '',
}

CheckboxWithLabel.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOf([true, false, 0, 1]),
  onChange: PropTypes.func.isRequired,
}

CheckboxWithoutLabel.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOf([true, false, 0, 1]),
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
