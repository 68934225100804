import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import { Modal, ModalFooter, ModalColumns, Button } from '../../../ui'
import { Form, FormikInput, FormikText, Error } from '../../../form'
import { dateRegex } from '../../../../constants'
import { handleBackEndErrors } from '../../../../utilities'

export const UpdatePlacementModal = ({
  candidate,
  job_id,
  isVisible,
  setModalVisibility,
}) => {
  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})

  const onSubmit = async (values, { setSubmitting }) => {
    console.log(values)
    axios({
      method: 'post',
      url: '/v1/candidates/jobs/placement-details',
      data: values,
    })
      .then(response => {
        setModalVisibility(false)
        addToast(`Placement updated successfully`, {
          appearance: 'success',
        })
      })
      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
          addToast(`Error updating placement, please try again`, {
            appearance: 'error',
          })
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(`Error updating placement,, please try again`, {
            appearance: 'error',
          })
        }
      })

    setSubmitting(false)
  }
  return (
    <Modal
      isVisible={isVisible}
      heading="Update Placement Details"
      onClose={() => setModalVisibility(false)}
      size="small"
    >
      <Formik
        initialValues={{
          candidate_id: candidate.id ? candidate.id : '',
          job_id: job_id ? job_id : '',
          start_date: '',
          end_date: '',
          end_reason: candidate.end_reason ? candidate.end_reason : '',
        }}
        validationSchema={Yup.object().shape({
          start_date: Yup.string()
            .matches(dateRegex, 'Must be a valid date in the format dd/mm/yyyy')
            .required('Required'),
          end_date: Yup.string().matches(
            dateRegex,
            'Must be a valid date in the format dd/mm/yyyy',
          ),
          end_reason: Yup.string(),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalColumns>
              <FormikInput label="Start Date" name="start_date" />
              <FormikInput label="End Date" name="end_date" />
            </ModalColumns>
            <FormikText name="end_reason" label="Reason" />
            <ModalFooter>
              {_.isEmpty(backEndErrors) ? (
                ''
              ) : (
                <Error>{handleBackEndErrors(backEndErrors)}</Error>
              )}
              <Button type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

UpdatePlacementModal.propTypes = {
  candidate: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
}
