import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import _ from 'lodash'
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

import { Modal, ModalFooter, Button, PaddedDivider } from '../../../ui'
import {
  FormikText,
  FormikInput,
  Form,
  FormikFileInput,
  Error,
} from '../../../form'
import { createFormData, handleBackEndErrors } from '../../../../utilities'

const FileUploadContainer = styled.div`
  min-height: 151px;
`
const FileModal = ({
  isVisible,
  file,
  setModalVisibility,
  candidate,
  submitResponse,
  setFileToEdit,
}) => {
  const newFile = _.isEmpty(file)

  const { addToast } = useToasts()
  const [backEndErrors, setBackEndErrors] = React.useState({})

  const onSubmit = async (values, { setSubmitting }) => {
    const newValues = createFormData(values)
    axios({
      method: 'post',
      url: newFile
        ? 'v1/candidates/media/create'
        : 'v1/candidates/media/update',
      data: newValues,
    })
      .then(response => {
        submitResponse(response.data, newFile ? 'add' : 'edit')
        addToast(`File ${newFile ? 'created' : 'updated'} successfully`, {
          appearance: 'success',
        })
        setModalVisibility(false)
        setFileToEdit({})
      })

      .catch(error => {
        if (error.response.status === 422) {
          setBackEndErrors(error.response.data.errors)
        } else {
          setBackEndErrors(error.response.data.error)
          addToast(
            `Error ${newFile ? 'creating' : 'updating'} file, please try again`,
            {
              appearance: 'error',
            },
          )
        }
      })

    setSubmitting(false)
  }

  return (
    <Modal
      isVisible={isVisible}
      heading={newFile ? 'Add New File' : 'Update File'}
      onClose={() => {
        setModalVisibility(false)
        setFileToEdit({})
      }}
    >
      <Formik
        initialValues={{
          candidate_id: candidate ? candidate : null,
          media_id: file && file.id ? file.id : null,
          file: file && file.file ? file.file : null,
          title: file && file.title ? file.title : '',
          description: file && file.description ? file.description : '',
        }}
        validationSchema={Yup.object().shape({
          file: Yup.mixed(),
          title: Yup.string().required('Required'),
          description: Yup.string().required('Required'),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormikInput label="Title" name="title" />
            <FormikText name="description" label="Description" rows={5} />
            <FileUploadContainer>
              <FormikFileInput label="Attach a file" name="file" />
            </FileUploadContainer>
            {_.isEmpty(backEndErrors) ? (
              ''
            ) : (
              <>
                <PaddedDivider />
                <Error>{handleBackEndErrors(backEndErrors)}</Error>
              </>
            )}
            <ModalFooter>
              <Button type="submit" disabled={isSubmitting}>
                {newFile ? 'Save' : 'Save changes'}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

FileModal.defaultProps = {
  file: {},
}

FileModal.propTypes = {
  file: PropTypes.object,
  isVisible: PropTypes.bool.isRequired,
  setModalVisibility: PropTypes.func.isRequired,
  candidate: PropTypes.string.isRequired,
  submitResponse: PropTypes.func.isRequired,
  setFileToEdit: PropTypes.func.isRequired,
}

export default FileModal
